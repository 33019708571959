import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
// import vegatheme
import "./Styles/vegatheme.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// navigation router
import { BrowserRouter } from "react-router-dom";

// Notification
import { SnackbarProvider } from "notistack";
import Fade from "@mui/material/Fade";

// Redux
import { Provider } from "react-redux";
import { store, persistor } from "./Components/Store";
import { PersistGate } from "redux-persist/integration/react";

// keyboard shortcuts
import CustomGlobalHotKeys from "../src/Components/KeyboardShortcuts";

// sentry
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";

const { REACT_APP_SENTRY_SUPPORT } = process.env;

if (REACT_APP_SENTRY_SUPPORT === "true") {
  Sentry.init({
    dsn: "https://dd932e8fb09c4065addbe2fb5fff8ff0@sentry.peerxp.com/6",
    integrations: [new Integrations.BrowserTracing()],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
}

const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <SnackbarProvider maxSnack={3} TransitionComponent={Fade}>
          <CustomGlobalHotKeys>
            <MsalProvider instance={msalInstance}>
              <App />
            </MsalProvider>
          </CustomGlobalHotKeys>
        </SnackbarProvider>
      </PersistGate>
    </Provider>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
