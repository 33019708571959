import { useEffect } from "react";
import { get } from "../Components/AxiosUtilities";
// import redux
import { connect } from "react-redux";
// import urls
import { account_user_data_url } from "../Components/Urls";
import { withRouter } from "react-router-dom";

// get value from .env file
// const { REACT_APP_DEPLOYMENT_CROSS_DOMAIN } = process.env;

function RedirectAuth(props) {

  const { history, setUserAuth } = props;

  useEffect(() => {
    get(account_user_data_url)
      .then((res) => {
        console.log(res);

        // this.setCookie('email', this.state.email, 90);
        let user_data = res.data.user_data;

        // set user data in react redux
        setUserAuth(user_data);

        history.push("/profile/personal")
      })
      .catch((err) => {
        console.log(err);

        if (err.response && err.response.status === 403) {
          history.push({
            pathname: "/",
          });
        }
      });
  }, [history, setUserAuth]);


  // return jsx elements
  return (
    <div className="flex h-screen">
      <div className="m-auto">
        <div className="flex items-center justify-center ">
          <div className="w-32 h-32 border-t-2 border-b-2 border-blue-500 rounded-full animate-spin"></div>
        </div>
        <p className="mt-4 text-center text-gray-800 dark:text-white">
          Securely logging you in...
        </p>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => {
  return {
    // dispatching plain actions
    setUserAuth: (data) => dispatch({ type: "setUserAuth", ...data }),
    setOrganizationUID: (data) => dispatch({ type: "setOrganizationUID", ...data }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RedirectAuth));
