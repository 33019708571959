// // import react icons
// import { RiCloseLine } from "react-icons/ri";
// // import tooltip component
// import Tooltip from "../Components/Tooltip";
// // import modal component
// import Modal from "../Components/Modal";
// // import react component
// import { useEffect, useState } from "react";
// // import feedback component
// import Feedback from "../Pages/Feedback";
// // import version from package.json
// var package_version = require("../../package.json");

// export default function Footer() {
//   // feedback modal state
//   const [ShowFeedBackModal, setShowFeedBackModal] = useState(false);

//   //  close the modal using the "ESC" key1
//   useEffect(() => {
//     const close = (e) => {
//       if (e.keyCode === 27) {
//         setShowFeedBackModal(false);
//       }
//     };
//     window.addEventListener("keydown", close);
//     return () => window.removeEventListener("keydown", close);
//   }, []);

//   return (
//     // <footer className="w-full">
//     //   <div className="px-4 py-2 mx-auto xs:py-3 max-full sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
//     //     <div className="flex justify-center space-x-6 md:order-2">
//     //       <span
//     //         className="cursor-pointer"
//     //         onClick={(e) => {
//     //           e.preventDefault();
//     //           setShowFeedBackModal(true);
//     //         }}
//     //       >
//     //         <Tooltip placement="top" trigger="hover" tooltip={"Feedback"}>
//     //           <ChatAltIcon className="w-5 h-5 text-gray-600 fill-current xs:h-4 xs:w-4 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white" />
//     //         </Tooltip>
//     //       </span>

//         // <Modal
//         //     open={ShowFeedBackModal}
//         //     heading={
//         //       <div className="flex justify-between mb-4">
//         //         <p className="text-gray-900 dark:text-gray-300">
//         //           VegaMaster Feedback
//         //         </p>

//         //         <button
//         //           type="button"
//         //           className="closemodalicon"
//         //           onClick={() => setShowFeedBackModal(false)}
//         //         >
//         //           <span className="sr-only">Close</span>
//         //           <RiCloseLine className="w-6 h-6" aria-hidden="true" />
//         //         </button>
//         //       </div>
//         //     }
//         //   >
//         //     <Feedback setShowFeedBackModal={setShowFeedBackModal} />
//         //   </Modal>

//           // {/* END FEEDBACK MODAL SECTION */}

//           // {/* {navigation.map((item) => (
//                         // <a key={item.name} href={item.href} className="text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white">
//                         //     <span className="sr-only">{item.name}</span>
//                         //     <item.icon className="w-6 h-6" aria-hidden="true" />
//                         // </a>
//                     // ))} */}

//     //       <p className="text-sm text-gray-600 xs:text-xs dark:text-gray-300">
//     //         v {package_version.version ? package_version.version : "0.0"}
//     //       </p>
//     //     </div>
//     //     <div className="mt-4 md:mt-0 md:order-1">
//     //       <p className="text-sm text-center text-gray-600 xs:text-xs dark:text-gray-300">
//     //         &copy; {`${new Date().getFullYear()}`} VegaStack - PeerXP
//     //       </p>
//     //     </div>
//     //   </div>
//     // </footer>
//   );
// }

import React from "react";

const Footer = () => {
  return <div></div>;
};

export default Footer;
