// import react components
import React, { useEffect, useState } from "react";
// import react router components
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Card from "../../Components/Card";
import { account_profile_url } from "../../Components/Urls";
import { get, put } from "../../Components/AxiosUtilities";
import { useSnackbar } from "notistack";

function EmailSettings(props) {
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const close = (e) => {
      if (e.ctrlKey && (e.keyCode === 10 || e.keyCode === 13)) {
        document.getElementById("submit").click();
      }
    };

    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);

  // email settings section
  const emailSettingsOptions = [
    {
      label: "Receive all emails, except those I unsubscribe from.",
      value: false,
      description:
        "we'll occasionally contact you with the latest news and happenings from the GitHub Universe.",
    },
    {
      label: "Only receive account related emails, and those I subscribe to.",
      value: false,
      description:
        "we'll only send you legal or administrative emails, and any emails you're specifically subscribed to.",
    },
  ];
  // email settings section ends

  const [selectedEmailSettings, setSelectedEmailSettings] =
    useState(emailSettingsOptions);

  const [settingsData, setSettingsData] = React.useState({});

  useEffect(() => {
    get(account_profile_url)
      .then((res) => {
        // console.log("email settings res", res.data);

        let settings = res.data.settings;

        setSettingsData(settings);

        // check settings has email_settings key
        if (settings.hasOwnProperty("email_settings")) {
          // if label matches return value as true
          // let values = selectedEmailSettings.map((emailOption) => {
          //   if (emailOption.label === settings.email_settings) {
          //     return {
          //       ...emailOption,
          //       value: true
          //     };
          //   }
          //   // else return value as false
          //   return {
          //     ...emailOption,
          //     value: false
          //   }
          // })
          setSelectedEmailSettings((prevState) =>
            prevState.map((emailOption) => {
              if (emailOption.label === settings.email_settings) {
                return {
                  ...emailOption,
                  value: true,
                };
              }
              // else return value as false
              return {
                ...emailOption,
                value: false,
              };
            })
          );
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const profileSubmitHandler = (e) => {
    e.preventDefault();

    let email_settings = selectedEmailSettings.filter(
      (option) => option.value === true
    );

    console.log(email_settings);

    put(account_profile_url, {
      settings: {
        ...settingsData,
        email_settings: email_settings[0].label,
      },
    })
      .then((res) => {
        console.log(res.data);

        let settings = res.data.settings;
        // check settings has email_settings key
        if (settings && settings.hasOwnProperty("email_settings")) {
          // if label matches return value as true
          let values = selectedEmailSettings.map((emailOption) => {
            if (emailOption.label === settings.email_settings) {
              return emailOption;
            }
            // else return value as false
            return {
              ...emailOption,
              value: false,
            };
          });
          setSelectedEmailSettings(values);
        }

        enqueueSnackbar("Preferences updated successfully.", {
          variant: "success",
          autoHideDuration: 3000,
        });
      })
      .catch((err) => {
        console.log(err);

        enqueueSnackbar("An error occured while updating preferences.", {
          variant: "error",
          autoHideDuration: 3000,
        });
      });
  };

  return (
    <>
      <Card
        id="email_settings_div"
        heading="Email Settings"
        description="Manage your preferences for email notifications settings."
      >
        <form onSubmit={profileSubmitHandler}>
          {selectedEmailSettings.map((option, index) => {
            return (
              <div
                className="hover:bg-gray-200 dark:hover:bg-gray-600 hover:cursor-pointer px-5 py-4"
                key={index}
              >
                <label className="flex items-center xs:items-start md:items-start align-middle cursor-pointer">
                  <input
                    type="radio"
                    name="email_settings"
                    className="radio xs:mt-1.5 md:mt-1.5"
                    checked={option.value}
                    onChange={(e) => {
                      setSelectedEmailSettings(
                        selectedEmailSettings.map((emailSettingsOption, i) => {
                          if (index === i) {
                            return {
                              ...emailSettingsOption,
                              value: e.target.checked,
                            };
                          }
                          return {
                            ...emailSettingsOption,
                            value: false,
                          };
                        })
                      );
                    }}
                  />
                  <span className="ml-4 text-gray-900 font-weight-bold dark:text-gray-300">
                    {option.label}
                  </span>
                </label>

                <p className="mt-0.5 ml-8 text-sm text-gray-1500 dark:text-gray-3000">
                  {option.description}
                </p>
              </div>
            );
          })}

          <div className="px-5 pt-5">
            <button id="submit" type="submit" className="button button-success">
              Save Email Setings
            </button>
          </div>
        </form>
      </Card>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setTimeZone: (data) => dispatch({ type: "setTimeZone", ...data }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EmailSettings));
