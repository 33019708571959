// import axios
import { post } from "../Components/AxiosUtilities";
// import notfication alert
import { useSnackbar } from "notistack";
// import urls
import { verify_email_url } from "../Components/Urls";
// import react components
import { useState, useEffect } from "react";
// import use previous function
import { setInitialTheme } from "../Components/utilities";
// import react router component
import { Link, withRouter } from "react-router-dom";
// import vegamaster logo component
import VegastackLogo from "../Components/VegastackLogo";
// import redux
import { connect } from "react-redux";
// import vegatheme
import "../Styles/vegatheme.css";
// import main css file
import "../Styles/main.css";

// set theme function
setInitialTheme();

function VerifyEmail(props) {
  // get uid and token from url
  const uid = props.match.params.uid;
  const token = props.match.params.token;

  const [loading, setLoading] = useState(true);
  const [showError, setShowError] = useState(false);

  // show alert and close alert functions
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { history } = props;

  useEffect(() => {
    const checkTokenValid = () => {
      post(verify_email_url, { uid: uid, token: token })
        .then((res) => {
          setLoading(false);
        })
        .catch((err) => {
          // console.log('error', err.response);
          setShowError(true);

          setLoading(false);
          if (err.response && err.response.data.message) {
            enqueueSnackbar(err.response.data.message, {
              variant: "error",
              autoHideDuration: 3000,
            });
          } else {
            enqueueSnackbar("Invalid or Expired Link", {
              variant: "error",
              autoHideDuration: 3000,
            });
          }
        });
    };

    checkTokenValid();
  }, [uid, token, enqueueSnackbar, history, closeSnackbar]);

  // return jsx elements
  return (
    <>
      {loading && (
        <div className="flex h-screen">
          <div className="m-auto">
            <div className="flex items-center justify-center ">
              <div className="w-32 h-32 border-t-2 border-b-2 border-blue-500 rounded-full animate-spin"></div>
            </div>
            <p className="mt-4 text-center text-gray-800 dark:text-white">
              Verifying your email...
            </p>
          </div>
        </div>
      )}

      {!loading && (
        <div className="flex flex-col justify-center min-h-screen py-12 bg-gray-50 dark:bg-gray-900 sm:px-6 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <VegastackLogo className="ap-logo" />

            {/* <h2 className="ap-heading">Email Verified</h2> */}
          </div>

          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div className="ap-card">
              <div className="space-y-6">
                {!showError && (
                  <>
                    <div className="text-center">
                      <h1 className="text-lg text-gray-900 dark:text-gray-300">
                        Email Verified
                      </h1>
                      <p className="text-base text-gray-1500 dark:text-gray-3000">
                        Your email is successfully verified.
                      </p>
                    </div>

                    <a
                      href="/profile/email-address"
                      className={`w-full flex justify-center py-2 px-4 border border-transparent rounded shadow-sm text-sm font-medium
                                           text-white bg-green-600 hover:bg-green-700 focus:ring-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2  dark:focus:ring-offset-gray-800 focus:ring-offset-white `}
                    >
                      Continue
                    </a>
                  </>
                )}

                {showError && (
                  <>
                    <div className="text-center">
                      <h1 className="text-lg text-gray-900 dark:text-gray-300">
                        Email Not Verified
                      </h1>
                      <p className="text-base text-gray-1500 dark:text-gray-3000">
                        Your email was not verified.
                      </p>
                    </div>

                    <a
                      href="/profile/email-address"
                      className={`w-full flex justify-center py-2 px-4 border border-transparent rounded shadow-sm text-sm font-medium
                                            text-white bg-indigo-500 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2  dark:focus:ring-offset-gray-800 focus:ring-offset-white focus:ring-blue-500`}
                    >
                      Verify Again
                    </a>
                  </>
                )}
              </div>

              <div className="mt-6">
                <div className="relative">
                  <div className="absolute inset-0 flex items-center">
                    <div className="w-full" />
                  </div>
                  <div className="relative flex justify-center text-sm">
                    <Link to="/" className="ap-links">
                      Go Back
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps)(withRouter(VerifyEmail));
