// import react component
import React, { useRef, useEffect } from "react";
// import title component
import TitleComponent from "../Components/TitleComponent";
// import jquery
import $ from "jquery";
// import react responsive
import { useMediaQuery } from "react-responsive";
// import cryptojs package
import CryptoJS from "crypto-js";
import {
  RiBookOpenLine,
  RiFacebookCircleFill,
  RiFileTextLine,
  RiInstagramLine,
  RiLinkedinFill,
  RiNewspaperLine,
  RiQuestionnaireLine,
  RiServiceLine,
  RiTv2Line,
  RiTwitterLine,
  RiYoutubeFill,
  RiPulseLine,
  RiUser3Line,
  RiShieldKeyholeLine,
  RiSettings3Line,
  RiLayoutMasonryLine
} from "react-icons/ri";

// total rows in table
export const per_page = 14;

// function to get previous props or state
export default function usePrevious(value) {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

// set initial theme
export function setInitialTheme() {
  // if theme is not in localstorage or system default color is dark
  if (
    !("theme" in localStorage) &&
    window.matchMedia("(prefers-color-scheme: dark)").matches
  ) {
    // add classname as dark in html tag
    document.documentElement.classList.add("dark");
    // save theme mode as system in local storage
    localStorage.setItem("theme", "system");
  }
  // if theme is in local storage
  else if ("theme" in localStorage) {
    // if theme is light
    if (localStorage.theme === "light") {
      document.documentElement.classList.remove("dark");
      localStorage.setItem("theme", "light");
    }
    // if theme is dark
    else if (localStorage.theme === "dark") {
      document.documentElement.classList.add("dark");
      localStorage.setItem("theme", "dark");
    }
    // if theme is system default
    else if (localStorage.theme === "system") {
      // if system prefered color is dark
      if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
        document.documentElement.classList.add("dark");
        localStorage.setItem("theme", "system");
      }
      // if system prefered color is light
      else {
        document.documentElement.classList.remove("dark");
        localStorage.setItem("theme", "system");
      }
    }
  } else {
    // remove classname dark from html tag
    document.documentElement.classList.remove("dark");
    // save theme mode as light in local storage
    localStorage.setItem("theme", "system");
  }
}

//  function to set dark mode base on the user’s operating system
export const mediaQueryListener = (e) => {
  // mdeia query matches true
  // local storage theme is  system
  if (e.matches && localStorage.getItem("theme") === "system") {
    // add classname as dark in html tag
    document.documentElement.classList.add("dark");
  } else {
    // set theme
    setInitialTheme();
  }
};

// function to handle show or hide password
export function handleShowPassword(id) {
  // get id and check input if input type is password change it to text else password
  $(`#${id}`).each(function () {
    if ($(this)[0].type === "password") {
      $(this)[0].type = "text";
    } else {
      $(this)[0].type = "password";
    }
  });
}

// handle to toggle security text to normal text
export function handleShowText(id) {
  $(`#${id}`).each(function () {
    if ($(this)[0].classList[1] === "hide-text") {
      $(this).addClass("show-text");
      $(this).removeClass("hide-text");
    } else {
      $(this).addClass("hide-text");
      $(this).removeClass("show-text");
    }
  });
}

// function to capitalize string
export const strCapitalize = (string) => {
  if (string !== null && string !== undefined) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  } else {
    return "";
  }
};

// funtion to change format of a date object
export function formatDate(date) {
  var dd = date.getDate();

  var mm = date.getMonth() + 1;
  var yyyy = date.getFullYear();

  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }

  return dd + "-" + mm + "-" + yyyy;
}
export function formatDate2(date) {
  var dd = date.getDate();

  var mm = date.getMonth() + 1;
  var yyyy = date.getFullYear();

  if (dd < 10) {
    dd = "0" + dd;
  }

  if (mm < 10) {
    mm = "0" + mm;
  }

  return yyyy + "-" + mm + "-" + dd;
}

// funtion to change format of a date object to time format
export function formatTime(date) {
  var hour = date.getHours();

  var min = date.getMinutes();

  // var sec = date.getSeconds();

  if (hour < 10) {
    hour = "0" + hour;
  }

  if (min < 10) {
    min = "0" + min;
  }
  return hour + ":" + min;
}

// function to add class with conditional check
export function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function HideInitialSidebarInMobile(params) {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 768 });
  return isTabletOrMobile ? false : true;
}

// Wrapper function
export const Wrapper = ({ component: Component, title }) => {
  return class Title extends React.Component {
    render() {
      return (
        <React.Fragment>
          <TitleComponent title={title} />

          <Component {...this.props} />
        </React.Fragment>
      );
    }
  };
};

export const copyIdToClipboard = (id) => {
  const el = document.createElement("textarea");
  el.value = `${id}`; //str is your string to copy
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy"); // Copy command
  document.body.removeChild(el);
};

// function to encrypt a string
export const encrypt = (text) => {
  const passphrase = "vegamaster";
  // Advanced Encryption Standard method
  return CryptoJS.AES.encrypt(text, passphrase).toString();
};

// function to decrypt a base64data
export const decrypt = (ciphertext) => {
  const passphrase = "vegamaster";
  const bytes = CryptoJS.AES.decrypt(ciphertext, passphrase);
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
};

// This function handles redirect to error page
// based on the error status code
export const redirectToErrorPage = (history, error) => {
  // check error has response and status
  if (error.response && error.response.status) {
    // assign status code in variable
    var status = error.response.status;

    // console.log(status, props);

    // checks status is 404
    if (status === 404) {
      // redirect to page not found page
      history.push("/page_not_found/");
    }
    // checks status is 403 forbidden
    else if (status === 403) {
      // redirect to forbidden page
      history.push("/forbidden/");
    }
    // checks status is 500 internal server error
    else if (status === 500) {
      // redirect to internal server error page
      history.push("/server_error/");
    }
  }
};

export const replaceInput = (e) => {
  var regex = /[!@#:$%^&*?~`)(+=._-]/g;

  // when user enter a input if it is letter we will set as empty string else will allow number
  e.target.value = e.target.value.replace(regex, "");

  return e.target.value;
};

// function to filter react select values
export const customFilterOptions = (options, searchText) => {
  // if search text is empty
  // returns all options
  if (!searchText) {
    return options;
  }

  const re = new RegExp(searchText, "i");
  // filter option using search text
  return options.filter(({ label }) => label && label.match(re));
};

// debouce function for unique object name

export const debounceFunction = (fn, d) => {
  let timer;
  return function () {
    let context = this,
      args = arguments;
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn.apply(context, args);
    }, d);
  };
};

export const announcementTypes = [
  {
    index: 1,
    icon: RiTv2Line,
    title: "Free Webinar",
    bgColor: "bg-red-100",
    iconColor: "text-red-400",
  },
  {
    index: 2,
    icon: RiServiceLine,
    title: "What's New",
    bgColor: "bg-blue-100",
    iconColor: "text-blue-400",
  },
  {
    index: 3,
    icon: RiBookOpenLine,
    title: "Product Guide",
    bgColor: "bg-yellow-100",
    iconColor: "text-yellow-400",
  },
  {
    index: 4,
    icon: RiFileTextLine,
    title: "Forum",
    bgColor: "bg-indigo-100",
    iconColor: "text-indigo-400",
  },
  {
    index: 5,
    icon: RiQuestionnaireLine,
    title: "FAQs",
    bgColor: "bg-pink-100",
    iconColor: "text-pink-400",
  },
  {
    index: 6,
    icon: RiNewspaperLine,
    title: "Journals",
    bgColor: "bg-green-100",
    iconColor: "text-green-400",
  },
];

export const socialHandles = [
  {
    index: 1,
    color: "bg-blue-600 rounded-full text-white",
    icon: RiFacebookCircleFill,
    url: "#",
  },
  {
    index: 2,
    color: "bg-blue-400 rounded-full text-white",
    icon: RiTwitterLine,
    url: "#",
  },
  {
    index: 3,
    color: "bg-indigo-600 rounded-full text-white",
    icon: RiLinkedinFill,
    url: "#",
  },
  {
    index: 4,
    color: "bg-pink-600 rounded-full text-white",
    icon: RiInstagramLine,
    url: "#",
  },
  {
    index: 5,
    color: "bg-red-600 rounded-full text-white",
    icon: RiYoutubeFill,
    url: "#",
  },
];

//country data
export const countryList = [
  { value: "AFG", label: "Afghanistan", number: "004" },
  { value: "ALB", label: "Albania", number: "008" },
  { value: "DZA", label: "Algeria", number: "012" },
  { value: "ASM", label: "American Samoa", number: "016" },
  { value: "AND", label: "Andorra", number: "020" },
  { value: "AGO", label: "Angola", number: "024" },
  { value: "AIA", label: "Anguilla", number: "660" },
  { value: "ATA", label: "Antarctica", number: "010" },
  { value: "ATG", label: "Antigua and Barbuda", number: "028" },
  { value: "ARG", label: "Argentina", number: "032" },
  { value: "ARM", label: "Armenia", number: "051" },
  { value: "ABW", label: "Aruba", number: "533" },
  { value: "AUS", label: "Australia", number: "036" },
  { value: "AUT", label: "Austria", number: "040" },
  { value: "AZE", label: "Azerbaijan", number: "031" },
  { value: "BHS", label: "Bahamas (the)", number: "044" },
  { value: "BHR", label: "Bahrain", number: "048" },
  { value: "BGD", label: "Bangladesh", number: "050" },
  { value: "BRB", label: "Barbados", number: "052" },
  { value: "BLR", label: "Belarus", number: "112" },
  { value: "BEL", label: "Belgium", number: "056" },
  { value: "BLZ", label: "Belize", number: "084" },
  { value: "BEN", label: "Benin", number: "204" },
  { value: "BMU", label: "Bermuda", number: "060" },
  { value: "BTN", label: "Bhutan", number: "064" },
  {
    value: "BOL",
    label: "Bolivia (Plurinational State of)",
    number: "068",
  },
  {
    value: "BES",
    label: "Bonaire, Sint Eustatius and Saba",
    number: "535",
  },
  { value: "BIH", label: "Bosnia and Herzegovina", number: "070" },
  { value: "BWA", label: "Botswana", number: "072" },
  { value: "BVT", label: "Bouvet Island", number: "074" },
  { value: "BRA", label: "Brazil", number: "076" },
  {
    value: "IOT",
    label: "British Indian Ocean Territory (the)",
    number: "086",
  },
  { value: "BRN", label: "Brunei Darussalam", number: "096" },
  { value: "BGR", label: "Bulgaria", number: "100" },
  { value: "BFA", label: "Burkina Faso", number: "854" },
  { value: "BDI", label: "Burundi", number: "108" },
  { value: "CPV", label: "Cabo Verde", number: "132" },
  { value: "KHM", label: "Cambodia", number: "116" },
  { value: "CMR", label: "Cameroon", number: "120" },
  { value: "CAN", label: "Canada", number: "124" },
  { value: "CYM", label: "Cayman Islands (the)", number: "136" },
  {
    value: "CAF",
    label: "Central African Republic (the)",
    number: "140",
  },
  { value: "TCD", label: "Chad", number: "148" },
  { value: "CHL", label: "Chile", number: "152" },
  { value: "CHN", label: "China", number: "156" },
  { value: "CXR", label: "Christmas Island", number: "162" },
  {
    value: "CCK",
    label: "Cocos (Keeling) Islands (the)",
    number: "166",
  },
  { value: "COL", label: "Colombia", number: "170" },
  { value: "COM", label: "Comoros (the)", number: "174" },
  {
    value: "COD",
    label: "Congo (the Democratic Republic of the)",
    number: "180",
  },
  { value: "COG", label: "Congo (the)", number: "178" },
  { value: "COK", label: "Cook Islands (the)", number: "184" },
  { value: "CRI", label: "Costa Rica", number: "188" },
  { value: "HRV", label: "Croatia", number: "191" },
  { value: "CUB", label: "Cuba", number: "192" },
  { value: "CUW", label: "Curaçao", number: "531" },
  { value: "CYP", label: "Cyprus", number: "196" },
  { value: "CZE", label: "Czechia", number: "203" },
  { value: "CIV", label: "Côte d'Ivoire", number: "384" },
  { value: "DNK", label: "Denmark", number: "208" },
  { value: "DJI", label: "Djibouti", number: "262" },
  { value: "DMA", label: "Dominica", number: "212" },
  { value: "DOM", label: "Dominican Republic (the)", number: "214" },
  { value: "ECU", label: "Ecuador", number: "218" },
  { value: "EGY", label: "Egypt", number: "818" },
  { value: "SLV", label: "El Salvador", number: "222" },
  { value: "GNQ", label: "Equatorial Guinea", number: "226" },
  { value: "ERI", label: "Eritrea", number: "232" },
  { value: "EST", label: "Estonia", number: "233" },
  { value: "SWZ", label: "Eswatini", number: "748" },
  { value: "ETH", label: "Ethiopia", number: "231" },
  {
    value: "FLK",
    label: "Falkland Islands (the) [Malvinas]",
    number: "238",
  },
  { value: "FRO", label: "Faroe Islands (the)", number: "234" },
  { value: "FJI", label: "Fiji", number: "242" },
  { value: "FIN", label: "Finland", number: "246" },
  { value: "FRA", label: "France", number: "250" },
  { value: "GUF", label: "French Guiana", number: "254" },
  { value: "PYF", label: "French Polynesia", number: "258" },
  {
    value: "ATF",
    label: "French Southern Territories (the)",
    number: "260",
  },
  { value: "GAB", label: "Gabon", number: "266" },
  { value: "GMB", label: "Gambia (the)", number: "270" },
  { value: "GEO", label: "Georgia", number: "268" },
  { value: "DEU", label: "Germany", number: "276" },
  { value: "GHA", label: "Ghana", number: "288" },
  { value: "GIB", label: "Gibraltar", number: "292" },
  { value: "GRC", label: "Greece", number: "300" },
  { value: "GRL", label: "Greenland", number: "304" },
  { value: "GRD", label: "Grenada", number: "308" },
  { value: "GLP", label: "Guadeloupe", number: "312" },
  { value: "GUM", label: "Guam", number: "316" },
  { value: "GTM", label: "Guatemala", number: "320" },
  { value: "GGY", label: "Guernsey", number: "831" },
  { value: "GIN", label: "Guinea", number: "324" },
  { value: "GNB", label: "Guinea-Bissau", number: "624" },
  { value: "GUY", label: "Guyana", number: "328" },
  { value: "HTI", label: "Haiti", number: "332" },
  {
    value: "HMD",
    label: "Heard Island and McDonald Islands",
    number: "334",
  },
  { value: "VAT", label: "Holy See (the)", number: "336" },
  { value: "HND", label: "Honduras", number: "340" },
  { value: "HKG", label: "Hong Kong", number: "344" },
  { value: "HUN", label: "Hungary", number: "348" },
  { value: "ISL", label: "Iceland", number: "352" },
  { value: "IND", label: "India", number: "356" },
  { value: "IDN", label: "Indonesia", number: "360" },
  {
    value: "IRN",
    label: "Iran (Islamic Republic of)",
    number: "364",
  },
  { value: "IRQ", label: "Iraq", number: "368" },
  { value: "IRL", label: "Ireland", number: "372" },
  { value: "IMN", label: "Isle of Man", number: "833" },
  { value: "ISR", label: "Israel", number: "376" },
  { value: "ITA", label: "Italy", number: "380" },
  { value: "JAM", label: "Jamaica", number: "388" },
  { value: "JPN", label: "Japan", number: "392" },
  { value: "JEY", label: "Jersey", number: "832" },
  { value: "JOR", label: "Jordan", number: "400" },
  { value: "KAZ", label: "Kazakhstan", number: "398" },
  { value: "KEN", label: "Kenya", number: "404" },
  { value: "KIR", label: "Kiribati", number: "296" },
  {
    value: "PRK",
    label: "Korea (the Democratic People's Republic of)",
    number: "408",
  },
  { value: "KOR", label: "Korea (the Republic of)", number: "410" },
  { value: "KWT", label: "Kuwait", number: "414" },
  { value: "KGZ", label: "Kyrgyzstan", number: "417" },
  {
    value: "LAO",
    label: "Lao People's Democratic Republic (the)",
    number: "418",
  },
  { value: "LVA", label: "Latvia", number: "428" },
  { value: "LBN", label: "Lebanon", number: "422" },
  { value: "LSO", label: "Lesotho", number: "426" },
  { value: "LBR", label: "Liberia", number: "430" },
  { value: "LBY", label: "Libya", number: "434" },
  { value: "LIE", label: "Liechtenstein", number: "438" },
  { value: "LTU", label: "Lithuania", number: "440" },
  { value: "LUX", label: "Luxembourg", number: "442" },
  { value: "MAC", label: "Macao", number: "446" },
  { value: "MDG", label: "Madagascar", number: "450" },
  { value: "MWI", label: "Malawi", number: "454" },
  { value: "MYS", label: "Malaysia", number: "458" },
  { value: "MDV", label: "Maldives", number: "462" },
  { value: "MLI", label: "Mali", number: "466" },
  { value: "MLT", label: "Malta", number: "470" },
  { value: "MHL", label: "Marshall Islands (the)", number: "584" },
  { value: "MTQ", label: "Martinique", number: "474" },
  { value: "MRT", label: "Mauritania", number: "478" },
  { value: "MUS", label: "Mauritius", number: "480" },
  { value: "MYT", label: "Mayotte", number: "175" },
  { value: "MEX", label: "Mexico", number: "484" },
  {
    value: "FSM",
    label: "Micronesia (Federated States of)",
    number: "583",
  },
  {
    value: "MDA",
    label: "Moldova (the Republic of)",
    number: "498",
  },
  { value: "MCO", label: "Monaco", number: "492" },
  { value: "MNG", label: "Mongolia", number: "496" },
  { value: "MNE", label: "Montenegro", number: "499" },
  { value: "MSR", label: "Montserrat", number: "500" },
  { value: "MAR", label: "Morocco", number: "504" },
  { value: "MOZ", label: "Mozambique", number: "508" },
  { value: "MMR", label: "Myanmar", number: "104" },
  { value: "NAM", label: "Namibia", number: "516" },
  { value: "NRU", label: "Nauru", number: "520" },
  { value: "NPL", label: "Nepal", number: "524" },
  { value: "NLD", label: "Netherlands (the)", number: "528" },
  { value: "NCL", label: "New Caledonia", number: "540" },
  { value: "NZL", label: "New Zealand", number: "554" },
  { value: "NIC", label: "Nicaragua", number: "558" },
  { value: "NER", label: "Niger (the)", number: "562" },
  { value: "NGA", label: "Nigeria", number: "566" },
  { value: "NIU", label: "Niue", number: "570" },
  { value: "NFK", label: "Norfolk Island", number: "574" },
  {
    value: "MNP",
    label: "Northern Mariana Islands (the)",
    number: "580",
  },
  { value: "NOR", label: "Norway", number: "578" },
  { value: "OMN", label: "Oman", number: "512" },
  { value: "PAK", label: "Pakistan", number: "586" },
  { value: "PLW", label: "Palau", number: "585" },
  { value: "PSE", label: "Palestine, State of", number: "275" },
  { value: "PAN", label: "Panama", number: "591" },
  { value: "PNG", label: "Papua New Guinea", number: "598" },
  { value: "PRY", label: "Paraguay", number: "600" },
  { value: "PER", label: "Peru", number: "604" },
  { value: "PHL", label: "Philippines (the)", number: "608" },
  { value: "PCN", label: "Pitcairn", number: "612" },
  { value: "POL", label: "Poland", number: "616" },
  { value: "PRT", label: "Portugal", number: "620" },
  { value: "PRI", label: "Puerto Rico", number: "630" },
  { value: "QAT", label: "Qatar", number: "634" },
  {
    value: "MKD",
    label: "Republic of North Macedonia",
    number: "807",
  },
  { value: "ROU", label: "Romania", number: "642" },
  { value: "RUS", label: "Russian Federation (the)", number: "643" },
  { value: "RWA", label: "Rwanda", number: "646" },
  { value: "REU", label: "Réunion", number: "638" },
  { value: "BLM", label: "Saint Barthélemy", number: "652" },
  {
    value: "SHN",
    label: "Saint Helena, Ascension and Tristan da Cunha",
    number: "654",
  },
  { value: "KNA", label: "Saint Kitts and Nevis", number: "659" },
  { value: "LCA", label: "Saint Lucia", number: "662" },
  {
    value: "MAF",
    label: "Saint Martin (French part)",
    number: "663",
  },
  {
    value: "SPM",
    label: "Saint Pierre and Miquelon",
    number: "666",
  },
  {
    value: "VCT",
    label: "Saint Vincent and the Grenadines",
    number: "670",
  },
  { value: "WSM", label: "Samoa", number: "882" },
  { value: "SMR", label: "San Marino", number: "674" },
  { value: "STP", label: "Sao Tome and Principe", number: "678" },
  { value: "SAU", label: "Saudi Arabia", number: "682" },
  { value: "SEN", label: "Senegal", number: "686" },
  { value: "SRB", label: "Serbia", number: "688" },
  { value: "SYC", label: "Seychelles", number: "690" },
  { value: "SLE", label: "Sierra Leone", number: "694" },
  { value: "SGP", label: "Singapore", number: "702" },
  {
    value: "SXM",
    label: "Sint Maarten (Dutch part)",
    number: "534",
  },
  { value: "SVK", label: "Slovakia", number: "703" },
  { value: "SVN", label: "Slovenia", number: "705" },
  { value: "SLB", label: "Solomon Islands", number: "090" },
  { value: "SOM", label: "Somalia", number: "706" },
  { value: "ZAF", label: "South Africa", number: "710" },
  {
    value: "SGS",
    label: "South Georgia and the South Sandwich Islands",
    number: "239",
  },
  { value: "SSD", label: "South Sudan", number: "728" },
  { value: "ESP", label: "Spain", number: "724" },
  { value: "LKA", label: "Sri Lanka", number: "144" },
  { value: "SDN", label: "Sudan (the)", number: "729" },
  { value: "SUR", label: "Suriname", number: "740" },
  { value: "SJM", label: "Svalbard and Jan Mayen", number: "744" },
  { value: "SWE", label: "Sweden", number: "752" },
  { value: "CHE", label: "Switzerland", number: "756" },
  { value: "SYR", label: "Syrian Arab Republic", number: "760" },
  { value: "TWN", label: "Taiwan", number: "158" },
  { value: "TJK", label: "Tajikistan", number: "762" },
  {
    value: "TZA",
    label: "Tanzania, United Republic of",
    number: "834",
  },
  { value: "THA", label: "Thailand", number: "764" },
  { value: "TLS", label: "Timor-Leste", number: "626" },
  { value: "TGO", label: "Togo", number: "768" },
  { value: "TKL", label: "Tokelau", number: "772" },
  { value: "TON", label: "Tonga", number: "776" },
  { value: "TTO", label: "Trinidad and Tobago", number: "780" },
  { value: "TUN", label: "Tunisia", number: "788" },
  { value: "TUR", label: "Turkey", number: "792" },
  { value: "TKM", label: "Turkmenistan", number: "795" },
  {
    value: "TCA",
    label: "Turks and Caicos Islands (the)",
    number: "796",
  },
  { value: "TUV", label: "Tuvalu", number: "798" },
  { value: "UGA", label: "Uganda", number: "800" },
  { value: "UKR", label: "Ukraine", number: "804" },
  {
    value: "ARE",
    label: "United Arab Emirates (the)",
    number: "784",
  },
  {
    value: "GBR",
    label: "United Kingdom of Great Britain and Northern Ireland (the)",
    number: "826",
  },
  {
    value: "UMI",
    label: "United States Minor Outlying Islands (the)",
    number: "581",
  },
  {
    value: "USA",
    label: "United States of America (the)",
    number: "840",
  },
  { value: "URY", label: "Uruguay", number: "858" },
  { value: "UZB", label: "Uzbekistan", number: "860" },
  { value: "VUT", label: "Vanuatu", number: "548" },
  {
    value: "VEN",
    label: "Venezuela (Bolivarian Republic of)",
    number: "862",
  },
  { value: "VNM", label: "Viet Nam", number: "704" },
  { value: "VGB", label: "Virgin Islands (British)", number: "092" },
  { value: "VIR", label: "Virgin Islands (U.S.)", number: "850" },
  { value: "WLF", label: "Wallis and Futuna", number: "876" },
  { value: "ESH", label: "Western Sahara", number: "732" },
  { value: "YEM", label: "Yemen", number: "887" },
  { value: "ZMB", label: "Zambia", number: "894" },
  { value: "ZWE", label: "Zimbabwe", number: "716" },
  { value: "ALA", label: "Åland Islands", number: "248" },
];

// indian states data
export const statesList = [
  {
    value: "AN",
    label: "Andaman and Nicobar Islands",
  },
  {
    value: "AP",
    label: "Andhra Pradesh",
  },
  {
    value: "AR",
    label: "Arunachal Pradesh",
  },
  {
    value: "AS",
    label: "Assam",
  },
  {
    value: "BR",
    label: "Bihar",
  },
  {
    value: "CG",
    label: "Chandigarh",
  },
  {
    value: "CH",
    label: "Chhattisgarh",
  },
  {
    value: "DH",
    label: "Dadra and Nagar Haveli",
  },
  {
    value: "DD",
    label: "Daman and Diu",
  },
  {
    value: "DL",
    label: "Delhi",
  },
  {
    value: "GA",
    label: "Goa",
  },
  {
    value: "GJ",
    label: "Gujarat",
  },
  {
    value: "HR",
    label: "Haryana",
  },
  {
    value: "HP",
    label: "Himachal Pradesh",
  },
  {
    value: "JK",
    label: "Jammu and Kashmir",
  },
  {
    value: "JH",
    label: "Jharkhand",
  },
  {
    value: "KA",
    label: "Karnataka",
  },
  {
    value: "KL",
    label: "Kerala",
  },
  {
    value: "LD",
    label: "Lakshadweep",
  },
  {
    value: "MP",
    label: "Madhya Pradesh",
  },
  {
    value: "MH",
    label: "Maharashtra",
  },
  {
    value: "MN",
    label: "Manipur",
  },
  {
    value: "ML",
    label: "Meghalaya",
  },
  {
    value: "MZ",
    label: "Mizoram",
  },
  {
    value: "NL",
    label: "Nagaland",
  },
  {
    value: "OR",
    label: "Odisha",
  },
  {
    value: "PY",
    label: "Puducherry",
  },
  {
    value: "PB",
    label: "Punjab",
  },
  {
    value: "RJ",
    label: "Rajasthan",
  },
  {
    value: "SK",
    label: "Sikkim",
  },
  {
    value: "TN",
    label: "Tamil Nadu",
  },
  {
    value: "TS",
    label: "Telangana",
  },
  {
    value: "TR",
    label: "Tripura",
  },
  {
    value: "UP",
    label: "Uttar Pradesh",
  },
  {
    value: "UK",
    label: "Uttarakhand",
  },
  {
    value: "WB",
    label: "West Bengal",
  },
];

// sidebar navigation
export const navigation = (props) => {
  // current url
  const currentURL = props.location.pathname;

  const profilePages = [
    { name: "Personal Information", href: `/profile/personal` },
    { name: "Email Address", href: `/profile/email-address` },
    { name: "Mobile Numbers", href: `/profile/mobile-numbers` },
    { name: "Connected Accounts", href: `/profile/connected-accounts` },
  ];

  const securityPages = [
    {
      name: "Change Password",
      href: `/security/change_password`,
    },
    {
      name: "Multi-Factor Authentication",
      href: `/security/mfa_authentication`,
    },
  ];

  const SettingsPages = [
    {
      name: "Preferences",
      href: `/setting/preferences`,
    },
    {
      name: "Email Settings",
      href: `/setting/email_settings`,
    },
  ];

  const activityPages = [
    {
      name: "Active Sessions",
      href: "/activity/active-sessions",
      current: currentURL === "/activity/active-sessions",
    },
    {
      name: "Authentication Log",
      href: "/activity/authentication-log",
      current: currentURL === "/activity/authentication-log",
    },
    {
      name: "Activity History",
      href: "/activity/activity-history",
      current: currentURL === "/activity/activity-history",
    },
  ];


  return [
    {
      name: "Profile",
      icon: RiUser3Line,
      href: `/profile`,
      current: profilePages.filter((key) => key.href === currentURL).length > 0,
      children: profilePages.map((key) => ({
        ...key,
        current: key.href === currentURL,
      })),
    },
    {
      name: "Security",
      icon: RiShieldKeyholeLine,
      href: `/security/security_pwd`,
      current:
        securityPages.filter((key) => key.href === currentURL).length > 0,
      children: securityPages.map((key) => ({
        ...key,
        current: key.href === currentURL,
      })),
    },
    {
      name: "Settings",
      icon: RiSettings3Line,
      href: `/setting/preferences`,
      current:
        SettingsPages.filter((key) => key.href === currentURL).length > 0,
      children: SettingsPages.map((key) => ({
        ...key,
        current: key.href === currentURL,
      })),
    },
    {
      name: "Activity",
      icon: RiPulseLine,
      // href: `/activity`,
      children: activityPages,
      current: activityPages
        .map((key) => {
          return key.href;
        })
        .includes(currentURL),
    },
  ];
};
