// import headless ui component
import { Disclosure } from "@headlessui/react";
// import router component
import { Link, withRouter } from "react-router-dom";
// import redux
import { connect } from "react-redux";
// import vegamaster logo component
import VegastackLogo from "./VegastackLogo";

function HeaderTwo(props) {


  return (
    <Disclosure
      as="nav"
      className="fixed top-0 z-40 w-full bg-blue-500 dark:bg-gray-800"
    >
      {({ open }) => (
        <>
          <div className="px-2 mx-auto max-full sm:px-4 lg:px-32">
            <div className="relative flex items-center justify-between h-12">
              <div className="flex items-center px-2 lg:px-0">
                <Link to="/profile/personal" className="flex-shrink-0">
                  <VegastackLogo
                    className="block w-auto h-5 text-white fill-current lg:hidden"
                    symbol={true}
                  />

                  <VegastackLogo className="hidden w-auto h-5 text-white fill-current lg:block" />
                </Link>

              </div>

            </div>
          </div>

        </>
      )}
    </Disclosure>
  );
}

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps)(withRouter(HeaderTwo));
