// import Helmet  component from react-helmet
import Helmet from "react-helmet";

// function for title component
const TitleComponent = ({ title }) => {
  var defaultTitle = "VegaMaster by VegaStack";

  return (
    <Helmet defaultTitle={defaultTitle}>
      <title>{title && `${title} | ${defaultTitle}`}</title>
    </Helmet>
  );
};

export default TitleComponent;
