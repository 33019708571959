import Footer from "../Components/Footer";
// import classNames join function
import { classNames } from "../Components/utilities";
// import react responsive
import { useMediaQuery } from "react-responsive";


export default function Content(props) {

  const isDesktopOrLaptop = useMediaQuery({ minDeviceWidth: 768 });


  return (
    <div
      className={classNames(
        "relative mt-10 mb-0",
        isDesktopOrLaptop ? "lg:ml-56 md:ml-56" : ""
      )}
    >
      <main className="bg-gray-100 dark:bg-gray-900 min-h-content">
        <div
          className={`${props.inlineSpace ? "md:px-3" : "md:px-8"
            } max-w-full mx-auto px-4 sm:px-6 py-8`}
        >
          {/* Replace with your content */}

          {props.children}

          {/* /End replace */}
        </div>
      </main>

      <Footer />
    </div>
  );
}
