// import react icons
import { RiEyeOffFill, RiEyeFill, RiLockLine } from "react-icons/ri";
// import axios
import { get, post } from "../Components/AxiosUtilities";
// import notfication alert
import { useSnackbar } from "notistack";
// import urls
import {
  account_user_activation,
} from "../Components/Urls";
// import react components
import { useState, useEffect } from "react";
// import use previous function
import {
  setInitialTheme,
  handleShowPassword,
  redirectToErrorPage,
} from "../Components/utilities";
// import react router component
import { Link, withRouter } from "react-router-dom";
// import PIN Input component
// import PinInput from "../Components/PinInput";
// import step progress indicator
// import StepProgressIndicator from "../Components/StepProgressIndicator";
// import vegamaster logo component
import VegastackLogo from "../Components/VegastackLogo";
// import redux
import { connect } from "react-redux";
// import vegatheme
import "../Styles/vegatheme.css";
// import main css file
import "../Styles/main.css";

// set theme function
setInitialTheme();

const { REACT_APP_KEYCLOAK_URL } = process.env;


function OnboardActivation(props) {
  // Initialize states
  const [values, setValues] = useState({
    password: "",
    confirm_password: "",
    pin: "",
    confirm_pin: "",
  });

  const [showPassword, setshowPassword] = useState(false);

  const [showConfirmPassword, setshowConfirmPassword] = useState(false);

  // get uid and token from url
  const uid = props.match.params.uid;
  const token = props.match.params.token;

  // show alert and close alert functions
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { history } = props;

  useEffect(() => {
    const action = (key) => {
      return (
        <svg
          role="button"
          title="dismiss"
          className="mr-2 css-i6dzq1"
          onClick={() => {
            closeSnackbar(key);
          }}
          viewBox="0 0 24 24"
          width="20"
          height="20"
          stroke="currentColor"
          stroke-width="2"
          fill="none"
          stroke-linecap="round"
          strokeLinejoin="round"
        >
          <line x1="18" y1="6" x2="6" y2="18"></line>
          <line x1="6" y1="6" x2="18" y2="18"></line>
        </svg>
      );
    };


    const checkTokenValid = () => {
      get(account_user_activation, { params: { uid: uid, token: token } })
        .then((res) => {
          // if (res.data.email) {
          //   setEmail(res.data.email);
          // }
        })
        .catch((err) => {
          // console.log('error', err.response);

          if (err.response && err.response.status === 400) {
            enqueueSnackbar("Invalid or Expired Link", {
              variant: "error",
              preventDuplicate: true,
              persist: true,
              action: action,
            });
          } else {
            redirectToErrorPage(history, err);

            enqueueSnackbar("error.", {
              variant: "error",
              autoHideDuration: 3000,
            });
          }
        });
    };


    checkTokenValid();


  }, [uid, token, enqueueSnackbar, history, closeSnackbar]);


  // function to save inputs in states
  const handleInputChange = (e) => {
    // takes input name and value from event target input field
    const { name, value } = e.target;

    // sets state values
    setValues({
      ...values,
      [name]: value,
    });
  };

  const OnboardActivationAPI = () => {
    post(account_user_activation, {
      uid: uid,
      token: token,
      new_password: values.password,
      confirm_new_password: values.confirm_password
    })
      .then((res) => {
        // console.log(res.data);

        enqueueSnackbar("Activated Account Successfully.", {
          variant: "success",
          autoHideDuration: 3000,
        });

        // redirect to sign in page
        window.location.href = REACT_APP_KEYCLOAK_URL;
      })
      .catch((err) => {
        // console.log('error', err);

        if (err.response && err.response.data.message) {
          enqueueSnackbar(err.response.data.message, {
            variant: "error",
            autoHideDuration: 3000,
          });
        } else {
          enqueueSnackbar("An error occured while activating your account.", {
            variant: "error",
            autoHideDuration: 3000,
          });
        }
      });
  };

  // function to handle form submit
  const handleFormSubmit = (e) => {
    e.preventDefault();

    if (values["password"] !== values["confirm_password"]) {
      enqueueSnackbar("Password and Confirm Password should match", {
        variant: "warning",
        autoHideDuration: 3000,
      });
    } else {

      OnboardActivationAPI();

    }
  };


  // return jsx elements
  return (
    <div className="flex flex-col justify-center min-h-screen py-12 bg-gray-50 dark:bg-gray-900 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <VegastackLogo className="ap-logo" />

        <h2 className="ap-heading">Set Password</h2>
      </div>
      {/* 
      <div className="flex justify-center w-full mt-6">
        <StepProgressIndicator
          steps={[
            { name: "Step 1", href: "#", status: "complete" },
            { name: "Step 2", href: "#", status: "current" },
            { name: "Step 3", href: "#", status: "upcoming" },
            { name: "Step 4", href: "#", status: "upcoming" },
          ]}
        />
      </div> */}

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="ap-card">
          <form
            className="space-y-6"
            action="#"
            method="POST"
            onSubmit={handleFormSubmit}
          >
            <div className="password_section">
              <label htmlFor="password" className="form-label">
                Password
              </label>

              <div className="relative mt-1 rounded shadow-sm">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <RiLockLine className="ap-icons" aria-hidden="true" />
                </div>
                <input
                  type="password"
                  name="password"
                  id="password"
                  value={values.password}
                  onChange={handleInputChange}
                  autoComplete="current-password"
                  required
                  minLength="8"
                  className="ap-input-fields"
                  //   required={true}
                  autoFocus
                />
                <div
                  className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                  onClick={() => {
                    handleShowPassword("password");
                    // set show password state opposite of current state
                    setshowPassword(!showPassword);
                  }}
                >
                  {showPassword === false && (
                    <RiEyeFill className="ap-icons" aria-hidden="true" />
                  )}

                  {showPassword && (
                    <RiEyeOffFill className="ap-icons" aria-hidden="true" />
                  )}
                </div>
              </div>
            </div>

            <div className="password_section">
              <label htmlFor="confirm_password" className="form-label">
                Confirm Password
              </label>

              <div className="relative mt-1 rounded shadow-sm">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <RiLockLine className="ap-icons" aria-hidden="true" />
                </div>
                <input
                  type="password"
                  name="confirm_password"
                  id="confirm_password"
                  value={values.confirm_password}
                  onChange={handleInputChange}
                  // onInput={handlePasswordMatchCheck}
                  autoComplete="current-password"
                  required
                  minLength="8"
                  className="ap-input-fields"
                //   required={true}
                />
                <div
                  className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                  onClick={() => {
                    handleShowPassword("confirm_password");
                    // set show password state opposite of current state
                    setshowConfirmPassword(!showConfirmPassword);
                  }}
                >
                  {showConfirmPassword === false && (
                    <RiEyeFill className="ap-icons " aria-hidden="true" />
                  )}

                  {showConfirmPassword && (
                    <RiEyeOffFill className="ap-icons " aria-hidden="true" />
                  )}
                </div>
              </div>
            </div>

            <div className="password_section">
              <button
                type="submit"
                className={`w-full flex justify-center py-2 px-4 border border-transparent rounded shadow-sm text-sm font-medium
                                text-white bg-indigo-500 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2  dark:focus:ring-offset-gray-800 focus:ring-offset-white focus:ring-blue-500`}
              >
                Submit
              </button>
            </div>
          </form>

          <div className="mt-6">
            <div className="relative">
              <div className="absolute inset-0 flex items-center">
                <div className="w-full" />
              </div>
              <div className="relative flex justify-center text-sm">
                <Link to="/signup" className="ap-links">
                  Go Back
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  ...state,
});

export default connect(mapStateToProps)(withRouter(OnboardActivation));
