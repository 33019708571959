import { createStore } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
// import storageSession from 'redux-persist/lib/storage/session';


const persistConfig = {
  key: "root",
  storage: storage,
};


const initialState = {
  email: "",
  first_name: "",
  last_name: "",
  timezone: "",
  avatar_url: "",
  organizations: [],
  organization_uid: "",
  last_accessed_organization: "",
  isAuthenticated: false,
  enableKeyboardShorcuts: true,
};


export const reducer = function (state = initialState, action) {
  if (action.type === "setUserAuth") {
    return {
      ...state,
      ...action,
      isAuthenticated: true,
    };
  } else if (action.type === "setOrganizationUID") {
    return {
      ...state,
      organization_uid: action.organization_uid,
    };
  } else if (action.type === "getUserAuth") {
    return {
      ...state,
    };
  } else if (action.type === "setTimeZone") {
    return {
      ...state,
      timezone: action.timezone,
    };
  } else if (action.type === "setPageHeading") {
    return {
      ...state,
      page_heading: action.page_heading,
    };
  } else if (action.type === "logoutUser") {
    return {
      state: undefined,
    };
  } else if (action.type === "changeSlug") {
    return {
      ...state,
      change_slug: action.change_slug,
    };
  } else if (action.type === "enableKeyboardShorcuts") {
    return {
      ...state,
      enableKeyboardShorcuts: action.enableKeyboardShorcuts,
    };
  } else if (action.type === "setUserDetails") {
    return {
      ...state,
      ...action,
    };
  } else {
    return state;
  }
};


const persistedReducer = persistReducer(persistConfig, reducer);

export const store = createStore(persistedReducer);
export const persistor = persistStore(store);
