// import redux store
import { store } from "../Components/Store";
// import axios function
import axios from "axios";
// import cookie handler
import { getCookie } from "../Components/Cookie_handler";

// get value from .env file
const { REACT_APP_DEPLOYMENT_CROSS_DOMAIN } = process.env;

axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.withCredentials = true;

export const cancelTokenSource = axios.CancelToken.source();

export function get(url, requestData = {}) {
  setOrganizationUIDHeader();
  return axios.get(url, requestData);
}

export function post(url, requestData) {
  setOrganizationUIDHeader();

  return REACT_APP_DEPLOYMENT_CROSS_DOMAIN === "true"
    ? axios.post(url, requestData, {
        headers: {
          "X-CSRFToken": getCookie("X-CSRFToken"),
        },
      })
    : axios.post(url, requestData);
}

export function put(url, requestData) {
  setOrganizationUIDHeader();

  return REACT_APP_DEPLOYMENT_CROSS_DOMAIN === "true"
    ? axios.put(url, requestData, {
        headers: {
          "X-CSRFToken": getCookie("X-CSRFToken"),
        },
      })
    : axios.put(url, requestData);
}

export function Delete(url, requestData) {
  setOrganizationUIDHeader();

  return REACT_APP_DEPLOYMENT_CROSS_DOMAIN === "true"
    ? axios.delete(url, {
        headers: {
          "X-CSRFToken": getCookie("X-CSRFToken"),
        },
        ...requestData,
      })
    : axios.delete(url, requestData);
}

// function to set organization-uid in axios headers
function setOrganizationUIDHeader() {
  // get current state from redux
  // const state = store.getState();

  // Add organization-uid in headers and return
  // return (axios.defaults.headers.common["organization-uid"] = decrypt(
  //   state.organization_uid ? state.organization_uid : ""
  // ));

  return (axios.defaults.headers.common["organization-uid"] =
    "76c89dd7-44a8-46c5-8138-fd7a600c2728");
}
