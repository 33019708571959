// import react responsive
import { useMediaQuery } from "react-responsive";
// import react component
import { Fragment, useState, useEffect } from "react";
// import headlessui component
import { Dialog, Transition, Disclosure } from "@headlessui/react";
// import react icons
import { RiCloseLine } from "react-icons/ri";
// import react router components
import { Link } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Sidebar(props) {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const isDesktopOrLaptop = useMediaQuery({ minDeviceWidth: 768 });
  const isTabletOrMobile = useMediaQuery({ maxWidth: 767 });

  useEffect(() => {
    // console.log("sidebar", props.showSidebar);

    setSidebarOpen(props.showSidebar);
  }, [props.showSidebar]);

  return (
    <>
      {isTabletOrMobile && (
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            static
            className="fixed inset-0 z-40 flex md:hidden"
            open={sidebarOpen}
            onClose={props.setshowSidebar}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative flex flex-col w-56 bg-gray-200 dark:bg-gray-800">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 pt-2 -mr-12">
                    <button
                      className="flex items-center justify-center w-10 h-10 ml-1 rounded-full focus:outline-none"
                      onClick={() => props.setshowSidebar(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <RiCloseLine
                        className="w-6 h-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                  {/* <div className="flex items-center flex-shrink-0 px-4">
                    <img
                      className="w-auto h-8"
                      src="https://tailwindui.com/img/logos/workflow-logo-indigo-600-mark-gray-800-text.svg"
                      alt="Workflow"
                    />
                  </div> */}
                  <nav
                    className="flex-1 px-2 mt-4 space-y-1"
                    aria-label="Sidebar"
                  >
                    {props.navigation.map((item) => {
                      if (item) {
                        return !item.children ? (
                          <div key={item.name}>
                            <Link
                              to={item.href}
                              className={classNames(
                                item.current
                                  ? "bg-gray-300 dark:bg-gray-600 text-gray-900 dark:text-gray-300"
                                  : "bg-gray-200 dark:bg-gray-800 dark:hover:bg-gray-600 dark:focus:bg-gray-600 text-gray-600 dark:text-gray-300 hover:bg-gray-300 focus:hover:bg-gray-300 dark:hover:text-white dark:focus:text-white",
                                "group w-full flex items-center pl-2 py-2 text-sm font-medium rounded justify-between"
                              )}
                            >
                              <div className="flex">
                                <item.icon
                                  className={classNames(
                                    item.current
                                      ? "text-gray-900 dark:text-gray-300"
                                      : "text-gray-900 dark:text-gray-300 group-hover:text-gray-900 group-focus:text-gray-900 dark:group-hover:text-gray-300 dark:group-focus:text-gray-300",
                                    "mr-3 h-6 w-6"
                                  )}
                                  aria-hidden="true"
                                />
                                {item.name}
                              </div>

                              {item.search_count && (
                                <span className="inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-green-500 text-white mr-2">
                                  {item.search_count}
                                </span>
                              )}
                            </Link>
                          </div>
                        ) : (
                          <Disclosure
                            as="div"
                            key={item.name}
                            defaultOpen={item.current ? true : false}
                            className="space-y-1"
                          >
                            {({ open }) => (
                              <>
                                <Disclosure.Button
                                  className={classNames(
                                    item.current
                                      ? "dark:bg-gray-800 text-gray-900 dark:text-gray-300"
                                      : "bg-gray-200 dark:bg-gray-800 dark:hover:bg-gray-600 dark:focus:bg-gray-600 text-gray-600 dark:text-gray-300 hover:bg-gray-300 focus:bg-gray-300 dark:hover:text-white dark:focus:text-white",
                                    "group w-full flex items-center pl-2 pr-1 py-2 text-sm font-medium rounded focus:outline-none"
                                  )}
                                >
                                  <item.icon
                                    className="w-6 h-6 mr-3 text-gray-900 dark:text-gray-300 group-hover:text-gray-900 group-focus:text-gray-900 dark:group-hover:text-gray-300 dark:group-focus:text-gray-300"
                                    aria-hidden="true"
                                  />
                                  {item.name}
                                  <svg
                                    className={classNames(
                                      open
                                        ? "text-gray-900 dark:text-gray-300 rotate-90"
                                        : "text-gray-900 dark:text-gray-300",
                                      "ml-auto h-5 w-5 group-hover:text-gray-900 dark:group-hover:text-gray-300 dark:text-gray-300 transition-colors ease-in-out duration-150"
                                    )}
                                    viewBox="0 0 20 20"
                                    aria-hidden="true"
                                  >
                                    <path
                                      d="M6 6L14 10L6 14V6Z"
                                      fill="currentColor"
                                    />
                                  </svg>
                                </Disclosure.Button>

                                <Disclosure.Panel className="space-y-1">
                                  {item.children.map((subItem) => (
                                    <Link
                                      key={subItem.name}
                                      to={subItem.href}
                                      className={classNames(
                                        subItem.current
                                          ? "bg-gray-300 dark:bg-gray-600 text-gray-900 dark:text-gray-300"
                                          : "",
                                        `group w-full flex items-center pl-11 pr-2 py-2 text-sm font-medium
                                        text-gray-600 rounded dark:text-gray-300 dark:hover:text-gray-300 dark:focus:text-gray-300 hover:bg-gray-300 focus:bg-gray-300 dark:hover:bg-gray-600 dark:focus:bg-gray-600`
                                      )}
                                    >
                                      {subItem.name}
                                    </Link>
                                  ))}
                                </Disclosure.Panel>
                              </>
                            )}
                          </Disclosure>
                        );
                      }
                      return null;
                    })}
                  </nav>
                </div>
                {/* <div className="flex flex-shrink-0 p-4 border-t border-gray-200">
                
                </div> */}
              </div>
            </Transition.Child>
            <div className="flex-shrink-0 w-14">
              {/* Force sidebar to shrink to fit close icon */}
            </div>
          </Dialog>
        </Transition.Root>
      )}
      {/* Static sidebar for desktop */}
      {isDesktopOrLaptop && (
        <div className="fixed bottom-0 z-30 hidden md:flex md:flex-shrink-0 top-12">
          <div className="flex flex-col w-56">
            {/* Sidebar component, swap this element with another sidebar if you like */}
            <div className="flex flex-col flex-1 h-0 bg-gray-200 border-r border-gray-300 dark:border-gray-600 dark:bg-gray-800 ">
              <div className="flex flex-col flex-1 pb-4 overflow-y-auto">
                <nav
                  className="flex-1 px-2 mt-4 space-y-1 bg-gray-200 dark:bg-gray-800"
                  aria-label="Sidebar"
                >
                  {props.navigation.map((item) => {
                    if (item) {
                      return !item.children ? (
                        <div key={item.name}>
                          <Link
                            to={item.href}
                            className={classNames(
                              item.current
                                ? "bg-gray-300 dark:bg-gray-600 text-gray-900 dark:text-gray-300"
                                : "bg-gray-200 dark:bg-gray-800 dark:hover:bg-gray-600 dark:focus:bg-gray-600 text-gray-600 dark:text-gray-300 hover:bg-gray-300 focus:bg-gray-300 dark:hover:text-white dark:focus:text-white",
                              "group w-full flex items-center pl-2 py-2 text-sm font-medium rounded justify-between"
                            )}
                          >
                            <div className="flex">
                              <item.icon
                                className={classNames(
                                  item.current
                                    ? "text-gray-900 dark:text-gray-300"
                                    : "text-gray-900 dark:text-gray-300 group-hover:text-gray-900 dark:group-hover:text-gray-300",
                                  "mr-3 h-6 w-6"
                                )}
                                aria-hidden="true"
                              />
                              {item.name}
                            </div>

                            {item.search_count && (
                              <span className="inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-green-500 text-white mr-2">
                                {item.search_count}
                              </span>
                            )}
                          </Link>
                        </div>
                      ) : (
                        <Disclosure
                          as="div"
                          key={item.name}
                          defaultOpen={item.current ? true : false}
                          className="space-y-1"
                        >
                          {({ open }) => (
                            <>
                              <Disclosure.Button
                                className={classNames(
                                  item.current
                                    ? "dark:bg-gray-800 text-gray-900 dark:text-gray-300"
                                    : "bg-gray-200 dark:bg-gray-800 dark:hover:bg-gray-600 dark:focus:bg-gray-600 text-gray-600 dark:text-gray-300 hover:bg-gray-300 focus:bg-gray-300 dark:hover:text-white dark:focus:text-white",
                                  "group w-full flex items-center pl-2 pr-1 py-2 text-sm font-medium rounded focus:outline-none"
                                )}
                              >
                                <item.icon
                                  className="w-6 h-6 mr-3 text-gray-900 dark:text-gray-300 group-hover:text-gray-900 group-focus:text-gray-900 dark:group-hover:text-gray-300 dark:group-focus:text-gray-300"
                                  aria-hidden="true"
                                />
                                {item.name}
                                <svg
                                  className={classNames(
                                    open
                                      ? "text-gray-900 dark:text-gray-300 rotate-90"
                                      : "text-gray-900 dark:text-gray-300",
                                    "ml-auto h-5 w-5 group-hover:text-gray-900 group-focus:text-gray-900 dark:group-hover:text-gray-300 dark:group-focus:text-gray-300 dark:text-gray-300 transition-colors ease-in-out duration-150"
                                  )}
                                  viewBox="0 0 20 20"
                                  aria-hidden="true"
                                >
                                  <path
                                    d="M6 6L14 10L6 14V6Z"
                                    fill="currentColor"
                                  />
                                </svg>
                              </Disclosure.Button>

                              <Disclosure.Panel className="space-y-1">
                                {item.children.map((subItem) => (
                                  <Link
                                    key={subItem.name}
                                    to={subItem.href}
                                    className={classNames(
                                      subItem.current
                                        ? "bg-gray-300 dark:bg-gray-600 text-gray-900 dark:text-gray-300"
                                        : "",
                                      `group w-full flex items-center pl-11 pr-2 py-2 text-sm font-medium
                                  text-gray-600 rounded dark:text-gray-300 dark:hover:text-gray-300 dark:focus:text-gray-300 hover:bg-gray-300 dark:hover:bg-gray-600 dark:focus:bg-gray-600`
                                    )}
                                  >
                                    {subItem.name}
                                  </Link>
                                ))}
                              </Disclosure.Panel>
                            </>
                          )}
                        </Disclosure>
                      );
                    }
                    return null;
                  })}
                </nav>
              </div>

              {/* <div className="flex flex-shrink-0 p-4 border-t border-gray-200">
            
              </div> */}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
