// Allows access to authenticated user who are guest
export const IsGuest = "10";

// Allows access to authenticated user who are user
export const IsUser = "20";

// Allows access to authenticated user who are Manager
export const IsManager = "30";

// Allows access to authenticated user who are Admin
export const IsAdmin = "40";

// Allows access to authenticated user who are Owner
export const IsOwner = "50";

// Allows access to authenticated user who are Bot
export const IsBot = "60";
