// import react components
import React, { useEffect, useState } from "react";
// import react router components
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
// import notfication alert
import { useSnackbar } from "notistack";
// import page components
import Header from "../../Components/Header";
import Content from "../../Components/Content";
import Sidebar from "../../Components/Sidebar";
import {
  HideInitialSidebarInMobile,
  navigation,
} from "../../Components/utilities";
import ProfileInformation from "./ProfileInformation";
import ConnectedAccounts from "./ConnectedAccounts";
import MobileNumber from "./MobileNumber";
import EmailAddress from "./EmailAddress";

function Profile(props) {
  // for sidebar
  const [showSidebar, setshowSidebar] = useState(HideInitialSidebarInMobile());

  const currentURL = props.match.url;

  // show alert and close alert functions
  const { enqueueSnackbar } = useSnackbar();

  var showAlreadyLoggedInAlert =
    props.location &&
    props.location.state &&
    props.location.state.showAlreadyLoggedInAlert;

  React.useEffect(() => {
    // if url location state has show already logged is true
    if (showAlreadyLoggedInAlert) {
      enqueueSnackbar("You are already logged in", {
        variant: "success",
        autoHideDuration: 4000,
        preventDuplicate: true,
      });
    }
  }, [showAlreadyLoggedInAlert, enqueueSnackbar]);

  // listner for url change
  useEffect(() => {
    let div_id = "";
    // if url matches assign div_id
    if (currentURL === "/profile/personal") {
      div_id = "personal_div";
    } else if (currentURL === "/profile/email-address") {
      div_id = "email_div";
    } else if (currentURL === "/profile/mobile-numbers") {
      div_id = "mobile_div";
    } else if (currentURL === "/profile/connected-accounts") {
      div_id = "connected_accounts";
    }

    // scroll to div position
    let div_position = document.getElementById(div_id);
    window.scrollTo({
      top: div_position.offsetTop - 50,
      behavior: "smooth",
    });
  }, [currentURL]);

  return (
    <>
      {/* pass current page as props as true */}
      <Header
        Profile={true}
        handleSidebar={() => setshowSidebar(!showSidebar)}
      />

      <Sidebar
        showSidebar={showSidebar}
        setshowSidebar={setshowSidebar}
        navigation={navigation(props)}
      />

      <Content showSidebar={showSidebar}>
        <ProfileInformation />

        <EmailAddress />

        <MobileNumber />

        <ConnectedAccounts />
      </Content>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setTimeZone: (data) => dispatch({ type: "setTimeZone", ...data }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Profile));
