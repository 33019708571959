import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { classNames } from "./utilities";
import { RiCloseLine } from "react-icons/ri";

const ConfirmationModal = (props) => {
  const [open, setOpen] = useState(false);

  const cancelButtonRef = useRef();

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        initialFocus={cancelButtonRef}
        open={open}
        onClose={setOpen}
      >
        <div className="flex items-start justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="w-full max-w-md inline-block px-4 pt-5 pb-4 mt-12 text-left align-top transition-all transform bg-white rounded-lg shadow-xl over dark:bg-gray-900">
              <div className="flex items-start">
                <div className="flex-shrink-0">{props.icon && props.icon}</div>
                <div className="flex-1 w-0 ml-3">
                  {props.heading && props.heading}
                  <div className="flex justify-start space-x-3">
                    <button
                      type="button"
                      className={classNames(
                        props.confirmButtonClassName
                          ? props.confirmButtonClassName
                          : "button button-danger"
                      )}
                      onClick={props.accept}
                    >
                      {props.confirmButtonName
                        ? props.confirmButtonName
                        : "Delete"}
                    </button>
                    <button
                      type="button"
                      className="text-gray-900 dark:text-gray-300"
                      onClick={() => props.close(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
                <div className="flex flex-shrink-0 ml-4">
                  <button
                    type="button"
                    className="closemodalicon"
                    onClick={() => props.close(false)}
                  >
                    <span className="sr-only">Close</span>
                    <RiCloseLine className="w-6 h-6" aria-hidden="true" />
                  </button>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ConfirmationModal;
