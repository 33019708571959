export default function Card({ id, heading, description, children }) {
  return (
    <div
      id={id}
      className="bg-white border border-gray-300 dark:border-gray-600
        dark:bg-gray-800 sm:rounded-lg overflow-hidden 
        shadow rounded-lg divide-y divide-gray-200 dark:divide-gray-600 "
    >
      <div className="p-5">
        <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-200">
          {heading}
        </h3>
        <p className="mt-1 text-sm text-gray-1500 dark:text-gray-3000">
          {description}
        </p>
      </div>
      <div className="pb-6">{children}</div>
    </div>
  );
}
