import React, { useEffect, useState } from "react";
// import react hotkeys
import { getApplicationKeyMap } from "react-hotkeys";
// import router component
import { withRouter } from "react-router-dom";
import SearchInput from "./SearchInput";
import NoDataMessageComponent from "../Components/NoDataMessageComponent";
import { RiSettings5Line } from "react-icons/ri";

function ShortcutsSlider(props) {
  const [searchKeys, setSearchKeys] = useState("");

  const [filteredData, setFilteredData] = useState([]);

  // listner for search input
  useEffect(() => {
    // get app key map from hot keys
    const keyMap = getApplicationKeyMap();

    let datas = Object.keys(keyMap).map((key) => {
      const { sequences, name, description } = keyMap[key];

      // filter sequence shorcuts
      let sequence_list = sequences.map((data) => data["sequence"]);
      // content to display

      let render_data = () => (
        <li key={name} className="flex justify-between py-1">
          <span className="text-gray-900 dark:text-white text-sm ">
            {description}
          </span>
          <span className="flex">
            {/* loop sequence and render */}
            {sequence_list.length >= 2
              ? sequence_list
                  .map((sequence) => (
                    <span
                      key={sequence}
                      className="dark:text-gray-900 text-white bg-gray-600 dark:bg-gray-300 mx-3 px-2 py-0.5 rounded text-sm"
                    >
                      {sequence}
                    </span>
                  ))
                  .reduce((prev, curr) => [
                    prev,
                    <span className="dark:text-white"> or </span>,
                    curr,
                  ])
              : sequence_list.map((sequence) => (
                  <>
                    <p
                      key={sequence}
                      className="dark:text-gray-900 text-white bg-gray-600 dark:bg-gray-300 mx-1 px-2 py-0.5 mr-1 rounded text-sm"
                    >
                      {sequence.split("+")[0]}
                    </p>

                    {sequence.split("+").length >= 2 && (
                      <span className="dark:text-white"> + </span>
                    )}

                    {sequence.split("+").length >= 2 ? (
                      <p
                        key={sequence}
                        className="dark:text-gray-900 text-white bg-gray-600 dark:bg-gray-300 mx-1 text-center w-10 h-5.5 ml-2 rounded text-sm"
                      >
                        {sequence.split("+")[1]}
                      </p>
                    ) : (
                      ""
                    )}

                    {/* three keys combination */}
                    {sequence.split("+").length === 3 && (
                      <span className="dark:text-white"> + </span>
                    )}
                    {sequence.split("+").length === 3 && (
                      <p
                        // key={sequence}
                        className={`${
                          sequence.split("+")[2] !== ""
                            ? "dark:text-gray-900 text-white bg-gray-600 dark:bg-gray-300 mx-1 text-center w-10 h-5.5 ml-2 rounded text-sm"
                            : "hidden"
                        } `}
                      >
                        {sequence.split("+")[2]}
                      </p>
                    )}
                  </>
                ))}
          </span>
        </li>
      );

      // filter data based on search input
      if (searchKeys) {
        // lowercase description and check search key in description
        // if exists
        if (description.toLowerCase().includes(searchKeys)) {
          // render data
          return render_data();
        }
        return null;
      }
      // if search key is empty
      else {
        return render_data();
      }
    });

    // set filtered shortcuts
    setFilteredData(datas);
  }, [searchKeys]);

  // listener of slideoverlay open or close
  // set search key as empty
  React.useEffect(() => {
    setSearchKeys("");
  }, [props.open]);

  return (
    <>
      {filteredData.length > 0 ? (
        // <div className="w-screen max-w-lg">

        <div className="flex flex-col">
          <div>
            <SearchInput
              searchHandler={setSearchKeys}
              placeholder="Filter by Keywords"
            />
          </div>
          <div className="py-3">
            {/* list of keyboard shortcuts */}
            <ul>{filteredData}</ul>
          </div>
        </div>
      ) : (
        // </div>

        <NoDataMessageComponent
          {...{
            title: "Enable Keyboard Shortcuts",
            content: " ",
            url: `/user/profile`,
            button_icon: (
              <RiSettings5Line
                className="w-5 h-5 mr-2 -ml-1"
                aria-hidden="true"
              />
            ),
            button_name: "Settings",
          }}
        />
      )}
    </>
  );
}

export default withRouter(ShortcutsSlider);
