// keyboard shortcuts
import { GlobalHotKeys } from "react-hotkeys";
// import jquery
import $ from "jquery";
// import react route
import { useHistory, withRouter } from "react-router-dom";
import React, { useState, useEffect } from "react";
// import redux component
import { connect } from "react-redux";

function CustomGlobalHotKeys(props) {
  const history = useHistory();

  // Initialize states
  const [keyboardShortcuts, setKeyboardShortcuts] = useState(true);

  // listner for keyboard shortcuts enable || disable
  useEffect(() => {
    // console.log("enableKeyboardShorcuts", props.enableKeyboardShorcuts);
    setKeyboardShortcuts(props.enableKeyboardShorcuts);
  }, [props.enableKeyboardShorcuts]);

  // shortcuts keys
  const keyMap = {
    // SEARCH: {
    //   name: "search",
    //   description: "Start search",
    //   sequences: ["s", "/"],
    // },

    // REPOSITORIES: {
    //   name: "repositories",
    //   description: "Go to your repositories",
    //   sequences: ["shift+r"],
    // },

    // PRODUCTS: {
    //   name: "products",
    //   description: "Go to your products",
    //   sequences: ["shift+p"],
    // },

    // TEAMS: {
    //   name: "teams",
    //   description: "Go to your teams",
    //   sequences: ["shift+t"],
    // },

    // ORGANIZATION_SETTING: {
    //   name: "organization_setting",
    //   description: "Go to your Organization setting",
    //   sequences: ["shift+s"],
    // },

    SUBMIT_FORM: {
      name: "submit_form",
      description: "Submit any form",
      sequences: ["ctrl+enter"],
    },

    TOGGLE_THEME: {
      name: "toggle_theme",
      description: "Toggle between Light and Dark mode",
      sequences: ["ctrl+shift+l"],
    },

    PREVIOUS_PAGE: {
      name: "previous_page",
      description: "To go back to Previous page",
      sequences: ["ctrl+["],
    },

    NEXT_PAGE: {
      name: "next_page",
      description: "To go back to Next page",
      sequences: ["ctrl+]"],
    },

    EXIT: {
      name: "esc",
      description: "To close or exit from modals, popups & slide-overs",
      sequences: ["esc"],
    },

    // CREATE_OBJECT: {
    //   name: "create_object",
    //   description: "To create a new object (based on the page opened)",
    //   sequences: ["shift+n"],
    // },
  };

  const keyHandlers = {
    SEARCH: (event) => {
      let GlobalSearchInput = $("#search");

      if (GlobalSearchInput !== null) {
        // console.log(event);

        event.preventDefault();

        GlobalSearchInput.focus();
      }
      return true;
    },

    REPOSITORIES: (event) => {
      history.push("/profile/personal");
      return true;
    },

    PRODUCTS: (event) => {
      history.push("/products");
      return true;
    },

    TEAMS: (event) => {
      history.push("/teams");
      return true;
    },

    ORGANIZATION_SETTING: (event) => {
      history.push("/organization_settings");
      return true;
    },

    PREVIOUS_PAGE: (event) => {
      history.goBack();
      return true;
    },

    NEXT_PAGE: (event) => {
      history.goForward();
      return true;
    },

    TOGGLE_THEME: (event) => {
      event.preventDefault();
      if (localStorage.getItem("theme") === "dark") {
        // remove classname dark from html tag
        document.documentElement.classList.remove("dark");

        // save theme mode as light in local storage
        localStorage.setItem("theme", "light");

        return true;
      } else if (localStorage.getItem("theme") === "light") {
        // add classname as dark in html tag
        document.documentElement.classList.add("dark");

        // save theme mode as dark in local storage
        localStorage.setItem("theme", "dark");

        return true;
      } else {
        // if theme is dark in localstorage or system default color is dark
        if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
          // add classname as dark in html tag
          document.documentElement.classList.add("dark");

          // save theme mode as dark in local storage
          localStorage.setItem("theme", "system");

          return true;
        } else {
          // remove classname dark from html tag
          document.documentElement.classList.remove("dark");

          // save theme mode as light in local storage
          localStorage.setItem("theme", "system");
        }

        return true;
      }
    },
  };

  return (
    // if keyboardShortcuts is true enable keyboard Shortcuts
    // else pass empty object in kepMap
    <GlobalHotKeys
      keyMap={keyboardShortcuts ? keyMap : {}}
      handlers={keyHandlers}
      allowChanges={true}
    >
      {props.children}
    </GlobalHotKeys>
  );
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(withRouter(CustomGlobalHotKeys));
