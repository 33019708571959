import { Dialog } from "@headlessui/react";
// import react icons
import {
  RiCloseLine,
  RiSettings5Line,
  RiCheckboxCircleLine,
  RiMailLine,
  RiMessage2Line,
  RiKeyboardBoxLine,
} from "react-icons/ri";
// import redux
import { connect } from "react-redux";
// import router component
import { Link, withRouter } from "react-router-dom";
// import axios
import { get, post } from "./AxiosUtilities";
// import urls
import {
  fetch_image_url,
  keycloak_logout_url,
  last_accessed_org_url,
} from "./Urls";
import React, { useCallback, useEffect, useState } from "react";
// import avatar component
import Avatar from "react-avatar";
// import modal component
import Modal from "../Components/Modal";
// import react component
// import feedback component
import Feedback from "../Pages/Feedback";
import ShortcutsSlider from "./ShortcutsSlider";
import { useSnackbar } from "notistack";

// import version from package.json
var package_version = require("../../package.json");

function SlideOverlay(props) {
  // feedback modal state
  const [ShowFeedBackModal, setShowFeedBackModal] = useState(false);
  const [showKeyboardShortcutModal, setShowKeyboardShortcutModal] =
    useState(false);

  // show alert and close alert functions
  const { enqueueSnackbar } = useSnackbar();

  // const [values, setValues] = useState({
  //   user_image: "",
  // });

  //  close the modal using the "ESC" key1
  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        setShowFeedBackModal(false);
        setShowKeyboardShortcutModal(false);
      } else if (e.shiftKey && e.which === 191) {
        setShowKeyboardShortcutModal(true);
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);

  // function to logout the user
  const handleLogout = () => {
    post(
      // REACT_APP_SSO_ENABLED === "true"
      keycloak_logout_url
      // : account_logout_url
    )
      .then((res) => {
        // // console.log('logout response', res.data);
        props.logoutUser();
        // props.history.push(REACT_APP_SSO_ENABLED ? REACT_APP_KEYCLOAK_URL : '/');
      })
      .catch((err) => {
        // console.log(err);
        if (err.response && err.response.data.message) {
          enqueueSnackbar(err.response.data.message, {
            variant: "error",
            autoHideDuration: 3000,
          });
        } else {
          enqueueSnackbar("Logout request failed, Please try again.", {
            variant: "error",
            autoHideDuration: 3000,
          });
        }
      });
  };

  // handle component update
  // useEffect(() => {
  //   const getUserData = () => {
  //     get(account_user_data_url)
  //       .then((res) => {
  //         console.log("response", res);

  //         if (props.hasOwnProperty("handleTimezone")) {
  //           props.handleTimezone(res.data.timezone);
  //         }

  //         // set state values
  //         setValues((prevState) => {
  //           return {
  //             ...prevState,
  //             timezone: res.data.user_data.timezone,
  //             avatar_url: res.data.user_data.avatar_url,
  //             first_name: res.data.user_data.first_name,
  //             last_name: res.data.user_data.last_name,
  //             email: res.data.user_data.email,
  //             organizations: res.data.user_data.organizations,
  //           };
  //         });
  //       })
  //       .catch((err) => {
  //         // console.log(err);

  //         // if user is unathenticated logout the user
  //         props.logoutUser();
  //       });
  //   };

  //   // function to get user data
  //   getUserData();
  // }, [props]);

  // fetching the image
  // useEffect(() => {
  //   const fetchImage = () => {
    
  //     get(
  //       fetch_image_url +
  //         `?image_type=profile&user_uid=${props.user_uid}&width=64`
  //     )
  //       .then((res) => {
  //         // console.log(res);
  //         setValues({
  //           user_image: res.data.url,
  //         });
  //       })
  //       .catch((err) => console.log(err));
  //   };
  //   fetchImage();
  // }, []);

  return (
    <div className="flex flex-col h-full bg-white shadow-xl dark:bg-gray-800">
      <div className="flex items-center justify-between px-4 py-6 bg-gray-50 dark:bg-gray-600 sm:px-6">
        <div>
          {!props.avatar_url && (
            <Avatar
              name={props.first_name && props.first_name[0]}
              size="60"
              round="30px"
              className="rounded-full h-16 w-16"
            />
          )}
          {props.avatar_url && (
            <img
              className="w-16 h-16 rounded-full"
              src={props.avatar_url}
              alt={props.first_name}
            />
          )}
        </div>

        <div className="flex flex-col mr-3 xs:mr-0">
          {props.first_name !== "NA" && (
            <Dialog.Title className="text-lg text-gray-900 dark:text-white">
              {props.first_name} {props.last_name}
            </Dialog.Title>
          )}

          <p className="text-sm text-gray-1300 dark:text-gray-1500">
            {props.email}
          </p>

          <div className="flex mt-2">
            {/* <Link
              to="/profile/personal"
              className="text-sm font-normal text-indigo-700 dark:text-indigo-400 focus:outline-none"
            >
              Settings
            </Link>

            <span className="mx-4 border border-gray-300 dark:border-gray-400"></span> */}

            <span
              className="text-sm font-normal text-red-500 cursor-pointer focus:outline-none"
              onClick={handleLogout}
            >
              Sign Out
            </span>
          </div>
        </div>
        <div className="relative mb-8 pb-4">
          <button
            className="text-gray-500 rounded-md dark:text-gray-400 hover:text-gray-600 dark:hover:text-white focus:outline-none"
            onClick={() => props.Close(false)}
          >
            <span className="sr-only">Close panel</span>
            <RiCloseLine className="w-6 h-6" aria-hidden="true" />
          </button>
        </div>
      </div>

      {/* <div className="px-2 pt-2 pb-3 space-y-1 border-b border-gray-200 lg:hidden">
                                        {
                                            props.nav_links &&

                                            props.nav_links.map((link_detail, index) =>

                                                !link_detail.children ? (
                                                    (
                                                        <Link to={link_detail.href}
                                                            className={classNames(
                                                                link_detail.active ?
                                                                    'bg-gray-400 dark:bg-gray-600 text-gray-800 dark:text-gray-100  block px-3 py-2 rounded text-base font-medium'
                                                                    :
                                                                    'text-gray-900 dark:text-gray-100',
                                                                'block px-3 py-2 rounded text-base font-normal'
                                                            )}
                                                        >
                                                            {link_detail.name}
                                                        </Link>
                                                    )
                                                )

                                                    :
                                                    (
                                                        <Disclosure>
                                                            {({ open }) => (
                                                                <>
                                                                    <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-base font-normal text-left text-gray-800 rounded-lg dark:text-white focus:outline-none ">
                                                                        <span>{link_detail.name}</span>
                                                                        <ChevronUpIcon
                                                                            className={`${open ? 'rotate-180' : ''
                                                                                } w-5 h-5 text-gray-800 dark:text-white`}
                                                                        />
                                                                    </Disclosure.Button>
                                                                    <Disclosure.Panel className="px-4 py-2 pb-2 text-sm ">
                                                                        {
                                                                            link_detail.children.map((link) => {
                                                                                return (<a
                                                                                    href={link.href}
                                                                                    target="_blank"
                                                                                    className={`${link.active ? 'text-gray-800 dark:text-white ' : 'text-gray-800 dark:text-white'
                                                                                        } group flex rounded items-center w-full px-2 py-2 text-sm font-medium cursor-pointer`}
                                                                                >
                                                                                    {link.active ? (
                                                                                        <ExternalLinkIcon
                                                                                            className="w-5 h-5 mr-2"
                                                                                            aria-hidden="true"
                                                                                        />
                                                                                    ) : (
                                                                                            <ExternalLinkIcon
                                                                                                className="w-5 h-5 mr-2"
                                                                                                aria-hidden="true"
                                                                                            />
                                                                                        )}
                                                                                    {link.name}
                                                                                </a>)
                                                                            })
                                                                        }
                                                                    </Disclosure.Panel>
                                                                </>
                                                            )}
                                                        </Disclosure>
                                                    )
                                            )
                                        }

                                    </div> */}

      <div className="relative flex-1 px-4 py-3 sm:px-6 overflow-y-auto">
        <div className="inset-0 h-full">
          <div className="flex items-center justify-between border-b border-gray-300 dark:border-gray-600 pb-3">
            <p className="text-lg font-medium text-gray-900 dark:text-white">
              My Organizations
            </p>

            <Link
              to="/switch/organization/"
              className="flex items-center text-sm font-normal text-indigo-700 cursor-pointer dark:text-indigo-400 focus:outline-none"
            >
              <RiSettings5Line className="w-4 h-4" />
              Manage
            </Link>
          </div>

          <div className="overflow-hidden sm:rounded-md">
            <ul className="divide-y divide-gray-200 dark:divide-gray-600">
              {React.Children.toArray(
                props?.organizations?.map((organization) => (
                  <li>
                    <Link
                      to="#"
                      // to={organization.status ? '' : `/onboard/organization/${organization.uid}/connection/`}
                      className="block bg-white dark:bg-gray-800"
                      onClick={(e) => {
                        e.preventDefault();
                        // Allow user to switch organization if this is not current organization
                        if (props.uid !== organization.uid) {
                          props.setUserAuth(organization);
                          // props.setOrganizationUID({
                          //   organization_uid: organization.organization_uid,
                          // });

                          // post method to assign last accessed organization_uid in backend
                          post(last_accessed_org_url, {
                            organization_uid: organization.uid,
                          })
                            .then((res) => {
                              console.log(
                                "successfully assigned last accessed organization_uid."
                              );

                              // redirect to home page
                              props.history.push("/profile/personal");
                            })
                            .catch((err) => {
                              console.log(
                                "Error occured while assigning last accessed organization_uid."
                              );
                            });
                        }
                      }}
                    >
                      <div className="flex items-center py-3">
                        <div className="flex items-center flex-1 min-w-0">
                          <div className="flex-1 min-w-0">
                            <div className="w-full">
                              <p className="text-sm font-medium text-gray-900 truncate dark:text-gray-300">
                                {organization.name}
                              </p>
                              <p className="flex items-center mt-1 text-sm text-gray-1300 dark:text-gray-1500">
                                <span className="truncate">
                                  {organization?.email}
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div>
                          {props.uid === organization.uid && (
                            <RiCheckboxCircleLine
                              className="w-5 h-5 text-green-500"
                              aria-hidden="true"
                            />
                          )}
                        </div>
                      </div>
                    </Link>
                  </li>
                ))
              )}
            </ul>
          </div>
        </div>
      </div>
      <footer className="border-t border-gray-300 dark:border-gray-600">
        <div className="px-4 mx-2 mt-2 pt-2">
          <p className="text-lg font-medium text-gray-900 dark:text-white">
            Need Help?
          </p>
          <div className="grid grid-cols-2 gap-x-2 mt-1 xs:grid-cols-1">
            <button
              className="flex items-center hover:bg-gray-200 dark:hover:bg-gray-600 rounded p-2 -ml-2"
              onClick={(e) => {
                e.preventDefault();
                setShowFeedBackModal(true);
              }}
            >
              <RiMessage2Line className="w-4 h-4 mt-0.5 text-gray-600 fill-current dark:text-gray-300 hover:text-gray-900 dark:hover:text-white" />
              <p className="ml-2 text-sm font-sm text-gray-600 dark:text-gray-100">
                Send Feedback
              </p>
            </button>
            <button className="flex items-center hover:bg-gray-200 dark:hover:bg-gray-600 rounded p-2 -ml-2">
              <RiMailLine className="w-4 h-4 ml-2 xs:ml-0 mt-0.5 text-gray-600 fill-current dark:text-gray-300 hover:text-gray-900 dark:hover:text-white" />
              <p className="ml-2 text-sm font-sm text-gray-600 dark:text-gray-100">
                Write to us
              </p>
            </button>
            <button
              className="flex items-center hover:bg-gray-200 dark:hover:bg-gray-600 rounded p-2 -ml-2"
              onClick={() => {
                setShowKeyboardShortcutModal(true);
              }}
            >
              <RiKeyboardBoxLine className="w-4 h-4 mt-0.5 text-gray-600 fill-current dark:text-gray-300 hover:text-gray-900 dark:hover:text-white" />
              <p className="ml-2 text-sm font-sm text-gray-600 dark:text-gray-100">
                Keyboard Shortcuts
              </p>
            </button>
          </div>
          <div className="flex justify-between pt-4 pb-2">
            <p className="text-sm text-center xs:text-xs text-gray-1300 dark:text-gray-1500">
              &copy; {`${new Date().getFullYear()}`} VegaStack - PeerXP
            </p>
            <p className="text-sm text-gray-600 xs:text-xs dark:text-gray-300">
              v{package_version.version ? package_version.version : "0.0"}
            </p>
          </div>
        </div>
      </footer>
      <Modal
        open={ShowFeedBackModal}
        heading={
          <div className="flex justify-between mb-4 items-center">
            <p className="text-gray-900 dark:text-gray-300">
              VegaMaster Feedback
            </p>

            <button
              type="button"
              className="closemodalicon"
              onClick={() => setShowFeedBackModal(false)}
            >
              <span className="sr-only">Close</span>
              <RiCloseLine className="w-6 h-6" aria-hidden="true" />
            </button>
          </div>
        }
      >
        {/* feedback section */}
        <Feedback setShowFeedBackModal={setShowFeedBackModal} />
      </Modal>
      <Modal
        open={showKeyboardShortcutModal}
        heading={
          <div className="flex justify-between mb-4 items-center">
            <p className="text-gray-900 dark:text-gray-300">
              Keyboard Shortcuts
            </p>

            <button
              type="button"
              className="closemodalicon"
              onClick={() => setShowKeyboardShortcutModal(false)}
            >
              <span className="sr-only">Close</span>
              <RiCloseLine className="w-6 h-6" aria-hidden="true" />
            </button>
          </div>
        }
      >
        <ShortcutsSlider setShowFeedBackModal={setShowKeyboardShortcutModal} />
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => {
  return {
    // dispatching plain actions
    setUserAuth: (data) => dispatch({ type: "setUserAuth", ...data }),
    logoutUser: () => dispatch({ type: "logoutUser" }),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SlideOverlay));
