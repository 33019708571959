import React, { useCallback, useMemo, useState } from "react";
import { format } from "timeago.js";
import { get } from "../../Components/AxiosUtilities";
import { convertTZ } from "../../Components/TimezoneHandler";
import Table from "../../Components/Tables/Table";
import TableLoader from "../../Components/Loaders/TableLoader.jsx";
import Tooltip from "../../Components/Tooltip";
import {
  authentication_log_download_url,
  authentication_log_url,
} from "../../Components/Urls";
import {
  HideInitialSidebarInMobile,
  navigation,
} from "../../Components/utilities";
import Header from "../../Components/Header";
import Sidebar from "../../Components/Sidebar";
import Content from "../../Components/Content";
import {
  RiAppleFill,
  RiFileDownloadLine,
  RiMailLine,
  RiUbuntuFill,
  RiWindowsFill,
  RiCellphoneLine,
  RiTabletLine,
  RiComputerLine,
} from "react-icons/ri";
import { FaLinux } from "react-icons/fa";

const AuthenticationLog = (props) => {
  // for sidebar
  const [showSidebar, setshowSidebar] = useState(HideInitialSidebarInMobile());

  const fetchIdRef = React.useRef(0);

  // authtokens table states
  const [authtokensData, setAuthtokensData] = useState([]);
  const [authtokensLoading, setAuthtokensLoading] = useState(false);
  const [authPageCount, setAuthPageCount] = useState(0);

  const getIdentityProviderLogo = (identityProvider) => {
    if (identityProvider === "microsoft") {
      return (
        <svg
          className="h-5 w-5"
          enableBackground="new 0 0 2499.6 2500"
          viewBox="0 0 2499.6 2500"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="m1187.9 1187.9h-1187.9v-1187.9h1187.9z" fill="#f1511b" />
          <path
            d="m2499.6 1187.9h-1188v-1187.9h1187.9v1187.9z"
            fill="#80cc28"
          />
          <path d="m1187.9 2500h-1187.9v-1187.9h1187.9z" fill="#00adef" />
          <path d="m2499.6 2500h-1188v-1187.9h1187.9v1187.9z" fill="#fbbc09" />
        </svg>
      );
    } else if (identityProvider === "google") {
      return (
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 48 48"
          className="h-5 w-5"
        >
          <g>
            <path
              fill="#EA4335"
              d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
            ></path>
            <path
              fill="#4285F4"
              d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
            ></path>
            <path
              fill="#FBBC05"
              d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
            ></path>
            <path
              fill="#34A853"
              d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
            ></path>
            <path fill="none" d="M0 0h48v48H0z"></path>
          </g>
        </svg>
      );
    } else {
      return <RiMailLine className="h-6 w-6" />;
    }
  };

  const getOperatingSystem = (system, version) => {
    if (system === "Windows" || system === "windows") {
      return (
        <span className="flex">
          <Tooltip
            placement="top"
            trigger="hover"
            tooltip={`Operating System : ${system} ${version}`}
          >
            <RiWindowsFill className="w-5 h-5 text-blue-600" />
          </Tooltip>
        </span>
      );
    } else if (system === "macOS") {
      return (
        <span className="flex">
          <Tooltip
            placement="top"
            trigger="hover"
            tooltip={`Operating System : ${system} ${version}`}
          >
            <RiAppleFill className="w-5 h-5 text-gray-300" />
          </Tooltip>
        </span>
      );
    } else if (system === "Ubuntu") {
      return (
        <span className="flex">
          <Tooltip
            placement="top"
            trigger="hover"
            tooltip={`Operating System : ${system} ${version}`}
          >
            <RiUbuntuFill className="w-5 h-5 text-orange-600" />
          </Tooltip>
        </span>
      );
    } else if (system === "Linux") {
      return (
        <span className="flex">
          <Tooltip
            placement="top"
            trigger="hover"
            tooltip={`Operating System : ${system} ${version}`}
          >
            <FaLinux className="w-5 h-5 text-yellow-600" />
          </Tooltip>
        </span>
      );
    }
  };

  const getBrowserName = (browser) => {
    if (browser?.name === "Edge") {
      return (
        <span className="flex">
          <Tooltip
            placement="top"
            trigger="hover"
            tooltip={`Browser : Mircosoft ${browser?.name},   Version : ${browser?.version}`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 48 48"
              className="h-5 w-5"
            >
              <path
                fill="#1976D2"
                d="M13.3,15.1C8.7,17.9,6,21.9,6,21.9s0.7-8.4,7.3-13.3c2.6-2,6.2-3.6,11-3.6c1.8,0,5.6,0.3,9,2.4s4.8,3.8,6.4,6.3c0.7,1.1,1.2,2.5,1.5,3.8c0.6,2.5,0.7,5.6,0.7,5.6V27H17c0,0-0.1,8.1,11.2,8.1c3.9,0,5.3-0.6,6.6-1c2-0.6,4.2-2.1,4.2-2.1v8c0,0-4.9,3-11.9,3c-2,0-4-0.2-6-0.8c-1.7-0.6-5.4-2-7.8-5.5c-0.9-1.2-1.8-2.9-2.3-4.5c-0.5-1.7-0.5-3.4-0.5-4.3c0-3.5,1.2-6.8,3.3-9.2C16.6,15.6,20,14,20,14s-3,2.8-3,6h14c0,0,0.1-8-7.7-8C20.1,12,16.1,13.4,13.3,15.1z"
              ></path>
            </svg>{" "}
          </Tooltip>
        </span>
      );
    } else if (browser?.name === "Chrome") {
      return (
        <span className="flex">
          <Tooltip
            placement="top"
            trigger="hover"
            tooltip={`Browser : ${browser?.name}, Version : ${browser?.version}`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 48 48"
              className="h-5 w-5"
            >
              <path
                fill="#4caf50"
                d="M44,24c0,11.044-8.956,20-20,20S4,35.044,4,24S12.956,4,24,4S44,12.956,44,24z"
              ></path>
              <path
                fill="#ffc107"
                d="M24,4v20l8,4l-8.843,16c0.317,0,0.526,0,0.843,0c11.053,0,20-8.947,20-20S35.053,4,24,4z"
              ></path>
              <path
                fill="#4caf50"
                d="M44,24c0,11.044-8.956,20-20,20S4,35.044,4,24S12.956,4,24,4S44,12.956,44,24z"
              ></path>
              <path
                fill="#ffc107"
                d="M24,4v20l8,4l-8.843,16c0.317,0,0.526,0,0.843,0c11.053,0,20-8.947,20-20S35.053,4,24,4z"
              ></path>
              <path
                fill="#f44336"
                d="M41.84,15H24v13l-3-1L7.16,13.26H7.14C10.68,7.69,16.91,4,24,4C31.8,4,38.55,8.48,41.84,15z"
              ></path>
              <path
                fill="#dd2c00"
                d="M7.158,13.264l8.843,14.862L21,27L7.158,13.264z"
              ></path>
              <path
                fill="#558b2f"
                d="M23.157,44l8.934-16.059L28,25L23.157,44z"
              ></path>
              <path
                fill="#f9a825"
                d="M41.865,15H24l-1.579,4.58L41.865,15z"
              ></path>
              <path
                fill="#fff"
                d="M33,24c0,4.969-4.031,9-9,9s-9-4.031-9-9s4.031-9,9-9S33,19.031,33,24z"
              ></path>
              <path
                fill="#2196f3"
                d="M31,24c0,3.867-3.133,7-7,7s-7-3.133-7-7s3.133-7,7-7S31,20.133,31,24z"
              ></path>
            </svg>
          </Tooltip>
        </span>
      );
    } else if (browser?.name === "Safari") {
      return (
        <span className="flex">
          <Tooltip
            placement="top"
            trigger="hover"
            tooltip={`Browser : ${browser?.name}, Version : ${browser?.version}`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 48 48"
            >
              <path
                fill="#cfd8dc"
                d="M44,24c0,11.044-8.956,20-20,20S4,35.044,4,24S12.956,4,24,4S44,12.956,44,24z"
              ></path>
              <path
                fill="#448aff"
                d="M41,24c0,9.391-7.609,17-17,17S7,33.391,7,24S14.609,7,24,7S41,14.609,41,24z"
              ></path>
              <path
                fill="#ff3d00"
                d="M21.898,21.898l4.203,4.203l9.199-13.402L21.898,21.898z"
              ></path>
              <path
                fill="#bf360c"
                d="M24,24l11.301-11.301l-9.199,13.402L24,24z"
              ></path>
              <path
                fill="#fff"
                d="M21.898,21.898l-9.199,13.402l13.402-9.199L21.898,21.898z"
              ></path>
              <path
                fill="#bdbdbd"
                d="M24,24L12.699,35.301l13.402-9.199L24,24z"
              ></path>
              <path
                fill="#bbdefb"
                d="M17.102,10.699c0.598-0.301,1.199-0.598,1.797-0.801l1.203,2.703l-1.801,0.797L17.102,10.699z M36,25h2.898c0-0.301,0.102-0.699,0.102-1s0-0.699-0.102-1H36V25z M12.699,14.102l2.102,2.098l1.398-1.398l-2.098-2.102C13.602,13.199,13.199,13.602,12.699,14.102z M25,9.102C24.699,9,24.301,9,24,9s-0.699,0-1,0.102V12h2V9.102z M30.398,10.5c-0.598-0.301-1.199-0.5-1.898-0.699l-1.102,2.801l1.902,0.699L30.398,10.5z M12.5,20.5l0.699-1.898L10.5,17.5c-0.301,0.602-0.5,1.199-0.699,1.898L12.5,20.5z M12,23H9.102C9,23.301,9,23.699,9,24s0,0.699,0.102,1H12V23z M35.5,27.5l-0.699,1.898L37.5,30.5c0.301-0.602,0.5-1.199,0.699-1.898L35.5,27.5z M38.102,18.898c-0.203-0.598-0.5-1.199-0.801-1.797l-2.699,1.199l0.797,1.801L38.102,18.898z M35.301,33.898l-2.102-2.098l-1.398,1.398l2.098,2.102C34.398,34.801,34.801,34.398,35.301,33.898z M13.398,29.699l-0.797-1.801l-2.703,1.203c0.203,0.598,0.5,1.199,0.801,1.797L13.398,29.699z M29.699,34.602l-1.801,0.797l1.203,2.703c0.598-0.203,1.199-0.5,1.797-0.801L29.699,34.602z M20.5,35.5l-1.898-0.699L17.5,37.5c0.602,0.301,1.199,0.5,1.898,0.699L20.5,35.5z M25,38.898V36h-2v2.898c0.301,0,0.699,0.102,1,0.102S24.699,39,25,38.898z"
              ></path>
            </svg>
          </Tooltip>
        </span>
      );
    }
  };

  const getDeviceName = (device) => {
    if (device?.is_mobile) {
      return (
        <span className="flex">
          <Tooltip placement="top" trigger="hover" tooltip={`Device : Mobile`}>
            <RiCellphoneLine className="w-5 h-5" />
          </Tooltip>
        </span>
      );
    } else if (device?.is_tablet) {
      return (
        <span className="flex">
          <Tooltip placement="top" trigger="hover" tooltip={`Device : Tablet`}>
            <RiTabletLine className="w-5 h-5" />
          </Tooltip>
        </span>
      );
    } else if (device?.is_pc) {
      return (
        <span className="flex">
          <Tooltip
            placement="top"
            trigger="hover"
            tooltip={`Device : Personal Computer`}
          >
            <RiComputerLine className="w-5 h-5" />
          </Tooltip>
        </span>
      );
    }
  };

  const fetchAuthenticationLog = useCallback(
    ({ pageIndex, pageSize, authtokensLoading = true }) => {
      // Give this fetch an ID
      const fetchId = ++fetchIdRef.current;
      // Set the loading state
      if (authtokensLoading) {
        setAuthtokensLoading(true);
      }

      if (fetchId === fetchIdRef.current) {
        get(authentication_log_url, {
          params: {
            page: pageIndex + 1,
          },
        })
          .then((result) => {
            // console.log("auth_log", result);

            const new_data = [];

            result.data.data.map((res) => {
              return new_data.push({
                key: res.login_time,
                idp: res.metadata.identity_provider ? (
                  <span className="flex">
                    <Tooltip
                      placement="top"
                      trigger="hover"
                      tooltip={res.metadata.identity_provider}
                    >
                      {getIdentityProviderLogo(res.metadata.identity_provider)}
                    </Tooltip>
                  </span>
                ) : (
                  "NA"
                ),
                ip_address: res.metadata.ip_address,
                details: (
                  <div>
                    {res.metadata.os ? (
                      <div className="flex space-x-2 xs:text-right xs:inline-flex">
                        {getOperatingSystem(
                          res.metadata.os?.name,
                          res.metadata.os?.version
                        )}
                        {getBrowserName(res.metadata?.browser)}
                        {getDeviceName(res.metadata?.device)}
                      </div>
                    ) : (
                      <div>Unknown Device</div>
                    )}

                    {res.metadata.location ? (
                      <div className="text-sm dark:text-gray-1500 text-gray-1300">
                        <Tooltip
                          placement="top"
                          trigger="hover"
                          tooltip="This location shown is based on accessed IP address."
                        >
                          {res.metadata.location?.city.city},{" "}
                          {res.metadata.location?.city.region},{" "}
                          {res.metadata.location?.city.country_name}
                        </Tooltip>
                      </div>
                    ) : (
                      "Unknown Location"
                    )}
                  </div>
                ),
                login_time: (
                  <p className="truncate xs:text-sm dark:text-gray-300 xs:w-40 xs:text-right">
                    {res.login_time && (
                      <Tooltip
                        placement="top"
                        trigger="hover"
                        tooltip={convertTZ(res.login_time, props.timezone)}
                      >
                        {format(convertTZ(res.login_time, props.timezone))}
                      </Tooltip>
                    )}
                  </p>
                ),
              });
            });

            setAuthtokensData(new_data);
            setAuthtokensLoading(false);
            setAuthPageCount(result.data.pagination.total_pages);
          })
          .catch((err) => console.log(err));
      }
    },
    []
  );

  const authtokensColumns = useMemo(
    () => [
      {
        Header: "Identity Provider",
        accessor: "idp",
        style: {
          width: "20%",
        },
      },
      {
        Header: "IP Address",
        accessor: "ip_address",
        style: {
          width: "20%",
        },
      },
      {
        Header: "Details",
        accessor: "details",
        style: {
          width: "40%",
        },
      },
      {
        Header: "Login Time",
        accessor: "login_time",
        style: {
          width: "20%",
        },
      },
    ],
    []
  );

  const downloadAuthLog = () => {
    get(authentication_log_download_url)
      .then((res) => {
        // console.log(res);

        // function to create csv file
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `authentication-log.csv`); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => console.log(err));
  };

  return (
    <div>
      <Header handleSidebar={() => setshowSidebar(!showSidebar)} />
      <Sidebar
        showSidebar={showSidebar}
        setshowSidebar={setshowSidebar}
        navigation={navigation(props)}
      />
      <Content showSidebar={showSidebar}>
        <div className="z-0 bg-white border border-gray-300 rounded-lg shadow overflow-hidden dark:bg-gray-800 dark:border-gray-600">
          <div className="flex justify-between z-0 bg-gray-100 dark:bg-gray-700 border-b border-gray-200 dark:border-gray-600">
            <div className=" p-5">
              <h6 className="text-base font-medium text-gray-900 dark:text-white ">
                Authentication Log
              </h6>
              <p className="text-sm text-gray-1500 dark:text-gray-3000">
                View and manage all of the sessions you've signed in to with
                authtokens.
              </p>
            </div>

            <div className="p-5">
              <Tooltip placement="top" trigger="hover" tooltip="Download CSV">
                <button
                  type="button"
                  className="mr-1 actionbutton"
                  onClick={() => downloadAuthLog()}
                >
                  <RiFileDownloadLine className="w-5 h-5 text-gray-900 dark:text-gray-300" />
                </button>
              </Tooltip>
            </div>
          </div>

          {authtokensLoading && (
            <TableLoader columns={2} headingDescription={{ index: 1 }} />
          )}

          <Table
            columns={authtokensColumns}
            data={authtokensData}
            fetchData={fetchAuthenticationLog}
            loading={authtokensLoading}
            pageCount={authPageCount}
            borderProperty={false}
          />
        </div>
      </Content>
    </div>
  );
};

export default AuthenticationLog;
