// import use previous function
import { setInitialTheme } from "../Components/utilities";
// import react router dom component
import { Link } from "react-router-dom";

// set theme function
setInitialTheme();

// 404 page not found page
function ForbiddenPage(props) {
  // return jsx elements
  return (
    <div className="max-w-lg px-4 mx-auto text-center">
      <svg viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg">
        <rect y="382.4" width="500" height=".25" fill="#ebebeb" />
        <rect x="52.46" y="391.92" width="33.12" height=".25" fill="#ebebeb" />
        <rect x="145.33" y="389.21" width="34.5" height=".25" fill="#ebebeb" />
        <rect x="76.08" y="396.75" width="55.42" height=".25" fill="#ebebeb" />
        <rect x="405.64" y="397.67" width="43.19" height=".25" fill="#ebebeb" />
        <rect x="370.43" y="391.42" width="22.9" height=".25" fill="#ebebeb" />
        <rect x="195.63" y="395.43" width="93.68" height=".25" fill="#ebebeb" />
        <rect x="148.6" y="379.3" width="54.14" height="3.1" fill="#e0e0e0" />
        <path
          d="m170.8 311.59-15 67.71h39.65l-15-67.71a5 5 0 0 0-9.65 0z"
          fill="#e0e0e0"
        />
        <polygon
          points="183.51 325.03 167.83 325.03 164.04 342.19 187.3 342.19"
          fill="#f5f5f5"
        />
        <polygon
          points="187.96 345.21 163.38 345.21 161.45 353.9 189.88 353.9"
          fill="#f5f5f5"
        />
        <polygon
          points="72.52 382.4 80.56 382.4 104.24 250 96.19 250"
          fill="#e0e0e0"
        />
        <polygon
          points="283.15 382.4 291.2 382.4 314.88 250 306.83 250"
          fill="#e0e0e0"
        />
        <rect
          x="72.52"
          y="256.45"
          width="266.04"
          height="48.12"
          fill="#ebebeb"
        />
        <polygon
          points="101.36 302.44 73.78 302.44 89.08 258.57 116.66 258.57"
          fill="#fafafa"
        />
        <polygon
          points="156.52 302.44 128.94 302.44 144.24 258.57 171.81 258.57"
          fill="#fafafa"
        />
        <polygon
          points="211.68 302.44 184.1 302.44 199.39 258.57 226.97 258.57"
          fill="#fafafa"
        />
        <polygon
          points="266.83 302.44 239.25 302.44 254.55 258.57 282.13 258.57"
          fill="#fafafa"
        />
        <polygon
          points="321.99 302.44 294.41 302.44 309.71 258.57 337.29 258.57"
          fill="#fafafa"
        />
        <polygon
          points="127.92 382.4 119.87 382.4 96.19 250 104.24 250"
          fill="#e0e0e0"
        />
        <polygon
          points="338.55 382.4 330.51 382.4 306.83 250 314.88 250"
          fill="#e0e0e0"
        />
        <rect x="376" y="378.33" width="71" height="4.07" fill="#e0e0e0" />
        <path
          d="m404.65 291.65-19.15 86.68h52l-19.15-86.68c-1.62-7.33-12.08-7.33-13.7 0z"
          fill="#e0e0e0"
        />
        <polygon
          points="421.78 307.17 401.22 307.17 396.25 329.67 426.75 329.67"
          fill="#f5f5f5"
        />
        <polygon
          points="427.62 333.63 395.38 333.63 392.86 345.02 430.14 345.02"
          fill="#f5f5f5"
        />
        <path
          d="m463 241.9v29.3a2.91 2.91 0 0 1-3.78 2.77l-423-134.05a2.9 2.9 0 0 1-2-2.76v-29.31a2.89 2.89 0 0 1 3.78-2.76l423 134a2.9 2.9 0 0 1 2 2.81z"
          fill="#ebebeb"
        />
        <polygon
          points="429.71 262.38 453 269.76 440.08 234.75 416.8 227.37"
          fill="#fafafa"
        />
        <polygon
          points="383.14 247.62 406.43 255 393.51 219.99 370.23 212.61"
          fill="#fafafa"
        />
        <polygon
          points="336.57 232.87 359.86 240.24 346.94 205.23 323.65 197.85"
          fill="#fafafa"
        />
        <polygon
          points="290 218.1 313.29 225.49 300.37 190.47 277.08 183.09"
          fill="#fafafa"
        />
        <polygon
          points="243.43 203.35 266.71 210.73 253.8 175.71 230.51 168.33"
          fill="#fafafa"
        />
        <polygon
          points="196.86 188.59 220.14 195.97 207.23 160.95 183.94 153.57"
          fill="#fafafa"
        />
        <polygon
          points="150.28 173.83 173.57 181.21 160.65 146.19 137.37 138.81"
          fill="#fafafa"
        />
        <polygon
          points="103.71 159.07 127 166.45 114.08 131.43 90.8 124.05"
          fill="#fafafa"
        />
        <polygon
          points="57.14 144.31 80.43 151.69 67.51 116.67 44.23 109.29"
          fill="#fafafa"
        />
        <ellipse cx="250" cy="416.24" rx="193.89" ry="11.32" fill="#f5f5f5" />
        <path
          d="M326.47,407.57H314.94V60.12c0-1.87,11.53-1.87,11.53,0Z"
          fill="#263238"
        />
        <polygon points="326.47 190.6 314.94 192.92 314.94 176.97 326.47 176.97" />
        <rect
          transform="translate(10.02 -23.48) rotate(4.25)"
          x="227.98"
          y="65.63"
          width="186.24"
          height="115.11"
          rx="12"
          fill="#fff"
        />
        <path
          d="m398.63 188.13h-1l-161.8-12a13.54 13.54 0 0 1-12.46-14.46l6.76-90.86a13.54 13.54 0 0 1 14.46-12.47l161.8 12a13.54 13.54 0 0 1 12.46 14.46l-6.76 90.86a13.53 13.53 0 0 1-13.45 12.5zm-155.06-126.89a10.53 10.53 0 0 0-10.46 9.76l-6.76 90.86a10.51 10.51 0 0 0 9.65 11.21l161.8 12a10.53 10.53 0 0 0 11.25-9.69l6.76-90.86a10.51 10.51 0 0 0-9.69-11.25l-161.8-12c-0.22-0.02-0.49-0.03-0.75-0.03z"
          fill="#06f"
        />
        <path
          d="M287.44,108.05l-20.32-1.52.68-9.17,22.12-22.65,9.73.72-1.84,24.68,5,.38-.64,8.65-5-.37-.56,7.51-9.72-.73Zm.65-8.66L289,86.75,277.35,98.59Z"
          fill="#06f"
        />
        <path
          d="M306.64,96.67Q307.5,85.21,312,81t12.9-3.65a19,19,0,0,1,6.59,1.5,12.5,12.5,0,0,1,4.05,2.92,14.37,14.37,0,0,1,2.34,3.56A18,18,0,0,1,339,89.52a38.47,38.47,0,0,1,.43,9.45q-.83,10.94-4.89,15.72t-13.12,4.12a17.9,17.9,0,0,1-8.07-2.22,13.31,13.31,0,0,1-4.77-5.12,17.89,17.89,0,0,1-1.81-6.24A41.46,41.46,0,0,1,306.64,96.67Zm11.06.84q-.57,7.68.58,10.59c.76,1.93,2,3,3.72,3.1a4.14,4.14,0,0,0,3-1,7.38,7.38,0,0,0,2.11-3.63,39.42,39.42,0,0,0,1.19-8q.59-8-.56-10.85a4.35,4.35,0,0,0-3.86-3.06,4.15,4.15,0,0,0-4.21,2.51Q318.27,89.93,317.7,97.51Z"
          fill="#06f"
        />
        <path
          d="M355.5,92l-10.41-2.67a13.25,13.25,0,0,1,5.63-7.35q3.94-2.42,10.79-1.9,7.86.59,11.15,3.78a9.24,9.24,0,0,1,3,7.63,8.21,8.21,0,0,1-1.78,4.6,12,12,0,0,1-4.57,3.38,12.94,12.94,0,0,1,3.46,1.61,8.72,8.72,0,0,1,2.87,3.48,9.8,9.8,0,0,1,.75,4.89A13.54,13.54,0,0,1,374,116a12.37,12.37,0,0,1-5.65,4.56,20.39,20.39,0,0,1-9.2,1.07,23.92,23.92,0,0,1-8.55-1.93,12.92,12.92,0,0,1-4.92-4.16,17.41,17.41,0,0,1-2.68-6.46l11.26-.65a8.67,8.67,0,0,0,1.7,4.82,5.25,5.25,0,0,0,7.28.2,6.26,6.26,0,0,0,1.82-4.28,6,6,0,0,0-1.12-4.43,5.09,5.09,0,0,0-3.79-1.82,14,14,0,0,0-3.66.38l1.17-7.93a10.93,10.93,0,0,0,1.44.25,5.1,5.1,0,0,0,3.76-1.13,4.38,4.38,0,0,0,1.71-3.2,4,4,0,0,0-.87-3A4.07,4.07,0,0,0,360.77,87a4.43,4.43,0,0,0-3.29,1A7.22,7.22,0,0,0,355.5,92Z"
          fill="#06f"
        />
        <path
          d="M272.07,123.81,287.59,125l-.3,4-9.71-.72-.23,3,9,.68-.29,3.82-9-.67-.27,3.69,10,.75-.31,4.24-15.8-1.18Z"
          fill="#06f"
        />
        <path
          d="M289.67,144l1.4-18.74,9.65.71a14.15,14.15,0,0,1,4.07.77,4.39,4.39,0,0,1,2.16,1.88,5.8,5.8,0,0,1-.22,5.74,5.24,5.24,0,0,1-2,1.7,6.93,6.93,0,0,1-2.09.59,5.8,5.8,0,0,1,1.46.8,5.53,5.53,0,0,1,.85,1.06,7.58,7.58,0,0,1,.73,1.22l2.4,5.64-6.55-.49-2.67-6a4.33,4.33,0,0,0-.94-1.52,2.41,2.41,0,0,0-1.38-.54l-.51,0-.57,7.61Zm6.65-10.72,2.44.18a8.05,8.05,0,0,0,1.55-.14,1.59,1.59,0,0,0,1-.51,1.8,1.8,0,0,0,.44-1.07,1.7,1.7,0,0,0-.47-1.43,3.52,3.52,0,0,0-2.12-.65l-2.55-.19Z"
          fill="#06f"
        />
        <path
          d="M310,145.48l1.39-18.74,9.66.72a14.13,14.13,0,0,1,4.06.76,4.42,4.42,0,0,1,2.17,1.88,5.32,5.32,0,0,1-2.19,7.44,7,7,0,0,1-2.09.59,6,6,0,0,1,1.46.8,6.83,6.83,0,0,1,.85,1.06,6.69,6.69,0,0,1,.72,1.22l2.4,5.64-6.54-.48-2.67-6a4.37,4.37,0,0,0-.94-1.52,2.39,2.39,0,0,0-1.39-.54l-.51,0-.56,7.61Zm6.65-10.71,2.44.18a9.07,9.07,0,0,0,1.55-.14,1.51,1.51,0,0,0,1-.52,1.72,1.72,0,0,0,.45-1.06,1.75,1.75,0,0,0-.47-1.44,3.47,3.47,0,0,0-2.13-.64L317,131Z"
          fill="#06f"
        />
        <path
          d="M329.85,137.55a8.9,8.9,0,0,1,10.4-9q4.68.34,7,3.05a9.54,9.54,0,0,1,2,7.23,11.5,11.5,0,0,1-1.51,5.3,7.92,7.92,0,0,1-3.44,3A10.56,10.56,0,0,1,339,148a12,12,0,0,1-5.18-1.4,7.92,7.92,0,0,1-3.13-3.45A10.66,10.66,0,0,1,329.85,137.55Zm5.79.45a6.33,6.33,0,0,0,.75,4.16,4,4,0,0,0,5.76.45c.74-.76,1.19-2.18,1.34-4.28a5.84,5.84,0,0,0-.78-4,3.66,3.66,0,0,0-2.8-1.43,3.51,3.51,0,0,0-2.91,1A6.28,6.28,0,0,0,335.64,138Z"
          fill="#06f"
        />
        <path
          d="M351.77,148.59l1.39-18.75,9.65.72a14.15,14.15,0,0,1,4.07.77A4.34,4.34,0,0,1,369,133.2a5.18,5.18,0,0,1,.65,3.1,5.28,5.28,0,0,1-.87,2.65,5.19,5.19,0,0,1-2,1.69,7.08,7.08,0,0,1-2.09.6,4,4,0,0,1,2.31,1.85,7.34,7.34,0,0,1,.72,1.23l2.4,5.64-6.54-.49-2.67-6a4.48,4.48,0,0,0-.94-1.52,2.46,2.46,0,0,0-1.39-.54l-.51,0-.57,7.61Zm6.64-10.72,2.44.18a8.13,8.13,0,0,0,1.56-.14,1.51,1.51,0,0,0,1-.52,1.7,1.7,0,0,0,.45-1.06,1.76,1.76,0,0,0-.47-1.43,3.46,3.46,0,0,0-2.13-.65l-2.54-.19Z"
          fill="#06f"
        />
        <path
          d="M252.75,163.34l1.27-17.1,11.54.86-.15,2-9.28-.69-.39,5.29,8,.6-.15,2-8-.6-.58,7.77Z"
          fill="#263238"
        />
        <path
          d="m267.16 156a9.35 9.35 0 0 1 2.78-6.5 7.79 7.79 0 0 1 6.08-2 8.23 8.23 0 0 1 4.19 1.45 7.43 7.43 0 0 1 2.66 3.37 10.33 10.33 0 0 1 0.66 4.67 10.11 10.11 0 0 1-1.4 4.57 7.16 7.16 0 0 1-3.21 2.89 8.49 8.49 0 0 1-4.23 0.75 8.13 8.13 0 0 1-4.23-1.49 7.46 7.46 0 0 1-2.64-3.4 9.73 9.73 0 0 1-0.66-4.31zm2.33 0.21a6.88 6.88 0 0 0 1.3 5 5.81 5.81 0 0 0 8.37 0.61 7.29 7.29 0 0 0 2-5 8.76 8.76 0 0 0-0.43-3.7 5.43 5.43 0 0 0-1.88-2.56 5.69 5.69 0 0 0-3-1.09 5.79 5.79 0 0 0-4.26 1.35c-1.2 1.06-1.91 2.86-2.1 5.43z"
          fill="#263238"
        />
        <path
          d="M285.84,165.8l1.28-17.1,7.58.57a10,10,0,0,1,3.44.72,3.79,3.79,0,0,1,1.78,1.76,4.88,4.88,0,0,1,.52,2.64,4.32,4.32,0,0,1-1.41,3,6,6,0,0,1-3.75,1.31,6.08,6.08,0,0,1,1.3.95,12.59,12.59,0,0,1,1.71,2.42l2.63,4.88-2.85-.22-2-3.72c-.58-1.08-1.07-1.9-1.46-2.48a4.92,4.92,0,0,0-1.06-1.23,3.46,3.46,0,0,0-1-.53,7.67,7.67,0,0,0-1.25-.18l-2.63-.19-.56,7.59Zm3-9.38,4.86.36a7.24,7.24,0,0,0,2.45-.14,2.59,2.59,0,0,0,1.41-.93,2.78,2.78,0,0,0,.57-1.5,2.59,2.59,0,0,0-.73-2.06,4.15,4.15,0,0,0-2.73-1l-5.41-.41Z"
          fill="#263238"
        />
        <path
          d="M303,167.08l1.27-17.1,6.42.47a7.9,7.9,0,0,1,3.1.76,4.09,4.09,0,0,1,1.74,1.73,4.26,4.26,0,0,1,.5,2.31,3.79,3.79,0,0,1-.75,2,4.22,4.22,0,0,1-1.91,1.43,4.49,4.49,0,0,1,2.27,1.73,4.2,4.2,0,0,1,.64,2.65,5.05,5.05,0,0,1-.67,2.2,4.38,4.38,0,0,1-1.38,1.5,5.24,5.24,0,0,1-1.93.7,11.22,11.22,0,0,1-2.78.08Zm2.41-1.85,4.26.32a10.71,10.71,0,0,0,1.55,0,3.92,3.92,0,0,0,1.34-.37,2.54,2.54,0,0,0,.93-.89,3.11,3.11,0,0,0,.08-3.11,2.59,2.59,0,0,0-1.29-1.1,8.49,8.49,0,0,0-2.48-.47l-3.95-.29Zm.59-7.9,3.7.28a8.44,8.44,0,0,0,2.17,0,2.37,2.37,0,0,0,1.36-.76,2.42,2.42,0,0,0,.55-1.46,2.81,2.81,0,0,0-.29-1.53,2.12,2.12,0,0,0-1.11-1,9.54,9.54,0,0,0-2.58-.43l-3.42-.26Z"
          fill="#263238"
        />
        <path d="M319.38,168.3l1.27-17.1,2.27.17-1.28,17.1Z" fill="#263238" />
        <path
          d="M325.63,168.76l1.28-17.1,5.89.44a14.61,14.61,0,0,1,3,.47,5.83,5.83,0,0,1,2.42,1.41,7,7,0,0,1,1.81,3.08,11.32,11.32,0,0,1,.36,4.11,11.68,11.68,0,0,1-.71,3.42,8.25,8.25,0,0,1-1.35,2.4,6.15,6.15,0,0,1-1.67,1.44,6.39,6.39,0,0,1-2.11.71,11.82,11.82,0,0,1-2.77.08Zm2.42-1.84,3.65.27a9.1,9.1,0,0,0,2.68-.12,3.86,3.86,0,0,0,1.6-.77,5.53,5.53,0,0,0,1.41-2.08,10.59,10.59,0,0,0,.7-3.26,8,8,0,0,0-.58-4.19,4.47,4.47,0,0,0-2-2.09,9.1,9.1,0,0,0-2.9-.56l-3.59-.27Z"
          fill="#263238"
        />
        <path
          d="M342.89,170.05l1.27-17.1,5.89.43a14.07,14.07,0,0,1,3,.48,5.75,5.75,0,0,1,2.41,1.41,6.94,6.94,0,0,1,1.81,3.07,11.54,11.54,0,0,1,.37,4.11,12.37,12.37,0,0,1-.71,3.42,8.19,8.19,0,0,1-1.36,2.41,6.1,6.1,0,0,1-1.67,1.43,6.34,6.34,0,0,1-2.1.71,12.39,12.39,0,0,1-2.77.09Zm2.41-1.85,3.65.27a9.1,9.1,0,0,0,2.68-.12,3.8,3.8,0,0,0,1.6-.77,5.45,5.45,0,0,0,1.41-2.07,10.59,10.59,0,0,0,.7-3.26,8,8,0,0,0-.58-4.19,4.41,4.41,0,0,0-2-2.09,8.85,8.85,0,0,0-2.91-.57l-3.59-.27Z"
          fill="#263238"
        />
        <path
          d="M360.19,171.33l1.27-17.1,12.36.92-.15,2-10.1-.75-.39,5.24,9.46.7-.15,2-9.46-.71-.43,5.82,10.5.79-.15,2Z"
          fill="#263238"
        />
        <path
          d="M376.05,172.51l1.27-17.1,2.32.17,8,14.1,1-13.43,2.17.16-1.28,17.11-2.32-.18-8-14.1-1,13.43Z"
          fill="#263238"
        />
        <polygon
          points="241.88 417.46 215.7 417.46 234.73 266.28 260.92 266.28"
          fill="#06f"
        />
        <polygon
          points="241.88 417.46 215.7 417.46 234.73 266.28 260.92 266.28"
          opacity=".4"
        />
        <polygon
          points="213.36 417.46 215.7 417.46 234.73 266.28 232.39 266.28"
          fill="#263238"
        />
        <polygon
          points="339.53 417.46 313.35 417.46 332.38 266.28 358.57 266.28"
          fill="#06f"
        />
        <polygon
          points="339.53 417.46 313.35 417.46 332.38 266.28 358.57 266.28"
          opacity=".4"
        />
        <polygon
          points="311 417.46 313.35 417.46 332.38 266.28 330.04 266.28"
          fill="#263238"
        />
        <polygon
          points="266.12 399.47 361.08 399.47 356.94 366.56 261.98 366.56"
          fill="#06f"
        />
        <polygon
          points="266.12 399.47 264.57 399.47 260.42 366.56 261.98 366.56"
          fill="#263238"
        />
        <polygon
          points="355.85 368 359.63 398.03 354.02 398.03 338.95 368"
          fill="#fff"
        />
        <polygon
          points="329.38 368 344.45 398.03 323.85 398.03 308.78 368"
          fill="#fff"
        />
        <polygon
          points="299.21 368 314.28 398.03 293.67 398.03 278.61 368"
          fill="#fff"
        />
        <polygon
          points="269.05 368 284.11 398.03 267.21 398.03 263.43 368"
          fill="#fff"
        />
        <polygon
          points="351.99 417.46 378.18 417.46 359.14 266.28 332.96 266.28"
          fill="#06f"
        />
        <polygon
          points="341.44 333.63 368.99 344.46 361.08 281.68 334.89 281.68"
          opacity=".2"
        />
        <polygon
          points="351.99 417.46 349.65 417.46 330.62 266.28 332.96 266.28"
          fill="#263238"
        />
        <polygon
          points="254.34 417.46 280.53 417.46 261.5 266.28 235.31 266.28"
          fill="#06f"
        />
        <polygon
          points="243.79 333.63 271.34 344.46 263.43 281.68 237.25 281.68"
          opacity=".2"
        />
        <polygon
          points="254.34 417.46 252 417.46 232.97 266.28 235.31 266.28"
          fill="#263238"
        />
        <polygon
          points="233.33 326.66 376.32 326.66 370.08 277.11 227.09 277.11"
          fill="#06f"
        />
        <polygon
          points="233.33 326.66 230.99 326.66 224.75 277.11 227.09 277.11"
          fill="#263238"
        />
        <polygon
          points="368.44 279.27 374.14 324.49 365.69 324.49 343 279.27"
          fill="#fff"
        />
        <polygon
          points="328.59 279.27 351.28 324.49 320.25 324.49 297.57 279.27"
          fill="#fff"
        />
        <polygon
          points="283.16 279.27 305.85 324.49 274.82 324.49 252.13 279.27"
          fill="#fff"
        />
        <polygon
          points="237.74 279.27 260.42 324.49 234.97 324.49 229.28 279.27"
          fill="#fff"
        />
      </svg>

      <h4 className="text-lg font-semibold text-center text-gray-900 dark:text-white">
        Forbidden
      </h4>

      <p className="mt-2 text-sm font-light text-center text-gray-900 dark:text-white">
        You don't have permission to access this page.
      </p>

      <Link
        to="/profile/personal"
        className="inline-flex items-center px-3 py-2 mt-4 text-sm font-medium leading-4 text-gray-900 bg-transparent border border-gray-700 rounded-md shadow-sm dark:text-white dark:border-white dark:hover:bg-transparent hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Home
      </Link>
    </div>
  );
}

export default ForbiddenPage;
