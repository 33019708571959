// import react components
import React, { useCallback, useEffect, useMemo, useState } from "react";
// import react router components
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  RiCloseLine,
  RiDeleteBinLine,
  RiPhoneLine,
  RiCheckboxCircleLine,
  RiShieldLine,
  RiVipCrown2Line,
  RiAlertLine,
} from "react-icons/ri";
import { useSnackbar } from "notistack";
import Modal from "../../Components/Modal";
import "../../Styles/React_Select.css";
import TableLoader from "../../Components/Loaders/TableLoader";
import SimpleTable from "../../Components/Tables/SimpleTable";
import Tooltip from "../../Components/Tooltip";
import ConfirmationModal from "../../Components/ConfirmationModal";
import {
  user_mobile_number_url,
  primary_mobile_number_url,
  verify_mobile_number_url,
  send_verification_sms,
} from "../../Components/Urls";
import { Delete, get, post, put } from "../../Components/AxiosUtilities";
import { format } from "timeago.js";
import { convertTZ } from "../../Components/TimezoneHandler";

function MobileNumber(props) {
  const { enqueueSnackbar } = useSnackbar();

  const { timezone } = props;

  // Initialize states
  const [values, setValues] = useState({
    email: "",
    phone_number: "",
    otp: "",
  });

  const [ShowMobileModal, setShowMobileModal] = useState(false);
  const [ShowOTPModal, setShowOTPModal] = useState(false);

  // mobile table states
  const [mobileData, setMobileData] = useState([]);
  const [mobileLoading, setMobileLoading] = useState(false);

  const [deleteMobileNumber, setDeleteMobileNumber] = useState("");
  const [showMobileConfirmation, setShowMobileConfirmation] = useState(false);

  const [primaryMobileNumber, setPrimaryMobileNumber] = useState("");
  const [
    showPrimaryMobileNumberConfirmation,
    setShowPrimaryMobileNumberConfirmation,
  ] = useState(false);

  const [verifyMobileNumber, setVerifyMobileNumber] = useState("");
  const [
    showVerifyMobileNumberConfirmation,
    setShowVerifyMobileNumberConfirmation,
  ] = useState(false);

  //  close the modal using the "ESC" key1
  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        setShowMobileModal(false);
        setShowMobileConfirmation(false);
        setShowPrimaryMobileNumberConfirmation(false);
        setShowVerifyMobileNumberConfirmation(false);
      } else if (e.ctrlKey && (e.keyCode === 10 || e.keyCode === 13)) {
        document.getElementById("submit").click();
      }
    };

    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);

  // function to save inputs in states
  const handleInputChange = (e) => {
    // takes input name and value from event target input field
    const { name, value } = e.target;

    // sets state values
    setValues({
      ...values,
      [name]: value,
    });
  };

  const fetchIdRef = React.useRef(0);

  const fetchMobileNumberData = useCallback(
    ({ pageIndex, pageSize, mobileLoading = true }) => {
      // Give this fetch an ID
      const fetchId = ++fetchIdRef.current;
      // Set the loading state
      if (mobileLoading) {
        setMobileLoading(true);
      }

      if (fetchId === fetchIdRef.current) {
        get(user_mobile_number_url)
          .then((result) => {
            console.log("mobile", result.data);

            let datas = result.data;

            let new_data = [];

            // primary email
            if (datas.primary !== null) {
              new_data.push({
                key: datas.primary,
                mobile: (
                  <div className="flex">
                    <span className="bg-blue-500 w-10 h-10 rounded-full flex justify-center items-center">
                      <RiPhoneLine className="h-5 w-5 text-white" />
                    </span>
                    <p className="flex flex-col pl-2">
                      <span>{datas.primary}</span>
                      <span className="text-sm text-left text-gray-1500 dark:text-gray-3000">
                        <Tooltip
                          placement="top"
                          trigger="hover"
                          tooltip={Object.entries(datas.updated_at)
                            .filter((key) => key[0] === datas.primary)
                            .map((res) => convertTZ(res[1], timezone))}
                        >
                          {Object.entries(datas.updated_at)
                            .filter((key) => key[0] === datas.primary)
                            .map((res) =>
                              format(convertTZ(res[1], timezone))
                            )}
                        </Tooltip>{" "}
                      </span>
                    </p>

                    <span className="flex">
                      <Tooltip
                        placement="top"
                        trigger="hover"
                        tooltip={
                          <div>
                            <p className="flex items-center">
                              <RiVipCrown2Line className="w-5 h-5 text-green-600 " />
                              <span className="ml-1 text-base">
                                Primary Mobile Number
                              </span>
                            </p>
                            <p className="flex text-gray-1500 dark:text-gray-3000 text-left pt-0.5">
                              You can use this mobile number to sign in to your
                              VegaMaster account.
                            </p>
                          </div>
                        }
                      >
                        <span className="flex items-center ml-2">
                          <RiVipCrown2Line className="w-5 h-5 text-green-600 " />
                        </span>
                      </Tooltip>
                    </span>

                    <span className="flex">
                      <Tooltip
                        placement="top"
                        trigger="hover"
                        tooltip={
                          <div>
                            <p className="flex items-center">
                              <RiShieldLine className="w-5 h-5 text-red-600 " />
                              <span className="ml-1 text-base">
                                MFA Mobile Number
                              </span>
                            </p>
                            <p className="flex text-gray-1500 dark:text-gray-3000 text-left pt-0.5">
                              You will be asked to verify this mobile number
                              during sign-in after entering your VegaMaster
                              account password.
                            </p>
                          </div>
                        }
                      >
                        <span className="flex items-center ml-2">
                          <RiShieldLine className="w-5 h-5 text-red-600 " />
                        </span>
                      </Tooltip>
                    </span>
                  </div>
                ),
              });
            }

            // verified secondary emails
            datas.verified.map((mobile) => {
              return new_data.push({
                key: mobile,
                email: (
                  <div className="flex">
                    <span className="bg-blue-500 w-10 h-10 rounded-full flex justify-center items-center">
                      <RiPhoneLine className="h-5 w-5 text-white" />
                    </span>
                    <p className="flex flex-col pl-2">
                      <span>{mobile}</span>
                      <span className="text-sm text-left text-gray-1500 dark:text-gray-3000">
                        <Tooltip
                          placement="top"
                          trigger="hover"
                          tooltip={Object.entries(datas.updated_at)
                            .filter((key) => key[0] === mobile)
                            .map((res) => convertTZ(res[1], timezone))}
                        >
                          {Object.entries(datas.updated_at)
                            .filter((key) => key[0] === mobile)
                            .map((res) =>
                              format(convertTZ(res[1], timezone))
                            )}
                        </Tooltip>
                      </span>
                    </p>
                    <span className="flex ml-8">
                      <Tooltip
                        placement="top"
                        trigger="hover"
                        tooltip={"Mobile number is verified"}
                      >
                        <RiCheckboxCircleLine className="ml-2 h-5 w-5 text-green-600" />
                      </Tooltip>
                    </span>
                  </div>
                ),

                actions: (
                  <div id={`action_${mobile}`} className="hidden">
                    <div className="flex items-center justify-end space-x-4 sm:justify-start xs:justify-start md:justify-end lg:justify-end">
                      <button
                        type="button"
                        className="inline-flex items-center shadow-sm px-2.5 py-2
                                 text-sm leading-5 font-medium rounded-full text-gray-500 dark:text-gray-300 bg-gray-400 
                                dark:bg-gray-900 "
                        onClick={() => {
                          setPrimaryMobileNumber(mobile);
                          setShowPrimaryMobileNumberConfirmation(true);
                        }}
                      >
                        <RiVipCrown2Line className="w-5 h-5 text-gray-500 dark:text-gray-300 mr-2" />
                        Set as Primary number
                      </button>
                      <span
                        className="flex"
                        onClick={() => {
                          setDeleteMobileNumber(mobile);
                          setShowMobileConfirmation(true);
                        }}
                      >
                        <Tooltip
                          placement="top"
                          trigger="hover"
                          tooltip="Delete Number"
                        >
                          <RiDeleteBinLine className="w-5 h-5" />
                        </Tooltip>
                      </span>
                    </div>
                  </div>
                ),
              });
            });

            // unverified secondary emails
            datas.unverified.map((mobile) => {
              return new_data.push({
                key: mobile,
                mobile: (
                  <div className="flex">
                    <span className="bg-blue-500 w-10 h-10 rounded-full flex justify-center items-center">
                      <RiPhoneLine className="h-5 w-5 text-white" />
                    </span>
                    <div className="pl-2">
                      <p> {mobile}</p>

                      <span className="text-sm text-left text-gray-1500 dark:text-gray-3000">
                        <Tooltip
                          placement="top"
                          trigger="hover"
                          tooltip={Object.entries(datas.updated_at)
                            .filter((key) => key[0] === mobile)
                            .map((res) => convertTZ(res[1], timezone))}
                        >
                          {Object.entries(datas.updated_at)
                            .filter((key) => key[0] === mobile)
                            .map((res) =>
                              format(convertTZ(res[1], timezone))
                            )}
                        </Tooltip>{" "}
                      </span>
                    </div>
                    <span className="flex ml-8">
                      <Tooltip
                        placement="top"
                        trigger="hover"
                        tooltip={"Mobile Number is not verified"}
                      >
                        <RiAlertLine className="ml-2 h-5 w-5 text-red-600" />
                      </Tooltip>
                    </span>
                  </div>
                ),

                actions: (
                  <div id={`action_${mobile}`} className="hidden">
                    <div className="flex items-center space-x-4 justify-end sm:justify-start xs:justify-start md:justify-end lg:justify-end">
                      <button
                        type="button"
                        className="inline-flex items-center shadow-sm px-2.5 py-2
                                 text-sm leading-5 font-medium rounded-full text-gray-500 dark:text-gray-300 bg-gray-400 
                                dark:bg-gray-900 "
                        onClick={() => {
                          setVerifyMobileNumber(mobile);
                          setShowVerifyMobileNumberConfirmation(true);
                        }}
                      >
                        Verify this Number
                      </button>
                      <span
                        className="flex"
                        onClick={() => {
                          setDeleteMobileNumber(mobile);
                          setShowMobileConfirmation(true);
                        }}
                      >
                        <Tooltip
                          placement="top"
                          trigger="hover"
                          tooltip="Delete Number"
                        >
                          <RiDeleteBinLine className="w-5 h-5" />
                        </Tooltip>
                      </span>
                    </div>
                  </div>
                ),
              });
            });

            setMobileData(new_data);
            setMobileLoading(false);
          })
          .catch((err) => {
            console.log(err);
            setMobileLoading(false);
          });
      }
    },
    [timezone]
  );

  const mobileColumns = useMemo(
    () => [
      { Header: "", accessor: "mobile" },
      { Header: "", accessor: "actions" },
    ],
    []
  );

  const addMobileNumber = (e) => {
    e.preventDefault();

    post(user_mobile_number_url, {
      phone_number: values.phone_number,
    })
      .then((res) => {
        console.log(res);

        enqueueSnackbar("Mobile number added successfully.", {
          variant: "success",
          autoHideDuration: 3000,
        });

        fetchMobileNumberData({ page: 0, pageSize: 0, emailLoading: false });

        setShowMobileModal(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.data.message) {
          enqueueSnackbar(err.response.data.message, {
            variant: "error",
            autoHideDuration: 3000,
          });
        } else {
          enqueueSnackbar("An error occured while adding mobile number.", {
            variant: "error",
            autoHideDuration: 3000,
          });
        }
      });
  };

  const deleteMobileNumberHandler = () => {
    Delete(user_mobile_number_url, {
      data: { phone_number: deleteMobileNumber },
    })
      .then((res) => {
        console.log(res);

        fetchMobileNumberData({ page: 0, pageSize: 0, emailLoading: false });

        enqueueSnackbar("Mobile number deleted successfully.", {
          variant: "success",
          autoHideDuration: 3000,
        });

        setShowMobileConfirmation(false);
      })
      .catch((err) => {
        console.log(err);

        enqueueSnackbar("An error occured while deleting mobile number.", {
          variant: "error",
          autoHideDuration: 3000,
        });
        setShowMobileConfirmation(false);
      });
  };

  const getVerficationLink = () => {
    post(send_verification_sms, {
      phone_number: verifyMobileNumber,
    })
      .then((res) => {
        console.log(res);

        fetchMobileNumberData({ page: 0, pageSize: 0, mobileLoading: false });
        enqueueSnackbar(
          "OTP sent successfully for verification of given mobile number.",
          {
            variant: "success",
            autoHideDuration: 3000,
          }
        );
        setShowVerifyMobileNumberConfirmation(false);
        setShowOTPModal(true);
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar("An error occured while verifying mobile number.", {
          variant: "error",
          autoHideDuration: 3000,
        });
        setShowVerifyMobileNumberConfirmation(false);
      });
  };

  const setPrimaryMobileNumberHandler = () => {
    post(primary_mobile_number_url, {
      phone_number: primaryMobileNumber,
    })
      .then((res) => {
        console.log(res);

        fetchMobileNumberData({ page: 0, pageSize: 0, emailLoading: false });
        enqueueSnackbar(
          "Mobile number converted successfully to primary mobile number",
          {
            variant: "success",
            autoHideDuration: 3000,
          }
        );
        setShowPrimaryMobileNumberConfirmation(false);
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar(
          "An error occured while converting mobile number to primary mobile number.",
          {
            variant: "success",
            autoHideDuration: 3000,
          }
        );
        setShowPrimaryMobileNumberConfirmation(false);
      });
  };

  const OTPHandler = () => {
    put(verify_mobile_number_url, {
      otp: values.otp,
    })
      .then((res) => {
        console.log(res);

        setShowOTPModal(false);
      })
      .catch((err) => {
        console.log(err);
        setShowOTPModal(false);
      });
  };

  return (
    <>
      <div className="mt-8" id="mobile_div">
        <div className="bg-white dark:bg-gray-800 border rounded-lg sm:rounded-lg overflow-hidden shadow border-gray-300  dark:border-gray-600">
          <div className="z-0 bg-white dark:bg-gray-800 ">
            <div>
              <div className="z-0 bg-white  dark:bg-gray-800">
                <div className="flex justify-between items-center xs:flex-col bg-gray-100 dark:bg-gray-700 border-b border-gray-200 dark:border-gray-600">
                  <div className="p-5">
                    <h6 className="text-base font-medium text-gray-900 dark:text-white ">
                      My Mobile Numbers
                    </h6>
                    <p className="text-sm text-gray-1500 dark:text-gray-3000">
                      View and manage all of the mobile numbers associated with
                      your account.
                    </p>
                  </div>

                  <div className="p-5 xs:w-full xs:pt-0">
                    <button
                      className="button button-primary xs:w-full"
                      onClick={() => {
                        setShowMobileModal(true);
                        setValues({
                          phone_number: "",
                        });
                      }}
                    >
                      Add Mobile Number
                    </button>
                  </div>
                </div>

                {mobileLoading && (
                  <TableLoader columns={2} headingDescription={{ index: 1 }} />
                )}

                <div className="">
                  <SimpleTable
                    columns={mobileColumns}
                    data={mobileData}
                    fetchData={fetchMobileNumberData}
                    loading={mobileLoading}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* mobile number */}
      <Modal
        open={ShowMobileModal}
        modalWidth={true}
        heading={
          <div className="flex justify-between mb-4 items-center">
            <p className="text-gray-900 dark:text-gray-300">
              Add Mobile Number
            </p>
            <button
              type="button"
              className="closemodalicon"
              onClick={() => {
                setShowMobileModal(false);
              }}
            >
              <span className="sr-only">Close</span>
              <RiCloseLine className="w-6 h-6" aria-hidden="true" />
            </button>
          </div>
        }
      >
        <form className="mt-2" onSubmit={addMobileNumber}>
          <div className="grid grid-cols-2 xs:grid-cols-1">
            <div className="text-left">
              <label htmlFor="name" className="form-label">
                Enter mobile number
                <span className="ml-1 text-base text-red-600">*</span>
              </label>
              <input
                type="tel"
                name="phone_number"
                value={values.phone_number}
                onChange={handleInputChange}
                className="input-field"
                required
                placeholder="Enter mobile number"
              />
            </div>
          </div>
          <div className="flex items-center mt-4 space-x-3">
            <button id="submit" type="submit" className="button button-success">
              Add
            </button>
            <button
              type="button"
              className="text-gray-900 dark:text-gray-300"
              onClick={() => {
                setShowMobileModal(false);
              }}
            >
              Cancel
            </button>
          </div>
        </form>
      </Modal>

      {/* mobile number */}
      <Modal
        open={ShowOTPModal}
        modalWidth={true}
        heading={
          <div className="flex justify-between mb-4 items-center">
            <p className="text-gray-900 dark:text-gray-300">
              Verify Mobile Number
            </p>
            <button
              type="button"
              className="closemodalicon"
              onClick={() => {
                setShowOTPModal(false);
              }}
            >
              <span className="sr-only">Close</span>
              <RiCloseLine className="w-6 h-6" aria-hidden="true" />
            </button>
          </div>
        }
      >
        <form className="mt-2" onSubmit={OTPHandler}>
          <div className="grid grid-cols-2 xs:grid-cols-1">
            <div className="text-left">
              <label htmlFor="name" className="form-label">
                Enter OTP
                <span className="ml-1 text-base text-red-600">*</span>
              </label>
              <input
                type="number"
                max={6}
                name="otp"
                value={values.otp}
                onChange={handleInputChange}
                className="input-field"
                required
                placeholder="Enter OTP"
              />
            </div>
          </div>
          <div className="flex items-center mt-4 space-x-3">
            <button id="submit" type="submit" className="button button-success">
              Submit
            </button>
            <button
              type="button"
              className="text-gray-900 dark:text-gray-300"
              onClick={() => {
                setShowOTPModal(false);
              }}
            >
              Cancel
            </button>
          </div>
        </form>
      </Modal>

      <ConfirmationModal
        open={showMobileConfirmation}
        close={setShowMobileConfirmation}
        modalWidth={true}
        heading={
          <div className="mb-4">
            <p className="text-sm font-medium text-gray-900 dark:text-white">
              Are you sure?
            </p>
            <p className="mt-1 text-sm text-gray-500 dark:text-gray-300">
              This action will delete mobile number permanently.
            </p>
          </div>
        }
        icon={<RiDeleteBinLine className="w-8 h-8 text-red-500" />}
        accept={() => {
          deleteMobileNumberHandler();
        }}
      />

      <ConfirmationModal
        open={showPrimaryMobileNumberConfirmation}
        close={setShowPrimaryMobileNumberConfirmation}
        modalWidth={true}
        heading={
          <div className="mb-4">
            <p className="text-sm font-medium text-gray-900 dark:text-white">
              Are you sure?
            </p>
            <p className="mt-1 text-sm text-gray-500 dark:text-gray-300">
              This action will set given mobile number to primary mobile number
              permanently.
            </p>
          </div>
        }
        confirmButtonName="Confirm"
        confirmButtonClassName="button button-success"
        accept={() => {
          setPrimaryMobileNumberHandler();
        }}
      />

      <ConfirmationModal
        open={showVerifyMobileNumberConfirmation}
        close={setShowVerifyMobileNumberConfirmation}
        modalWidth={true}
        heading={
          <div className="mb-4">
            <p className="text-sm font-medium text-gray-900 dark:text-white">
              Are you sure?
            </p>
            <p className="mt-1 text-sm text-gray-500 dark:text-gray-300">
              This action will set given mobile number to primary mobile number
              permanently.
            </p>
          </div>
        }
        confirmButtonName="Confirm"
        confirmButtonClassName="button button-success"
        accept={() => {
          getVerficationLink();
        }}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setTimeZone: (data) => dispatch({ type: "setTimeZone", ...data }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MobileNumber));
