const { REACT_APP_API_URL, REACT_APP_WS_URL } = process.env;

export const BASE_URL = `${REACT_APP_API_URL}`;

export const WS_BASE_URL = `${REACT_APP_WS_URL}`;

// urls

export const account_user_registration =
  BASE_URL + "/account/user-registration/";

export const account_user_activation = BASE_URL + "/account/user-activation/";

export const account_microsoft_sign_up =
  BASE_URL + "/account/microsoft-sign-up/";

export const account_google_sign_up = BASE_URL + "/account/google-sign-up/";

export const account_profile_url = BASE_URL + "/account/profile/";

export const account_set_password = BASE_URL + "/account/set-password/";

export const account_reset_password = BASE_URL + "/account/reset-password/";

export const account_setup_mfa = BASE_URL + "/account/setup-mfa/";

export const active_session_url = BASE_URL + "/account/active-sessions/";

export const authentication_log_url = BASE_URL + "/account/authentication-log/";

export const activity_history_url = BASE_URL + "/account/profile-activity/";

export const account_user_data_url = BASE_URL + "/account/user-data/";

export const last_accessed_org_url =
  BASE_URL + "/account/user/set-last-accessed-organization/";

export const user_email_url = BASE_URL + "/account/user-emails/";

export const verify_email_url = BASE_URL + "/account/verify-email/";

export const send_verification_email =
  BASE_URL + "/account/send-verification-email/";

export const primary_email_url = BASE_URL + "/account/set-primary-email/";

export const user_mobile_number_url = BASE_URL + "/account/user-phone-numbers/";

export const verify_mobile_number_url =
  BASE_URL + "/account/verify-phone-number/";

export const primary_mobile_number_url =
  BASE_URL + "/account/set-primary-phone-number/";

export const send_verification_sms =
  BASE_URL + "/account/send-verification-sms/";

export const last_updated_password_url =
  BASE_URL + "/account/password-last-updated-at/";

export const keycloak_logout_url = BASE_URL + "/account/keycloak-logout/";

export const connected_accounts_url = BASE_URL + "/account/connected-accounts/";

export const fetch_image_url = BASE_URL + "/account/fetch-image/";

export const post_image_url = BASE_URL + "/account/profile-picture/";

export const connect_microsoft_url = BASE_URL + "/account/connect-microsoft/";

export const connect_google_url = BASE_URL + "/account/connect-google/";

export const terminate_all_sessions_url =
  BASE_URL + "/account/terminate-all-sessions/";

export const validate_password_url = BASE_URL + "/account/validate-password/";

export const activity_download_url =
  BASE_URL + "/account/profile-activity/export/";

export const authentication_log_download_url =
  BASE_URL + "/account/authentication-log/export/";
