import React, { useCallback, useMemo, useState } from "react";
import { format } from "timeago.js";
import { get } from "../../Components/AxiosUtilities";
import { convertTZ } from "../../Components/TimezoneHandler";
import Table from "../../Components/Tables/Table";
import TableLoader from "../../Components/Loaders/TableLoader.jsx";
import Tooltip from "../../Components/Tooltip";
import {
  activity_download_url,
  activity_history_url,
} from "../../Components/Urls";
import {
  HideInitialSidebarInMobile,
  navigation,
  strCapitalize,
} from "../../Components/utilities";
import Header from "../../Components/Header";
import Sidebar from "../../Components/Sidebar";
import Content from "../../Components/Content";
import {
  RiAddLine,
  RiCheckLine,
  RiDeleteBinLine,
  RiEditBoxLine,
  RiFileDownloadLine,
  RiLinksLine,
  RiUserAddLine,
} from "react-icons/ri";
import InfiniteScroll from "react-infinite-scroller";
import Activity from "../../Components/Activity";

const ActivityHistory = (props) => {
  // for sidebar
  const [showSidebar, setshowSidebar] = useState(HideInitialSidebarInMobile());

  const fetchIdRef = React.useRef(0);

  // activity history table states
  const [activityData, setActivityData] = useState([]);
  const [activityLoading, setActivityLoading] = useState(false);
  const [activityPageCount, setActivityPageCount] = useState(0);

  const randomColor = (action) => {
    if (action === "added") {
      return (
        <span
          className={`bg-green-500 w-10 h-10 rounded-full flex justify-center items-center`}
        >
          <RiAddLine className="h-5 w-5 text-white" />
        </span>
      );
    } else if (action === "updated") {
      return (
        <span
          className={`bg-blue-500 w-10 h-10 rounded-full flex justify-center items-center`}
        >
          <RiEditBoxLine className="h-5 w-5 text-white" />
        </span>
      );
    } else if (action === "removed" || action === "disconnected") {
      return (
        <span
          className={`bg-red-500 w-10 h-10 rounded-full flex justify-center items-center`}
        >
          <RiDeleteBinLine className="h-5 w-5 text-white" />
        </span>
      );
    } else if (action === "verified") {
      return (
        <span
          className={`bg-green-500 w-10 h-10 rounded-full flex justify-center items-center`}
        >
          <RiCheckLine className="h-5 w-5 text-white" />
        </span>
      );
    } else if (action === "joined") {
      return (
        <span
          className={`bg-blue-500 w-10 h-10 rounded-full flex justify-center items-center`}
        >
          <RiUserAddLine className="h-5 w-5 text-white" />
        </span>
      );
    } else if (action === "connected") {
      return (
        <span
          className={`bg-blue-500 w-10 h-10 rounded-full flex justify-center items-center`}
        >
          <RiLinksLine className="h-5 w-5 text-white" />
        </span>
      );
    }
  };

  const fetchActivityData = useCallback(
    ({ pageIndex, pageSize, activityLoading = true }) => {
      // Give this fetch an ID
      const fetchId = ++fetchIdRef.current;
      // Set the loading state
      if (activityLoading) {
        setActivityLoading(true);
      }

      if (fetchId === fetchIdRef.current) {
        get(activity_history_url, {
          params: {
            page: pageIndex + 1,
          },
        })
          .then((result) => {
            console.log("activity", result);

            const new_data = [];

            result.data.data.map((res) => {
              return new_data.push({
                key: res.created_at,
                name: randomColor(res.action),
                details: (
                  <div>
                    <span className="">{res.display_message}</span>
                  </div>
                ),

                actions: (
                  <p className="truncate xs:text-sm dark:text-gray-300 xs:w-40 xs:text-right">
                    {res.created_at && (
                      <Tooltip
                        placement="top"
                        trigger="hover"
                        tooltip={convertTZ(res.created_at, props.timezone)}
                      >
                        {format(convertTZ(res.created_at, props.timezone))}
                      </Tooltip>
                    )}
                  </p>
                ),
              });
            });

            setActivityData(new_data);
            setActivityLoading(false);
            setActivityPageCount(result.data.pagination.total_pages);
          })
          .catch((err) => console.log(err));
      }
    },
    []
  );

  const activityColumns = useMemo(
    () => [
      {
        Header: "Action",
        accessor: "name",
        style: {
          width: "20%",
        },
      },
      {
        Header: "Details",
        accessor: "details",
        style: {
          width: "60%",
        },
      },
      {
        Header: "Created at",
        accessor: "actions",
        style: {
          width: "20%",
        },
      },
    ],
    []
  );

  const downloadActivity = () => {
    get(activity_download_url)
      .then((res) => {
        // console.log(res);

        // function to create csv file
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `activity-history.csv`); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
      .catch((err) => console.log(err));
  };

  // Initialize states
  const initialDatas = {
    activities: [],
    totalPages: 10,
  };
  const [datas, setDatas] = useState(initialDatas);
  const [loadingActivity, setLoadingActivity] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const getActivity = useCallback(
    (page) => {
      setLoadingActivity(true);
      get(activity_history_url, {
        params: {
          page: page,
          per_page: 20,
        },
      })
        .then((res) => {
          console.log(res);

          if (page <= res.data.pagination.total_pages && hasMore) {
            setDatas((prevState) => {
              let activitiesData = prevState.activities.concat(res.data.data);

              let sortedactivitiesData = activitiesData.sort(
                (a, b) => new Date(b.created_at) - new Date(a.created_at)
              );
              return {
                activities: sortedactivitiesData,
                totalPages: res.data.pagination.total_pages,
              };
            });
          }

          if (page === res.data.pagination.total_pages) {
            setHasMore(false);
          }

          setLoadingActivity(false);
        })
        .catch((err) => {
          console.log(err);
          setLoadingActivity(false);
        });
    },
    [hasMore]
  );

  console.log(datas);

  // listner of activity id change
  React.useEffect(() => {
    // if activity id is not 0 and hasmore is true
    if (hasMore) {
      getActivity(1);
    }
  }, [hasMore, getActivity]);

  const activitiesItems = datas.activities.map((res) => {
    return {
      id: res.created_at,
      action: res.action,
      display_message: res.display_message,
      created_at: (
        <p className="truncate xs:text-sm dark:text-gray-300 xs:w-40 xs:text-right">
          {res.created_at && (
            <Tooltip
              placement="top"
              trigger="hover"
              tooltip={convertTZ(res.created_at, props.timezone)}
            >
              {format(convertTZ(res.created_at, props.timezone))}
            </Tooltip>
          )}
        </p>
      ),
    };
  });

  return (
    <div>
      <Header handleSidebar={() => setshowSidebar(!showSidebar)} />
      <Sidebar
        showSidebar={showSidebar}
        setshowSidebar={setshowSidebar}
        navigation={navigation(props)}
      />
      <Content showSidebar={showSidebar}>
        <div id="activity_history">
          <div className="z-0 bg-white border border-gray-300 rounded-lg shadow overflow-hidden dark:bg-gray-800 dark:border-gray-600">
            <div className="flex justify-between z-0 bg-gray-100 dark:bg-gray-700 border-b border-gray-200 dark:border-gray-600">
              <div className=" p-5">
                <h6 className="text-base font-medium text-gray-900 dark:text-white ">
                  Activity History
                </h6>
                <p className="text-sm text-gray-1500 dark:text-gray-3000">
                  View and manage all of your VegaStack account activity.
                </p>
              </div>

              <div className="p-5">
                <Tooltip placement="top" trigger="hover" tooltip="Download CSV">
                  <button
                    type="button"
                    className="mr-1 actionbutton"
                    onClick={() => downloadActivity()}
                  >
                    <RiFileDownloadLine className="w-5 h-5 text-gray-900 dark:text-gray-300" />
                  </button>
                </Tooltip>
              </div>
            </div>

            <div className="">
              <InfiniteScroll
                pageStart={1}
                initialLoad={false}
                loadMore={getActivity}
                hasMore={hasMore}
                loader={
                  <div className="flex items-center justify-center mb-6 ">
                    <div className="w-6 h-6 border-t-2 mt-4 border-b-2 border-gray-700 rounded-full dark:border-gray-500 animate-spin"></div>
                  </div>
                }
              >
                <Activity
                  activityItems={activitiesItems}
                  loading={loadingActivity}
                />
              </InfiniteScroll>
            </div>

            {/* 
            {activityLoading && (
              <TableLoader columns={2} headingDescription={{ index: 1 }} />
            )}

            <Table
              columns={activityColumns}
              data={activityData}
              fetchData={fetchActivityData}
              loading={activityLoading}
              pageCount={activityPageCount}
              borderProperty={false}
            /> */}
          </div>
        </div>
      </Content>
    </div>
  );
};

export default ActivityHistory;
