// import react components
import React from "react";
// import react router components
import { withRouter, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { useSnackbar } from "notistack";
import { RiLoader4Fill } from "react-icons/ri";
import Card from "../../Components/Card";
import TimebasedOTP from "../../images/TimebasedOTP.png";
import { account_setup_mfa } from "../../Components/Urls";
import { get, post, Delete } from "../../Components/AxiosUtilities";

function SecurityMFAAuthentication(props) {
  // show alert and close alert functions
  const { enqueueSnackbar } = useSnackbar();

  const history = useHistory();

  const [MFAEnabled, setMFAEnabled] = React.useState(false);
  const [loading, setLoading] = React.useState(true);

  // manage states

  const getMFAStatus = React.useCallback(() => {
    get(account_setup_mfa)
      .then((res) => {
        // console.log(res.data);

        let mfa_enabled = res.data.mfa_enabled;

        setMFAEnabled(mfa_enabled);

        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  // listner for fetch MFA details
  React.useEffect(() => {
    getMFAStatus();
  }, [getMFAStatus]);

  const handleFormSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    post(account_setup_mfa, {})
      .then((res) => {
        console.log(res.data);

        // let datas = res.data;

        enqueueSnackbar("MFA is configured successfully.", {
          variant: "success",
          autoHideDuration: 3000,
        });

        // getMFAStatus();

        // redirect to login page
        history.push("/");
      })
      .catch((err) => {
        console.log(err);

        if (err.response && err.response.data.message) {
          enqueueSnackbar(err.response.data.message, {
            variant: "error",
            autoHideDuration: 3000,
          });
        } else {
          enqueueSnackbar(
            "An error occured while configuring the multi-factor authentication.",
            {
              variant: "error",
              autoHideDuration: 3000,
            }
          );
        }

        setLoading(false);
      });
  };

  const handleRemoveMFA = (e) => {
    e.preventDefault();

    setLoading(true);

    Delete(account_setup_mfa)
      .then((res) => {
        console.log(res.data);

        enqueueSnackbar("MFA removed successfully.", {
          variant: "success",
          autoHideDuration: 3000,
        });

        getMFAStatus();
      })
      .catch((err) => {
        console.log(err);

        if (err.response && err.response.data.message) {
          enqueueSnackbar(err.response.data.message, {
            variant: "error",
            autoHideDuration: 3000,
          });
        } else {
          enqueueSnackbar("An error occured while removing MFA.", {
            variant: "error",
            autoHideDuration: 3000,
          });
        }

        setLoading(false);
      });
  };

  return (
    <>
      {/* Multi-Factor Authentication section starts */}
      <Card
        id={"mfa_authentication_div"}
        heading={"Multi-Factor Authentication"}
        description={`Use multi-factor authentication (MFA) to add an extra layer of security to your account.`}
      >
        <div className="grid grid-cols-2 xs:grid-cols-1 px-5 mt-5">
          <div className="p-6 border border-gray-300 shadow dark:border-gray-600 rounded-2xl">
            <img
              className="w-16 h-16"
              src={TimebasedOTP}
              alt="Change Password"
            />

            <p className="mt-4 mb-1 font-medium text-gray-900 dark:text-white">
              OTP Authenticator
            </p>

            <p className="mb-4 text-sm text-gray-1500 dark:text-gray-3000">
              Use time-based OTPs generated by authenticator apps to securely
              sign in to your vegastack account.
            </p>

            {loading && (
              <RiLoader4Fill className="mt-6 h-6 w-6 animate-spin text-blue-500 ml-6" />
            )}

            {!MFAEnabled && !loading && (
              <p
                className="mt-6 font-medium text-blue-500 cursor-pointer"
                onClick={handleFormSubmit}
              >
                Set up Now
              </p>
            )}

            {MFAEnabled && !loading && (
              <p
                className="mt-6 font-medium text-red-500 cursor-pointer"
                onClick={handleRemoveMFA}
              >
                Remove MFA
              </p>
            )}
          </div>
        </div>
      </Card>
      {/* Multi-Factor Authentication section ends */}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setTimeZone: (data) => dispatch({ type: "setTimeZone", ...data }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SecurityMFAAuthentication));
