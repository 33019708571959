// import react component
import { useEffect, useState } from "react";
// import Resize Observer
import useResizeObserver from "use-resize-observer";
import { RiEmotionSadLine } from "react-icons/ri";
import {
  RiAddLine,
  RiCheckLine,
  RiDeleteBinLine,
  RiEditBoxLine,
  RiFileDownloadLine,
  RiLinksLine,
  RiUserAddLine,
} from "react-icons/ri";

function Activity(props) {
  const { ref, width = 1 } = useResizeObserver();
  const [activityItems, setActivityItems] = useState([]);

  // listner for width and props activityitems
  useEffect(() => {
    setActivityItems(props.activityItems);
  }, [props.activityItems, width]);

  const randomColor = (action) => {
    if (action === "added") {
      return (
        <span
          className={`bg-green-500 w-10 h-10 rounded-full flex justify-center items-center`}
        >
          <RiAddLine className="h-5 w-5 text-white" />
        </span>
      );
    } else if (action === "updated") {
      return (
        <span
          className={`bg-blue-500 w-10 h-10 rounded-full flex justify-center items-center`}
        >
          <RiEditBoxLine className="h-5 w-5 text-white" />
        </span>
      );
    } else if (
      action === "removed" ||
      action === "disconnected" ||
      action === "terminated"
    ) {
      return (
        <span
          className={`bg-red-500 w-10 h-10 rounded-full flex justify-center items-center`}
        >
          <RiDeleteBinLine className="h-5 w-5 text-white" />
        </span>
      );
    } else if (action === "verified") {
      return (
        <span
          className={`bg-green-500 w-10 h-10 rounded-full flex justify-center items-center`}
        >
          <RiCheckLine className="h-5 w-5 text-white" />
        </span>
      );
    } else if (action === "joined") {
      return (
        <span
          className={`bg-blue-500 w-10 h-10 rounded-full flex justify-center items-center`}
        >
          <RiUserAddLine className="h-5 w-5 text-white" />
        </span>
      );
    } else if (action === "connected") {
      return (
        <span
          className={`bg-blue-500 w-10 h-10 rounded-full flex justify-center items-center`}
        >
          <RiLinksLine className="h-5 w-5 text-white" />
        </span>
      );
    }
  };

  return (
    <>
      {
        <div className="pr-1 overflow-y-auto rounded dark:bg-gray-900">
          <ul
            ref={ref}
            className="w-full divide-y divide-gray-200 dark:divide-gray-600"
          >
            {activityItems &&
              activityItems.map((activityItem) => (
                <li key={activityItem.id}>
                  <div className="flex justify-between px-5 py-4">
                    <div className="flex">
                      <span>{randomColor(activityItem.action)}</span>
                      <p className="text-sm pl-3 text-gray-1300 dark:text-gray-1500 break-all">
                        {activityItem?.display_message &&
                          activityItem?.display_message}
                      </p>
                    </div>

                    {/* timestamp */}
                    <div className=" text-sm text-gray-1300 dark:text-gray-1500">
                      {activityItem?.created_at}
                    </div>
                  </div>
                </li>
              ))}
            {/* no data */}
            {!props.loading && activityItems?.length === 0 && (
              <p className="text-center text-gray-900 dark:text-gray-300">
                <RiEmotionSadLine className="w-8 h-8 mx-auto text-gray-1300 dark:text-gray-1300" />
                No Recent Activity
              </p>
            )}
          </ul>
        </div>
      }
    </>
  );
}

export default Activity;
