// import react components
import React from "react";
// import react router components
import { withRouter, useHistory, useLocation } from "react-router-dom";
import { connect } from "react-redux";
// import page components
import HeaderTwo from "../../Components/HeaderTwo";
import {
  RiCheckboxCircleLine,
  RiArrowDropLeftLine,
  RiLoader4Fill,
} from "react-icons/ri";
import Select from "react-select";
import {
  account_user_registration,
  account_microsoft_sign_up,
  account_google_sign_up,
  account_user_data_url,
} from "../../Components/Urls";
import { get, post } from "../../Components/AxiosUtilities";
import { useSnackbar } from "notistack";
// import * as msTeams from '@microsoft/teams-js';
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../authConfig";
// google sign in
import { useGoogleLogin } from "react-google-login";
// import useGoogleLogin from "../../Components/GoogleSignIN/use-google-login";

const { REACT_APP_GOOGLE_APP_ID } = process.env;

function SignUP(props) {
  const history = useHistory();

  // show alert and close alert functions
  const { enqueueSnackbar } = useSnackbar();

  const [signupLoader, setSignupLoader] = React.useState(false);
  const [signupGoogleLoader, setSignupGoogleLoader] = React.useState(false);
  const [signupMicrosoftLoader, setSignupMicrosoftLoader] =
    React.useState(false);

  const dataCenters = ["Europe", "Australia", "India", "United States"];

  const [selectedCenter, setSelectedCenter] = React.useState({
    label: dataCenters[2],
    value: dataCenters[2],
  });

  const [showDataCenter, setShowDataCenter] = React.useState(false);

  const [values, setValues] = React.useState({
    first_name: "",
    last_name: "",
    email: "",
    organization: "",
    phone_number: "",
  });

  // form wizard states
  const [currentPage, setcurrentPage] = React.useState(1);
  const nextPage = () => setcurrentPage((prev) => ++prev);
  const prevPage = () => setcurrentPage((prev) => --prev);

  // listner for login
  // if user already logged in redurect to home page
  React.useEffect(() => {
    get(account_user_data_url)
      .then((res) => {
        console.log(res);

        history.push({
          pathname: "/profile/personal",
          state: {
            showAlreadyLoggedInAlert: true,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [history]);

  const onSuccess = (res) => {
    // console.log(res.accessToken);

    // console.log("Login Success: currentUser:", res.profileObj);

    setSignupGoogleLoader(false);

    post(account_google_sign_up, {
      access_token: res.accessToken,
      data_center: selectedCenter,
      browser_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    })
      .then((res) => {
        console.log(res);

        enqueueSnackbar(res.data.message, {
          variant: "success",
          autoHideDuration: 3000,
        });
        setSignupMicrosoftLoader(false);

        // redirect to authentication validator page
        history.push("/redirect");
      })
      .catch((err) => {
        console.log(err);

        if (err.response && err.response.data.message) {
          enqueueSnackbar(err.response.data.message, {
            variant: "error",
            autoHideDuration: 3000,
          });
        }

        setSignupMicrosoftLoader(false);
      });
  };

  const onFailure = (res) => {
    console.log("Login failed: res:", res);
    setSignupGoogleLoader(false);
  };

  const clientId = REACT_APP_GOOGLE_APP_ID;

  const { signIn } = useGoogleLogin({
    onSuccess,
    onFailure,
    clientId: clientId,
    isSignedIn: false,
    accessType: "offline",
    scope:
      "https://www.googleapis.com/auth/user.organization.read https://www.googleapis.com/auth/user.phonenumbers.read",
    // onAutoLoadFinished,
    // cookiePolicy,
    // loginHint,
    // hostedDomain,
    // autoLoad,
    // redirectUri,
    // discoveryDocs,
    // uxMode,
    // scope,
    // accessType,
    // responseType,
    // jsSrc,
    // onRequest,
    // prompt
  });

  //   microsoft sign in

  const { instance } = useMsal();

  const MicrosoftSignIN = (accessToken) => {
    // console.log(accessToken);

    post(account_microsoft_sign_up, {
      access_token: accessToken,
      data_center: selectedCenter,
      browser_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    })
      .then((res) => {
        console.log(res);

        enqueueSnackbar(res.data.message, {
          variant: "success",
          autoHideDuration: 3000,
        });
        setSignupMicrosoftLoader(false);

        // redirect to authentication validator page
        history.push("/redirect");
      })
      .catch((err) => {
        console.log(err);

        if (err.response && err.response.data.message) {
          enqueueSnackbar(err.response.data.message, {
            variant: "error",
            autoHideDuration: 3000,
          });
        }

        setSignupMicrosoftLoader(false);
      });
  };

  function handleLogin(instance) {
    setSignupMicrosoftLoader(true);
    instance
      .loginPopup(loginRequest)
      .then((res) => {
        // console.log(res);

        MicrosoftSignIN(res.accessToken);
      })
      .catch((e) => {
        console.error(e);
        setSignupMicrosoftLoader(false);
      });
  }

  const handleInputChange = (e) => {
    setValues((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    setSignupLoader(true);

    post(account_user_registration, {
      ...values,
      data_center: selectedCenter.value,
      browser_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    })
      .then((res) => {
        console.log(res);

        setSignupLoader(false);

        enqueueSnackbar(res.data.message, {
          variant: "success",
          autoHideDuration: 3000,
        });

        // redirect to authentication validator page
        history.push("/redirect");
      })
      .catch((err) => {
        console.log(err);

        if (err.response && err.response.data.message) {
          enqueueSnackbar(err.response.data.message, {
            variant: "error",
            autoHideDuration: 3000,
          });
        }
        setSignupLoader(false);
      });
  };


  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  let query = useQuery();


  return (
    <>
      <HeaderTwo />

      <div className="px-32 xs:px-8 mt-16 mb-12">
        <p className="font-semibold h-1 capitalize text-lg text-gray-900 dark:text-gray-300">
          {query.get("client_id").replace("-", " ")}
        </p>
      </div>

      <div className="grid lg:grid-cols-2 grid-cols-1 gap-x-8 px-32 xs:px-8 text-gray-1300 dark:text-gray-300 font-medium">
        <div className="divide-y divide-gray-300 dark:divide-gray-400 space-y-4 lg:mt-4">
          <div className="space-y-4">
            <h1 className="text-4xl text-gray-900 dark:text-gray-200 font-bold">
              Try Vegastack free
            </h1>

            <p className="text-lg ">
              No credit card required. No strings attached.
            </p>
          </div>

          <div>
            <ul className="space-y-4 my-4">
              <li className="flex items-center ">
                <RiCheckboxCircleLine className="h-5 w-5 mr-2 text-green-400 flex-none" />
                <p className="grow">75% reduction in ticket resolution time</p>
              </li>
              <li className="flex items-center">
                <RiCheckboxCircleLine className="h-5 w-5 mr-2 text-green-400 flex-none" />
                <p className="grow">&lt; 3 month payback period</p>
              </li>
              <li className="flex items-center">
                <RiCheckboxCircleLine className="h-5 w-5 mr-2 text-green-400 flex-none" />
                <p className="grow">
                  54 hours per agent per year saved with automations
                </p>
              </li>
            </ul>

            <p>
              <strong>*Source:&nbsp;</strong>2020 Forrester Total Economic
              Impact™ Study
            </p>
          </div>

          <div className="space-y-2">
            <h6 className="mt-8 font-semibold text-gray-900 dark:text-gray-200">
              Trusted by 50,000+ businesses
            </h6>

            <div className="grid grid-cols-3 grid-rows-2">
              <div className="customer-logo tight">
                <div className="lazy-image-wrapper loaded">
                  <img
                    className="original-image fadeIn   "
                    alt=""
                    title=""
                    src="https://website-assets-fd.freshworks.com/attachments/ckx8zudw502yenyfnvl2tr3fq-aramex.one-half.png"
                    srcSet="https://website-assets-fd.freshworks.com/attachments/ckx8zudw502yenyfnvl2tr3fq-aramex.full.png 2x"
                  />
                </div>
              </div>

              <div className="customer-logo tight">
                <div className="lazy-image-wrapper loaded">
                  <img
                    className="original-image fadeIn   "
                    alt=""
                    title=""
                    src="https://website-assets-fd.freshworks.com/attachments/ckx8zuydq02yrnyfnj28jl5o6-orderin.one-half.png"
                    srcSet="https://website-assets-fd.freshworks.com/attachments/ckx8zuydq02yrnyfnj28jl5o6-orderin.full.png 2x"
                  />
                </div>
              </div>

              <div className="customer-logo tight">
                <div className="lazy-image-wrapper loaded">
                  <img
                    className="original-image fadeIn   "
                    alt=""
                    title=""
                    src="https://website-assets-fd.freshworks.com/attachments/ckx8ztvn002xynyfn9p0vg0f9-bridgestone.one-half.png"
                    srcSet="https://website-assets-fd.freshworks.com/attachments/ckx8ztvn002xynyfn9p0vg0f9-bridgestone.full.png 2x"
                  />
                </div>
              </div>

              <div className="customer-logo tight">
                <div className="lazy-image-wrapper loaded">
                  <img
                    className="original-image fadeIn   "
                    alt=""
                    title=""
                    src="https://website-assets-fd.freshworks.com/attachments/ckx8ztcuv00fmmgfnryeyumev-decathalon.one-half.png"
                    srcSet="https://website-assets-fd.freshworks.com/attachments/ckx8ztcuv00fmmgfnryeyumev-decathalon.full.png 2x"
                  />
                </div>
              </div>

              <div className="customer-logo tight">
                <div className="lazy-image-wrapper loaded">
                  <img
                    className="original-image fadeIn   "
                    alt=""
                    title=""
                    src="https://website-assets-fd.freshworks.com/attachments/ckx8zstkt00f8mgfnfnxvegid-pearson.one-half.png"
                    srcSet="https://website-assets-fd.freshworks.com/attachments/ckx8zstkt00f8mgfnfnxvegid-pearson.full.png 2x"
                  />
                </div>
              </div>

              <div className="customer-logo tight">
                <div className="lazy-image-wrapper loaded">
                  <img
                    className="original-image fadeIn   "
                    alt=""
                    title=""
                    src="https://website-assets-fd.freshworks.com/attachments/ckx8zs87w02wfnyfn4vdw84or-cinnamon-hotel.one-half.png"
                    srcSet="https://website-assets-fd.freshworks.com/attachments/ckx8zs87w02wfnyfn4vdw84or-cinnamon-hotel.full.png 2x"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="py-8 lg:py-0">
          {currentPage === 1 && (
            <div
              className="bg-white 
                        dark:bg-gray-800 rounded-md overflow-hidden 
                        drop-shadow-xl  px-6 py-4 text-center max-w-md mx-auto text-gray-900
                         dark:text-gray-300 border border-gray-50 dark:border-gray-400"
            >
              <h1 className="text-lg text-gray-900 dark:text-gray-200 font-bold mb-4 mt-2">
                Sign up
              </h1>

              <div
                className="flex items-center py-1 px-1 my-2 bg-blue-500 text-white rounded cursor-pointer hover:bg-blue-400"
                onClick={() => {
                  signIn();
                  setSignupGoogleLoader(true);
                }}
              >
                {signupGoogleLoader && (
                  <RiLoader4Fill className="h-8 w-8 animate-spin mx-auto" />
                )}

                {!signupGoogleLoader && (
                  <>
                    <div className="bg-white py-2 px-2 rounded-l">
                      <svg
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 48 48"
                        className="h-4 w-4"
                      >
                        <g>
                          <path
                            fill="#EA4335"
                            d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
                          ></path>
                          <path
                            fill="#4285F4"
                            d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
                          ></path>
                          <path
                            fill="#FBBC05"
                            d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
                          ></path>
                          <path
                            fill="#34A853"
                            d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
                          ></path>
                          <path fill="none" d="M0 0h48v48H0z"></path>
                        </g>
                      </svg>
                    </div>
                    <div className="mx-auto">
                      <span>Sign up with Google</span>
                    </div>
                  </>
                )}
              </div>

              <div
                className="flex items-center py-1 px-1 my-2 bg-stone-600 text-white rounded cursor-pointer hover:bg-stone-400"
                onClick={() => handleLogin(instance)}
              >
                {signupMicrosoftLoader && (
                  <RiLoader4Fill className="h-8 w-8 animate-spin mx-auto" />
                )}

                {!signupMicrosoftLoader && (
                  <>
                    <div className="bg-white py-2 px-2 rounded-l">
                      <svg
                        className="h-4 w-4"
                        enableBackground="new 0 0 2499.6 2500"
                        viewBox="0 0 2499.6 2500"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="m1187.9 1187.9h-1187.9v-1187.9h1187.9z"
                          fill="#f1511b"
                        />
                        <path
                          d="m2499.6 1187.9h-1188v-1187.9h1187.9v1187.9z"
                          fill="#80cc28"
                        />
                        <path
                          d="m1187.9 2500h-1187.9v-1187.9h1187.9z"
                          fill="#00adef"
                        />
                        <path
                          d="m2499.6 2500h-1188v-1187.9h1187.9v1187.9z"
                          fill="#fbbc09"
                        />
                      </svg>
                    </div>
                    <div className="mx-auto">
                      <span>Sign up with Microsoft</span>
                    </div>
                  </>
                )}
              </div>

              {!showDataCenter && (
                <p className="text-sm">
                  Your data will be located in{" "}
                  <strong className="capitalize">{selectedCenter.label}</strong>
                  <span
                    className="text-red-800 cursor-pointer"
                    onClick={() => setShowDataCenter(true)}
                  >
                    {" "}
                    Change
                  </span>
                </p>
              )}

              {showDataCenter && (
                <div>
                  {/* <label className="form-label text-left">Data Center</label> */}
                  <Select
                    name="tabs"
                    className="custom-width"
                    value={selectedCenter}
                    onChange={(result) => {
                      setSelectedCenter(result);
                    }}
                    options={dataCenters.map((center) => {
                      return {
                        label: center,
                        value: center,
                      };
                    })}
                    isSearchable={false}
                    isRequired
                  />
                </div>
              )}

              <p className="my-4">or</p>

              <button
                className="py-2 px-4 bg-black dark:bg-white text-white dark:text-gray-900 rounded w-full"
                onClick={nextPage}
              >
                Sign up with email
              </button>

              <p className="text-sm w-full mt-2">
                By signing up, you are agreeing to our
                <span className="text-blue-500"> terms </span>
                and acknowledge that you read our
                <span className="text-blue-500"> privacy notice</span>.
              </p>
            </div>
          )}

          {currentPage === 2 && (
            <div
              className="bg-white 
                        dark:bg-gray-800 rounded-md overflow-hidden 
                        drop-shadow-xl  px-6 py-4 text-center max-w-md mx-auto text-gray-900
                         dark:text-gray-300 border border-gray-50 dark:border-gray-400"
            >
              <div className="flex items-center justify-between mb-4 mt-2">
                <RiArrowDropLeftLine
                  className="h-7 w-7 bg-black dark:bg-white text-white dark:text-gray-900 rounded cursor-pointer"
                  onClick={prevPage}
                />
                <h1 className="text-lg w-full text-gray-900 dark:text-gray-200 font-bold">
                  Sign up
                </h1>
              </div>

              <form onSubmit={handleFormSubmit}>
                <div className="space-y-4 my-4">
                  <div className="grid grid-cols-2 gap-x-4">
                    <input
                      type="text"
                      name="first_name"
                      id="first_name"
                      required={true}
                      placeholder="First Name"
                      value={values.first_name}
                      onChange={handleInputChange}
                      className="input-field"
                    />

                    <input
                      type="text"
                      name="last_name"
                      id="last_name"
                      required={true}
                      placeholder="Last Name"
                      value={values.last_name}
                      onChange={handleInputChange}
                      className="input-field"
                    />
                  </div>

                  <input
                    type="email"
                    name="email"
                    id="email"
                    required={true}
                    placeholder="Email"
                    value={values.email}
                    onChange={handleInputChange}
                    className="input-field"
                  />

                  <input
                    type="text"
                    name="organization"
                    id="organization"
                    required={true}
                    placeholder="Organization"
                    value={values.organization}
                    onChange={handleInputChange}
                    className="input-field"
                  />

                  <input
                    type="text"
                    name="phone_number"
                    id="phone_number"
                    required={false}
                    placeholder="Phone No."
                    value={values.phone_number}
                    onChange={handleInputChange}
                    className="input-field"
                  />

                  {!showDataCenter && (
                    <p className="text-sm">
                      Your data will be located in{" "}
                      <strong className="capitalize">
                        {selectedCenter.label}
                      </strong>
                      <span
                        className="text-red-800 cursor-pointer"
                        onClick={() => setShowDataCenter(true)}
                      >
                        {" "}
                        Change
                      </span>
                    </p>
                  )}

                  {showDataCenter && (
                    <div>
                      {/* <label className="form-label text-left">Data Center</label> */}
                      <Select
                        name="tabs"
                        className="custom-width"
                        value={selectedCenter}
                        onChange={(result) => {
                          setSelectedCenter(result);
                        }}
                        options={dataCenters.map((center) => {
                          return {
                            label: center,
                            value: center,
                          };
                        })}
                        isSearchable={false}
                        isRequired
                      />
                    </div>
                  )}
                </div>

                <button
                  className="py-2 px-4 bg-black dark:bg-white text-white dark:text-gray-900 rounded w-full"
                  type="submit"
                >
                  {!signupLoader && "Sign up"}

                  {signupLoader && (
                    <RiLoader4Fill className="h-6 w-6 animate-spin mx-auto" />
                  )}
                </button>
              </form>

              <p className="text-sm w-full mt-2">
                By clicking on "signing up" you are agreeing to our
                <span className="text-blue-500"> terms </span>
                and acknowledge that you read our
                <span className="text-blue-500"> privacy notice</span>.
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setTimeZone: (data) => dispatch({ type: "setTimeZone", ...data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SignUP));
