import { Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import React from "react";

export default function CustomSlideOver(props) {
  let refDiv = useRef(null);

  const [open, setOpen] = React.useState(false);

  return (
    <Transition.Root show={props.Open} as={Fragment}>
      <Dialog
        initialFocus={refDiv}
        as="div"
        className="fixed inset-0 overflow-hidden z-50"
        open={open}
        onClose={setOpen}
      >
        <div ref={refDiv} className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0" />

          <div
            className={`fixed inset-y-0 right-0 flex sm:pl-16 top-12 ${props.hasOwnProperty("top") && props.top}`}
          >
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div
                className={
                  props.customCss
                    ? props.customCss
                    : "w-screen max-w-2xl bg-white dark:bg-gray-800"
                }
              >
                {/* render children starts*/}

                {props.children}

                {/* render childern ends */}
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
