// import react content loader component
import ContentLoader from "react-content-loader";
import { useMediaQuery } from "react-responsive";

function ConnectedAccountsLoader(props) {
  const backgroundcolor =
    localStorage.getItem("theme") === "light" ||
    localStorage.getItem("theme") === null
      ? "#EAEAEA"
      : "#242424";
  const foregroundcolor =
    localStorage.getItem("theme") === "light" ||
    localStorage.getItem("theme") === null
      ? "#FCFCFC"
      : "#050505";

  const isMobileOrOther = useMediaQuery({ maxWidth: 640 });

  const searchBarWidth = () => {
    return isMobileOrOther ? "100%" : "300px";
  };

  return (
    <>
      <div className="">
        <div className="mt-5 md:mt-0 md:col-span-2">
          <div className="shadow sm:rounded">
            <div className="bg-white dark:bg-gray-800 sm:p-6 z-0">
              <div className="flex justify-between gap-4 xs:flex-col m-5">
                <div className="border-gray-300 dark:bg-gray-800 dark:border-gray-600">
                  <ContentLoader
                    speed={2}
                    height={38}
                    width={searchBarWidth()}
                    backgroundColor={backgroundcolor}
                    foregroundColor={foregroundcolor}
                    className="react_content_loader"
                  >
                    <rect
                      x="0"
                      y="0"
                      rx="5"
                      ry="5"
                      width={searchBarWidth()}
                      height="38"
                    />
                  </ContentLoader>
                </div>
                <div className="border-gray-300 dark:bg-gray-800 dark:border-gray-600">
                  <ContentLoader
                    speed={2}
                    height={38}
                    width={searchBarWidth()}
                    backgroundColor={backgroundcolor}
                    foregroundColor={foregroundcolor}
                    className="react_content_loader"
                  >
                    <rect
                      x="0"
                      y="0"
                      rx="5"
                      ry="5"
                      width={searchBarWidth()}
                      height="38"
                    />
                  </ContentLoader>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ConnectedAccountsLoader;
