


function VegastackLogo(props) {

    return (
        <svg version="1.1" id="Layer_1" xmlns={"http://www.w3.org/2000/svg"} xmlnsXlink={"http://www.w3.org/1999/xlink"} x="0px" y="0px"
            viewBox={props.symbol ? "0 0 45 23" : "0 0 180 31.39"} xmlSpace={"preserve"} className={props.className} >
            <g>
                <path className="st0" d="M16.99,0l-2.63,4.6c0,0,1.68,0,2.31,0c0.64,0,0.57,0.45,0.22,0.93c-0.36,0.5-5.62,7.52-6,7.94
		c-0.37,0.42-0.87,0.51-0.53-0.5c0.34-1.01,1.96-5.55,1.96-5.55s-2.36,0-2.78,0c-0.42,0-0.34-0.23-0.17-0.54
		C9.54,6.57,12.75,0,12.75,0S4.09,0,2.66,0S0,0.92,0,2.41C0,3.9,0.71,4.86,1.16,5.75s7.64,13.3,8.51,14.9
		c0.87,1.6,1.79,2.83,3.42,2.83s2.3-0.68,3.62-2.98c1.32-2.3,7.92-13.67,8.67-15.07c0.76-1.4,0.93-1.85,0.93-2.83S25.54,0,23.14,0
		S16.99,0,16.99,0z"/>
                {
                    !props.symbol &&
                    <g>
                        <path className="st0" d="M45.67,15.65h-12.8c0.11,1.24,0.55,2.21,1.33,2.91c0.78,0.7,1.74,1.05,2.88,1.05c1.64,0,2.81-0.69,3.51-2.08
			h4.77c-0.51,1.65-1.48,3.01-2.91,4.07c-1.43,1.06-3.19,1.6-5.28,1.6c-1.69,0-3.2-0.37-4.54-1.1c-1.34-0.73-2.38-1.77-3.13-3.11
			s-1.12-2.89-1.12-4.65c0-1.78,0.37-3.34,1.11-4.68c0.74-1.34,1.77-2.37,3.1-3.1c1.33-0.72,2.86-1.08,4.58-1.08
			c1.66,0,3.16,0.35,4.47,1.05c1.32,0.7,2.34,1.7,3.07,2.99c0.73,1.29,1.09,2.77,1.09,4.45C45.8,14.59,45.76,15.15,45.67,15.65z
			 M41.22,12.73c-0.02-1.12-0.43-2.01-1.23-2.68c-0.8-0.67-1.78-1.01-2.94-1.01c-1.1,0-2.02,0.33-2.77,0.98
			c-0.75,0.65-1.21,1.56-1.38,2.71H41.22z"/>
                        <path className="st0" d="M59.33,6.24c0.99,0.51,1.77,1.16,2.34,1.97V5.76h4.46v17.29c0,1.59-0.33,3.01-0.98,4.26
			c-0.65,1.25-1.63,2.24-2.94,2.97c-1.31,0.73-2.89,1.1-4.74,1.1c-2.49,0-4.53-0.57-6.12-1.71c-1.59-1.14-2.49-2.68-2.7-4.65h4.39
			c0.23,0.78,0.73,1.41,1.5,1.87c0.77,0.47,1.7,0.7,2.8,0.7c1.28,0,2.33-0.38,3.13-1.13c0.8-0.75,1.2-1.9,1.2-3.42v-2.66
			c-0.57,0.81-1.35,1.48-2.35,2.01c-1,0.54-2.14,0.81-3.43,0.81c-1.48,0-2.82-0.37-4.05-1.12c-1.22-0.74-2.19-1.79-2.89-3.14
			c-0.71-1.35-1.06-2.91-1.06-4.66c0-1.74,0.35-3.27,1.06-4.62c0.71-1.34,1.66-2.37,2.88-3.1c1.21-0.72,2.57-1.08,4.06-1.08
			C57.19,5.48,58.34,5.74,59.33,6.24z M61.04,11.63c-0.42-0.75-0.99-1.33-1.71-1.73c-0.72-0.4-1.49-0.6-2.31-0.6
			s-1.58,0.2-2.28,0.59c-0.7,0.39-1.26,0.97-1.69,1.72c-0.43,0.75-0.65,1.65-0.65,2.68c0,1.03,0.22,1.94,0.65,2.71
			c0.43,0.77,1,1.37,1.71,1.78s1.46,0.62,2.26,0.62c0.82,0,1.59-0.2,2.31-0.6c0.72-0.4,1.28-0.98,1.71-1.73
			c0.42-0.75,0.63-1.66,0.63-2.71S61.46,12.39,61.04,11.63z"/>
                        <path className="st0" d="M70.38,9.67c0.71-1.34,1.66-2.37,2.88-3.1c1.21-0.72,2.57-1.08,4.06-1.08c1.31,0,2.45,0.26,3.43,0.77
			c0.98,0.52,1.76,1.17,2.35,1.95V5.76h4.46v17.17H83.1v-2.51c-0.57,0.81-1.35,1.47-2.35,2c-1,0.53-2.16,0.79-3.46,0.79
			c-1.48,0-2.82-0.37-4.03-1.12c-1.21-0.74-2.17-1.79-2.88-3.14c-0.71-1.35-1.06-2.91-1.06-4.66C69.32,12.55,69.67,11.01,70.38,9.67
			z M82.47,11.63c-0.42-0.75-0.99-1.33-1.71-1.73c-0.72-0.4-1.49-0.6-2.31-0.6c-0.82,0-1.58,0.2-2.28,0.59
			c-0.7,0.39-1.26,0.97-1.69,1.72c-0.43,0.75-0.65,1.65-0.65,2.68c0,1.03,0.22,1.94,0.65,2.71c0.43,0.77,1,1.37,1.71,1.78
			c0.71,0.41,1.46,0.62,2.26,0.62c0.82,0,1.59-0.2,2.31-0.6c0.72-0.4,1.28-0.98,1.71-1.73c0.42-0.75,0.63-1.66,0.63-2.71
			S82.89,12.39,82.47,11.63z"/>
                        <path className="st0" d="M95.35,22.37c-1.23-0.52-2.21-1.26-2.92-2.23c-0.72-0.97-1.09-2.12-1.11-3.44h4.74
			c0.06,0.89,0.38,1.59,0.96,2.11c0.58,0.52,1.37,0.77,2.39,0.77c1.03,0,1.84-0.24,2.43-0.73c0.59-0.48,0.89-1.12,0.89-1.9
			c0-0.64-0.2-1.17-0.6-1.58c-0.4-0.41-0.9-0.74-1.5-0.98c-0.6-0.24-1.43-0.5-2.48-0.79c-1.43-0.41-2.6-0.82-3.49-1.22
			c-0.9-0.4-1.66-1.01-2.31-1.83c-0.64-0.82-0.96-1.91-0.96-3.27c0-1.28,0.33-2.4,0.98-3.35s1.57-1.68,2.75-2.18
			c1.18-0.51,2.53-0.76,4.05-0.76c2.28,0,4.12,0.54,5.55,1.63c1.42,1.08,2.21,2.6,2.36,4.54h-4.87c-0.04-0.74-0.36-1.36-0.96-1.84
			c-0.6-0.48-1.4-0.73-2.39-0.73c-0.86,0-1.55,0.22-2.07,0.65C96.26,5.67,96,6.3,96,7.13c0,0.58,0.2,1.06,0.59,1.44
			c0.39,0.38,0.87,0.69,1.45,0.93c0.58,0.24,1.4,0.51,2.45,0.82c1.43,0.41,2.6,0.83,3.51,1.24c0.91,0.41,1.69,1.03,2.34,1.86
			c0.65,0.83,0.98,1.91,0.98,3.25c0,1.16-0.31,2.23-0.92,3.22c-0.61,0.99-1.51,1.78-2.69,2.37c-1.18,0.59-2.58,0.88-4.2,0.88
			C97.97,23.15,96.59,22.89,95.35,22.37z"/>
                        <path className="st0" d="M116.3,9.33v8.3c0,0.58,0.14,1,0.43,1.25c0.28,0.26,0.76,0.39,1.44,0.39h2.05v3.66h-2.78
			c-3.73,0-5.59-1.78-5.59-5.33V9.33h-2.09V5.76h2.09V1.52h4.46v4.25h3.92v3.56H116.3z"/>
                        <path className="st0" d="M123.33,9.67c0.71-1.34,1.66-2.37,2.88-3.1c1.21-0.72,2.57-1.08,4.06-1.08c1.31,0,2.45,0.26,3.43,0.77
			c0.98,0.52,1.76,1.17,2.36,1.95V5.76h4.46v17.17h-4.46v-2.51c-0.57,0.81-1.35,1.47-2.36,2c-1,0.53-2.16,0.79-3.46,0.79
			c-1.48,0-2.82-0.37-4.03-1.12c-1.21-0.74-2.17-1.79-2.88-3.14c-0.71-1.35-1.06-2.91-1.06-4.66
			C122.27,12.55,122.63,11.01,123.33,9.67z M135.42,11.63c-0.42-0.75-0.99-1.33-1.71-1.73c-0.72-0.4-1.49-0.6-2.31-0.6
			c-0.82,0-1.58,0.2-2.28,0.59c-0.69,0.39-1.26,0.97-1.69,1.72c-0.43,0.75-0.65,1.65-0.65,2.68c0,1.03,0.22,1.94,0.65,2.71
			c0.43,0.77,1,1.37,1.71,1.78c0.71,0.41,1.46,0.62,2.26,0.62c0.82,0,1.59-0.2,2.31-0.6c0.72-0.4,1.29-0.98,1.71-1.73
			c0.42-0.75,0.63-1.66,0.63-2.71S135.84,12.39,135.42,11.63z"/>
                        <path className="st0" d="M144.81,9.68c0.74-1.33,1.76-2.37,3.07-3.1c1.31-0.73,2.8-1.1,4.49-1.1c2.17,0,3.97,0.53,5.39,1.6
			c1.42,1.06,2.38,2.56,2.86,4.48h-4.77c-0.25-0.74-0.68-1.33-1.28-1.75c-0.6-0.42-1.34-0.63-2.23-0.63c-1.26,0-2.26,0.45-3,1.35
			c-0.74,0.9-1.11,2.17-1.11,3.83c0,1.63,0.37,2.9,1.11,3.8c0.74,0.9,1.74,1.35,3,1.35c1.79,0,2.96-0.78,3.51-2.35h4.77
			c-0.49,1.86-1.44,3.34-2.88,4.43c-1.43,1.1-3.22,1.64-5.37,1.64c-1.69,0-3.18-0.37-4.49-1.1c-1.31-0.73-2.33-1.77-3.07-3.1
			c-0.74-1.33-1.11-2.89-1.11-4.66S144.08,11.01,144.81,9.68z"/>
                        <path className="st0" d="M174.25,22.93l-5.94-7.31v7.31h-4.43V0h4.43v13.04l5.88-7.28h5.75l-7.71,8.61l7.78,8.55H174.25z" />
                    </g>
                }
            </g>
        </svg>
    )
}


export default VegastackLogo;