import { useSnackbar } from "notistack";
import React, { useCallback, useMemo, useState } from "react";
import {
  RiWindowsFill,
  RiDeleteBinLine,
  RiAppleFill,
  RiUbuntuFill,
  RiCellphoneLine,
  RiTabletLine,
  RiComputerLine,
} from "react-icons/ri";
import { FaLinux } from "react-icons/fa";
import { format } from "timeago.js";
import { Delete, get } from "../../Components/AxiosUtilities";
import { convertTZ } from "../../Components/TimezoneHandler";
import Table from "../../Components/Tables/Table";
import TableLoader from "../../Components/Loaders/TableLoader.jsx";
import Tooltip from "../../Components/Tooltip";
import ConfirmationModal from "../../Components/ConfirmationModal";
import {
  active_session_url,
  terminate_all_sessions_url,
} from "../../Components/Urls";
import {
  HideInitialSidebarInMobile,
  navigation,
} from "../../Components/utilities";
import Header from "../../Components/Header";
import Sidebar from "../../Components/Sidebar";
import Content from "../../Components/Content";

const ActiveSessions = (props) => {
  const { enqueueSnackbar } = useSnackbar();

  // for sidebar
  const [showSidebar, setshowSidebar] = useState(HideInitialSidebarInMobile());

  // terminate session states
  const [
    showTerminateSessionConfirmation,
    setShowTerminateSessionConfirmation,
  ] = useState(false);

  // terminate all session states
  const [
    showTerminateAllSessionConfirmation,
    setShowTerminateAllSessionConfirmation,
  ] = useState(false);

  const [sessionUID, setSessionUID] = useState(0);

  const fetchIdRef = React.useRef(0);

  // active session table states
  const [sessionData, setSessionData] = useState([]);
  const [sessionLoading, setSessionLoading] = useState(false);

  const getOperatingSystem = (system, version) => {
    if (system === "Windows") {
      return (
        <span className="flex">
          <Tooltip
            placement="top"
            trigger="hover"
            tooltip={`Operating System : ${system} ${version}`}
          >
            <RiWindowsFill className="w-5 h-5 text-blue-600" />
          </Tooltip>
        </span>
      );
    } else if (system === "macos") {
      return (
        <span className="flex">
          <Tooltip
            placement="top"
            trigger="hover"
            tooltip={`Operating System : ${system} ${version}`}
          >
            <RiAppleFill className="w-5 h-5 text-gray-300" />
          </Tooltip>
        </span>
      );
    } else if (system === "ubuntu") {
      return (
        <span className="flex">
          <Tooltip
            placement="top"
            trigger="hover"
            tooltip={`Operating System : ${system} ${version}`}
          >
            <RiUbuntuFill className="w-5 h-5 text-orange-600" />
          </Tooltip>
        </span>
      );
    } else if (system === "linux") {
      return (
        <span className="flex">
          <Tooltip
            placement="top"
            trigger="hover"
            tooltip={`Operating System : ${system} ${version}`}
          >
            <FaLinux className="w-5 h-5 text-yellow-600" />
          </Tooltip>
        </span>
      );
    }
  };

  const getBrowserName = (browser) => {
    if (browser?.name === "Edge") {
      return (
        <span className="flex">
          <Tooltip
            placement="top"
            trigger="hover"
            tooltip={`Browser : Mircosoft ${browser?.name},   Version : ${browser?.version}`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 48 48"
              className="h-5 w-5"
            >
              <path
                fill="#1976D2"
                d="M13.3,15.1C8.7,17.9,6,21.9,6,21.9s0.7-8.4,7.3-13.3c2.6-2,6.2-3.6,11-3.6c1.8,0,5.6,0.3,9,2.4s4.8,3.8,6.4,6.3c0.7,1.1,1.2,2.5,1.5,3.8c0.6,2.5,0.7,5.6,0.7,5.6V27H17c0,0-0.1,8.1,11.2,8.1c3.9,0,5.3-0.6,6.6-1c2-0.6,4.2-2.1,4.2-2.1v8c0,0-4.9,3-11.9,3c-2,0-4-0.2-6-0.8c-1.7-0.6-5.4-2-7.8-5.5c-0.9-1.2-1.8-2.9-2.3-4.5c-0.5-1.7-0.5-3.4-0.5-4.3c0-3.5,1.2-6.8,3.3-9.2C16.6,15.6,20,14,20,14s-3,2.8-3,6h14c0,0,0.1-8-7.7-8C20.1,12,16.1,13.4,13.3,15.1z"
              ></path>
            </svg>{" "}
          </Tooltip>
        </span>
      );
    } else if (browser?.name === "Chrome") {
      return (
        <span className="flex">
          <Tooltip
            placement="top"
            trigger="hover"
            tooltip={`Browser : ${browser?.name}, Version : ${browser?.version}`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 48 48"
              className="h-5 w-5"
            >
              <path
                fill="#4caf50"
                d="M44,24c0,11.044-8.956,20-20,20S4,35.044,4,24S12.956,4,24,4S44,12.956,44,24z"
              ></path>
              <path
                fill="#ffc107"
                d="M24,4v20l8,4l-8.843,16c0.317,0,0.526,0,0.843,0c11.053,0,20-8.947,20-20S35.053,4,24,4z"
              ></path>
              <path
                fill="#4caf50"
                d="M44,24c0,11.044-8.956,20-20,20S4,35.044,4,24S12.956,4,24,4S44,12.956,44,24z"
              ></path>
              <path
                fill="#ffc107"
                d="M24,4v20l8,4l-8.843,16c0.317,0,0.526,0,0.843,0c11.053,0,20-8.947,20-20S35.053,4,24,4z"
              ></path>
              <path
                fill="#f44336"
                d="M41.84,15H24v13l-3-1L7.16,13.26H7.14C10.68,7.69,16.91,4,24,4C31.8,4,38.55,8.48,41.84,15z"
              ></path>
              <path
                fill="#dd2c00"
                d="M7.158,13.264l8.843,14.862L21,27L7.158,13.264z"
              ></path>
              <path
                fill="#558b2f"
                d="M23.157,44l8.934-16.059L28,25L23.157,44z"
              ></path>
              <path
                fill="#f9a825"
                d="M41.865,15H24l-1.579,4.58L41.865,15z"
              ></path>
              <path
                fill="#fff"
                d="M33,24c0,4.969-4.031,9-9,9s-9-4.031-9-9s4.031-9,9-9S33,19.031,33,24z"
              ></path>
              <path
                fill="#2196f3"
                d="M31,24c0,3.867-3.133,7-7,7s-7-3.133-7-7s3.133-7,7-7S31,20.133,31,24z"
              ></path>
            </svg>
          </Tooltip>
        </span>
      );
    } else if (browser?.name === "Safari") {
      return (
        <span className="flex">
          <Tooltip
            placement="top"
            trigger="hover"
            tooltip={`Browser : ${browser?.name}, Version : ${browser?.version}`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 48 48"
            >
              <path
                fill="#cfd8dc"
                d="M44,24c0,11.044-8.956,20-20,20S4,35.044,4,24S12.956,4,24,4S44,12.956,44,24z"
              ></path>
              <path
                fill="#448aff"
                d="M41,24c0,9.391-7.609,17-17,17S7,33.391,7,24S14.609,7,24,7S41,14.609,41,24z"
              ></path>
              <path
                fill="#ff3d00"
                d="M21.898,21.898l4.203,4.203l9.199-13.402L21.898,21.898z"
              ></path>
              <path
                fill="#bf360c"
                d="M24,24l11.301-11.301l-9.199,13.402L24,24z"
              ></path>
              <path
                fill="#fff"
                d="M21.898,21.898l-9.199,13.402l13.402-9.199L21.898,21.898z"
              ></path>
              <path
                fill="#bdbdbd"
                d="M24,24L12.699,35.301l13.402-9.199L24,24z"
              ></path>
              <path
                fill="#bbdefb"
                d="M17.102,10.699c0.598-0.301,1.199-0.598,1.797-0.801l1.203,2.703l-1.801,0.797L17.102,10.699z M36,25h2.898c0-0.301,0.102-0.699,0.102-1s0-0.699-0.102-1H36V25z M12.699,14.102l2.102,2.098l1.398-1.398l-2.098-2.102C13.602,13.199,13.199,13.602,12.699,14.102z M25,9.102C24.699,9,24.301,9,24,9s-0.699,0-1,0.102V12h2V9.102z M30.398,10.5c-0.598-0.301-1.199-0.5-1.898-0.699l-1.102,2.801l1.902,0.699L30.398,10.5z M12.5,20.5l0.699-1.898L10.5,17.5c-0.301,0.602-0.5,1.199-0.699,1.898L12.5,20.5z M12,23H9.102C9,23.301,9,23.699,9,24s0,0.699,0.102,1H12V23z M35.5,27.5l-0.699,1.898L37.5,30.5c0.301-0.602,0.5-1.199,0.699-1.898L35.5,27.5z M38.102,18.898c-0.203-0.598-0.5-1.199-0.801-1.797l-2.699,1.199l0.797,1.801L38.102,18.898z M35.301,33.898l-2.102-2.098l-1.398,1.398l2.098,2.102C34.398,34.801,34.801,34.398,35.301,33.898z M13.398,29.699l-0.797-1.801l-2.703,1.203c0.203,0.598,0.5,1.199,0.801,1.797L13.398,29.699z M29.699,34.602l-1.801,0.797l1.203,2.703c0.598-0.203,1.199-0.5,1.797-0.801L29.699,34.602z M20.5,35.5l-1.898-0.699L17.5,37.5c0.602,0.301,1.199,0.5,1.898,0.699L20.5,35.5z M25,38.898V36h-2v2.898c0.301,0,0.699,0.102,1,0.102S24.699,39,25,38.898z"
              ></path>
            </svg>
          </Tooltip>
        </span>
      );
    }
  };

  const getDeviceName = (device) => {
    if (device.is_mobile) {
      return (
        <span className="flex">
          <Tooltip placement="top" trigger="hover" tooltip={`Device : Mobile`}>
            <RiCellphoneLine className="w-5 h-5" />
          </Tooltip>
        </span>
      );
    } else if (device.is_tablet) {
      return (
        <span className="flex">
          <Tooltip placement="top" trigger="hover" tooltip={`Device : Tablet`}>
            <RiTabletLine className="w-5 h-5" />
          </Tooltip>
        </span>
      );
    } else if (device.is_pc) {
      return (
        <span className="flex">
          <Tooltip
            placement="top"
            trigger="hover"
            tooltip={`Device : Personal Computer`}
          >
            <RiComputerLine className="w-5 h-5" />
          </Tooltip>
        </span>
      );
    }
  };

  const fetchSessionData = useCallback(
    ({ pageIndex, pageSize, sessionLoading = true }) => {
      // Give this fetch an ID
      const fetchId = ++fetchIdRef.current;
      // Set the loading state
      if (sessionLoading) {
        setSessionLoading(true);
      }

      if (fetchId === fetchIdRef.current) {
        get(active_session_url)
          .then((result) => {
            // console.log("session", result);

            const new_data = [];

            result.data.sessions
              .filter((res) => res.id === result.data.current_session_uid)
              .map((res) => {
                return new_data.push({
                  key: res.id,
                  details: (
                    <div>
                      {res.metadata.os ? (
                        <div className="flex space-x-2 xs:text-right xs:inline-flex">
                          {getOperatingSystem(
                            res.metadata.os?.name,
                            res.metadata.os?.version
                          )}
                          {getBrowserName(res.metadata?.browser)}
                          {getDeviceName(res.metadata?.device)}
                        </div>
                      ) : (
                        <div>Unknown Device</div>
                      )}

                      {res.metadata.location ? (
                        <div className="text-sm dark:text-gray-1500 text-gray-1300">
                          <Tooltip
                            placement="top"
                            trigger="hover"
                            tooltip="This location shown is based on accessed IP address."
                          >
                            {res.metadata.location?.city.city},{" "}
                            {res.metadata.location?.city.region},{" "}
                            {res.metadata.location?.city.country_name}
                          </Tooltip>
                        </div>
                      ) : (
                        "Unknown Location"
                      )}
                    </div>
                  ),
                  ip_address: res.ipAddress,
                  start_time: (
                    <Tooltip
                      placement="top"
                      trigger="hover"
                      tooltip={convertTZ(new Date(res.start), props.timezone)}
                    >
                      {format(convertTZ(new Date(res.start), props.timezone))}
                    </Tooltip>
                  ),
                  actions: (
                    <span className="text-green-500">Current Session</span>
                  ),
                });
              });

            result.data.sessions
              .filter((res) => res.id !== result.data.current_session_uid)
              .map((res) => {
                return new_data.push({
                  key: res.id,
                  details: (
                    <div>
                      {res.metadata.os ? (
                        <div className="flex space-x-2 xs:text-right xs:inline-flex">
                          {getOperatingSystem(
                            res.metadata.os?.name,
                            res.metadata.os?.version
                          )}

                          {getBrowserName(res.metadata?.browser)}
                          {getDeviceName(res.metadata?.device)}
                        </div>
                      ) : (
                        <div>Unknown Device</div>
                      )}

                      {res.metadata.location ? (
                        <div className="text-sm dark:text-gray-1500 text-gray-1300">
                          <Tooltip
                            placement="top"
                            trigger="hover"
                            tooltip="This location shown is based on accessed IP address."
                          >
                            {res.metadata.location?.city.city},{" "}
                            {res.metadata.location?.city.region},{" "}
                            {res.metadata.location?.city.country_name}
                          </Tooltip>
                        </div>
                      ) : (
                        "Unknown Location"
                      )}
                    </div>
                  ),
                  ip_address: res.ipAddress,
                  start_time: (
                    <Tooltip
                      placement="top"
                      trigger="hover"
                      tooltip={convertTZ(new Date(res.start), props.timezone)}
                    >
                      {format(convertTZ(new Date(res.start), props.timezone))}
                    </Tooltip>
                  ),
                  actions: (
                    <button
                      className="text-red-500 hover:dark:bg-gray-600 p-2 rounded-3xl"
                      onClick={() => {
                        setSessionUID(res.id);
                        setShowTerminateSessionConfirmation(true);
                      }}
                    >
                      Terminate Session
                    </button>
                  ),
                });
              });

            setSessionData(new_data);
            setSessionLoading(false);
          })
          .catch((err) => console.log(err));
      }
    },
    []
  );

  const sessionColumns = useMemo(
    () => [
      {
        Header: "Details",
        accessor: "details",
      },
      {
        Header: "Start Time",
        accessor: "start_time",
      },
      {
        Header: "IP Address",
        accessor: "ip_address",
      },
      {
        Header: "",
        accessor: "actions",
      },
    ],
    []
  );

  const terminateSession = () => {
    Delete(active_session_url, {
      data: { session_uid: sessionUID },
    })
      .then((res) => {
        console.log(res);

        fetchSessionData({ page: 0, pageSize: 0, sessionLoading: false });

        enqueueSnackbar("Sesssion terminated successfully.", {
          variant: "success",
          autoHideDuration: 3000,
        });
        setShowTerminateSessionConfirmation(false);
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar("An error occured while terminating a sesssion.", {
          variant: "error",
          autoHideDuration: 3000,
        });
        setShowTerminateSessionConfirmation(false);
      });
  };

  const terminateAllSessions = () => {
    Delete(terminate_all_sessions_url)
      .then((res) => {
        console.log(res);

        enqueueSnackbar("All sesssions terminated successfully.", {
          variant: "success",
          autoHideDuration: 3000,
        });
        setShowTerminateAllSessionConfirmation(false);
        props.history.push("/");
      })
      .catch((err) => {
        console.error(err);
        enqueueSnackbar("An error occured while terminating all sesssions.", {
          variant: "error",
          autoHideDuration: 3000,
        });
        setShowTerminateAllSessionConfirmation(false);
      });
  };

  return (
    <div>
      <Header handleSidebar={() => setshowSidebar(!showSidebar)} />
      <Sidebar
        showSidebar={showSidebar}
        setshowSidebar={setshowSidebar}
        navigation={navigation(props)}
      />
      <Content showSidebar={showSidebar}>
        <div id="activity_session">
          <div className="z-0 bg-white rounded-lg sm:rounded-lg shadow border border-gray-300  dark:bg-gray-800 overflow-hidden dark:border-gray-600">
            <div className="flex justify-between z-0 bg-gray-100 dark:bg-gray-700 xs:flex-col  border-b border-gray-200 dark:border-gray-600">
              <div className="p-5">
                <h6 className="text-base font-medium text-gray-900 dark:text-white ">
                  Active Sessions
                </h6>
                <p className="text-sm text-gray-1500 dark:text-gray-3000">
                  View and manage all of your active sessions.
                </p>
              </div>

              <div className="p-5 xs:w-full xs:pt-0">
                <button
                  className="button button-primary xs:w-full"
                  onClick={() => {
                    setShowTerminateAllSessionConfirmation(true);
                  }}
                >
                  Terminate all sessions
                </button>
              </div>
            </div>

            {sessionLoading && (
              <TableLoader columns={2} headingDescription={{ index: 1 }} />
            )}

            <Table
              columns={sessionColumns}
              data={sessionData}
              fetchData={fetchSessionData}
              loading={sessionLoading}
              showFooter={false}
              borderProperty={false}
            />
          </div>
        </div>

        <ConfirmationModal
          open={showTerminateSessionConfirmation}
          close={setShowTerminateSessionConfirmation}
          modalWidth={true}
          heading={
            <div className="mb-4">
              <p className="text-sm font-medium text-gray-900 dark:text-white">
                Are you sure?
              </p>
              <p className="mt-1 text-sm text-gray-500 dark:text-gray-300">
                This action will terminate session. You will lose this session
                permanently.
              </p>
            </div>
          }
          icon={<RiDeleteBinLine className="w-8 h-8 text-red-500" />}
          accept={() => {
            terminateSession();
          }}
        />

        <ConfirmationModal
          open={showTerminateAllSessionConfirmation}
          close={setShowTerminateAllSessionConfirmation}
          modalWidth={true}
          heading={
            <div className="mb-4">
              <p className="text-sm font-medium text-gray-900 dark:text-white">
                Are you sure?
              </p>
              <p className="mt-1 text-sm text-gray-500 dark:text-gray-300">
                This action will terminate all sessions. You will lose all
                sessions permanently.
              </p>
            </div>
          }
          icon={<RiDeleteBinLine className="w-8 h-8 text-red-500" />}
          accept={() => {
            terminateAllSessions();
          }}
        />
      </Content>
    </div>
  );
};

export default ActiveSessions;
