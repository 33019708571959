const { REACT_APP_MICROSOFT_APP_ID } = process.env;
const { REACT_APP_DOMAIN } = process.env;
const { REACT_APP_MICROSOFT_TENANT_ID } = process.env;

export const msalConfig = {
  auth: {
    clientId: REACT_APP_MICROSOFT_APP_ID,

    // 74481ef3-6907-4bf2-bdc5-e43b427197d2 - Dev client ID
    // 285c0b89-afc4-461b-b533-f8159ee5bdc5 - Dev tenant ID

    // 4e96c588-4ba9-454d-9fca-3825fac0736b - PeerXP client ID
    // 63e2b565-916d-4e55-85ae-c643cfedf1df - PeerXP tenant ID

    // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
    authority: `https://login.microsoftonline.com/${REACT_APP_MICROSOFT_TENANT_ID}`,
    // redirectUri: `${REACT_APP_DOMAIN}/signup`,
  },
  cache: {
    cacheLocation: "localStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ["User.Read"],
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0",
};
