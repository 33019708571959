// import react component
import React, { useState, useEffect, useCallback } from "react";
// import headless ui component
import { Disclosure } from "@headlessui/react";
import { RiMenuLine, RiCloseLine, RiVolumeDownLine } from "react-icons/ri";
// import router component
import { Link, withRouter } from "react-router-dom";
// import axios
import { get } from "../Components/AxiosUtilities";
// import urls
import { account_user_data_url, fetch_image_url } from "../Components/Urls";
// import redux
import { connect } from "react-redux";
// import vegamaster logo component
import VegastackLogo from "../Components/VegastackLogo";
// import avatar component
import Avatar from "react-avatar";
import CustomSlideOver from "./CustomSlideOver";
import ProfileSlideOver from "./ProfileSlideOver";
import AnnouncementSlider from "./AnnouncementSlider";
// import react responsive
import { useMediaQuery } from "react-responsive";

function Header(props) {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 767 });

  // initialize state variables
  const [values, setValues] = useState({
    user_type: "",
    timezone: "",
    user_image: "",
    search: "",
  });

  const [openSlideOverlay, setOpenSlideOverlay] = useState(false);

  // annoucement slider state
  const [openAnnouncementSlider, setOpenAnnouncementSlider] = useState(false);

  //  close the modal using the "ESC" key1
  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        setOpenAnnouncementSlider(false);
        setOpenSlideOverlay(false);
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);

  // loading image state
  const [avatarImageLoading, setavatarImageLoading] = useState(true);

  const { setUserDetails, logoutUser } = props;

  // handle component update
  useEffect(() => {
    const getUserData = () => {
      get(account_user_data_url)
        .then((res) => {
          // console.log("response", res.data);

          setUserDetails({
            timezone: res.data.user_data.timezone,
            // avatar_url: res.data.avatar_url,
            first_name: res.data.user_data.first_name,
            last_name: res.data.user_data.last_name,
            email: res.data.user_data.email,
            organizations: res.data.user_data.organizations,
            last_accessed_organization:
              res.data.user_data.last_accessed_organization,
            user_uid: res.data.user_data.uid,
          });

          // set state values
          setValues((prevState) => {
            return {
              ...prevState,
              user_type: res.data.user_type,
              timezone: res.data.timezone,
              user_image: res.data.avatar_url,
            };
          });
        })
        .catch((err) => {
          // console.log(err);

          // if user is unathenticated logout the user
          logoutUser();
        });
    };

    // function to get user data
    getUserData();
  }, [setUserDetails, logoutUser]);

  // fetching the image
  const fetchImage = useCallback(() => {
    get(
      fetch_image_url +
        `?image_type=profile&user_uid=${props.user_uid}&width=56`
    )
      .then((res) => {
        // console.log(res);

        setUserDetails({
          avatar_url: res.data.url,
        });
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    fetchImage();
  }, [fetchImage]);

  return (
    <Disclosure
      as="nav"
      className="fixed top-0 z-40 w-full bg-blue-500 dark:bg-gray-800 dark:border-b dark:border-gray-500"
    >
      {({ open }) => (
        <>
          <div className="px-2 mx-auto max-full sm:px-4 lg:px-8">
            <div className="relative flex items-center justify-between h-12 align-middle">
              {isTabletOrMobile && (
                <div className="flex-1">
                  <span
                    className="inline-flex items-center justify-center py-2 pr-2 text-white rounded dark:text-gray-300 hover:text-white focus:outline-none"
                    onClick={() => props.handleSidebar()}
                  >
                    {openSlideOverlay ? (
                      <RiCloseLine
                        className="block w-7 h-7"
                        aria-hidden="true"
                      />
                    ) : (
                      <RiMenuLine
                        className="block w-6 h-6"
                        aria-hidden="true"
                      />
                    )}
                  </span>
                </div>
              )}

              <div className="shrink-0">
                <Link to="/profile/personal" className="">
                  <VegastackLogo
                    className="block w-auto h-5 text-white fill-current md:hidden"
                    symbol={true}
                  />

                  <VegastackLogo className="hidden w-auto h-5 text-white fill-current md:block" />
                </Link>
              </div>

              {/* </div> */}

              <div className="flex flex-1 items-center justify-end  cursor-pointer space-x-3 lg:ml-6 lg:pr-0 pr-2">
                {/* annoucement section */}
                <span
                  className={`${
                    openAnnouncementSlider ? "bg-blue-800 dark:bg-gray-600" : ""
                  } dark:hover:bg-gray-600 focus:bg-blue-800 hover:bg-blue-800  
                      rounded px-2 py-1.5 text-white hover:text-white focus:text-white focus:outline-none`}
                >
                  <RiVolumeDownLine
                    className="w-6 h-6 text-white "
                    onClick={() => setOpenAnnouncementSlider(true)}
                  />
                </span>

                <AnnouncementSlider
                  open={openAnnouncementSlider}
                  Close={setOpenAnnouncementSlider}
                />
                {/* end annoucement section */}

                <div
                  onClick={() => setOpenSlideOverlay(true)}
                  className="lg:ml-4 flex text-sm text-white bg-blue-500 rounded-full cursor-pointer dark:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 dark:focus:ring-offset-gray-900 focus:ring-offset-indigo-500 focus:ring-white"
                >
                  <span className="sr-only">Open user menu</span>
                  {/* avatar name first letter */}
                  {!props.avatar_url && (
                    <Avatar
                      name={props.email && props.email[0]}
                      size="34"
                      round="30px"
                      className="rounded-full h-8 w-8"
                    />
                  )}

                  {/* avatar image */}

                  {props.avatar_url && (
                    <>
                      <div
                        style={{
                          display: avatarImageLoading ? "block" : "none",
                        }}
                      >
                        <Avatar
                          name={props.first_name && props.first_name[0]}
                          size="28"
                          round="30px"
                          className="rounded-full h-8 w-8"
                        />
                      </div>

                      <div
                        style={{
                          display: avatarImageLoading ? "none" : "block",
                        }}
                      >
                        <img
                          className="rounded-full h-7 w-7 "
                          src={props.avatar_url}
                          alt={props.first_name}
                          onLoad={() => setavatarImageLoading(false)}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>

          <CustomSlideOver
            Open={openSlideOverlay}
            Close={() => {}}
            customCss="w-screen max-w-sm xs:max-w-xs bg-white dark:bg-gray-800"
            top={"top-12"}
          >
            <ProfileSlideOver Close={setOpenSlideOverlay} />
          </CustomSlideOver>
        </>
      )}
    </Disclosure>
  );
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => {
  return {
    // dispatching plain actions
    logoutUser: () => dispatch({ type: "logoutUser" }),

    setUserDetails: (data) => dispatch({ type: "setUserDetails", ...data }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
