// import use previous function
import { setInitialTheme } from "../Components/utilities";
// import react router dom component
import { Link } from "react-router-dom";

// set theme function
setInitialTheme();

// 404 page not found page
function ServerErrorPage(props) {
  // return jsx elements
  return (
    <div className="max-w-lg px-4 py-8 mx-auto text-center">
      <svg viewBox="0 0 750 500" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M617.45,292.8c-15.48-13.28-17.1-35.23-14.51-54.55.43-3.23.42-6.68,2.24-9.36s5.61-4.27,8.37-2.62c2.29,1.37,3.06,4.27,4.18,6.72a19.06,19.06,0,0,0,6.86,8c2,1.27,4.44,2.18,6.59,1.26,3-1.26,3.66-5.09,4-8.31l1.68-18c.3-3.22.62-6.51,1.93-9.46s3.8-5.57,7-6.13,6.76,1.59,7.09,4.81a30.36,30.36,0,0,1-.31,4c0,1.34.43,2.88,1.66,3.38s2.63-.28,3.69-1.12c3.64-2.92,6.31-6.88,8.84-10.84s5-8,8.36-11.26,7.82-5.63,12.45-5.44,9.27,3.54,10,8.18-2.39,9-5.64,12.38a71.85,71.85,0,0,1-16.38,12.77,6,6,0,0,0-2.77,2.44c-.91,2.08.91,4.41,3,5.33,2.32,1,4.94,1.05,7.42,1.6s5.09,1.92,5.81,4.39c1,3.42-2.11,6.59-5,8.58a61.86,61.86,0,0,1-19.27,8.7c-2.45.63-5,1.14-7.1,2.62s-3.51,4.25-2.59,6.63,3.82,3.41,6.35,3.19,4.88-1.33,7.33-2c4.59-1.23,10.27-.35,12.73,3.75,1.61,2.7,1.42,6.22.18,9.12a23.58,23.58,0,0,1-5.67,7.47A62.3,62.3,0,0,1,647.5,295c-10.62,2.53-20.23,2.55-30-2.2"
          fill="#f5f5f5"
        />
        <path
          d="M623.67,281.14a289.21,289.21,0,0,1,14.54-29.91c1.43-2.58,2.83-5.13,4.22-7.65s2.77-5,4.26-7.34a146.66,146.66,0,0,1,9.38-13c3.19-4,6.35-7.66,9.34-11.07s5.84-6.53,8.56-9.26A116.75,116.75,0,0,1,687.75,191c1.73-1.27,3.11-2.22,4-2.85l1.07-.71.38-.22a2.28,2.28,0,0,1-.34.28l-1,.75c-.92.66-2.27,1.64-4,2.94a122,122,0,0,0-13.62,12.05c-2.69,2.74-5.52,5.87-8.49,9.29s-6.12,7.1-9.28,11.08a146.89,146.89,0,0,0-9.32,13c-1.48,2.34-2.84,4.8-4.24,7.32l-4.21,7.64a298.06,298.06,0,0,0-14.56,29.83"
          fill="#e0e0e0"
        />
        <path
          d="M646.87,236.24a5.48,5.48,0,0,1-.18-1.41c-.08-.9-.16-2.22-.22-3.84-.14-3.25-.17-7.75-.19-12.71s-.12-9.44-.3-12.68c-.09-1.62-.17-2.93-.24-3.83a6.17,6.17,0,0,1,0-1.42,7,7,0,0,1,.27,1.39c.14.9.29,2.21.43,3.83.29,3.25.46,7.75.47,12.71s0,9.31,0,12.7c0,1.52,0,2.81,0,3.84A7.22,7.22,0,0,1,646.87,236.24Z"
          fill="#e0e0e0"
        />
        <path
          d="M689,232.63a9.21,9.21,0,0,1-1.68,0c-1.07,0-2.62,0-4.53,0-3.83,0-9.13.26-15,.77s-11.08,1.21-14.87,1.82c-1.9.29-3.42.57-4.48.75a9.45,9.45,0,0,1-1.65.24,8.42,8.42,0,0,1,1.6-.47c1-.25,2.57-.59,4.46-.94,3.78-.72,9-1.49,14.89-2a148.52,148.52,0,0,1,15-.59c1.92,0,3.47.12,4.55.2A9.19,9.19,0,0,1,689,232.63Z"
          fill="#e0e0e0"
        />
        <path
          d="M624.25,280.43a1.82,1.82,0,0,1-.23-.53c-.15-.42-.34-.94-.56-1.57-.48-1.36-1.12-3.35-1.88-5.82-1.53-4.94-3.42-11.83-5.4-19.47s-3.81-14.53-5.23-19.5c-.69-2.41-1.27-4.4-1.7-5.87l-.45-1.6a2,2,0,0,1-.12-.58,2.46,2.46,0,0,1,.24.54l.55,1.57c.48,1.36,1.13,3.35,1.89,5.82,1.52,4.94,3.41,11.83,5.39,19.47s3.81,14.53,5.23,19.49l1.7,5.88.45,1.6A2.08,2.08,0,0,1,624.25,280.43Z"
          fill="#e0e0e0"
        />
        <path
          d="M681,268.19a2.38,2.38,0,0,1-.57.17l-1.65.38-6.08,1.32c-5.14,1.12-12.21,2.74-20,4.55S637.79,278,632.63,279c-2.58.51-4.67.9-6.12,1.14l-1.67.26a1.88,1.88,0,0,1-.59.05,2.19,2.19,0,0,1,.57-.17l1.65-.37,6.08-1.32c5.13-1.12,12.21-2.75,20-4.55s14.91-3.37,20.06-4.38c2.58-.5,4.67-.89,6.13-1.13l1.67-.27A2.36,2.36,0,0,1,681,268.19Z"
          fill="#e0e0e0"
        />
        <path
          d="m654.75 152.2a9.09 9.09 0 0 1-10.65-11.56l15.54-50a9 9 0 0 1 6.67-6.17 9 9 0 0 1 8.65 2.7l35.53 38.45a9.08 9.08 0 0 1-4.67 15l-51.04 11.58zm12.18-65.09a6.29 6.29 0 0 0-4.68 4.32l-15.54 50a6.38 6.38 0 0 0 7.47 8.11l51.07-11.54a6.37 6.37 0 0 0 3.28-10.54l-35.53-38.46a6.29 6.29 0 0 0-6.07-1.89z"
          fill="#e0e0e0"
        />
        <path
          d="m673.44 133a3.34 3.34 0 0 1 2.67-4 3.31 3.31 0 0 1 4.09 2.48 3.36 3.36 0 0 1-2.58 4.07 3.39 3.39 0 0 1-4.18-2.55zm-5.83-26.65 6.25-1.44 3.5 20-4.18 1z"
          fill="#e0e0e0"
        />
        <path
          d="M42.82,291.62s-6.44,3.62,3.25,17,16,19.83,16,19.83,1.84,3.17-.92,4.14-9.19.94-9.92,4.62,2.4,6.82,8.18,9,18.28,7.38,19.41,7.85,2.37,1.88.59,2.84S63.72,361.54,65.85,368s19.23,9.86,21.1,10.27,2.4,1.08,2.4,2.07-4.77,1.78-8.67,3-8.22,3.28-6.68,6.91,12.37,10.1,36,8.77l10.91-1.33,4.49-10.09c8.29-22.19,5.34-34.44,2.32-37s-6.28.95-8.58,4.32-4.48,7.7-5.42,7.4-1.43-1-1.27-2.91,2-19.24-3.54-23.2-13.16,8.21-14.61,9.62-2.42-.21-2.54-1.41-1.38-14.7-1.73-20.86-2.28-10.07-6.15-10.48-5.61,5.74-7.35,8.09-4.23-.34-4.23-.34S68,313,58.11,299.71s-15.41-8-15.41-8"
          fill="#ebebeb"
        />
        <path
          d="M121.88,400.16c-7.25-9.75-19.86-27.3-30.76-42.31s-20.78-28.57-28-38.36l-8.55-11.58-2.32-3.15-.6-.83c-.13-.18-.19-.28-.19-.28a3.08,3.08,0,0,1,.22.26c.15.19.36.46.62.81.56.72,1.35,1.77,2.38,3.11,2.06,2.7,5,6.64,8.63,11.52,7.26,9.75,17.17,23.3,28.07,38.3s23.47,32.58,30.68,42.36"
          fill="#e0e0e0"
        />
        <path
          d="M60.17,337.65a3.75,3.75,0,0,1,.79.09c.51.08,1.24.22,2.13.4,1.79.36,4.25.92,7,1.58L77,341.45l2.1.51a3.74,3.74,0,0,1,.76.22,3.12,3.12,0,0,1-.79-.09c-.5-.08-1.23-.21-2.12-.4-1.79-.38-4.25-1-7-1.64L63,338.37l-2.1-.5A4,4,0,0,1,60.17,337.65Z"
          fill="#e0e0e0"
        />
        <path
          d="m80.47 341.37c-0.09 0 0.83-5.14 2.07-11.45s2.32-11.4 2.41-11.38-0.83 5.14-2.07 11.45-2.32 11.4-2.41 11.38z"
          fill="#e0e0e0"
        />
        <path
          d="M96.17,365.17a4.11,4.11,0,0,1,.33-.74l1-2c.85-1.65,2-4,3.22-6.52s2.21-4.94,2.92-6.66c.34-.81.63-1.49.85-2a4.13,4.13,0,0,1,.35-.73,4.19,4.19,0,0,1-.22.78c-.17.49-.42,1.21-.75,2.08-.66,1.74-1.64,4.13-2.84,6.71s-2.41,4.87-3.32,6.5c-.46.81-.84,1.46-1.11,1.91A3.88,3.88,0,0,1,96.17,365.17Z"
          fill="#e0e0e0"
        />
        <path
          d="M77.07,365.84a4.14,4.14,0,0,1,.74-.1l2-.15c1.72-.11,4.09-.25,6.72-.37s5-.22,6.72-.28l2,0a3.71,3.71,0,0,1,.75,0,3.23,3.23,0,0,1-.74.1l-2,.15c-1.72.12-4.09.25-6.72.38s-5,.22-6.72.27l-2,0A2.47,2.47,0,0,1,77.07,365.84Z"
          fill="#e0e0e0"
        />
        <path
          d="M88.26,388.61a6.43,6.43,0,0,1,.95-.12l2.61-.2c2.2-.16,5.24-.36,8.61-.55s6.41-.35,8.61-.45l2.61-.09a5.08,5.08,0,0,1,1,0,4.72,4.72,0,0,1-1,.12l-2.61.21c-2.2.15-5.24.35-8.6.54s-6.41.35-8.62.45l-2.61.09A4.18,4.18,0,0,1,88.26,388.61Z"
          fill="#e0e0e0"
        />
        <path
          d="M112,387.08a6.62,6.62,0,0,1,.41-.95c.3-.65.71-1.5,1.19-2.53,1-2.14,2.39-5.1,3.82-8.4s2.66-6.34,3.53-8.54c.41-1.05.76-1.93,1-2.6a5.34,5.34,0,0,1,.41-.94,5.68,5.68,0,0,1-.29,1c-.21.63-.52,1.54-.93,2.65-.81,2.22-2,5.27-3.44,8.58s-2.85,6.26-3.91,8.38c-.54,1.05-1,1.9-1.3,2.48A5.8,5.8,0,0,1,112,387.08Z"
          fill="#e0e0e0"
        />
        <rect
          x="116.58"
          y="87.39"
          width="104.14"
          height="319.03"
          fill="#f5f5f5"
        />
        <rect
          x="220.4"
          y="87.39"
          width="82.31"
          height="319.03"
          fill="#fafafa"
        />
        <rect
          x="122.17"
          y="138.45"
          width="88.34"
          height="24.87"
          fill="#e0e0e0"
        />
        <rect
          x="124.63"
          y="141.57"
          width="4.95"
          height="19.04"
          fill="#f5f5f5"
        />
        <path
          d="m135.41 145.58a1.58 1.58 0 1 1-1.57-1.57 1.57 1.57 0 0 1 1.57 1.57z"
          fill="#fafafa"
        />
        <path
          d="m135.41 152.3a1.58 1.58 0 1 1-1.57-1.57 1.58 1.58 0 0 1 1.57 1.57z"
          fill="#fafafa"
        />
        <rect
          x="141.69"
          y="144.01"
          width="4.86"
          height="14.62"
          fill="#f5f5f5"
        />
        <rect
          x="148.71"
          y="144.01"
          width="4.86"
          height="14.62"
          fill="#f5f5f5"
        />
        <rect
          x="155.74"
          y="144.01"
          width="4.86"
          height="14.62"
          fill="#f5f5f5"
        />
        <rect
          x="162.76"
          y="144.01"
          width="4.86"
          height="14.62"
          fill="#f5f5f5"
        />
        <rect
          x="169.78"
          y="144.01"
          width="4.86"
          height="14.62"
          fill="#f5f5f5"
        />
        <rect x="176.8" y="144.01" width="4.86" height="14.62" fill="#f5f5f5" />
        <rect
          x="183.82"
          y="144.01"
          width="4.86"
          height="14.62"
          fill="#f5f5f5"
        />
        <rect
          x="190.84"
          y="144.01"
          width="4.86"
          height="14.62"
          fill="#f5f5f5"
        />
        <rect
          x="197.87"
          y="144.01"
          width="4.86"
          height="14.62"
          fill="#f5f5f5"
        />
        <rect
          x="204.89"
          y="144.01"
          width="4.86"
          height="14.62"
          fill="#f5f5f5"
        />
        <rect
          x="122.17"
          y="178.28"
          width="88.34"
          height="24.87"
          fill="#e0e0e0"
        />
        <rect
          x="124.63"
          y="181.41"
          width="4.95"
          height="19.04"
          fill="#f5f5f5"
        />
        <path
          d="m135.41 185.42a1.58 1.58 0 1 1-1.57-1.58 1.58 1.58 0 0 1 1.57 1.58z"
          fill="#fafafa"
        />
        <circle cx="133.84" cy="192.14" r="1.57" fill="#fafafa" />
        <rect
          x="141.69"
          y="183.84"
          width="4.86"
          height="14.62"
          fill="#f5f5f5"
        />
        <rect
          x="148.71"
          y="183.84"
          width="4.86"
          height="14.62"
          fill="#f5f5f5"
        />
        <rect
          x="155.74"
          y="183.84"
          width="4.86"
          height="14.62"
          fill="#f5f5f5"
        />
        <rect
          x="162.76"
          y="183.84"
          width="4.86"
          height="14.62"
          fill="#f5f5f5"
        />
        <rect
          x="169.78"
          y="183.84"
          width="4.86"
          height="14.62"
          fill="#f5f5f5"
        />
        <rect x="176.8" y="183.84" width="4.86" height="14.62" fill="#f5f5f5" />
        <rect
          x="183.82"
          y="183.84"
          width="4.86"
          height="14.62"
          fill="#f5f5f5"
        />
        <rect
          x="190.84"
          y="183.84"
          width="4.86"
          height="14.62"
          fill="#f5f5f5"
        />
        <rect
          x="197.87"
          y="183.84"
          width="4.86"
          height="14.62"
          fill="#f5f5f5"
        />
        <rect
          x="204.89"
          y="183.84"
          width="4.86"
          height="14.62"
          fill="#f5f5f5"
        />
        <rect
          x="122.17"
          y="218.12"
          width="88.34"
          height="24.87"
          fill="#e0e0e0"
        />
        <rect
          x="124.63"
          y="221.25"
          width="4.95"
          height="19.04"
          fill="#f5f5f5"
        />
        <path
          d="m135.41 225.25a1.58 1.58 0 1 1-1.57-1.57 1.58 1.58 0 0 1 1.57 1.57z"
          fill="#fafafa"
        />
        <circle cx="133.84" cy="231.98" r="1.57" fill="#fafafa" />
        <rect
          x="141.69"
          y="223.68"
          width="4.86"
          height="14.62"
          fill="#f5f5f5"
        />
        <rect
          x="148.71"
          y="223.68"
          width="4.86"
          height="14.62"
          fill="#f5f5f5"
        />
        <rect
          x="155.74"
          y="223.68"
          width="4.86"
          height="14.62"
          fill="#f5f5f5"
        />
        <rect
          x="162.76"
          y="223.68"
          width="4.86"
          height="14.62"
          fill="#f5f5f5"
        />
        <rect
          x="169.78"
          y="223.68"
          width="4.86"
          height="14.62"
          fill="#f5f5f5"
        />
        <rect x="176.8" y="223.68" width="4.86" height="14.62" fill="#f5f5f5" />
        <rect
          x="183.82"
          y="223.68"
          width="4.86"
          height="14.62"
          fill="#f5f5f5"
        />
        <rect
          x="190.84"
          y="223.68"
          width="4.86"
          height="14.62"
          fill="#f5f5f5"
        />
        <rect
          x="197.87"
          y="223.68"
          width="4.86"
          height="14.62"
          fill="#f5f5f5"
        />
        <rect
          x="204.89"
          y="223.68"
          width="4.86"
          height="14.62"
          fill="#f5f5f5"
        />
        <rect
          x="122.17"
          y="257.96"
          width="88.34"
          height="24.87"
          fill="#e0e0e0"
        />
        <rect
          x="124.63"
          y="261.09"
          width="4.95"
          height="19.04"
          fill="#f5f5f5"
        />
        <path
          d="m135.41 265.09a1.58 1.58 0 1 1-1.57-1.57 1.57 1.57 0 0 1 1.57 1.57z"
          fill="#fafafa"
        />
        <path
          d="m135.41 271.82a1.58 1.58 0 1 1-1.57-1.58 1.57 1.57 0 0 1 1.57 1.58z"
          fill="#fafafa"
        />
        <rect
          x="141.69"
          y="263.52"
          width="4.86"
          height="14.62"
          fill="#f5f5f5"
        />
        <rect
          x="148.71"
          y="263.52"
          width="4.86"
          height="14.62"
          fill="#f5f5f5"
        />
        <rect
          x="155.74"
          y="263.52"
          width="4.86"
          height="14.62"
          fill="#f5f5f5"
        />
        <rect
          x="162.76"
          y="263.52"
          width="4.86"
          height="14.62"
          fill="#f5f5f5"
        />
        <rect
          x="169.78"
          y="263.52"
          width="4.86"
          height="14.62"
          fill="#f5f5f5"
        />
        <rect x="176.8" y="263.52" width="4.86" height="14.62" fill="#f5f5f5" />
        <rect
          x="183.82"
          y="263.52"
          width="4.86"
          height="14.62"
          fill="#f5f5f5"
        />
        <rect
          x="190.84"
          y="263.52"
          width="4.86"
          height="14.62"
          fill="#f5f5f5"
        />
        <rect
          x="197.87"
          y="263.52"
          width="4.86"
          height="14.62"
          fill="#f5f5f5"
        />
        <rect
          x="204.89"
          y="263.52"
          width="4.86"
          height="14.62"
          fill="#f5f5f5"
        />
        <rect
          x="122.17"
          y="297.8"
          width="88.34"
          height="24.87"
          fill="#e0e0e0"
        />
        <rect
          x="124.63"
          y="300.92"
          width="4.95"
          height="19.04"
          fill="#f5f5f5"
        />
        <path
          d="m135.41 304.93a1.58 1.58 0 1 1-1.57-1.57 1.57 1.57 0 0 1 1.57 1.57z"
          fill="#fafafa"
        />
        <circle cx="133.84" cy="311.65" r="1.57" fill="#fafafa" />
        <rect
          x="141.69"
          y="303.36"
          width="4.86"
          height="14.62"
          fill="#f5f5f5"
        />
        <rect
          x="148.71"
          y="303.36"
          width="4.86"
          height="14.62"
          fill="#f5f5f5"
        />
        <rect
          x="155.74"
          y="303.36"
          width="4.86"
          height="14.62"
          fill="#f5f5f5"
        />
        <rect
          x="162.76"
          y="303.36"
          width="4.86"
          height="14.62"
          fill="#f5f5f5"
        />
        <rect
          x="169.78"
          y="303.36"
          width="4.86"
          height="14.62"
          fill="#f5f5f5"
        />
        <rect x="176.8" y="303.36" width="4.86" height="14.62" fill="#f5f5f5" />
        <rect
          x="183.82"
          y="303.36"
          width="4.86"
          height="14.62"
          fill="#f5f5f5"
        />
        <rect
          x="190.84"
          y="303.36"
          width="4.86"
          height="14.62"
          fill="#f5f5f5"
        />
        <rect
          x="197.87"
          y="303.36"
          width="4.86"
          height="14.62"
          fill="#f5f5f5"
        />
        <rect
          x="204.89"
          y="303.36"
          width="4.86"
          height="14.62"
          fill="#f5f5f5"
        />
        <rect
          x="122.17"
          y="337.64"
          width="88.34"
          height="24.87"
          fill="#e0e0e0"
        />
        <rect
          x="124.63"
          y="340.76"
          width="4.95"
          height="19.04"
          fill="#f5f5f5"
        />
        <path
          d="m135.41 344.77a1.58 1.58 0 1 1-1.57-1.58 1.58 1.58 0 0 1 1.57 1.58z"
          fill="#fafafa"
        />
        <path
          d="m135.41 351.49a1.58 1.58 0 1 1-1.57-1.57 1.57 1.57 0 0 1 1.57 1.57z"
          fill="#fafafa"
        />
        <rect
          x="141.69"
          y="343.19"
          width="4.86"
          height="14.62"
          fill="#f5f5f5"
        />
        <rect
          x="148.71"
          y="343.19"
          width="4.86"
          height="14.62"
          fill="#f5f5f5"
        />
        <rect
          x="155.74"
          y="343.19"
          width="4.86"
          height="14.62"
          fill="#f5f5f5"
        />
        <rect
          x="162.76"
          y="343.19"
          width="4.86"
          height="14.62"
          fill="#f5f5f5"
        />
        <rect
          x="169.78"
          y="343.19"
          width="4.86"
          height="14.62"
          fill="#f5f5f5"
        />
        <rect x="176.8" y="343.19" width="4.86" height="14.62" fill="#f5f5f5" />
        <rect
          x="183.82"
          y="343.19"
          width="4.86"
          height="14.62"
          fill="#f5f5f5"
        />
        <rect
          x="190.84"
          y="343.19"
          width="4.86"
          height="14.62"
          fill="#f5f5f5"
        />
        <rect
          x="197.87"
          y="343.19"
          width="4.86"
          height="14.62"
          fill="#f5f5f5"
        />
        <rect
          x="204.89"
          y="343.19"
          width="4.86"
          height="14.62"
          fill="#f5f5f5"
        />
        <rect
          x="122.17"
          y="377.47"
          width="88.34"
          height="24.87"
          fill="#e0e0e0"
        />
        <rect x="124.63" y="380.6" width="4.95" height="19.04" fill="#f5f5f5" />
        <path
          d="m135.41 384.6a1.58 1.58 0 1 1-1.57-1.57 1.58 1.58 0 0 1 1.57 1.57z"
          fill="#fafafa"
        />
        <circle cx="133.84" cy="391.33" r="1.57" fill="#fafafa" />
        <rect
          x="141.69"
          y="383.03"
          width="4.86"
          height="14.62"
          fill="#f5f5f5"
        />
        <rect
          x="148.71"
          y="383.03"
          width="4.86"
          height="14.62"
          fill="#f5f5f5"
        />
        <rect
          x="155.74"
          y="383.03"
          width="4.86"
          height="14.62"
          fill="#f5f5f5"
        />
        <rect
          x="162.76"
          y="383.03"
          width="4.86"
          height="14.62"
          fill="#f5f5f5"
        />
        <rect
          x="169.78"
          y="383.03"
          width="4.86"
          height="14.62"
          fill="#f5f5f5"
        />
        <rect x="176.8" y="383.03" width="4.86" height="14.62" fill="#f5f5f5" />
        <rect
          x="183.82"
          y="383.03"
          width="4.86"
          height="14.62"
          fill="#f5f5f5"
        />
        <rect
          x="190.84"
          y="383.03"
          width="4.86"
          height="14.62"
          fill="#f5f5f5"
        />
        <rect
          x="197.87"
          y="383.03"
          width="4.86"
          height="14.62"
          fill="#f5f5f5"
        />
        <rect
          x="204.89"
          y="383.03"
          width="4.86"
          height="14.62"
          fill="#f5f5f5"
        />
        <path
          d="M176.8,138.45a3.48,3.48,0,0,1-.1-.77c0-.51-.06-1.25-.09-2.21s-.05-2.13-.11-3.49a22.4,22.4,0,0,0-.61-4.53,16.25,16.25,0,0,0-2.36-5,11,11,0,0,0-4.83-3.89,23.55,23.55,0,0,0-6.86-1.39l-7.61-.65c-10.42-.91-19.88-1.49-26.73-1.62-3.43-.08-6.2-.06-8.12,0h-2.21a3.85,3.85,0,0,1-.77,0,3.34,3.34,0,0,1,.77-.1l2.2-.13c1.92-.09,4.7-.17,8.13-.15,6.87,0,16.35.52,26.79,1.43l7.61.67a23.71,23.71,0,0,1,7,1.47,11.41,11.41,0,0,1,5,4.12,16.58,16.58,0,0,1,2.37,5.15,21.94,21.94,0,0,1,.52,4.64c0,1.36,0,2.54,0,3.5s0,1.63,0,2.21A3.85,3.85,0,0,1,176.8,138.45Z"
          fill="#e0e0e0"
        />
        <path
          d="M150,138.45a3.47,3.47,0,0,1-.06-.73c0-.54,0-1.23,0-2.08s0-2-.11-3.27a10.09,10.09,0,0,0-1.09-4.09,7.79,7.79,0,0,0-4-3,11,11,0,0,0-5.8-.59,9.9,9.9,0,0,0-3,1,5.7,5.7,0,0,0-2.32,2.19,3.12,3.12,0,0,0,0,3.09,4.13,4.13,0,0,0,2.62,2,3.32,3.32,0,0,0,3.07-.89,4.41,4.41,0,0,0,.46-5.92,6.49,6.49,0,0,0-2.32-2,8.51,8.51,0,0,0-2.91-.75q-1.5-.22-2.91-.39c-1.88-.21-3.63-.33-5.24-.4-3.22-.13-5.83-.08-7.63-.09h-2.08a2.94,2.94,0,0,1-.73,0,3.2,3.2,0,0,1,.72-.08l2.08-.12c1.8-.07,4.42-.18,7.65-.11,1.62,0,3.39.13,5.29.32,1,.1,1.92.22,2.93.36.5.08,1,.14,1.53.24a6,6,0,0,1,1.57.54,7.25,7.25,0,0,1,2.55,2.23,5.05,5.05,0,0,1,1,3.4,5.17,5.17,0,0,1-1.56,3.34,4,4,0,0,1-3.65,1,4.77,4.77,0,0,1-3-2.28,3.76,3.76,0,0,1,0-3.69,6.27,6.27,0,0,1,2.57-2.44,10.6,10.6,0,0,1,3.19-1.06,11.47,11.47,0,0,1,6.1.67,8.24,8.24,0,0,1,4.18,3.33,10.24,10.24,0,0,1,1,4.28c.07,1.3,0,2.4,0,3.31s-.07,1.53-.09,2.07A3,3,0,0,1,150,138.45Z"
          fill="#e0e0e0"
        />
        <path
          d="M184,138.45a4.78,4.78,0,0,1-.18-.82c-.1-.55-.26-1.34-.48-2.36a45,45,0,0,0-2.61-8.42,24.62,24.62,0,0,0-7.68-10.31,14.44,14.44,0,0,0-7-2.67,73.79,73.79,0,0,0-8.28-.44c-11.42-.22-21.77-.18-29.25-.48-3.75-.13-6.77-.31-8.86-.46l-2.4-.21a3.19,3.19,0,0,1-.84-.12,4.9,4.9,0,0,1,.84,0l2.41.09c2.09.08,5.12.19,8.86.26,7.48.18,17.82.07,29.25.29a71.35,71.35,0,0,1,8.36.46,14.94,14.94,0,0,1,7.29,2.82,22.42,22.42,0,0,1,4.87,5.15,29.75,29.75,0,0,1,2.88,5.45,42.64,42.64,0,0,1,2.44,8.55c.18,1,.3,1.84.35,2.39A4.39,4.39,0,0,1,184,138.45Z"
          fill="#e0e0e0"
        />
        <rect
          x="123.38"
          y="91.36"
          width="85.91"
          height="12.47"
          fill="#e0e0e0"
        />
        <rect x="142.28" y="97.59" width="46.78" height="6.24" fill="#f5f5f5" />
        <rect
          x="270.84"
          y="285.58"
          width="138.56"
          height="98.08"
          fill="#263238"
        />
        <rect
          x="250.61"
          y="275.44"
          width="151.47"
          height="37.58"
          fill="#455a64"
        />
        <path
          d="m298.58 285.58a1.71 1.71 0 1 1-1.71-1.71 1.71 1.71 0 0 1 1.71 1.71z"
          fill="#263238"
        />
        <path
          d="m306.13 285.58a1.71 1.71 0 1 1-1.71-1.71 1.71 1.71 0 0 1 1.71 1.71z"
          fill="#263238"
        />
        <path
          d="m313.68 285.58a1.71 1.71 0 1 1-1.71-1.71 1.71 1.71 0 0 1 1.71 1.71z"
          fill="#263238"
        />
        <path
          d="m321.23 285.58a1.71 1.71 0 1 1-1.71-1.71 1.71 1.71 0 0 1 1.71 1.71z"
          fill="#263238"
        />
        <path
          d="m379.73 285.58a1.71 1.71 0 1 1-1.71-1.71 1.7 1.7 0 0 1 1.71 1.71z"
          fill="#263238"
        />
        <path
          d="m387.43 285.58a1.71 1.71 0 1 1-1.71-1.71 1.7 1.7 0 0 1 1.71 1.71z"
          fill="#263238"
        />
        <path
          d="m328.78 285.58a1.71 1.71 0 1 1-1.71-1.71 1.71 1.71 0 0 1 1.71 1.71z"
          fill="#263238"
        />
        <path
          d="m365.86 285.58a1.72 1.72 0 1 1-1.72-1.71 1.71 1.71 0 0 1 1.72 1.71z"
          fill="#263238"
        />
        <path
          d="m336.33 285.58a1.71 1.71 0 1 1-1.71-1.71 1.71 1.71 0 0 1 1.71 1.71z"
          fill="#f5f5f5"
        />
        <path
          d="m343.88 285.58a1.71 1.71 0 1 1-1.71-1.71 1.71 1.71 0 0 1 1.71 1.71z"
          fill="#f5f5f5"
        />
        <path
          d="m359.2 285.58a1.72 1.72 0 1 1-1.72-1.71 1.71 1.71 0 0 1 1.72 1.71z"
          fill="#f5f5f5"
        />
        <path
          d="m393.7 285.58a1.72 1.72 0 1 1-1.72-1.71 1.71 1.71 0 0 1 1.72 1.71z"
          fill="#f5f5f5"
        />
        <path
          d="m351.43 285.58a1.71 1.71 0 1 1-1.71-1.71 1.71 1.71 0 0 1 1.71 1.71z"
          fill="#06f"
        />
        <path
          d="m373 285.58a1.71 1.71 0 1 1-1.71-1.71 1.7 1.7 0 0 1 1.71 1.71z"
          fill="#06f"
        />
        <path
          d="m390.27 293.83a1.72 1.72 0 1 1 1.71 1.71 1.71 1.71 0 0 1-1.71-1.71z"
          fill="#263238"
        />
        <circle cx="384.43" cy="293.83" r="1.71" fill="#263238" />
        <path
          d="m375.17 293.83a1.72 1.72 0 1 1 1.72 1.71 1.71 1.71 0 0 1-1.72-1.71z"
          fill="#263238"
        />
        <path
          d="m367.62 293.83a1.72 1.72 0 1 1 1.72 1.71 1.71 1.71 0 0 1-1.72-1.71z"
          fill="#263238"
        />
        <path
          d="m309.12 293.83a1.71 1.71 0 1 1 1.71 1.71 1.71 1.71 0 0 1-1.71-1.71z"
          fill="#263238"
        />
        <path
          d="m301.42 293.83a1.71 1.71 0 1 1 1.71 1.71 1.71 1.71 0 0 1-1.71-1.71z"
          fill="#263238"
        />
        <circle cx="361.79" cy="293.83" r="1.71" fill="#263238" />
        <circle cx="324.71" cy="293.83" r="1.71" fill="#263238" />
        <path
          d="m352.52 293.83a1.72 1.72 0 1 1 1.72 1.71 1.71 1.71 0 0 1-1.72-1.71z"
          fill="#f5f5f5"
        />
        <path
          d="m345 293.83a1.71 1.71 0 1 1 1.71 1.71 1.7 1.7 0 0 1-1.71-1.71z"
          fill="#f5f5f5"
        />
        <path
          d="m329.66 293.83a1.71 1.71 0 1 1 1.71 1.71 1.7 1.7 0 0 1-1.71-1.71z"
          fill="#f5f5f5"
        />
        <path
          d="m295.16 293.83a1.71 1.71 0 1 1 1.71 1.71 1.7 1.7 0 0 1-1.71-1.71z"
          fill="#f5f5f5"
        />
        <path
          d="m337.43 293.83a1.71 1.71 0 1 1 1.71 1.71 1.7 1.7 0 0 1-1.71-1.71z"
          fill="#06f"
        />
        <path
          d="m315.81 293.83a1.71 1.71 0 1 1 1.71 1.71 1.71 1.71 0 0 1-1.71-1.71z"
          fill="#06f"
        />
        <path
          d="m298.58 301.16a1.71 1.71 0 1 1-1.71-1.71 1.71 1.71 0 0 1 1.71 1.71z"
          fill="#263238"
        />
        <path
          d="m306.13 301.16a1.71 1.71 0 1 1-1.71-1.71 1.71 1.71 0 0 1 1.71 1.71z"
          fill="#263238"
        />
        <path
          d="m313.68 301.16a1.71 1.71 0 1 1-1.71-1.71 1.71 1.71 0 0 1 1.71 1.71z"
          fill="#263238"
        />
        <path
          d="m321.23 301.16a1.71 1.71 0 1 1-1.71-1.71 1.71 1.71 0 0 1 1.71 1.71z"
          fill="#263238"
        />
        <path
          d="m379.73 301.16a1.71 1.71 0 1 1-1.71-1.71 1.7 1.7 0 0 1 1.71 1.71z"
          fill="#263238"
        />
        <path
          d="m387.43 301.16a1.71 1.71 0 1 1-1.71-1.71 1.7 1.7 0 0 1 1.71 1.71z"
          fill="#263238"
        />
        <path
          d="m328.78 301.16a1.71 1.71 0 1 1-1.71-1.71 1.71 1.71 0 0 1 1.71 1.71z"
          fill="#263238"
        />
        <path
          d="m365.86 301.16a1.72 1.72 0 1 1-1.72-1.71 1.71 1.71 0 0 1 1.72 1.71z"
          fill="#263238"
        />
        <path
          d="m336.33 301.16a1.71 1.71 0 1 1-1.71-1.71 1.71 1.71 0 0 1 1.71 1.71z"
          fill="#f5f5f5"
        />
        <path
          d="m343.88 301.16a1.71 1.71 0 1 1-1.71-1.71 1.71 1.71 0 0 1 1.71 1.71z"
          fill="#f5f5f5"
        />
        <path
          d="m359.2 301.16a1.72 1.72 0 1 1-1.72-1.71 1.71 1.71 0 0 1 1.72 1.71z"
          fill="#f5f5f5"
        />
        <path
          d="m393.7 301.16a1.72 1.72 0 1 1-1.72-1.71 1.71 1.71 0 0 1 1.72 1.71z"
          fill="#f5f5f5"
        />
        <path
          d="m351.43 301.16a1.71 1.71 0 1 1-1.71-1.71 1.71 1.71 0 0 1 1.71 1.71z"
          fill="#06f"
        />
        <path
          d="m373 301.16a1.71 1.71 0 1 1-1.71-1.71 1.7 1.7 0 0 1 1.71 1.71z"
          fill="#06f"
        />
        <rect
          x="257.34"
          y="283.18"
          width="29.82"
          height="4.59"
          fill="#263238"
        />
        <rect
          x="257.34"
          y="291.08"
          width="29.82"
          height="4.59"
          fill="#263238"
        />
        <rect
          x="257.34"
          y="299.24"
          width="29.82"
          height="4.59"
          fill="#263238"
        />
        <rect
          x="250.61"
          y="321.82"
          width="151.47"
          height="37.58"
          fill="#455a64"
        />
        <rect
          x="257.34"
          y="329.56"
          width="29.82"
          height="4.59"
          fill="#263238"
        />
        <rect
          x="257.34"
          y="337.45"
          width="29.82"
          height="4.59"
          fill="#263238"
        />
        <rect
          x="257.34"
          y="345.62"
          width="29.82"
          height="4.59"
          fill="#263238"
        />
        <path
          d="M298,332a1.71,1.71,0,1,1-1.71-1.71A1.72,1.72,0,0,1,298,332Z"
          fill="#263238"
        />
        <path
          d="m305.58 332a1.71 1.71 0 1 1-1.71-1.71 1.72 1.72 0 0 1 1.71 1.71z"
          fill="#263238"
        />
        <path
          d="m313.13 332a1.71 1.71 0 1 1-1.71-1.71 1.72 1.72 0 0 1 1.71 1.71z"
          fill="#263238"
        />
        <path
          d="m320.68 332a1.71 1.71 0 1 1-1.71-1.71 1.72 1.72 0 0 1 1.71 1.71z"
          fill="#263238"
        />
        <path
          d="m379.18 332a1.71 1.71 0 1 1-1.71-1.71 1.71 1.71 0 0 1 1.71 1.71z"
          fill="#263238"
        />
        <path
          d="m386.88 332a1.71 1.71 0 1 1-1.71-1.71 1.71 1.71 0 0 1 1.71 1.71z"
          fill="#263238"
        />
        <path
          d="m328.23 332a1.71 1.71 0 1 1-1.71-1.71 1.72 1.72 0 0 1 1.71 1.71z"
          fill="#263238"
        />
        <path
          d="m365.31 332a1.72 1.72 0 1 1-1.71-1.71 1.72 1.72 0 0 1 1.71 1.71z"
          fill="#263238"
        />
        <path
          d="m335.78 332a1.71 1.71 0 1 1-1.71-1.71 1.72 1.72 0 0 1 1.71 1.71z"
          fill="#f5f5f5"
        />
        <path
          d="m343.33 332a1.71 1.71 0 1 1-1.71-1.71 1.72 1.72 0 0 1 1.71 1.71z"
          fill="#f5f5f5"
        />
        <path
          d="m358.65 332a1.71 1.71 0 1 1-1.71-1.71 1.72 1.72 0 0 1 1.71 1.71z"
          fill="#f5f5f5"
        />
        <path
          d="m393.15 332a1.71 1.71 0 1 1-1.71-1.71 1.72 1.72 0 0 1 1.71 1.71z"
          fill="#f5f5f5"
        />
        <path
          d="m350.88 332a1.71 1.71 0 1 1-1.71-1.71 1.72 1.72 0 0 1 1.71 1.71z"
          fill="#06f"
        />
        <path
          d="m372.5 332a1.72 1.72 0 1 1-1.72-1.71 1.72 1.72 0 0 1 1.72 1.71z"
          fill="#06f"
        />
        <path
          d="m389.73 340.2a1.71 1.71 0 1 1 1.71 1.71 1.71 1.71 0 0 1-1.71-1.71z"
          fill="#263238"
        />
        <path
          d="m382.18 340.2a1.71 1.71 0 1 1 1.71 1.71 1.71 1.71 0 0 1-1.71-1.71z"
          fill="#263238"
        />
        <path
          d="m374.63 340.2a1.71 1.71 0 1 1 1.71 1.71 1.71 1.71 0 0 1-1.71-1.71z"
          fill="#263238"
        />
        <path
          d="m367.08 340.2a1.71 1.71 0 1 1 1.71 1.71 1.71 1.71 0 0 1-1.71-1.71z"
          fill="#263238"
        />
        <path
          d="m308.57 340.2a1.71 1.71 0 1 1 1.71 1.71 1.72 1.72 0 0 1-1.71-1.71z"
          fill="#263238"
        />
        <path
          d="m300.87 340.2a1.71 1.71 0 1 1 1.71 1.71 1.72 1.72 0 0 1-1.71-1.71z"
          fill="#263238"
        />
        <path
          d="m359.53 340.2a1.71 1.71 0 1 1 1.71 1.71 1.71 1.71 0 0 1-1.71-1.71z"
          fill="#263238"
        />
        <path
          d="m322.45 340.2a1.71 1.71 0 1 1 1.71 1.71 1.72 1.72 0 0 1-1.71-1.71z"
          fill="#263238"
        />
        <path
          d="m352 340.2a1.71 1.71 0 1 1 1.71 1.71 1.71 1.71 0 0 1-1.71-1.71z"
          fill="#f5f5f5"
        />
        <path
          d="m344.43 340.2a1.71 1.71 0 1 1 1.71 1.71 1.71 1.71 0 0 1-1.71-1.71z"
          fill="#f5f5f5"
        />
        <path
          d="m329.11 340.2a1.71 1.71 0 1 1 1.71 1.71 1.72 1.72 0 0 1-1.71-1.71z"
          fill="#f5f5f5"
        />
        <path
          d="m294.61 340.2a1.71 1.71 0 1 1 1.71 1.71 1.71 1.71 0 0 1-1.71-1.71z"
          fill="#f5f5f5"
        />
        <path
          d="m336.88 340.2a1.71 1.71 0 1 1 1.71 1.71 1.71 1.71 0 0 1-1.71-1.71z"
          fill="#06f"
        />
        <path
          d="m315.26 340.2a1.71 1.71 0 1 1 1.71 1.71 1.72 1.72 0 0 1-1.71-1.71z"
          fill="#06f"
        />
        <path
          d="m298 347.54a1.71 1.71 0 1 1-1.71-1.71 1.72 1.72 0 0 1 1.71 1.71z"
          fill="#263238"
        />
        <path
          d="m305.58 347.54a1.71 1.71 0 1 1-1.71-1.71 1.72 1.72 0 0 1 1.71 1.71z"
          fill="#263238"
        />
        <path
          d="m313.13 347.54a1.71 1.71 0 1 1-1.71-1.71 1.72 1.72 0 0 1 1.71 1.71z"
          fill="#263238"
        />
        <path
          d="m320.68 347.54a1.71 1.71 0 1 1-1.71-1.71 1.72 1.72 0 0 1 1.71 1.71z"
          fill="#263238"
        />
        <path
          d="m379.18 347.54a1.71 1.71 0 1 1-1.71-1.71 1.71 1.71 0 0 1 1.71 1.71z"
          fill="#263238"
        />
        <path
          d="m386.88 347.54a1.71 1.71 0 1 1-1.71-1.71 1.71 1.71 0 0 1 1.71 1.71z"
          fill="#263238"
        />
        <path
          d="m328.23 347.54a1.71 1.71 0 1 1-1.71-1.71 1.72 1.72 0 0 1 1.71 1.71z"
          fill="#263238"
        />
        <path
          d="m365.31 347.54a1.72 1.72 0 1 1-1.71-1.71 1.72 1.72 0 0 1 1.71 1.71z"
          fill="#263238"
        />
        <path
          d="m335.78 347.54a1.71 1.71 0 1 1-1.71-1.71 1.72 1.72 0 0 1 1.71 1.71z"
          fill="#f5f5f5"
        />
        <path
          d="m343.33 347.54a1.71 1.71 0 1 1-1.71-1.71 1.72 1.72 0 0 1 1.71 1.71z"
          fill="#f5f5f5"
        />
        <path
          d="m358.65 347.54a1.71 1.71 0 1 1-1.71-1.71 1.72 1.72 0 0 1 1.71 1.71z"
          fill="#f5f5f5"
        />
        <path
          d="m393.15 347.54a1.71 1.71 0 1 1-1.71-1.71 1.72 1.72 0 0 1 1.71 1.71z"
          fill="#f5f5f5"
        />
        <path
          d="m350.88 347.54a1.71 1.71 0 1 1-1.71-1.71 1.72 1.72 0 0 1 1.71 1.71z"
          fill="#06f"
        />
        <path
          d="m372.5 347.54a1.72 1.72 0 1 1-1.72-1.71 1.72 1.72 0 0 1 1.72 1.71z"
          fill="#06f"
        />
        <polygon
          points="424.85 312.67 402.08 312.73 402.08 275.15 424.85 275.09"
          fill="#263238"
        />
        <polygon
          points="424.85 359.34 402.08 359.4 402.08 321.82 424.85 321.76"
          fill="#263238"
        />
        <polygon
          points="423.45 406.36 400.68 406.42 400.68 368.84 423.45 368.77"
          fill="#263238"
        />
        <rect
          x="250.31"
          y="368.84"
          width="151.47"
          height="37.58"
          fill="#455a64"
        />
        <rect
          x="257.03"
          y="376.58"
          width="29.82"
          height="4.59"
          fill="#263238"
        />
        <rect
          x="257.03"
          y="384.47"
          width="29.82"
          height="4.59"
          fill="#263238"
        />
        <path
          d="m298.65 379.35a1.71 1.71 0 1 1-1.71-1.71 1.72 1.72 0 0 1 1.71 1.71z"
          fill="#263238"
        />
        <path
          d="m306.2 379.35a1.71 1.71 0 1 1-1.71-1.71 1.72 1.72 0 0 1 1.71 1.71z"
          fill="#263238"
        />
        <path
          d="m313.75 379.35a1.71 1.71 0 1 1-1.71-1.71 1.72 1.72 0 0 1 1.71 1.71z"
          fill="#263238"
        />
        <path
          d="m321.3 379.35a1.71 1.71 0 1 1-1.71-1.71 1.72 1.72 0 0 1 1.71 1.71z"
          fill="#263238"
        />
        <path
          d="m379.8 379.35a1.71 1.71 0 1 1-1.71-1.71 1.71 1.71 0 0 1 1.71 1.71z"
          fill="#263238"
        />
        <path
          d="m387.5 379.35a1.71 1.71 0 1 1-1.71-1.71 1.72 1.72 0 0 1 1.71 1.71z"
          fill="#263238"
        />
        <path
          d="m328.85 379.35a1.71 1.71 0 1 1-1.71-1.71 1.72 1.72 0 0 1 1.71 1.71z"
          fill="#263238"
        />
        <path
          d="m365.92 379.35a1.71 1.71 0 1 1-1.71-1.71 1.71 1.71 0 0 1 1.71 1.71z"
          fill="#263238"
        />
        <path
          d="m336.4 379.35a1.71 1.71 0 1 1-1.71-1.71 1.72 1.72 0 0 1 1.71 1.71z"
          fill="#f5f5f5"
        />
        <path
          d="m344 379.35a1.72 1.72 0 1 1-1.71-1.71 1.72 1.72 0 0 1 1.71 1.71z"
          fill="#f5f5f5"
        />
        <path
          d="m359.26 379.35a1.71 1.71 0 1 1-1.71-1.71 1.71 1.71 0 0 1 1.71 1.71z"
          fill="#f5f5f5"
        />
        <path
          d="m393.76 379.35a1.71 1.71 0 1 1-1.71-1.71 1.71 1.71 0 0 1 1.71 1.71z"
          fill="#f5f5f5"
        />
        <path
          d="m351.5 379.35a1.72 1.72 0 1 1-1.71-1.71 1.72 1.72 0 0 1 1.71 1.71z"
          fill="#06f"
        />
        <path
          d="m373.11 379.35a1.71 1.71 0 1 1-1.71-1.71 1.71 1.71 0 0 1 1.71 1.71z"
          fill="#06f"
        />
        <path
          d="m390.34 387.6a1.71 1.71 0 1 1 1.71 1.71 1.72 1.72 0 0 1-1.71-1.71z"
          fill="#263238"
        />
        <path
          d="m382.79 387.6a1.71 1.71 0 1 1 1.71 1.71 1.72 1.72 0 0 1-1.71-1.71z"
          fill="#263238"
        />
        <path
          d="m375.24 387.6a1.71 1.71 0 1 1 1.71 1.71 1.72 1.72 0 0 1-1.71-1.71z"
          fill="#263238"
        />
        <path
          d="m367.69 387.6a1.71 1.71 0 1 1 1.71 1.71 1.72 1.72 0 0 1-1.71-1.71z"
          fill="#263238"
        />
        <path
          d="m309.19 387.6a1.71 1.71 0 1 1 1.71 1.71 1.71 1.71 0 0 1-1.71-1.71z"
          fill="#263238"
        />
        <path
          d="m301.49 387.6a1.71 1.71 0 1 1 1.71 1.71 1.72 1.72 0 0 1-1.71-1.71z"
          fill="#263238"
        />
        <path
          d="m360.14 387.6a1.71 1.71 0 1 1 1.71 1.71 1.72 1.72 0 0 1-1.71-1.71z"
          fill="#263238"
        />
        <path
          d="m323.07 387.6a1.71 1.71 0 1 1 1.71 1.71 1.71 1.71 0 0 1-1.71-1.71z"
          fill="#263238"
        />
        <path
          d="m352.59 387.6a1.72 1.72 0 1 1 1.71 1.71 1.72 1.72 0 0 1-1.71-1.71z"
          fill="#f5f5f5"
        />
        <path
          d="m345 387.6a1.72 1.72 0 1 1 1.71 1.71 1.72 1.72 0 0 1-1.71-1.71z"
          fill="#f5f5f5"
        />
        <path
          d="m329.73 387.6a1.71 1.71 0 1 1 1.71 1.71 1.71 1.71 0 0 1-1.71-1.71z"
          fill="#f5f5f5"
        />
        <path
          d="m295.23 387.6a1.71 1.71 0 1 1 1.71 1.71 1.71 1.71 0 0 1-1.71-1.71z"
          fill="#f5f5f5"
        />
        <path
          d="m337.49 387.6a1.72 1.72 0 1 1 1.71 1.71 1.72 1.72 0 0 1-1.71-1.71z"
          fill="#06f"
        />
        <path
          d="m315.88 387.6a1.71 1.71 0 1 1 1.71 1.71 1.71 1.71 0 0 1-1.71-1.71z"
          fill="#06f"
        />
        <path
          d="m298.65 394.93a1.71 1.71 0 1 1-1.71-1.71 1.72 1.72 0 0 1 1.71 1.71z"
          fill="#263238"
        />
        <path
          d="m306.2 394.93a1.71 1.71 0 1 1-1.71-1.71 1.72 1.72 0 0 1 1.71 1.71z"
          fill="#263238"
        />
        <path
          d="m313.75 394.93a1.71 1.71 0 1 1-1.71-1.71 1.72 1.72 0 0 1 1.71 1.71z"
          fill="#263238"
        />
        <path
          d="m321.3 394.93a1.71 1.71 0 1 1-1.71-1.71 1.72 1.72 0 0 1 1.71 1.71z"
          fill="#263238"
        />
        <path
          d="m379.8 394.93a1.71 1.71 0 1 1-1.71-1.71 1.71 1.71 0 0 1 1.71 1.71z"
          fill="#263238"
        />
        <path
          d="m387.5 394.93a1.71 1.71 0 1 1-1.71-1.71 1.72 1.72 0 0 1 1.71 1.71z"
          fill="#263238"
        />
        <path
          d="m328.85 394.93a1.71 1.71 0 1 1-1.71-1.71 1.72 1.72 0 0 1 1.71 1.71z"
          fill="#263238"
        />
        <path
          d="m365.92 394.93a1.71 1.71 0 1 1-1.71-1.71 1.71 1.71 0 0 1 1.71 1.71z"
          fill="#263238"
        />
        <path
          d="m336.4 394.93a1.71 1.71 0 1 1-1.71-1.71 1.72 1.72 0 0 1 1.71 1.71z"
          fill="#f5f5f5"
        />
        <path
          d="m344 394.93a1.72 1.72 0 1 1-1.71-1.71 1.72 1.72 0 0 1 1.71 1.71z"
          fill="#f5f5f5"
        />
        <path
          d="m359.26 394.93a1.71 1.71 0 1 1-1.71-1.71 1.71 1.71 0 0 1 1.71 1.71z"
          fill="#f5f5f5"
        />
        <path
          d="m393.76 394.93a1.71 1.71 0 1 1-1.71-1.71 1.71 1.71 0 0 1 1.71 1.71z"
          fill="#f5f5f5"
        />
        <path
          d="m351.5 394.93a1.72 1.72 0 1 1-1.71-1.71 1.72 1.72 0 0 1 1.71 1.71z"
          fill="#06f"
        />
        <path
          d="m373.11 394.93a1.71 1.71 0 1 1-1.71-1.71 1.71 1.71 0 0 1 1.71 1.71z"
          fill="#06f"
        />
        <rect
          x="430.16"
          y="63.44"
          width="111.96"
          height="342.97"
          fill="#ebebeb"
        />
        <polygon
          points="630.45 406.42 542.12 406.42 541.96 63.44 630.45 63.44"
          fill="#e0e0e0"
        />
        <rect
          x="436.36"
          y="118.33"
          width="94.97"
          height="26.74"
          fill="#263238"
        />
        <rect x="439.01" y="121.7" width="5.32" height="20.46" fill="#455a64" />
        <path
          d="m450.59 126a1.69 1.69 0 1 1-1.69-1.69 1.69 1.69 0 0 1 1.69 1.69z"
          fill="#fff"
        />
        <path
          d="m450.59 133.23a1.69 1.69 0 1 1-1.69-1.69 1.69 1.69 0 0 1 1.69 1.69z"
          fill="#fff"
        />
        <rect
          x="457.35"
          y="124.31"
          width="5.23"
          height="15.72"
          fill="#455a64"
        />
        <rect x="464.9" y="124.31" width="5.23" height="15.72" fill="#455a64" />
        <rect
          x="472.45"
          y="124.31"
          width="5.23"
          height="15.72"
          fill="#455a64"
        />
        <rect
          x="479.99"
          y="124.31"
          width="5.23"
          height="15.72"
          fill="#455a64"
        />
        <rect
          x="487.54"
          y="124.31"
          width="5.23"
          height="15.72"
          fill="#455a64"
        />
        <rect
          x="495.09"
          y="124.31"
          width="5.23"
          height="15.72"
          fill="#455a64"
        />
        <rect
          x="502.64"
          y="124.31"
          width="5.23"
          height="15.72"
          fill="#455a64"
        />
        <rect
          x="510.19"
          y="124.31"
          width="5.23"
          height="15.72"
          fill="#455a64"
        />
        <rect
          x="517.74"
          y="124.31"
          width="5.23"
          height="15.72"
          fill="#455a64"
        />
        <rect
          x="525.29"
          y="124.31"
          width="5.23"
          height="15.72"
          fill="#455a64"
        />
        <rect
          x="436.36"
          y="156.75"
          width="94.97"
          height="26.74"
          fill="#263238"
        />
        <rect
          x="439.01"
          y="160.11"
          width="5.32"
          height="20.46"
          fill="#455a64"
        />
        <path
          d="m450.59 164.41a1.69 1.69 0 1 1-1.69-1.69 1.69 1.69 0 0 1 1.69 1.69z"
          fill="#fff"
        />
        <path
          d="m450.59 171.64a1.69 1.69 0 1 1-1.69-1.64 1.69 1.69 0 0 1 1.69 1.64z"
          fill="#fff"
        />
        <rect
          x="457.35"
          y="162.72"
          width="5.23"
          height="15.72"
          fill="#455a64"
        />
        <rect x="464.9" y="162.72" width="5.23" height="15.72" fill="#455a64" />
        <rect
          x="472.45"
          y="162.72"
          width="5.23"
          height="15.72"
          fill="#455a64"
        />
        <rect
          x="479.99"
          y="162.72"
          width="5.23"
          height="15.72"
          fill="#455a64"
        />
        <rect
          x="487.54"
          y="162.72"
          width="5.23"
          height="15.72"
          fill="#455a64"
        />
        <rect
          x="495.09"
          y="162.72"
          width="5.23"
          height="15.72"
          fill="#455a64"
        />
        <rect
          x="502.64"
          y="162.72"
          width="5.23"
          height="15.72"
          fill="#455a64"
        />
        <rect
          x="510.19"
          y="162.72"
          width="5.23"
          height="15.72"
          fill="#455a64"
        />
        <rect
          x="517.74"
          y="162.72"
          width="5.23"
          height="15.72"
          fill="#455a64"
        />
        <rect
          x="525.29"
          y="162.72"
          width="5.23"
          height="15.72"
          fill="#455a64"
        />
        <rect
          x="436.36"
          y="195.47"
          width="94.97"
          height="26.74"
          fill="#263238"
        />
        <rect
          x="439.01"
          y="198.83"
          width="5.32"
          height="20.46"
          fill="#455a64"
        />
        <path
          d="m450.59 203.13a1.69 1.69 0 1 1-1.69-1.69 1.69 1.69 0 0 1 1.69 1.69z"
          fill="#fff"
        />
        <path
          d="m450.59 210.36a1.69 1.69 0 1 1-1.69-1.69 1.69 1.69 0 0 1 1.69 1.69z"
          fill="#fff"
        />
        <rect
          x="457.35"
          y="201.44"
          width="5.23"
          height="15.72"
          fill="#455a64"
        />
        <rect x="464.9" y="201.44" width="5.23" height="15.72" fill="#455a64" />
        <rect
          x="472.45"
          y="201.44"
          width="5.23"
          height="15.72"
          fill="#455a64"
        />
        <rect
          x="479.99"
          y="201.44"
          width="5.23"
          height="15.72"
          fill="#455a64"
        />
        <rect
          x="487.54"
          y="201.44"
          width="5.23"
          height="15.72"
          fill="#455a64"
        />
        <rect
          x="495.09"
          y="201.44"
          width="5.23"
          height="15.72"
          fill="#455a64"
        />
        <rect
          x="502.64"
          y="201.44"
          width="5.23"
          height="15.72"
          fill="#455a64"
        />
        <rect
          x="510.19"
          y="201.44"
          width="5.23"
          height="15.72"
          fill="#455a64"
        />
        <rect
          x="517.74"
          y="201.44"
          width="5.23"
          height="15.72"
          fill="#455a64"
        />
        <rect
          x="525.29"
          y="201.44"
          width="5.23"
          height="15.72"
          fill="#455a64"
        />
        <rect
          x="436.36"
          y="237.29"
          width="94.97"
          height="26.74"
          fill="#263238"
        />
        <rect
          x="439.01"
          y="240.66"
          width="5.32"
          height="20.46"
          fill="#455a64"
        />
        <path
          d="m450.59 245a1.69 1.69 0 1 1-1.69-1.69 1.69 1.69 0 0 1 1.69 1.69z"
          fill="#fff"
        />
        <path
          d="m450.59 252.19a1.69 1.69 0 1 1-1.69-1.69 1.69 1.69 0 0 1 1.69 1.69z"
          fill="#fff"
        />
        <rect
          x="457.35"
          y="243.27"
          width="5.23"
          height="15.72"
          fill="#455a64"
        />
        <rect x="464.9" y="243.27" width="5.23" height="15.72" fill="#455a64" />
        <rect
          x="472.45"
          y="243.27"
          width="5.23"
          height="15.72"
          fill="#455a64"
        />
        <rect
          x="479.99"
          y="243.27"
          width="5.23"
          height="15.72"
          fill="#455a64"
        />
        <rect
          x="487.54"
          y="243.27"
          width="5.23"
          height="15.72"
          fill="#455a64"
        />
        <rect
          x="495.09"
          y="243.27"
          width="5.23"
          height="15.72"
          fill="#455a64"
        />
        <rect
          x="502.64"
          y="243.27"
          width="5.23"
          height="15.72"
          fill="#455a64"
        />
        <rect
          x="510.19"
          y="243.27"
          width="5.23"
          height="15.72"
          fill="#455a64"
        />
        <rect
          x="517.74"
          y="243.27"
          width="5.23"
          height="15.72"
          fill="#455a64"
        />
        <rect
          x="525.29"
          y="243.27"
          width="5.23"
          height="15.72"
          fill="#455a64"
        />
        <rect
          x="436.36"
          y="279.53"
          width="94.97"
          height="26.74"
          fill="#263238"
        />
        <rect x="439.01" y="282.9" width="5.32" height="20.46" fill="#455a64" />
        <path
          d="m450.59 287.2a1.69 1.69 0 1 1-1.69-1.69 1.69 1.69 0 0 1 1.69 1.69z"
          fill="#fff"
        />
        <path
          d="m450.59 294.43a1.69 1.69 0 1 1-1.69-1.69 1.69 1.69 0 0 1 1.69 1.69z"
          fill="#fff"
        />
        <rect
          x="457.35"
          y="285.51"
          width="5.23"
          height="15.72"
          fill="#455a64"
        />
        <rect x="464.9" y="285.51" width="5.23" height="15.72" fill="#455a64" />
        <rect
          x="472.45"
          y="285.51"
          width="5.23"
          height="15.72"
          fill="#455a64"
        />
        <rect
          x="479.99"
          y="285.51"
          width="5.23"
          height="15.72"
          fill="#455a64"
        />
        <rect
          x="487.54"
          y="285.51"
          width="5.23"
          height="15.72"
          fill="#455a64"
        />
        <rect
          x="495.09"
          y="285.51"
          width="5.23"
          height="15.72"
          fill="#455a64"
        />
        <rect
          x="502.64"
          y="285.51"
          width="5.23"
          height="15.72"
          fill="#455a64"
        />
        <rect
          x="510.19"
          y="285.51"
          width="5.23"
          height="15.72"
          fill="#455a64"
        />
        <rect
          x="517.74"
          y="285.51"
          width="5.23"
          height="15.72"
          fill="#455a64"
        />
        <rect
          x="525.29"
          y="285.51"
          width="5.23"
          height="15.72"
          fill="#455a64"
        />
        <rect
          x="436.36"
          y="363.6"
          width="94.97"
          height="26.74"
          fill="#263238"
        />
        <rect
          x="439.01"
          y="366.96"
          width="5.32"
          height="20.46"
          fill="#455a64"
        />
        <path
          d="m450.59 371.27a1.69 1.69 0 1 1-1.69-1.69 1.69 1.69 0 0 1 1.69 1.69z"
          fill="#fff"
        />
        <path
          d="m450.59 378.5a1.69 1.69 0 1 1-1.69-1.69 1.69 1.69 0 0 1 1.69 1.69z"
          fill="#fff"
        />
        <rect
          x="457.35"
          y="369.58"
          width="5.23"
          height="15.72"
          fill="#455a64"
        />
        <rect x="464.9" y="369.58" width="5.23" height="15.72" fill="#455a64" />
        <rect
          x="472.45"
          y="369.58"
          width="5.23"
          height="15.72"
          fill="#455a64"
        />
        <rect
          x="479.99"
          y="369.58"
          width="5.23"
          height="15.72"
          fill="#455a64"
        />
        <rect
          x="487.54"
          y="369.58"
          width="5.23"
          height="15.72"
          fill="#455a64"
        />
        <rect
          x="495.09"
          y="369.58"
          width="5.23"
          height="15.72"
          fill="#455a64"
        />
        <rect
          x="502.64"
          y="369.58"
          width="5.23"
          height="15.72"
          fill="#455a64"
        />
        <rect
          x="510.19"
          y="369.58"
          width="5.23"
          height="15.72"
          fill="#455a64"
        />
        <rect
          x="517.74"
          y="369.58"
          width="5.23"
          height="15.72"
          fill="#455a64"
        />
        <rect
          x="525.29"
          y="369.58"
          width="5.23"
          height="15.72"
          fill="#455a64"
        />
        <rect
          x="436.36"
          y="321.6"
          width="94.97"
          height="26.74"
          fill="#263238"
        />
        <rect
          x="439.01"
          y="324.97"
          width="5.32"
          height="20.46"
          fill="#455a64"
        />
        <path
          d="m450.59 329.27a1.69 1.69 0 1 1-1.69-1.69 1.69 1.69 0 0 1 1.69 1.69z"
          fill="#fff"
        />
        <path
          d="m450.59 336.5a1.69 1.69 0 1 1-1.69-1.69 1.69 1.69 0 0 1 1.69 1.69z"
          fill="#fff"
        />
        <rect
          x="457.35"
          y="327.58"
          width="5.23"
          height="15.72"
          fill="#455a64"
        />
        <rect x="464.9" y="327.58" width="5.23" height="15.72" fill="#455a64" />
        <rect
          x="472.45"
          y="327.58"
          width="5.23"
          height="15.72"
          fill="#455a64"
        />
        <rect
          x="479.99"
          y="327.58"
          width="5.23"
          height="15.72"
          fill="#455a64"
        />
        <rect
          x="487.54"
          y="327.58"
          width="5.23"
          height="15.72"
          fill="#455a64"
        />
        <rect
          x="495.09"
          y="327.58"
          width="5.23"
          height="15.72"
          fill="#455a64"
        />
        <rect
          x="502.64"
          y="327.58"
          width="5.23"
          height="15.72"
          fill="#455a64"
        />
        <rect
          x="510.19"
          y="327.58"
          width="5.23"
          height="15.72"
          fill="#455a64"
        />
        <rect
          x="517.74"
          y="327.58"
          width="5.23"
          height="15.72"
          fill="#455a64"
        />
        <rect
          x="525.29"
          y="327.58"
          width="5.23"
          height="15.72"
          fill="#455a64"
        />
        <path
          d="M495.09,118.33a4.26,4.26,0,0,1-.09-.82c0-.54-.07-1.35-.1-2.38s0-2.29-.11-3.75a24,24,0,0,0-.65-4.88,17.59,17.59,0,0,0-2.54-5.35A11.87,11.87,0,0,0,486.4,97a25.14,25.14,0,0,0-7.39-1.5l-8.18-.71c-11.21-1-21.37-1.6-28.74-1.73-3.69-.08-6.67,0-8.73,0L431,93a4.36,4.36,0,0,1-.83,0,3.14,3.14,0,0,1,.82-.1l2.38-.14c2.06-.08,5-.17,8.73-.15,7.39,0,17.58.58,28.8,1.56l8.18.72a25.36,25.36,0,0,1,7.54,1.57,12.37,12.37,0,0,1,5.4,4.41,17.62,17.62,0,0,1,2.54,5.53,23.54,23.54,0,0,1,.58,5c0,1.47,0,2.73,0,3.76s0,1.77,0,2.38A4.29,4.29,0,0,1,495.09,118.33Z"
          fill="#263238"
        />
        <path
          d="M466.28,118.33a3.81,3.81,0,0,1-.06-.77c0-.58,0-1.32,0-2.24s0-2.16-.11-3.52a10.88,10.88,0,0,0-1.17-4.41,8.49,8.49,0,0,0-4.29-3.3,11.77,11.77,0,0,0-6.26-.64,10.66,10.66,0,0,0-3.23,1.1,6.12,6.12,0,0,0-2.51,2.38,3.39,3.39,0,0,0,0,3.35,4.46,4.46,0,0,0,2.85,2.14,3.59,3.59,0,0,0,3.33-1,4.93,4.93,0,0,0,1.5-3.17,4.76,4.76,0,0,0-1-3.25,6.92,6.92,0,0,0-2.51-2.22,9.33,9.33,0,0,0-3.14-.81c-1.07-.16-2.11-.3-3.12-.41-2-.23-3.91-.36-5.64-.43-3.46-.13-6.27-.08-8.21-.08h-2.23a4.19,4.19,0,0,1-.78,0,3.55,3.55,0,0,1,.77-.08l2.23-.11c1.94-.08,4.75-.2,8.23-.12,1.74.05,3.64.15,5.68.36,1,.1,2.07.23,3.15.38.54.09,1.08.16,1.64.26a6.44,6.44,0,0,1,1.68.58,7.79,7.79,0,0,1,2.73,2.39,5.41,5.41,0,0,1,1.11,3.62,5.56,5.56,0,0,1-1.67,3.57,4.24,4.24,0,0,1-3.88,1.11,5,5,0,0,1-3.24-2.44,4,4,0,0,1,0-3.92,6.69,6.69,0,0,1,2.76-2.6,11.09,11.09,0,0,1,3.41-1.15,12.23,12.23,0,0,1,6.53.73,8.73,8.73,0,0,1,4.48,3.55,11,11,0,0,1,1.14,4.6c.07,1.38,0,2.57,0,3.54s-.07,1.66-.09,2.24A4.17,4.17,0,0,1,466.28,118.33Z"
          fill="#263238"
        />
        <path
          d="M502.84,118.33a5.58,5.58,0,0,1-.19-.88c-.1-.58-.26-1.44-.5-2.54a49.06,49.06,0,0,0-2.79-9.06,26.37,26.37,0,0,0-8.27-11.09,15.57,15.57,0,0,0-7.58-2.89,79.68,79.68,0,0,0-8.9-.48c-12.27-.23-23.4-.18-31.45-.49-4-.14-7.27-.33-9.52-.49l-2.58-.21a5.48,5.48,0,0,1-.9-.12,5.62,5.62,0,0,1,.9,0l2.59.11c2.25.08,5.5.21,9.52.29,8.05.2,19.16.09,31.45.32a77.33,77.33,0,0,1,9,.5,16.14,16.14,0,0,1,7.83,3,24,24,0,0,1,5.22,5.53,32.34,32.34,0,0,1,3.1,5.85,45.61,45.61,0,0,1,2.63,9.18c.2,1.1.33,2,.39,2.56A3.82,3.82,0,0,1,502.84,118.33Z"
          fill="#263238"
        />
        <rect
          x="437.66"
          y="67.71"
          width="92.36"
          height="13.41"
          fill="#263238"
        />
        <rect x="457.98" y="74.41" width="50.29" height="6.7" fill="#455a64" />
        <path
          d="M567.71,345.79c0,.06-1,.16-2.8.68a23.92,23.92,0,0,0-7,3.42,19.21,19.21,0,0,0-3.92,3.64,13.72,13.72,0,0,0-1.61,2.48,17.11,17.11,0,0,0-1,3c-1,4.23-.73,9.08-.52,14.14s-.12,10.07-2.12,14.12a15.76,15.76,0,0,1-3.77,5,18.32,18.32,0,0,1-9,4.23,15.76,15.76,0,0,1-3.4.14,12.46,12.46,0,0,1-2.13-.33,3.77,3.77,0,0,1-.71-.22c0-.08,1,.24,2.85.32a16.64,16.64,0,0,0,3.33-.25,17.89,17.89,0,0,0,4.21-1.35,17.56,17.56,0,0,0,4.47-2.92,15.4,15.4,0,0,0,3.6-4.9c1.9-3.93,2.22-8.84,2-13.86s-.52-10,.59-14.3a18.25,18.25,0,0,1,1.07-3.07,14.68,14.68,0,0,1,1.7-2.57,19.33,19.33,0,0,1,4.06-3.67,23,23,0,0,1,7.16-3.3,15.23,15.23,0,0,1,2.11-.4A2.51,2.51,0,0,1,567.71,345.79Z"
          fill="#263238"
        />
        <rect x="525.13" y="391.96" width="7.58" height="9.22" fill="#263238" />
        <rect
          x="567.71"
          y="322.49"
          width="42.52"
          height="46.59"
          fill="#455a64"
        />
        <circle cx="588.97" cy="345.97" r="14" fill="#263238" />
        <circle cx="588.97" cy="345.97" r="7" fill="#455a64" />
        <circle cx="573.46" cy="329.72" r="1.3" fill="#263238" />
        <path
          d="m605 329.72a1.3 1.3 0 1 1-1.3-1.3 1.3 1.3 0 0 1 1.3 1.3z"
          fill="#263238"
        />
        <path
          d="m605.3 364.12a1.3 1.3 0 1 1-1.3-1.3 1.3 1.3 0 0 1 1.3 1.3z"
          fill="#263238"
        />
        <path
          d="m574.76 364.66a1.3 1.3 0 1 1-1.3-1.3 1.3 1.3 0 0 1 1.3 1.3z"
          fill="#263238"
        />
        <rect
          x="610.23"
          y="322.49"
          width="3.43"
          height="46.59"
          fill="#263238"
        />
        <path
          d="M96.63,202.6l-18.54-4.55.13,0a30.79,30.79,0,0,1-16,4.79,33.6,33.6,0,0,1-9.92-1.44A31.13,31.13,0,0,1,31,175.59a30.66,30.66,0,0,1,8-24.75,32.2,32.2,0,0,1,5.37-4.68c1-.62,2-1.26,3-1.8l1.57-.76.79-.38.82-.3a31.38,31.38,0,0,1,13.32-2A30.9,30.9,0,0,1,91,161.66a32.14,32.14,0,0,1,1.71,10,30.71,30.71,0,0,1-4.44,16.06v-.17c5.16,9.31,7.76,14,8.34,15-.6-1-3.29-5.67-8.63-14.87l0-.09,0-.08a30.52,30.52,0,0,0,4.28-15.88,31.65,31.65,0,0,0-1.73-9.85,30.22,30.22,0,0,0-5.42-9.55,30.58,30.58,0,0,0-34.42-8.79l-.8.29-.77.38-1.55.74c-1,.53-2,1.17-3,1.77a31.38,31.38,0,0,0-5.26,4.59A30.53,30.53,0,0,0,52.46,200.9a33.1,33.1,0,0,0,9.75,1.46A30.54,30.54,0,0,0,78,197.73l.06,0,.07,0Z"
          fill="#263238"
        />
        <path
          d="m59.18 158.07 1 15.48a2.5 2.5 0 0 0 2.36 2.35 2.52 2.52 0 0 0 2.65-2.23l1.71-15.56a2 2 0 0 0-2-2.19l-3.78 0.05a2 2 0 0 0-1.94 2.1z"
          fill="#06f"
        />
        <path
          d="m66.62 184.61a3.78 3.78 0 1 1-2.39-4.77 3.76 3.76 0 0 1 2.39 4.77z"
          fill="#06f"
        />
        <path
          d="M313.79,156.92c-.06,0-.93-1.09-2.4-2.85s-3.57-4.15-5.9-6.77-4.4-5.06-5.8-6.89c-.7-.92-1.25-1.67-1.6-2.21a3.57,3.57,0,0,1-.51-.87c.06,0,.93,1.08,2.4,2.85s3.57,4.15,5.9,6.77,4.4,5.05,5.8,6.89c.7.92,1.25,1.67,1.6,2.21A3.37,3.37,0,0,1,313.79,156.92Z"
          fill="#263238"
        />
        <path
          d="m332.94 146.47c-0.13 0.05-4.32-12-9.36-27s-9-27.09-8.86-27.14 4.32 12 9.36 27 9 27.1 8.86 27.14z"
          fill="#263238"
        />
        <path
          d="m349.53 149.61c-0.14-0.05 1.91-6.72 4.56-14.9s4.91-14.79 5.06-14.71-1.9 6.72-4.56 14.91-4.92 14.74-5.06 14.7z"
          fill="#263238"
        />
        <path
          d="M377.63,148.77c.08.12-2.47,2.08-5.49,4.63s-5.4,4.72-5.5,4.62a8.09,8.09,0,0,1,1.4-1.59c.92-.92,2.24-2.15,3.76-3.43s3-2.37,4-3.12A7.46,7.46,0,0,1,377.63,148.77Z"
          fill="#263238"
        />
        <path
          d="m362.51 108.59c0.13 0 0 0.79-0.28 1.67s-0.64 1.54-0.78 1.49 0-0.78 0.28-1.66 0.64-1.54 0.78-1.5z"
          fill="#263238"
        />
        <path
          d="M292.72,132c-.11.09-.5-.26-.87-.77s-.56-1-.44-1.07.5.26.87.77S292.84,132,292.72,132Z"
          fill="#263238"
        />
        <path
          d="M399.9,164H274.1a3.93,3.93,0,0,0-3.93,3.93v88.86a3.93,3.93,0,0,0,3.93,3.93h48.28s3.07,9.49-6.45,14.41h45.15s-10.75-3.69-8-14.41H399.9a3.93,3.93,0,0,0,3.93-3.93V167.93A3.93,3.93,0,0,0,399.9,164Z"
          fill="#263238"
        />
        <path
          d="M338.68,251.37a2.42,2.42,0,0,1-3.41,3.41.7.7,0,0,1-.21-.21,2.42,2.42,0,0,1,3.41-3.41A1,1,0,0,1,338.68,251.37Z"
          fill="#455a64"
        />
        <path
          d="m275.19 165.63h123.62a2.85 2.85 0 0 1 2.85 2.85v76h-129.32v-76a2.85 2.85 0 0 1 2.85-2.85z"
          fill="#fff"
        />
        <path
          d="m275.32 165.63h123.9a2.45 2.45 0 0 1 2.45 2.45v4.1h-129.33v-3.57a3 3 0 0 1 2.98-2.98z"
          fill="#ebebeb"
        />
        <path
          d="M277.36,169.05a.91.91,0,1,1-.9-.91A.9.9,0,0,1,277.36,169.05Z"
          fill="#e0e0e0"
        />
        <path
          d="M280.07,169.05a.91.91,0,1,1-.91-.91A.9.9,0,0,1,280.07,169.05Z"
          fill="#e0e0e0"
        />
        <path
          d="M282.65,169.05a.91.91,0,1,1-.91-.91A.9.9,0,0,1,282.65,169.05Z"
          fill="#e0e0e0"
        />
        <path
          d="m377.41 260.73s-17.9 0.07-40 0.07-40 0-40-0.07 17.89-0.08 40-0.08 40 0.04 40 0.08z"
          fill="#455a64"
        />
        <path
          d="m324.76 201.15c0 4-2.92 7.45-9.35 7.45a15.53 15.53 0 0 1-8.57-2.41l2.06-4a10.83 10.83 0 0 0 6.39 2c2.6 0 4.2-1.09 4.2-2.92s-1.15-2.92-5.23-2.92h-5.75l1.16-12.65h13.71v4.17h-9.38l-0.35 4.27h1.83c6.64 0.04 9.28 3.03 9.28 7.01z"
          fill="#06f"
        />
        <path
          d="m326.87 197c0-7.41 4.11-11.62 9.63-11.62s9.63 4.21 9.63 11.62-4.07 11.63-9.63 11.63-9.63-4.24-9.63-11.63zm14 0c0-5.1-1.8-7.22-4.4-7.22s-4.36 2.12-4.36 7.22 1.79 7.23 4.36 7.23 4.43-2.15 4.43-7.23z"
          fill="#06f"
        />
        <path
          d="m348.67 197c0-7.41 4.11-11.62 9.63-11.62s9.63 4.21 9.63 11.62-4.08 11.63-9.63 11.63-9.63-4.24-9.63-11.63zm14 0c0-5.1-1.8-7.22-4.4-7.22s-4.37 2.12-4.37 7.22 1.8 7.23 4.37 7.23 4.43-2.15 4.43-7.23z"
          fill="#06f"
        />
        <path d="M291.51,214.06h.95v6.64h-.95Z" fill="#263238" />
        <path
          d="M300.16,214.06v6.64h-.77l-4-4.95v4.95h-.95v-6.64h.78l4,5v-5Z"
          fill="#263238"
        />
        <path
          d="M303.47,214.89h-2.28v-.83h5.5v.83h-2.28v5.81h-.94Z"
          fill="#263238"
        />
        <path
          d="M312.54,219.88v.82h-4.82v-6.64h4.69v.83h-3.74v2H312v.8h-3.33v2.15Z"
          fill="#263238"
        />
        <path
          d="m318.52 220.7-1.44-2h-0.41-1.67v2h-0.94v-6.64h2.59c1.72 0 2.77 0.87 2.77 2.32a2.09 2.09 0 0 1-1.46 2.09l1.57 2.23zm0-4.32c0-0.95-0.64-1.49-1.85-1.49h-1.67v3h1.62c1.23-0.01 1.87-0.56 1.87-1.51z"
          fill="#263238"
        />
        <path
          d="M326.69,214.06v6.64h-.78l-4-4.95v4.95H321v-6.64h.78l4,5v-5Z"
          fill="#263238"
        />
        <path
          d="M332.91,219h-3.53l-.73,1.66h-1l3-6.64h.94l3,6.64h-1Zm-.34-.76L331.14,215l-1.43,3.25Z"
          fill="#263238"
        />
        <path d="M335.62,214.06h.95v5.82h3.59v.82h-4.54Z" fill="#263238" />
        <path
          d="M343.19,220l.36-.74a3.42,3.42,0,0,0,2.19.76c1.15,0,1.64-.44,1.64-1,0-1.62-4-.6-4-3.07,0-1,.79-1.89,2.54-1.89a3.94,3.94,0,0,1,2.14.58l-.32.76a3.42,3.42,0,0,0-1.82-.54c-1.13,0-1.61.48-1.61,1.06,0,1.62,4,.6,4,3,0,1-.82,1.89-2.57,1.89A4,4,0,0,1,343.19,220Z"
          fill="#263238"
        />
        <path
          d="M354.52,219.88v.82h-4.83v-6.64h4.69v.83h-3.74v2H354v.8h-3.33v2.15Z"
          fill="#263238"
        />
        <path
          d="m360.49 220.7-1.43-2h-0.42-1.64v2h-0.95v-6.64h2.59c1.73 0 2.77 0.87 2.77 2.32a2.09 2.09 0 0 1-1.46 2.09l1.57 2.23zm0-4.32c0-0.95-0.63-1.49-1.85-1.49h-1.64v3h1.61c1.22-0.01 1.85-0.56 1.85-1.51z"
          fill="#263238"
        />
        <path
          d="m368.71 214.06-2.91 6.64h-0.94l-2.92-6.64h1l2.4 5.46 2.41-5.46z"
          fill="#263238"
        />
        <path
          d="M374.38,219.88v.82h-4.82v-6.64h4.68v.83H370.5v2h3.34v.8H370.5v2.15Z"
          fill="#263238"
        />
        <path
          d="m380.35 220.7-1.43-2h-0.42-1.64v2h-0.95v-6.64h2.59c1.73 0 2.77 0.87 2.77 2.32a2.09 2.09 0 0 1-1.46 2.09l1.58 2.23zm0-4.32c0-0.95-0.63-1.49-1.85-1.49h-1.61v3h1.61c1.19-0.01 1.82-0.56 1.82-1.51z"
          fill="#263238"
        />
        <path
          d="M326.12,231.33v1.32h-5.47v-7.07H326v1.31h-3.72v1.54h3.29v1.27h-3.29v1.63Z"
          fill="#263238"
        />
        <path
          d="m331.93 232.65-1.36-2h-1.51v2h-1.64v-7.07h3.06c1.89 0 3.08 1 3.08 2.56a2.3 2.3 0 0 1-1.46 2.23l1.59 2.28zm-1.54-5.74h-1.33v2.46h1.33c1 0 1.51-0.46 1.51-1.23s-0.51-1.23-1.51-1.23z"
          fill="#263238"
        />
        <path
          d="m339.35 232.65-1.36-2h-1.51v2h-1.63v-7.07h3.06c1.89 0 3.07 1 3.07 2.56a2.3 2.3 0 0 1-1.46 2.23l1.59 2.28zm-1.53-5.74h-1.34v2.46h1.34c1 0 1.5-0.46 1.5-1.23s-0.5-1.23-1.5-1.23z"
          fill="#263238"
        />
        <path
          d="m341.82 229.11a3.88 3.88 0 1 1 3.87 3.66 3.66 3.66 0 0 1-3.87-3.66zm6.08 0a2.21 2.21 0 1 0-2.21 2.26 2.16 2.16 0 0 0 2.21-2.26z"
          fill="#263238"
        />
        <path
          d="m355.3 232.65-1.37-2h-1.5v2h-1.64v-7.07h3.06c1.89 0 3.07 1 3.07 2.56a2.29 2.29 0 0 1-1.45 2.23l1.58 2.28zm-1.54-5.74h-1.33v2.46h1.33c1 0 1.51-0.46 1.51-1.23s-0.51-1.23-1.51-1.23z"
          fill="#263238"
        />
        <path
          d="m285.69 206.3a2.35 2.35 0 0 1-2.06-1.2 2.39 2.39 0 0 1 0-2.4l6.95-11.91a2.39 2.39 0 0 1 2.08-1.19 2.38 2.38 0 0 1 2.06 1.21l6.84 12a2.35 2.35 0 0 1 0 2.39 2.38 2.38 0 0 1-2.08 1.19l-13.79-0.07zm7-16a1.64 1.64 0 0 0-1.46 0.83l-6.95 11.91a1.68 1.68 0 0 0 1.44 2.53l13.79 0.06a1.68 1.68 0 0 0 1.47-2.51l-6.84-12a1.66 1.66 0 0 0-1.47-0.8z"
          fill="#06f"
        />
        <path
          d="M291.63,202.47a.87.87,0,0,1,.92-.87.86.86,0,0,1,.9.88.88.88,0,0,1-.9.89A.89.89,0,0,1,291.63,202.47Zm.08-7.18h1.69l-.29,5.35H292Z"
          fill="#06f"
        />
        <path
          d="m373.72 206.3a2.35 2.35 0 0 1-2.06-1.2 2.39 2.39 0 0 1 0-2.4l6.95-11.91a2.39 2.39 0 0 1 2.08-1.19 2.38 2.38 0 0 1 2.06 1.21l6.84 12a2.35 2.35 0 0 1 0 2.39 2.38 2.38 0 0 1-2.08 1.19l-13.79-0.07zm7-16a1.64 1.64 0 0 0-1.46 0.83l-6.95 11.91a1.68 1.68 0 0 0 1.44 2.53l13.8 0.06a1.68 1.68 0 0 0 1.46-2.51l-6.84-12a1.66 1.66 0 0 0-1.47-0.8z"
          fill="#06f"
        />
        <path
          d="M379.66,202.47a.87.87,0,0,1,.92-.87.86.86,0,0,1,.9.88.88.88,0,0,1-.9.89A.89.89,0,0,1,379.66,202.47Zm.08-7.18h1.69l-.29,5.35H380Z"
          fill="#06f"
        />
        <polygon
          points="639.4 367.22 645.3 406 679.59 406 687.55 367.78"
          fill="#06f"
        />
        <path
          d="M682.75,392.08c0,.11-8.78.2-19.62.2s-19.62-.09-19.62-.2,8.78-.2,19.62-.2S682.75,392,682.75,392.08Z"
          fill="#fafafa"
        />
        <path
          d="M682.89,390.17c0,.11-8.85.2-19.76.2s-19.76-.09-19.76-.2,8.85-.2,19.76-.2S682.89,390.06,682.89,390.17Z"
          fill="#fafafa"
        />
        <path
          d="M682.82,388c0,.12-8.85.21-19.76.21s-19.76-.09-19.76-.21,8.85-.2,19.76-.2S682.82,387.9,682.82,388Z"
          fill="#fafafa"
        />
        <path
          d="m649.24 403.79a31.07 31.07 0 0 1-1-4.88 30.41 30.41 0 0 1-0.66-5 30.73 30.73 0 0 1 1.05 4.88 31.66 31.66 0 0 1 0.61 5z"
          fill="#fafafa"
        />
        <path
          d="m651.57 403.38a37.92 37.92 0 0 1-0.43-4.76 39.88 39.88 0 0 1-0.22-4.78 22.55 22.55 0 0 1 0.63 4.75 22.8 22.8 0 0 1 0.02 4.79z"
          fill="#fafafa"
        />
        <path
          d="m655.82 403.38a38.95 38.95 0 0 1-1.15-9.85 144.66 144.66 0 0 1 1.15 9.85z"
          fill="#fafafa"
        />
        <path
          d="m658.81 403.58a24.1 24.1 0 0 1-0.48-5 24.42 24.42 0 0 1 0.15-5 41.19 41.19 0 0 1 0.25 5 43.85 43.85 0 0 1 0.08 5z"
          fill="#fafafa"
        />
        <path
          d="m664.09 403.38a28.39 28.39 0 0 1-0.36-4.71 28 28 0 0 1 0-4.73 28.45 28.45 0 0 1 0.36 4.72 27.81 27.81 0 0 1 0 4.72z"
          fill="#fafafa"
        />
        <path
          d="M668,403.58a28.82,28.82,0,0,1-.16-4.87,29.64,29.64,0,0,1,.24-4.87,30.13,30.13,0,0,1,.16,4.87A29.53,29.53,0,0,1,668,403.58Z"
          fill="#fafafa"
        />
        <path
          d="m671.57 403.07a64.61 64.61 0 0 1 1-10.15 64.92 64.92 0 0 1-1 10.15z"
          fill="#fafafa"
        />
        <path
          d="m676.23 402.56a17.51 17.51 0 0 1 0.58-4.33 17.14 17.14 0 0 1 1.27-4.18 37.49 37.49 0 0 1-0.88 4.26 36.38 36.38 0 0 1-0.97 4.25z"
          fill="#fafafa"
        />
        <path
          d="M672.93,290.68a.67.67,0,0,1,0,.19c0,.15-.1.33-.16.55-.16.51-.38,1.21-.65,2.09s-.65,2-1,3.32-.83,2.77-1.24,4.42-.9,3.45-1.33,5.41-.88,4-1.33,6.24c-.82,4.42-1.57,9.31-2.1,14.48s-.73,10.11-.8,14.6c0,2.25,0,4.39.06,6.39s.14,3.86.25,5.56.23,3.23.33,4.58.23,2.5.33,3.45.18,1.65.23,2.18c0,.23,0,.42,0,.57s0,.19,0,.19a1,1,0,0,1,0-.19c0-.15-.05-.33-.09-.56-.07-.53-.17-1.25-.3-2.17s-.28-2.1-.4-3.45-.31-2.88-.4-4.58-.24-3.57-.29-5.57-.09-4.14-.1-6.4c.05-4.51.27-9.46.77-14.64s1.3-10.08,2.14-14.51c.46-2.2.87-4.31,1.36-6.25s.93-3.76,1.39-5.4.9-3.12,1.29-4.41.76-2.41,1.08-3.3.54-1.57.72-2.08l.2-.53A.82.82,0,0,1,672.93,290.68Z"
          fill="#455a64"
        />
        <path
          d="M666.74,314.7a12.75,12.75,0,0,1,5.33-6.73,3,3,0,0,1,2.82-.52c1.2.58,1.29,2.26.94,3.54a9.41,9.41,0,0,1-3.88,5.29,8,8,0,0,1-5.83,1.47"
          fill="#455a64"
        />
        <path
          d="M664.66,330.92c.88-3.35,3.55-8.11,6.33-10.18a4,4,0,0,1,1.44-.77,1.67,1.67,0,0,1,1.54.33,2.39,2.39,0,0,1,.34,2.23,13.5,13.5,0,0,1-10,10.07"
          fill="#455a64"
        />
        <path
          d="M664.08,345.19a19.68,19.68,0,0,1,6.39-8,3,3,0,0,1,2.23-.79c1.21.22,1.76,1.7,1.65,2.92-.22,2.53-2.15,4.55-4.14,6.12a25.65,25.65,0,0,1-5.91,3"
          fill="#455a64"
        />
        <path
          d="M664.41,355.45a18.87,18.87,0,0,1,5.43-6.18,3.87,3.87,0,0,1,1.63-.73,1.67,1.67,0,0,1,1.6.61c.54.8,0,1.88-.56,2.65a15.5,15.5,0,0,1-7.75,5.69"
          fill="#455a64"
        />
        <path
          d="M664.14,351.29a60.58,60.58,0,0,0-7.77-7.92c-.44-.37-1-.76-1.55-.58a1.13,1.13,0,0,0-.71,1,3,3,0,0,0,.29,1.3,15,15,0,0,0,10,8.41"
          fill="#455a64"
        />
        <path
          d="m664.38 337.93-6.1-7.44a7.37 7.37 0 0 0-2-1.88 2.22 2.22 0 0 0-3.44 2.43 6.53 6.53 0 0 0 1.19 2.44 18.23 18.23 0 0 0 4.45 4.73c1.79 1.23 3.42 2 5.57 1.71"
          fill="#455a64"
        />
        <path
          d="M665.23,322.9a21.17,21.17,0,0,0-6.14-9.22,3.54,3.54,0,0,0-1.6-.94,2.71,2.71,0,0,0-2.8,2,5.06,5.06,0,0,0,.56,4,10.61,10.61,0,0,0,2.87,3,24.67,24.67,0,0,0,6.79,3.67"
          fill="#455a64"
        />
        <path
          d="M667.67,309.49c-.59-4.31-2.83-7.9-5.44-11.39-.54-.74-1.31-1.52-2.22-1.38a1.75,1.75,0,0,0-1.27,1.09,4,4,0,0,0-.24,1.73,13.31,13.31,0,0,0,8.64,11.9"
          fill="#455a64"
        />
        <path
          d="M669.38,302.64a35.42,35.42,0,0,1-.22-6.67,11.91,11.91,0,0,1,2.21-6.21,5.08,5.08,0,0,1,2.22-1.76,2.79,2.79,0,0,1,2.72.36,3,3,0,0,1,.93,2.39,7.67,7.67,0,0,1-.69,2.56c-1.63,3.91-4.34,8.24-7.17,9.33"
          fill="#455a64"
        />
        <path
          d="M642.79,307.78a2.7,2.7,0,0,1,0,.55c0,.4,0,.92,0,1.57s0,1.51,0,2.47,0,2.07.12,3.28c.1,2.42.34,5.31.71,8.5s.94,6.67,1.69,10.3,1.67,7,2.59,10.11,1.89,5.8,2.76,8.07c.42,1.14.85,2.15,1.21,3.05s.69,1.66,1,2.28.46,1.07.61,1.44a2.13,2.13,0,0,1,.19.51,3,3,0,0,1-.26-.48l-.68-1.41c-.3-.61-.65-1.36-1-2.26s-.84-1.89-1.27-3c-.91-2.25-1.89-5-2.86-8.06s-1.85-6.5-2.63-10.14-1.29-7.14-1.66-10.34-.56-6.1-.63-8.53c-.06-1.22,0-2.32-.06-3.28s0-1.8,0-2.48.05-1.17.06-1.57A3.43,3.43,0,0,1,642.79,307.78Z"
          fill="#263238"
        />
        <path
          d="M643.75,325.47a9.06,9.06,0,0,1,2.18-5.73,2.15,2.15,0,0,1,1.81-1c.93.13,1.36,1.26,1.39,2.21a6.67,6.67,0,0,1-1.5,4.43,5.63,5.63,0,0,1-3.65,2.26"
          fill="#263238"
        />
        <path
          d="M645.82,337c-.12-2.47.68-6.29,2.12-8.29a3.09,3.09,0,0,1,.81-.84,1.21,1.21,0,0,1,1.12-.1,1.69,1.69,0,0,1,.71,1.44,9.62,9.62,0,0,1-4.64,9"
          fill="#263238"
        />
        <path
          d="M648.5,346.8a14,14,0,0,1,2.62-6.84,2.14,2.14,0,0,1,1.35-1c.87-.11,1.56.78,1.75,1.64.4,1.76-.48,3.56-1.5,5.05a18.55,18.55,0,0,1-3.37,3.34"
          fill="#263238"
        />
        <path
          d="M650.93,353.72a13.31,13.31,0,0,1,2.37-5.38,2.67,2.67,0,0,1,1-.85,1.19,1.19,0,0,1,1.22.07c.54.43.42,1.28.19,1.93a11.11,11.11,0,0,1-4,5.54"
          fill="#263238"
        />
        <path
          d="M649.85,350.94a43.37,43.37,0,0,0-7-3.72,1.24,1.24,0,0,0-1.18-.06.81.81,0,0,0-.26.86,2,2,0,0,0,.48.82,10.74,10.74,0,0,0,8.62,3.58"
          fill="#263238"
        />
        <path
          d="m647.14 341.79-5.75-3.79a5.22 5.22 0 0 0-1.76-0.85 1.58 1.58 0 0 0-1.82 2.39 4.62 4.62 0 0 0 1.34 1.41 13 13 0 0 0 4.05 2.26 5.31 5.31 0 0 0 4.16 0"
          fill="#263238"
        />
        <path
          d="M644.49,331.37a15.3,15.3,0,0,0-6.17-4.95,2.57,2.57,0,0,0-1.29-.29,1.92,1.92,0,0,0-1.49,1.94,3.66,3.66,0,0,0,1.25,2.63,7.61,7.61,0,0,0,2.61,1.45,17.82,17.82,0,0,0,5.41,1"
          fill="#263238"
        />
        <path
          d="M643.26,321.72c-1.33-2.81-3.63-4.77-6.16-6.58a1.87,1.87,0,0,0-1.8-.47,1.27,1.27,0,0,0-.64,1,2.9,2.9,0,0,0,.21,1.23,9.51,9.51,0,0,0,8.45,6.24"
          fill="#263238"
        />
        <path
          d="M643,316.69a25.49,25.49,0,0,1-1.59-4.49,8.57,8.57,0,0,1,.17-4.71,3.75,3.75,0,0,1,1.13-1.68,2,2,0,0,1,1.93-.34,2.13,2.13,0,0,1,1.15,1.43,5.22,5.22,0,0,1,.08,1.89c-.26,3-1.18,6.55-2.87,7.9"
          fill="#263238"
        />
        <path
          d="M686.14,307.41a2.34,2.34,0,0,1,.05.54c0,.4,0,.92.06,1.57s0,1.51,0,2.48,0,2.07-.06,3.28c-.07,2.44-.28,5.33-.62,8.53s-.91,6.7-1.67,10.34-1.69,7.06-2.63,10.14-1.94,5.81-2.85,8.07c-.44,1.14-.89,2.14-1.27,3s-.73,1.64-1,2.26-.51,1.05-.69,1.41a3.71,3.71,0,0,1-.26.48,2.55,2.55,0,0,1,.19-.52c.16-.36.36-.84.61-1.44s.61-1.38,1-2.28.8-1.9,1.21-3.05c.88-2.26,1.82-5,2.77-8.06s1.82-6.49,2.59-10.12,1.29-7.11,1.69-10.3.6-6.07.71-8.5c.08-1.21.08-2.31.12-3.27s0-1.8,0-2.48,0-1.17,0-1.56A3.93,3.93,0,0,1,686.14,307.41Z"
          fill="#455a64"
        />
        <path
          d="M685.19,325.09a9.08,9.08,0,0,0-2.18-5.73,2.19,2.19,0,0,0-1.81-1c-.94.14-1.37,1.26-1.4,2.21a6.75,6.75,0,0,0,1.5,4.44A5.67,5.67,0,0,0,685,327.3"
          fill="#455a64"
        />
        <path
          d="M683.12,336.58c.11-2.47-.68-6.28-2.12-8.29a2.82,2.82,0,0,0-.82-.83,1.21,1.21,0,0,0-1.12-.11,1.69,1.69,0,0,0-.71,1.44,9.63,9.63,0,0,0,4.64,9"
          fill="#455a64"
        />
        <path
          d="M680.43,346.43a14,14,0,0,0-2.62-6.85,2.22,2.22,0,0,0-1.34-1c-.87-.11-1.57.78-1.76,1.64-.39,1.77.49,3.56,1.5,5.06a18.35,18.35,0,0,0,3.37,3.33"
          fill="#455a64"
        />
        <path
          d="M678,353.34a13.65,13.65,0,0,0-2.37-5.38,3,3,0,0,0-.95-.85,1.21,1.21,0,0,0-1.22.07c-.55.43-.43,1.28-.2,1.93a11.07,11.07,0,0,0,4.06,5.54"
          fill="#455a64"
        />
        <path
          d="M679.09,350.56a42.9,42.9,0,0,1,7-3.71,1.25,1.25,0,0,1,1.18-.07.83.83,0,0,1,.26.87,2.27,2.27,0,0,1-.48.82,10.78,10.78,0,0,1-8.62,3.57"
          fill="#455a64"
        />
        <path
          d="M681.79,341.42l5.76-3.76a5.36,5.36,0,0,1,1.75-.85,1.8,1.8,0,0,1,1.78.54,1.74,1.74,0,0,1,0,1.85,4.5,4.5,0,0,1-1.34,1.41,12.9,12.9,0,0,1-4,2.26,5.31,5.31,0,0,1-4.16,0"
          fill="#455a64"
        />
        <path
          d="M684.45,331a15.17,15.17,0,0,1,6.17-5,2.54,2.54,0,0,1,1.29-.29,1.92,1.92,0,0,1,1.48,1.94,3.57,3.57,0,0,1-1.25,2.63,7.52,7.52,0,0,1-2.61,1.45,17.48,17.48,0,0,1-5.41,1"
          fill="#455a64"
        />
        <path
          d="M685.68,321.35c1.33-2.81,3.63-4.77,6.15-6.59a1.87,1.87,0,0,1,1.81-.46,1.25,1.25,0,0,1,.63,1,2.86,2.86,0,0,1-.21,1.22,9.51,9.51,0,0,1-8.45,6.25"
          fill="#455a64"
        />
        <path
          d="M686,316.32a25.84,25.84,0,0,0,1.58-4.5,8.44,8.44,0,0,0-.17-4.7,3.59,3.59,0,0,0-1.13-1.68,2,2,0,0,0-1.92-.34,2.09,2.09,0,0,0-1.15,1.43,5.44,5.44,0,0,0-.09,1.89c.27,3,1.18,6.54,2.88,7.9"
          fill="#455a64"
        />
        <path
          d="M635.46,359.54v7.25a2.86,2.86,0,0,0,2.86,2.86H688a2.86,2.86,0,0,0,2.86-2.86v-7.25Z"
          fill="#06f"
        />
        <g opacity=".4">
          <polygon points="639.77 369.65 640.18 371.96 686 375.23 687.16 369.65" />
        </g>
        <path
          d="M716.25,406.42c0,.14-152.79.26-341.23.26s-341.27-.12-341.27-.26,152.76-.26,341.27-.26S716.25,406.27,716.25,406.42Z"
          fill="#263238"
        />
      </svg>

      <h4 className="text-lg font-semibold text-center text-gray-900 dark:text-white">
        Internal Server Error
      </h4>

      <p className="mt-2 text-sm font-light text-center text-gray-900 dark:text-white">
        The server encountered an internal error and was unable to complete your
        request.
      </p>

      <Link
        to="/profile/personal"
        className="inline-flex items-center px-3 py-2 mt-4 text-sm font-medium leading-4 text-gray-900 bg-transparent border border-gray-700 rounded-md shadow-sm dark:text-white dark:border-white dark:hover:bg-transparent hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Home
      </Link>
    </div>
  );
}

export default ServerErrorPage;
