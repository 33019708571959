// import react components
import React, { useCallback, useEffect, useMemo, useState } from "react";
// import react router components
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  RiCloseLine,
  RiDeleteBinLine,
  RiMailLine,
  RiShieldLine,
  RiVipCrown2Line,
  RiCheckboxCircleLine,
  RiAlertLine,
} from "react-icons/ri";
import { useSnackbar } from "notistack";
import Modal from "../../Components/Modal";
import "../../Styles/React_Select.css";
import TableLoader from "../../Components/Loaders/TableLoader";
import SimpleTable from "../../Components/Tables/SimpleTable";
import Tooltip from "../../Components/Tooltip";
import ConfirmationModal from "../../Components/ConfirmationModal";
import {
  primary_email_url,
  user_email_url,
  send_verification_email,
} from "../../Components/Urls";
import { Delete, get, post } from "../../Components/AxiosUtilities";
import { format } from "timeago.js";
import { convertTZ } from "../../Components/TimezoneHandler";
import { data } from "jquery";

function EmailAddress(props) {
  const { enqueueSnackbar } = useSnackbar();

  const { timezone } = props;

  // Initialize states
  const [values, setValues] = useState({
    email: "",
    phone_number: "",
  });

  const [ShowEmailModal, setShowEmailModal] = useState(false);

  // email table states
  const [emailData, setEmailData] = useState([]);
  const [emailLoading, setEmailLoading] = useState(false);

  // confirmation modal states
  const [deleteEmail, setDeleteEmail] = useState("");
  const [showEmailConfirmation, setShowEmailConfirmation] = useState(false);

  const [primaryEmail, setPrimaryEmail] = useState("");
  const [showPrimaryEmailConfirmation, setShowPrimaryEmailConfirmation] =
    useState(false);

  const [verifyEmail, setVerifyEmail] = useState("");
  const [showVerifyEmailConfirmation, setShowVerifyEmailConfirmation] =
    useState(false);

  //  close the modal using the "ESC" key1
  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        setShowEmailModal(false);
        setShowEmailConfirmation(false);
        setShowPrimaryEmailConfirmation(false);
        setShowVerifyEmailConfirmation(false);
      } else if (e.ctrlKey && (e.keyCode === 10 || e.keyCode === 13)) {
        document.getElementById("submit").click();
      }
    };

    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);

  // function to save inputs in states
  const handleInputChange = (e) => {
    // takes input name and value from event target input field
    const { name, value } = e.target;

    // sets state values
    setValues({
      ...values,
      [name]: value,
    });
  };

  const fetchIdRef = React.useRef(0);

  const getIdentityProviderLogo = (identityProvider) => {
    if (identityProvider === "microsoft") {
      return (
        <svg
          className="h-4 w-4"
          enableBackground="new 0 0 2499.6 2500"
          viewBox="0 0 2499.6 2500"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="m1187.9 1187.9h-1187.9v-1187.9h1187.9z" fill="#f1511b" />
          <path
            d="m2499.6 1187.9h-1188v-1187.9h1187.9v1187.9z"
            fill="#80cc28"
          />
          <path d="m1187.9 2500h-1187.9v-1187.9h1187.9z" fill="#00adef" />
          <path d="m2499.6 2500h-1188v-1187.9h1187.9v1187.9z" fill="#fbbc09" />
        </svg>
      );
    } else if (identityProvider === "google") {
      return (
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 48 48"
          className="h-4 w-4"
        >
          <g>
            <path
              fill="#EA4335"
              d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
            ></path>
            <path
              fill="#4285F4"
              d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
            ></path>
            <path
              fill="#FBBC05"
              d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
            ></path>
            <path
              fill="#34A853"
              d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
            ></path>
            <path fill="none" d="M0 0h48v48H0z"></path>
          </g>
        </svg>
      );
    }
  };

  const fetchEmailData = useCallback(
    ({ pageIndex, pageSize, emailLoading = true }) => {
      // Give this fetch an ID
      const fetchId = ++fetchIdRef.current;
      // Set the loading state
      if (emailLoading) {
        setEmailLoading(true);
      }

      if (fetchId === fetchIdRef.current) {
        get(user_email_url)
          .then((result) => {
            console.log("email", result.data);

            let datas = result.data;

            let new_data = [];

            // primary email
            if (datas.primary !== null) {
              {
                Object.entries(datas.idps)
                  .filter((key) => key[0] === datas.primary)
                  .map((res) => res[1].map((res) => console.log(res)));
              }
              new_data.push({
                key: datas.primary,
                email: (
                  <div className="flex">
                    <span className="bg-red-500 w-10 h-10 rounded-full flex justify-center items-center">
                      <RiMailLine className="h-5 w-5 text-white" />
                    </span>
                    <p className="flex flex-col pl-2">
                      <span>{datas.primary}</span>
                      <span className="text-sm text-left text-gray-1500 dark:text-gray-3000">
                        <Tooltip
                          placement="top"
                          trigger="hover"
                          tooltip={Object.entries(datas.updated_at)
                            .filter((key) => key[0] === datas.primary)
                            .map((res) => convertTZ(res[1], timezone))}
                        >
                          {Object.entries(datas.updated_at)
                            .filter((key) => key[0] === datas.primary)
                            .map((res) => format(convertTZ(res[1], timezone)))}
                        </Tooltip>
                      </span>
                    </p>

                    <span className="flex ml-8">
                      <Tooltip
                        placement="top"
                        trigger="hover"
                        tooltip={
                          <div>
                            <p className="flex items-center">
                              <RiVipCrown2Line className="h-5 w-5 text-green-600 " />
                              <span className="ml-1 text-base">
                                Primary Email Address
                              </span>
                            </p>
                            <p className="flex text-gray-1500 dark:text-gray-3000 text-left pt-0.5">
                              You can use this email address to sign in to your
                              VegaMaster account.
                            </p>
                          </div>
                        }
                      >
                        <span className="flex items-center ml-2">
                          <RiVipCrown2Line className="w-5 h-5 text-green-600 " />
                        </span>
                      </Tooltip>
                    </span>

                    <span className="flex mx-2">
                      <Tooltip
                        placement="top"
                        trigger="hover"
                        tooltip={
                          <div>
                            <p className="flex items-center">
                              <RiShieldLine className="w-5 h-5 text-red-600 " />
                              <span className="ml-1 text-base">
                                Recovery Email Address
                              </span>
                            </p>
                            <p className="flex text-gray-1500 dark:text-gray-3000 text-left pt-0.5">
                              You can use this email address for verification to
                              change your VegaMaster account password.
                            </p>
                          </div>
                        }
                      >
                        <span className="flex items-center ml-2">
                          <RiShieldLine className="w-5 h-5 text-red-600 " />
                        </span>
                      </Tooltip>
                    </span>

                    <span className="flex space-x-2">
                      {Object.entries(datas.idps)
                        .filter((key) => key[0] === datas.primary)
                        .map((res) =>
                          res[1].map((res) => {
                            return (
                              <Tooltip
                                placement="top"
                                trigger="hover"
                                tooltip={res}
                              >
                                <span className="flex items-center ml-2">
                                  {getIdentityProviderLogo(res)}
                                </span>
                              </Tooltip>
                            );
                          })
                        )}
                    </span>
                  </div>
                ),
              });
            }

            // verified secondary emails
            datas.verified.map((email) => {
              return new_data.push({
                key: email,
                email: (
                  <div className="flex">
                    <span className="bg-red-500 w-10 h-10 rounded-full flex justify-center items-center">
                      <RiMailLine className="h-5 w-5 text-white" />
                    </span>
                    <div className="pl-2">
                      <p>{email}</p>
                      <p className="text-sm text-left text-gray-1500 dark:text-gray-3000">
                        <Tooltip
                          placement="top"
                          trigger="hover"
                          tooltip={Object.entries(datas.updated_at)
                            .filter((key) => key[0] === email)
                            .map((res) => convertTZ(res[1], timezone))}
                        >
                          {Object.entries(datas.updated_at)
                            .filter((key) => key[0] === email)
                            .map((res) => format(convertTZ(res[1], timezone)))}
                        </Tooltip>
                      </p>
                    </div>
                    <span className="flex ml-8 mr-2">
                      <Tooltip
                        placement="top"
                        trigger="hover"
                        tooltip={"Email address is verified"}
                      >
                        <RiCheckboxCircleLine className="ml-2 h-5 w-5 text-green-600" />
                      </Tooltip>
                    </span>
                    {Object.entries(datas.idps)
                      .filter((key) => key[0] === email)
                      .map((res) => res[1].map((res) => res === email)) && (
                      <span className="flex space-x-2">
                        {Object.entries(datas.idps)
                          .filter((key) => key[0] === email)
                          .map((res) =>
                            res[1].map((res) => {
                              return (
                                <Tooltip
                                  placement="top"
                                  trigger="hover"
                                  tooltip={res}
                                >
                                  <span className="flex items-center ml-2">
                                    {getIdentityProviderLogo(res)}
                                  </span>
                                </Tooltip>
                              );
                            })
                          )}
                      </span>
                    )}
                  </div>
                ),

                actions: (
                  <div id={`action_${email}`} className="hidden">
                    <div className="flex items-center justify-end space-x-4 sm:justify-start xs:justify-start md:justify-end lg:justify-end">
                      <button
                        type="button"
                        className="inline-flex items-center shadow-sm px-2.5 py-2
                                 text-sm leading-5 font-medium rounded-full text-gray-500 dark:text-gray-300 bg-gray-400 
                                dark:bg-gray-900 "
                        onClick={() => {
                          setPrimaryEmail(email);
                          setShowPrimaryEmailConfirmation(true);
                        }}
                      >
                        <RiVipCrown2Line className="w-5 h-5 text-gray-500 dark:text-gray-300 mr-2" />
                        Set as Primary address
                      </button>
                      <span
                        className="flex"
                        onClick={() => {
                          setDeleteEmail(email);
                          setShowEmailConfirmation(true);
                        }}
                      >
                        <Tooltip
                          placement="top"
                          trigger="hover"
                          tooltip="Delete Email"
                        >
                          <RiDeleteBinLine className="w-5 h-5" />
                        </Tooltip>
                      </span>
                    </div>
                  </div>
                ),
              });
            });

            // unverified secondary emails
            datas.unverified.map((email) => {
              return new_data.push({
                key: email,
                email: (
                  <div className="flex">
                    <span className="bg-red-500 w-10 h-10 rounded-full flex justify-center items-center">
                      <RiMailLine className="h-5 w-5 text-white" />
                    </span>
                    <div className="pl-2">
                      <p className="">{email}</p>
                      <p className="text-sm text-left text-gray-1500 dark:text-gray-3000">
                        <Tooltip
                          placement="top"
                          trigger="hover"
                          tooltip={Object.entries(datas.updated_at)
                            .filter((key) => key[0] === email)
                            .map((res) => convertTZ(res[1], timezone))}
                        >
                          {Object.entries(datas.updated_at)
                            .filter((key) => key[0] === email)
                            .map((res) => format(convertTZ(res[1], timezone)))}
                        </Tooltip>
                      </p>
                    </div>
                    <span className="flex ml-8">
                      <Tooltip
                        placement="top"
                        trigger="hover"
                        tooltip={"Email address is not verified"}
                      >
                        <RiAlertLine className="ml-2 h-5 w-5 text-red-600" />
                      </Tooltip>
                    </span>

                    {Object.entries(datas.idps)
                      .filter((key) => key[0] === email)
                      .map((res) => res[1].map((res) => res === email)) && (
                      <span className="flex space-x-2">
                        {Object.entries(datas.idps)
                          .filter((key) => key[0] === email)
                          .map((res) =>
                            res[1].map((res) => {
                              return (
                                <Tooltip
                                  placement="top"
                                  trigger="hover"
                                  tooltip={res}
                                >
                                  <span className="flex items-center ml-2">
                                    {getIdentityProviderLogo(res)}
                                  </span>
                                </Tooltip>
                              );
                            })
                          )}
                      </span>
                    )}
                  </div>
                ),

                actions: (
                  <div id={`action_${email}`} className="hidden">
                    <div className="flex items-center space-x-4 justify-end sm:justify-start xs:justify-start md:justify-end lg:justify-end">
                      <button
                        type="button"
                        className="inline-flex items-center shadow-sm px-2.5 py-2
                                 text-sm leading-5 font-medium rounded-full text-gray-500 dark:text-gray-300 bg-gray-400 
                                dark:bg-gray-900 "
                        onClick={() => {
                          setVerifyEmail(email);
                          setShowVerifyEmailConfirmation(true);
                        }}
                      >
                        Verify this Email
                      </button>
                      <span
                        className="flex"
                        onClick={() => {
                          setDeleteEmail(email);
                          setShowEmailConfirmation(true);
                        }}
                      >
                        <Tooltip
                          placement="top"
                          trigger="hover"
                          tooltip="Delete Email"
                        >
                          <RiDeleteBinLine className="w-5 h-5" />
                        </Tooltip>
                      </span>
                    </div>
                  </div>
                ),
              });
            });

            setEmailData(new_data);
            setEmailLoading(false);
          })
          .catch((err) => {
            console.log(err);
            setEmailLoading(false);
          });
      }
    },
    [timezone]
  );

  const emailColumns = useMemo(
    () => [
      {
        Header: "",
        accessor: "email",
      },
      { Header: "", accessor: "actions" },
    ],
    []
  );

  const addEmailAddress = (e) => {
    e.preventDefault();

    post(user_email_url, {
      email: values.email,
    })
      .then((res) => {
        console.log(res);

        enqueueSnackbar("Email added successfully.", {
          variant: "success",
          autoHideDuration: 3000,
        });

        fetchEmailData({ page: 0, pageSize: 0, emailLoading: false });

        setShowEmailModal(false);
      })
      .catch((err) => {
        console.log(err);
        if (err.response && err.response.data.message) {
          enqueueSnackbar(err.response.data.message, {
            variant: "error",
            autoHideDuration: 3000,
          });
        } else {
          enqueueSnackbar("An error occured while adding email address.", {
            variant: "error",
            autoHideDuration: 3000,
          });
        }
      });
  };

  const deleteEmailHandler = () => {
    Delete(user_email_url, {
      data: { email: deleteEmail },
    })
      .then((res) => {
        console.log(res);

        fetchEmailData({ page: 0, pageSize: 0, emailLoading: false });

        enqueueSnackbar("Email address deleted successfully.", {
          variant: "success",
          autoHideDuration: 3000,
        });

        setShowEmailConfirmation(false);
      })
      .catch((err) => {
        console.log(err);

        enqueueSnackbar("An error occured while deleting email address.", {
          variant: "error",
          autoHideDuration: 3000,
        });
        setShowEmailConfirmation(false);
      });
  };

  const getVerficationLink = () => {
    post(send_verification_email, {
      email: verifyEmail,
    })
      .then((res) => {
        console.log(res);

        fetchEmailData({ page: 0, pageSize: 0, emailLoading: false });
        enqueueSnackbar(
          "Link sent successfully for verification of given email address.",
          {
            variant: "success",
            autoHideDuration: 3000,
          }
        );
        setShowVerifyEmailConfirmation(false);
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar("An error occured while verifying email address.", {
          variant: "error",
          autoHideDuration: 3000,
        });
        setShowVerifyEmailConfirmation(false);
      });
  };

  const setPrimaryEmailAddress = () => {
    post(primary_email_url, {
      email: primaryEmail,
    })
      .then((res) => {
        console.log(res);

        fetchEmailData({ page: 0, pageSize: 0, emailLoading: false });
        enqueueSnackbar(
          "Email address converted successfully to primary email address.",
          {
            variant: "success",
            autoHideDuration: 3000,
          }
        );
        setShowPrimaryEmailConfirmation(false);
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar(
          "An error occured while converting email address to primary email address.",
          {
            variant: "success",
            autoHideDuration: 3000,
          }
        );
        setShowPrimaryEmailConfirmation(false);
      });
  };

  return (
    <>
      {/* email section */}
      <div className="mt-8" id="email_div">
        <div className="bg-white dark:bg-gray-800 border rounded-lg sm:rounded-lg overflow-hidden shadow border-gray-300  dark:border-gray-600">
          <div>
            <div>
              <div className="flex justify-between items-center xs:flex-col bg-gray-100 dark:bg-gray-700 border-b border-gray-200 dark:border-gray-600">
                <div className="p-5">
                  <h6 className="text-base font-medium text-gray-900 dark:text-white ">
                    My Email Addresses
                  </h6>
                  <p className="text-sm text-gray-1500 dark:text-gray-3000">
                    View and manage all of the email addresses associated with
                    your account.
                  </p>
                </div>

                <div className="p-5 xs:w-full xs:pt-0">
                  <button
                    className="button button-primary xs:w-full"
                    onClick={() => {
                      setShowEmailModal(true);
                      setValues({
                        email: "",
                      });
                    }}
                  >
                    Add Email Address
                  </button>
                </div>
              </div>

              {emailLoading && (
                <TableLoader columns={2} headingDescription={{ index: 1 }} />
              )}

              <div className="">
                <SimpleTable
                  columns={emailColumns}
                  data={emailData}
                  fetchData={fetchEmailData}
                  loading={emailLoading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* email address */}
      <Modal
        open={ShowEmailModal}
        modalWidth={true}
        heading={
          <div className="flex justify-between mb-4 items-center">
            <p className="text-gray-900 dark:text-gray-300">
              Add Email Address
            </p>

            <button
              type="button"
              className="closemodalicon"
              onClick={() => setShowEmailModal(false)}
            >
              <span className="sr-only">Close</span>
              <RiCloseLine className="w-6 h-6" aria-hidden="true" />
            </button>
          </div>
        }
      >
        <p className="text-sm text-gray-1500 dark:text-gray-3000">
          View and manage all of the mobile numbers associated with your
          account.
        </p>
        <form className="mt-2" onSubmit={addEmailAddress}>
          <div className="grid grid-cols-2 xs:grid-cols-1">
            <div className="text-left">
              <label htmlFor="name" className="form-label">
                Enter email address
                <span className="ml-1 text-base text-red-600">*</span>
              </label>
              <input
                type="email"
                name="email"
                value={values.email}
                onChange={handleInputChange}
                className="input-field"
                required
                placeholder="Enter email address"
              />
            </div>
          </div>
          <div className="flex items-center mt-4 space-x-3">
            <button id="submit" type="submit" className="button button-success">
              Add
            </button>
            <button
              type="button"
              className="text-gray-900 dark:text-gray-300"
              onClick={() => {
                setShowEmailModal(false);
              }}
            >
              Cancel
            </button>
          </div>
        </form>
      </Modal>

      <ConfirmationModal
        open={showEmailConfirmation}
        close={setShowEmailConfirmation}
        modalWidth={true}
        heading={
          <div className="mb-4">
            <p className="text-sm font-medium text-gray-900 dark:text-gray-300">
              Are you sure?
            </p>
            <p className="mt-1 text-sm text-gray-500 dark:text-gray-300">
              This action will delete email permanently.
            </p>
          </div>
        }
        icon={<RiDeleteBinLine className="w-8 h-8 text-red-500" />}
        accept={() => {
          deleteEmailHandler();
        }}
      />

      <ConfirmationModal
        open={showPrimaryEmailConfirmation}
        close={setShowPrimaryEmailConfirmation}
        modalWidth={true}
        heading={
          <div className="mb-4">
            <p className="text-sm font-medium text-gray-900 dark:text-white">
              Are you sure?
            </p>
            <p className="mt-1 text-sm text-gray-500 dark:text-gray-300">
              This action will set given email address to primary email address
              permanently.
            </p>
          </div>
        }
        confirmButtonName="Confirm"
        confirmButtonClassName="button button-success"
        accept={() => {
          setPrimaryEmailAddress();
        }}
      />

      <ConfirmationModal
        open={showVerifyEmailConfirmation}
        close={setShowVerifyEmailConfirmation}
        modalWidth={true}
        heading={
          <div className="mb-4">
            <p className="text-sm font-medium text-gray-900 dark:text-white">
              Are you sure?
            </p>
            <p className="mt-1 text-sm text-gray-500 dark:text-gray-300">
              This action will send a verification link to given email address.
            </p>
          </div>
        }
        confirmButtonName="Confirm"
        confirmButtonClassName="button button-success"
        accept={() => {
          getVerficationLink();
        }}
      />
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setTimeZone: (data) => dispatch({ type: "setTimeZone", ...data }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(EmailAddress));
