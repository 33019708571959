// import classname function
// import { classNames } from "../Components/utilities";
// import react icons
import { RiAddLine, RiEmotionSadLine } from "react-icons/ri";
// import react router dom
import { Link } from "react-router-dom";

function NoDataMessageComponent(props) {
  // console.log(props.button_name);
  return (
    <div className="py-4 text-center">
      <RiEmotionSadLine className="w-8 h-8 mx-auto text-gray-1300 dark:text-gray-1300" />

      <h3 className="mt-2 text-sm font-medium text-gray-900 dark:text-gray-1500">
        {props.title ? props.title : "No Data Found"}
      </h3>

      <p className="mt-1 text-sm text-gray-500 dark:text-gray-1300">
        {props.content ? props.content : "Get started by creating a new data."}{" "}
      </p>

      <div className={`${props.button_name ? "mt-6" : "mt-0"}`}>
        {props.button_name && (
          <Link
            to={props.url ? props.url : "#"}
            className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            {props.button_icon ? props.button_icon : <RiAddLine className="w-5 h-5 mr-2 -ml-1" aria-hidden="true" />}
            {props.button_name}
          </Link>
        )}
      </div>
    </div>

    // <div className="max-w-xl py-4 mx-auto">
    //     {
    //         // if svg is street
    //         props.svg === 'empty' &&

    //         <svg width="410" height="250" xmlns="http://www.w3.org/2000/svg" version="1.1" className="mx-auto">
    //             <metadata id="metadata46">image/svg+xml</metadata>

    //             <g id="g38">
    //                 <title id="title2">Layer 1</title>
    //                 <path id="path4" fill="#263238" d="m663.67,439.83c0,0.14 -129.25,0.26 -288.66,0.26s-288.68,-0.09 -288.68,-0.26s129.23,-0.26 288.68,-0.26s288.66,0.11 288.66,0.26z" />
    //                 <path id="path18" fill="#06f" d="m173.23232,81.7041l-21.4456,110.03136l-2.78672,14.40455l142.97063,-0.68997c6.42156,-0.03026 12.11615,-5.7255 14.16984,-14.18666l25.35911,-103.87614c1.56904,-6.42152 -1.87194,-13.10329 -6.74264,-13.09724l-144.73959,0.15131c-3.1502,-0.00605 -5.97326,2.97169 -6.78505,7.2628l0.00001,-0.00001z" />
    //                 <g id="g22" opacity="0.5">
    //                     <path id="path20" d="m173.23231,81.7041l-21.4456,110.03136l-2.78671,14.40455l142.97066,-0.68997c6.42157,-0.03026 12.11616,-5.7255 14.16985,-14.18666l25.35912,-103.87614c1.56905,-6.42152 -1.87195,-13.10329 -6.74265,-13.09724l-144.73961,0.15131c-3.1502,-0.00605 -5.97327,2.97169 -6.78505,7.2628l-0.00001,-0.00001z" />
    //                 </g>
    //                 <path id="path24" fill="#06f" d="m276.77579,183.89519l-15.27463,-125.70996c-0.61098,-5.14923 -4.63738,-8.994 -9.38473,-8.97527l-33.48197,0.10611a9.16477,9.36224 0 0 0 -6.61697,2.99592l-15.88561,17.27021l-93.1141,0.5118c-5.68827,0.03121 -10.06292,5.53621 -9.31141,11.71529l14.18096,116.18543c0.61098,5.14299 4.63738,8.98151 9.37251,8.97527l162.30813,-0.3308c18.07293,0.38073 19.80202,-5.0681 19.80202,-5.0681c-20.74905,4.68736 -22.59422,-17.64471 -22.59422,-17.67592l0.00001,0.00001z" />
    //                 <g transform="rotate(1, 184.65, 126.135)" id="g30" opacity="0.5" fill="#fff">
    //                     <polygon id="polygon26" points="208.30078125,142.03805541992188 202.70431518554688,147.6197967529297 161,110.23204040527344 166.59646606445312,104.6502914428711 " />
    //                     <polygon id="polygon28" points="201.1588134765625,104 207.1424560546875,109.36602783203125 168.14808654785156,148.27020263671875 162.15830993652344,142.90406799316406 " />
    //                 </g>
    //                 <text fill="#80b3ff" x="127" y="178" id="svg_1" stroke-width="0" font-size="14" font-family="sans-serif" text-anchor="start" xmlSpace="preserve" font-weight="bold" font-style="normal">No Data Found</text>
    //                 <path id="path6" fill="#06f" d="m45.65128,146.31882a11.9121,12.62 0 0 1 8.2875,4.5a20.90753,22.15 0 0 1 4.21926,9c1.5952,6.32 0.61354,13.79 -1.08549,20.08c-6.45632,-2.45 -10.44904,-9.75 -12.27078,-13.59c-2.83172,-6.06 -4.52131,-18.86 0.8212,-20" />
    //                 <path id="path8" fill="#06f" d="m66.81004,186.56882a10.74,10.74 0 0 1 -0.5,-10.78a14.26,14.26 0 0 1 8.6,-6.91c1.67,-0.52 3.61,-0.73 5.06,0.24a4.58,4.58 0 0 1 1.77,4.31a9.27,9.27 0 0 1 -2,4.42c-3.33,4.51 -7.37,8.26 -13,8.72" />
    //                 <path id="path10" fill="#263238" d="m67.57004,207.69882a6.5,6.5 0 0 1 -0.31,-1.31c-0.18,-0.94 -0.42,-2.15 -0.71,-3.6a37.77,37.77 0 0 1 -0.74,-12.11a23,23 0 0 1 4.49,-11.21a15.32,15.32 0 0 1 2.59,-2.63a9.16,9.16 0 0 1 0.82,-0.58a1.15,1.15 0 0 1 0.3,-0.18a25.37,25.37 0 0 0 -3.45,3.59a23.85,23.85 0 0 0 -4.28,11.08c-0.64,4.66 0.15,8.93 0.61,12c0.24,1.53 0.44,2.77 0.55,3.63a8.23,8.23 0 0 1 0.13,1.32z" />
    //                 <path id="path12" fill="#263238" d="m47.06004,154.94882a2.34,2.34 0 0 1 0.28,0.51c0.18,0.4 0.42,0.9 0.7,1.53c0.61,1.33 1.46,3.26 2.49,5.65c2.06,4.79 4.82,11.45 7.7,18.87s5.33,14.19 7,19.12c0.86,2.46 1.53,4.46 2,5.85c0.21,0.66 0.37,1.19 0.5,1.6a2.61,2.61 0 0 1 0.15,0.57a2.46,2.46 0 0 1 -0.24,-0.54c-0.15,-0.4 -0.34,-0.92 -0.59,-1.57l-2.11,-5.8c-1.79,-4.89 -4.29,-11.65 -7.16,-19.06s-5.59,-14.09 -7.57,-18.91c-1,-2.37 -1.77,-4.3 -2.34,-5.71c-0.26,-0.64 -0.47,-1.15 -0.63,-1.56a3.49,3.49 0 0 1 -0.18,-0.55z" />
    //                 <path id="path14" fill="#06f" d="m59.44004,193.71882a25.18,25.18 0 0 0 -19.52,-12.13c-2,-0.14 -4.46,0.2 -5.36,2s0.3,4 1.68,5.46a21.49,21.49 0 0 0 23.27,5.2" />
    //                 <path id="path16" fill="#263238" d="m43.00004,187.00882a7.15,7.15 0 0 1 1.27,0.05a12.09,12.09 0 0 1 1.47,0.17c0.57,0.11 1.24,0.17 1.94,0.37a19.41,19.41 0 0 1 2.31,0.64a22.91,22.91 0 0 1 2.57,1a27.8,27.8 0 0 1 5.42,3.21a27.31,27.31 0 0 1 4.48,4.44a22.49,22.49 0 0 1 1.57,2.25a21.93,21.93 0 0 1 1.18,2.09a17.4,17.4 0 0 1 0.8,1.8a11.34,11.34 0 0 1 0.51,1.4a6.82,6.82 0 0 1 0.33,1.23c-0.11,0 -0.59,-1.72 -1.94,-4.28a22,22 0 0 0 -1.21,-2a25.32,25.32 0 0 0 -1.58,-2.19a26.65,26.65 0 0 0 -9.74,-7.52c-0.87,-0.38 -1.7,-0.74 -2.51,-1a21.87,21.87 0 0 0 -2.27,-0.69c-2.8,-0.77 -4.61,-0.86 -4.6,-0.97z" /></g>
    //         </svg>

    //     }

    //     <div className="mt-2 text-center">
    //         {
    //             props.title ?
    //                 <h4 className="font-bold text-gray-900 text-md dark:text-white">
    //                     {props.title}
    //                 </h4>

    //                 :

    //                 <h4 className="font-bold text-gray-900 text-md dark:text-white">
    //                     No Data Found
    //                 </h4>

    //         }

    //         {
    //             props.content &&

    //             <p className="text-sm font-normal text-gray-900 dark:text-white">
    //                 {props.content}
    //             </p>
    //         }

    //         {
    //             props.button &&

    //             props.button
    //         }

    //     </div>
    // </div >
  );
}

export default NoDataMessageComponent;
