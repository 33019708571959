// import react components
import React, { useEffect, useState } from "react";
// import react router components
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Card from "../../Components/Card";
import Modal from "../../Components/Modal";
import { RiCloseLine } from "react-icons/ri";
import Select from "react-select";
import "../../Styles/React_Select.css";
import TimezoneSelect from "react-timezone-select";
import { account_profile_url } from "../../Components/Urls";
import { put, get } from "../../Components/AxiosUtilities";
import { useSnackbar } from "notistack";
import PreferenceRow from "./PreferenceRow";
import { strCapitalize } from "../../Components/utilities";

function SettingPreferences(props) {
  const { enqueueSnackbar } = useSnackbar();

  // language section
  const languages = ["English"];

  const [selectedLanguage, setSelectedLanguage] = React.useState({
    label: languages[0],
    value: languages[0],
  });

  const [openLanguageModal, setOpenLanguageModal] = React.useState(false);

  // language section ends

  // timezone section start

  const [selectedTimezone, setSelectedTimezone] = React.useState("");

  const [openTimezoneModal, setOpenTimezoneModal] = React.useState(false);

  const [openThemeModal, setOpenThemeModal] = React.useState(false);

  // timezone section end

  const currentDate = new Date().getDate();
  const currentMonth = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear().toString().substring(2);
  const currentFullYear = new Date().getFullYear();

  const currentMonthName = new Date().toLocaleString("default", {
    month: "short",
  });

  const currentHour = new Date().getHours();
  const currentMinutes = new Date().getMinutes();
  const currentSeconds = new Date().getSeconds();

  // date format section
  const dateTimeFormats = [
    "Custom",

    `Gy.MM.dd - R4.${currentMonth < 10 ? "0" : ""}${currentMonth}.${
      currentDate < 10 ? "0" : ""
    }${currentDate}`,

    `M/d/yy - ${currentMonth}/${currentDate}/${currentYear}`,

    `MM-dd-yy - ${currentMonth < 10 ? "0" : ""}${currentMonth}-${
      currentDate < 10 ? "0" : ""
    }${currentDate}-${currentYear}`,

    `MM/dd/yy - ${currentMonth < 10 ? "0" : ""}${currentMonth}/${
      currentDate < 10 ? "0" : ""
    }${currentDate}/${currentYear}`,

    `MMM d, yyyy HH:mm:ss - ${currentMonthName} ${currentDate}, ${currentFullYear} ${
      currentHour < 10 ? "0" : ""
    }${currentHour}:${currentMinutes < 10 ? "0" : ""}${currentMinutes}:${
      currentSeconds < 10 ? "0" : ""
    }${currentSeconds}`,

    `d-M-yy - ${currentDate}-${currentMonth}-${currentYear}`,
    `d.M.yy - ${currentDate}.${currentMonth}.${currentYear}`,
    `d.M.yyyy - ${currentDate}.${currentMonth}.${currentFullYear}`,

    `d.MM.yyyy - ${currentDate}.${
      currentMonth < 10 ? "0" : ""
    }${currentMonth}.${currentFullYear}`,
    `d/M/yy - ${currentDate}/${currentMonth}/${currentYear}`,
    `d/M/yyyy - ${currentDate}/${currentMonth}/${currentFullYear}`,

    `d/MM/yy - ${currentDate}/${
      currentMonth < 10 ? "0" : ""
    }${currentMonth}/${currentYear}`,

    `d-MM-yy - ${currentDate}/${
      currentMonth < 10 ? "0" : ""
    }${currentMonth}/${currentYear}`,

    `d-MM-yyyy - ${currentDate}/${
      currentMonth < 10 ? "0" : ""
    }${currentMonth}/${currentFullYear}`,

    `dd-MM-yy -  ${currentDate < 10 ? "0" : ""}${currentDate}-${
      currentMonth < 10 ? "0" : ""
    }${currentMonth}-${currentYear}`,

    `dd-MM-yy -  ${currentDate < 10 ? "0" : ""}${currentDate}-${
      currentMonth < 10 ? "0" : ""
    }${currentMonth}-${currentFullYear}`,

    `dd.MM.yy -  ${currentDate < 10 ? "0" : ""}${currentDate}.${
      currentMonth < 10 ? "0" : ""
    }${currentMonth}.${currentYear}`,

    `dd.MM.yyyy -  ${currentDate < 10 ? "0" : ""}${currentDate}.${
      currentMonth < 10 ? "0" : ""
    }${currentMonth}.${currentFullYear}`,

    `dd/MM/yy -  ${currentDate < 10 ? "0" : ""}${currentDate}.${
      currentMonth < 10 ? "0" : ""
    }${currentMonth}.${currentYear}`,

    `dd/MM/yyyy - ${currentDate < 10 ? "0" : ""}${currentDate}/${
      currentMonth < 10 ? "0" : ""
    }${currentMonth}/${currentFullYear}`,

    `yy'年'M'月'd'日' - ${currentYear}年${currentMonth}月${currentDate}日`,

    `yy-M-d - ${currentYear}-${currentMonth}-${currentDate}`,

    `yy-MM-dd - ${currentYear}-${currentMonth < 10 ? "0" : ""}${currentMonth}-${
      currentDate < 10 ? "0" : ""
    }${currentDate}`,

    `yy. M. d - ${currentYear}. ${currentMonth}. ${currentDate}`,

    `yy.M.d - ${currentYear}.${currentMonth}.${currentDate}`,

    `yy.d.M - ${currentYear}.${currentDate}.${currentMonth}`,

    `yy/MM/dd - ${currentYear}/${currentMonth < 10 ? "0" : ""}${currentMonth}/${
      currentDate < 10 ? "0" : ""
    }${currentDate}`,

    `yyyy-MM-dd - ${currentFullYear}-${
      currentMonth < 10 ? "0" : ""
    }${currentMonth}-${currentDate < 10 ? "0" : ""}${currentDate}`,

    `yyyy.MM.dd - ${currentFullYear}.${
      currentMonth < 10 ? "0" : ""
    }${currentMonth}.${currentDate < 10 ? "0" : ""}${currentDate}`,

    `yyyy/M/d - ${currentFullYear}/${currentMonth}/${currentDate}`,

    `yyyy/MM/dd - ${currentFullYear}/${
      currentMonth < 10 ? "0" : ""
    }${currentMonth}/${currentDate < 10 ? "0" : ""}${currentDate}`,
  ];

  const [openDateFormatModal, setOpenDateFormatModal] = React.useState(false);

  const [selectedDateFormat, setSelectedDateFormat] = React.useState([]);

  const [customDateFormat, setCustomDateFormat] = React.useState("");

  // date format sectin ends

  // privacy settings section
  const [privacySettings, setPrivacySettings] = React.useState([
    {
      label: "Only registered Vegastack users",
      value: true,
    },
    {
      label: "Only contacts I chat with",
      value: false,
    },
    {
      label: "Only users in my organization",
      value: false,
    },
    {
      label: "Everyone",
      value: false,
    },
    {
      label: "Only myself",
      value: false,
    },
  ]);

  const [selectedPrivacySettings, setSelectedPrivacySettings] = useState({
    label: "",
    value: "",
  });

  const [openPrivacySettingsModal, setOpenPrivacySettingsModal] =
    React.useState(false);
  // privacy settings section ends

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        setOpenDateFormatModal(false);
        setOpenLanguageModal(false);
        setOpenPrivacySettingsModal(false);
        setOpenTimezoneModal(false);
        setOpenThemeModal(false);
      } else if (e.ctrlKey && (e.keyCode === 10 || e.keyCode === 13)) {
        document.getElementById("submit").click();
      }
    };

    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);

  useEffect(() => {
    get(account_profile_url)
      .then((res) => {
        // console.log("profile", res.data);

        setSelectedLanguage({
          label: res.data.language,
          value: res.data.language,
        });

        setSelectedDateFormat({
          label: res.data.date_format,
          value: res.data.date_format,
        });

        setSelectedTimezone(
          res.data.timezone
            ? res.data.timezone
            : Intl.DateTimeFormat().resolvedOptions().timeZone
        );

        setSelectedPrivacySettings({
          label: res.data.settings.privacy_settings,
          value: true,
        });
      })
      .catch((err) => console.log(err));
  }, []);

  const profileSubmitHandler = (e) => {
    e.preventDefault();

    put(account_profile_url, {
      language: selectedLanguage.value,
      timezone: selectedTimezone,
      date_format: selectedDateFormat.hasOwnProperty("value")
        ? selectedDateFormat.value.split(" - ")[0]
        : customDateFormat
        ? customDateFormat
        : "",
      settings: {
        privacy_settings: privacySettings.filter((res) => res.value === true)[0]
          .label,
      },
    })
      .then((res) => {
        // console.log("updated preferences response", res.data);

        setSelectedLanguage({
          label: res.data.language,
          value: res.data.language,
        });

        setSelectedDateFormat({
          label: res.data.date_format,
          value: res.data.date_format,
        });

        setSelectedTimezone(
          res.data.timezone
            ? res.data.timezone
            : Intl.DateTimeFormat().resolvedOptions().timeZone
        );

        setSelectedPrivacySettings({
          label: res.data.settings.privacy_settings,
          value: true,
        });

        enqueueSnackbar("Preferences updated successfully", {
          variant: "success",
          autoHideDuration: 3000,
        });

        setOpenDateFormatModal(false);
        setOpenLanguageModal(false);
        setOpenTimezoneModal(false);
        setOpenPrivacySettingsModal(false);
      })
      .catch((err) => {
        console.log(err);

        enqueueSnackbar("An error occured while updating preferences.", {
          variant: "error",
          autoHideDuration: 3000,
        });
      });
  };

  const currentTheme = localStorage.theme;

  // initialize states variables
  const [theme, setTheme] = useState({
    label: strCapitalize(currentTheme),
    value: currentTheme,
  });

  const handleThemeChange = (result) => {
    // check dark mode is in local storage

    // console.log(result);
    if (result.value === "light") {
      // remove classname dark from html tag
      document.documentElement.classList.remove("dark");

      // save theme mode as light in local storage
      localStorage.setItem("theme", "light");

      setTheme({
        label: "Light",
        value: "light",
      });

      // set theme state as light
    } else if (result.value === "dark") {
      // add classname as dark in html tag
      document.documentElement.classList.add("dark");

      // save theme mode as dark in local storage
      localStorage.setItem("theme", "dark");

      // set theme state as dark
      setTheme({
        label: "Dark",
        value: "dark",
      });
    } else {
      // if theme is dark in localstorage or system default color is dark
      if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
        // add classname as dark in html tag
        document.documentElement.classList.add("dark");

        // save theme mode as dark in local storage
        localStorage.setItem("theme", "system");
      } else {
        // remove classname dark from html tag
        document.documentElement.classList.remove("dark");

        // save theme mode as light in local storage
        localStorage.setItem("theme", "system");
      }

      setTheme({
        label: "System",
        value: "system",
      });
    }
  };

  const themeHandler = (e) => {
    e.preventDefault();

    put(account_profile_url, { theme: theme.value })
      .then((res) => {
        enqueueSnackbar("Theme updated successfully.", {
          variant: "success",
          autoHideDuration: 3000,
        });

        setOpenThemeModal(false);
      })
      .catch((err) => {
        // console.log(err);

        enqueueSnackbar("An error occurred while updating theme.", {
          variant: "error",
          autoHideDuration: 3000,
        });

        setOpenThemeModal(false);
      });
  };

  return (
    <>
      <Card
        id="preferences_div"
        heading="Preferences"
        description="Manage your preferences for language, timezone, date format, and privacy settings."
      >
        <div>
          {/* start language section */}
          <PreferenceRow
            heading="Language"
            selectedValue={selectedLanguage && selectedLanguage.value}
            buttonName="Edit"
            onClick={() => setOpenLanguageModal(true)}
          />

          <Modal
            open={openLanguageModal}
            heading={
              <div className="flex justify-between mb-4 items-center">
                <p className="text-gray-900 dark:text-gray-300">Language</p>

                <button
                  type="button"
                  className="closemodalicon"
                  onClick={() => setOpenLanguageModal(false)}
                >
                  <span className="sr-only">Close</span>
                  <RiCloseLine className="w-6 h-6" aria-hidden="true" />
                </button>
              </div>
            }
          >
            <form onSubmit={profileSubmitHandler}>
              <div className="space-y-4">
                <div>
                  <label htmlFor="date_format" className="form-label">
                    Select Language
                  </label>

                  <Select
                    name="date_format"
                    className="xs:w-full"
                    value={selectedLanguage}
                    onChange={(result) => {
                      setSelectedLanguage(result);
                    }}
                    placeholder="Select Language"
                    options={languages.map((language) => ({
                      label: language,
                      value: language,
                    }))}
                    isSearchable={false}
                    // isDisabled={true}
                  />
                </div>
                <div className="flex items-center mt-4 space-x-3">
                  <button
                    id="submit"
                    type="submit"
                    className="button button-success"
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="text-gray-900 dark:text-gray-300"
                    onClick={() => {
                      setOpenLanguageModal(false);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </Modal>
          {/* end language section */}

          {/* start timezone section */}
          <PreferenceRow
            heading={"Time zone"}
            selectedValue={selectedTimezone && selectedTimezone}
            buttonName={"Edit"}
            onClick={() => setOpenTimezoneModal(true)}
          />

          <Modal
            open={openTimezoneModal}
            heading={
              <div className="flex justify-between mb-4 items-center">
                <p className="text-gray-900 dark:text-gray-300">TimeZone</p>

                <button
                  type="button"
                  className="closemodalicon"
                  onClick={() => setOpenTimezoneModal(false)}
                >
                  <span className="sr-only">Close</span>
                  <RiCloseLine className="w-6 h-6" aria-hidden="true" />
                </button>
              </div>
            }
          >
            <form onSubmit={profileSubmitHandler}>
              <div className="space-y-4">
                <div>
                  <label htmlFor="date_format" className="form-label">
                    Select timezone
                  </label>

                  <TimezoneSelect
                    value={selectedTimezone}
                    onChange={(e) => setSelectedTimezone(e.value)}
                    className="custom_select"
                  />
                </div>

                <div className="flex items-center mt-4 space-x-3">
                  <button
                    id="submit"
                    type="submit"
                    className="button button-success"
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="text-gray-900 dark:text-gray-300"
                    onClick={() => {
                      setOpenTimezoneModal(false);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </Modal>
          {/* end timezone section */}

          {/* start Date format section */}
          <PreferenceRow
            heading={"Date Format"}
            selectedValue={
              selectedDateFormat.hasOwnProperty("label")
                ? selectedDateFormat.label
                : "NA"
            }
            buttonName={"Edit"}
            onClick={() => setOpenDateFormatModal(true)}
          />

          <Modal
            open={openDateFormatModal}
            heading={
              <div className="flex justify-between mb-4 items-center">
                <p className="text-gray-900 dark:text-gray-300">
                  Date and Time Format
                </p>

                <button
                  type="button"
                  className="closemodalicon"
                  onClick={() => setOpenDateFormatModal(false)}
                >
                  <span className="sr-only">Close</span>
                  <RiCloseLine className="w-6 h-6" aria-hidden="true" />
                </button>
              </div>
            }
          >
            <form onSubmit={profileSubmitHandler}>
              <div className="space-y-4">
                <p className="text-sm text-gray-900 dark:text-gray-300">
                  Select a date and time format for your vegastack account.
                </p>

                <div>
                  <label htmlFor="date_format" className="form-label">
                    Date Format
                  </label>
                  <Select
                    name="date_format"
                    className="xs:w-full"
                    value={selectedDateFormat}
                    onChange={(result) => {
                      setSelectedDateFormat(result);
                    }}
                    placeholder="Select Date Format"
                    options={dateTimeFormats.map((format) => ({
                      label: format,
                      value: format,
                    }))}
                    isSearchable={false}
                  />
                </div>

                {selectedDateFormat && selectedDateFormat.value === "Custom" && (
                  <div>
                    <label htmlFor="custom_date_format" className="form-label">
                      Enter the Date Format
                    </label>

                    <input
                      type="text"
                      name="custom_date_format"
                      id="custom_date_format"
                      required={true}
                      placeholder="Enter custom date format"
                      value={customDateFormat}
                      onChange={(e) => setCustomDateFormat(e.target.value)}
                      className="input-field"
                    />
                  </div>
                )}

                <div className="flex items-center mt-4 space-x-3">
                  <button
                    id="submit"
                    type="submit"
                    className="button button-success"
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="text-gray-900 dark:text-gray-300"
                    onClick={() => {
                      setOpenDateFormatModal(false);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </Modal>
          {/* end Date format section */}

          {/* start theme section */}
          <PreferenceRow
            heading={"Theme"}
            selectedValue={theme.label && theme.label}
            buttonName={"Edit"}
            onClick={() => setOpenThemeModal(true)}
          />

          <Modal
            open={openThemeModal}
            heading={
              <div className="flex justify-between mb-4 items-center">
                <p className="text-gray-900 dark:text-gray-300">Theme</p>

                <button
                  type="button"
                  className="closemodalicon"
                  onClick={() => setOpenThemeModal(false)}
                >
                  <span className="sr-only">Close</span>
                  <RiCloseLine className="w-6 h-6" aria-hidden="true" />
                </button>
              </div>
            }
          >
            <form onSubmit={themeHandler}>
              <div className="space-y-4">
                <label htmlFor="theme">
                  <p className="text-sm font-medium text-gray-900 dark:text-gray-300">
                    Select Theme
                  </p>
                </label>
                <div className="sm:col-span-2 w-full">
                  <Select
                    name="theme"
                    value={theme}
                    onChange={(result) => {
                      handleThemeChange(result);
                    }}
                    options={[
                      { label: "Light", value: "light" },
                      { label: "Dark", value: "dark" },
                      { label: "System", value: "system" },
                    ]}
                    className="xs:w-full"
                    isSearchable={false}
                  />
                </div>

                <div className="flex items-center mt-4 space-x-3">
                  <button
                    id="submit"
                    type="submit"
                    className="button button-success"
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="text-gray-900 dark:text-gray-300"
                    onClick={() => {
                      setOpenThemeModal(false);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </Modal>
          {/* end theme section */}

          {/* start privacy settings */}
          <PreferenceRow
            heading={"Privacy Settings"}
            selectedValue={
              selectedPrivacySettings.hasOwnProperty("value")
                ? selectedPrivacySettings.label
                : ""
            }
            buttonName={"Edit"}
            onClick={() => setOpenPrivacySettingsModal(true)}
          />

          <Modal
            open={openPrivacySettingsModal}
            heading={
              <div className="flex justify-between mb-4 items-center">
                <p className="text-gray-900 dark:text-gray-300">
                  Privacy Settings
                </p>

                <button
                  type="button"
                  className="closemodalicon"
                  onClick={() => setOpenPrivacySettingsModal(false)}
                >
                  <span className="sr-only">Close</span>
                  <RiCloseLine className="w-6 h-6" aria-hidden="true" />
                </button>
              </div>
            }
          >
            <form onSubmit={profileSubmitHandler}>
              <div className="space-y-4">
                <p className="text-sm text-gray-900 dark:text-gray-300">
                  Select who can view your profile picture.
                </p>

                {privacySettings.map((field, index) => (
                  <label
                    key={index}
                    className="flex items-center align-middle cursor-pointer"
                  >
                    <input
                      type="radio"
                      className=""
                      checked={field.value}
                      onChange={(e) => {
                        let values = privacySettings.map((option, i) => {
                          if (index === i) {
                            return { ...option, value: e.target.checked };
                          } else {
                            return { ...option, value: false };
                          }
                        });

                        setPrivacySettings(values);
                      }}
                    />
                    <p className="ml-4 text-gray-900 font-weight-bold dark:text-gray-300">
                      {field.label}
                    </p>
                  </label>
                ))}
                <div className="flex items-center mt-4 space-x-3">
                  <button
                    id="submit"
                    type="submit"
                    className="button button-success"
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="text-gray-900 dark:text-gray-300"
                    onClick={() => {
                      setOpenPrivacySettingsModal(false);
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </form>
          </Modal>
          {/* end privacy settings */}

          {/* <div className="flex items-center justify-between ">
                        <div>
                            <p className="text-gray-900 dark:text-white">Newsletter Subscription</p>

                            <p className="mt-1 text-sm text-gray-1300 dark:text-gray-300">
                                You have opted out of our newsletter subscription
                            </p>
                        </div>

                        <button
                            className="inline-flex items-center shadow-sm px-2.5 py-0.5 border-2
                                border-blue-500 text-sm leading-5 font-medium rounded-full text-blue-500 bg-white 
                                dark:bg-gray-900 hover:bg-blue-50 hover:dark:bg-blue-50"
                        >
                            Edit
                        </button>
                    </div> */}
        </div>
      </Card>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setTimeZone: (data) => dispatch({ type: "setTimeZone", ...data }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SettingPreferences));
