import React, { useEffect, useState } from "react";
import { get, Delete, post } from "../../Components/AxiosUtilities";
import {
  connected_accounts_url,
  connect_microsoft_url,
  connect_google_url,
} from "../../Components/Urls";
import ConfirmationModal from "../../Components/ConfirmationModal";
import { RiDeleteBinLine } from "react-icons/ri";
import { strCapitalize } from "../../Components/utilities";
import ConnectedAccountsLoader from "../../Components/Loaders/ConnectedAccountsLoader";
import { useSnackbar } from "notistack";
import { loginRequest } from "../../authConfig";
import { useMsal } from "@azure/msal-react";
// google sign in
import { useGoogleLogin } from "react-google-login";

const { REACT_APP_GOOGLE_APP_ID } = process.env;

const ConnectedAccounts = () => {
  const { instance } = useMsal();
  const { enqueueSnackbar } = useSnackbar();

  const [data, setData] = useState([]);

  const [service, setService] = useState("");
  const [showConfirmation, setShowConfirmation] = useState(false);

  const [showConnectConfirmation, setShowConnectConfirmation] = useState(false);

  const getIdentityProviderLogo = (identityProvider) => {
    if (identityProvider === "microsoft") {
      return (
        <svg
          className="h-4 w-4"
          enableBackground="new 0 0 2499.6 2500"
          viewBox="0 0 2499.6 2500"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="m1187.9 1187.9h-1187.9v-1187.9h1187.9z" fill="#f1511b" />
          <path
            d="m2499.6 1187.9h-1188v-1187.9h1187.9v1187.9z"
            fill="#80cc28"
          />
          <path d="m1187.9 2500h-1187.9v-1187.9h1187.9z" fill="#00adef" />
          <path d="m2499.6 2500h-1188v-1187.9h1187.9v1187.9z" fill="#fbbc09" />
        </svg>
      );
    } else if (identityProvider === "google") {
      return (
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 48 48"
          className="h-4 w-4"
        >
          <g>
            <path
              fill="#EA4335"
              d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"
            ></path>
            <path
              fill="#4285F4"
              d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"
            ></path>
            <path
              fill="#FBBC05"
              d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"
            ></path>
            <path
              fill="#34A853"
              d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"
            ></path>
            <path fill="none" d="M0 0h48v48H0z"></path>
          </g>
        </svg>
      );
    }
  };

  const [loading, setLoading] = useState(false);

  const fetchData = React.useCallback(() => {
    setLoading(true);
    get(connected_accounts_url)
      .then((result) => {
        // console.log(result.data);
        setData(result.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  function handleLogin(instance) {
    setShowConnectConfirmation(false);

    instance
      .loginPopup(loginRequest)
      .then((res) => {
        console.log(res);

        connectMicrosoft(res.accessToken);
      })
      .catch((e) => {
        console.error(e);
      });
  }

  const connectMicrosoft = (token) => {
    post(connect_microsoft_url, {
      access_token: token,
    })
      .then((res) => {
        console.log(res);
        setShowConnectConfirmation(false);

        fetchData();
      })
      .catch((err) => {
        console.log(err);
        setShowConnectConfirmation(false);
      });
  };

  const onSuccess = (res) => {
    post(connect_google_url, {
      access_token: res.accessToken,
    })
      .then((res) => {
        console.log(res);

        enqueueSnackbar(res.data.message, {
          variant: "success",
          autoHideDuration: 3000,
        });
      })
      .catch((err) => {
        console.log(err);

        if (err.response && err.response.data.message) {
          enqueueSnackbar(err.response.data.message, {
            variant: "error",
            autoHideDuration: 3000,
          });
        }
      });
  };

  const onFailure = (res) => {
    console.log("Login failed: res:", res);
  };

  const clientId = REACT_APP_GOOGLE_APP_ID;

  const { signIn } = useGoogleLogin({
    onSuccess,
    onFailure,
    clientId: clientId,
    isSignedIn: false,
    accessType: "offline",
    scope:
      "https://www.googleapis.com/auth/user.organization.read https://www.googleapis.com/auth/user.phonenumbers.read",
  });

  const removeConnection = () => {
    Delete(connected_accounts_url, {
      data: { service: service },
    })
      .then((result) => {
        console.log(result.data);

        setService("");
        setShowConfirmation(false);

        enqueueSnackbar("Identity provider disconnected successfully.", {
          variant: "success",
          autoHideDuration: 3000,
        });
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar(
          "An error occured while disconnecting identity provider.",
          {
            variant: "error",
            autoHideDuration: 3000,
          }
        );
      });
  };

  return (
    <>
      <div className="mt-8" id="connected_accounts">
        <div className="bg-white border border-gray-300 rounded-lg shadow overflow-hidden dark:bg-gray-800 dark:border-gray-600">
          <div className="p-5 bg-gray-100 dark:bg-gray-700 border-b border-gray-200 dark:border-gray-600">
            <h6 className="text-base font-medium text-gray-900 dark:text-white ">
              My Connected Accounts
            </h6>
            <p className="text-sm text-gray-1500 dark:text-gray-3000">
              View and manage all of your connected account.
            </p>
          </div>
          <div className="grid grid-cols-4 gap-2 p-5 xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4">
            {loading && <ConnectedAccountsLoader />}

            {!loading &&
              data.connected?.map((res) => {
                return (
                  <div key={res.userId}>
                    <span
                      className="mb-2 flex items-center text-gray-900 dark:text-gray-300 bg-gray-100 p-2 dark:bg-gray-800 border dark:border-gray-600 border-gray-300 rounded-md cursor-pointer hover:bg-gray-200 hover:dark:bg-gray-700"
                      onClick={() => {
                        setService(res.identityProvider);
                        setShowConfirmation(true);
                      }}
                    >
                      {getIdentityProviderLogo(res.identityProvider)}
                      <span className="pl-2">
                        Disconnect {strCapitalize(res.identityProvider)}
                      </span>
                    </span>
                  </div>
                );
              })}

            {!loading &&
              data.unconnected?.map((res) => {
                return (
                  <div key={res}>
                    <span
                      className="flex items-center text-gray-900 dark:text-gray-300 bg-gray-100 space-x-2 p-2 dark:bg-gray-800 border dark:border-gray-600 border-gray-300 rounded-md cursor-pointer hover:bg-gray-200 hover:dark:bg-gray-700"
                      onClick={() => {
                        setService(res);
                        setShowConnectConfirmation(true);
                      }}
                    >
                      {getIdentityProviderLogo(res)}
                      <span className="pl-2">Connect {strCapitalize(res)}</span>
                    </span>
                  </div>
                );
              })}
          </div>
        </div>
      </div>

      <ConfirmationModal
        open={showConfirmation}
        close={setShowConfirmation}
        modalWidth={true}
        heading={
          <div className="mb-4">
            <p className="text-sm font-medium text-gray-900 dark:text-white">
              Are you sure?
            </p>
            <p className="mt-1 text-sm text-gray-500 dark:text-gray-300">
              This action will disconnect your account from this application and
              redirect.
            </p>
          </div>
        }
        icon={<RiDeleteBinLine className="w-8 h-8 text-red-500" />}
        accept={() => {
          removeConnection();
        }}
      />

      <ConfirmationModal
        open={showConnectConfirmation}
        close={setShowConnectConfirmation}
        modalWidth={true}
        heading={
          <div className="mb-4">
            <p className="text-sm font-medium text-gray-900 dark:text-white">
              Are you sure?
            </p>
            <p className="mt-1 text-sm text-gray-500 dark:text-gray-300">
              This action will connect your account from this application and
              redirect.
            </p>
          </div>
        }
        confirmButtonName="Confirm"
        confirmButtonClassName="button button-success"
        accept={() => {
          if (service === "google") {
            signIn();
            setShowConnectConfirmation(false);
          } else if (service === "microsoft") {
            handleLogin(instance);
          }
        }}
      />
    </>
  );
};

export default ConnectedAccounts;
