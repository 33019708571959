// import react components
import React, { useEffect, useState } from "react";
// import react router components
import { withRouter, useHistory } from "react-router-dom";
import { connect } from "react-redux";
import Card from "../../Components/Card";
import passwordChangeImage from "../../images/passwordChange.png";
import { convertTZ } from "../../Components/TimezoneHandler";
import { format } from "timeago.js";
import Modal from "../../Components/Modal";
import {
  RiCloseLine,
  RiErrorWarningLine,
  RiInformationLine,
} from "react-icons/ri";
import ConfirmationModal from "../../Components/ConfirmationModal";
import {
  account_reset_password,
  last_updated_password_url,
  keycloak_logout_url,
  validate_password_url,
} from "../../Components/Urls";
import { post } from "../../Components/AxiosUtilities";
import { useSnackbar } from "notistack";
import { get } from "../../Components/AxiosUtilities";
import Tooltip from "../../Components/Tooltip";
import { handleShowPassword } from "../../Components/utilities";

const { REACT_APP_KEYCLOAK_FORGOT_PASSWORD_URL } = process.env;

function SecurityPassword(props) {
  const history = useHistory();

  // show alert and close alert functions
  const { enqueueSnackbar } = useSnackbar();

  const [values, setValues] = React.useState({
    current_password: "",
    new_password: "",
    confirm_new_password: "",
  });

  const [openModal, setOpenModal] = React.useState(false);

  const [lastUpdatedPasswordTime, setLastUpdatedPasswordTime] = useState("");

  const [openConfirmationModal, setOpenConfrimationModal] =
    React.useState(false);

  const [passwordError, setPasswordError] = useState("");

  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        setOpenConfrimationModal(false);
        setOpenModal(false);
      } else if (e.ctrlKey && (e.keyCode === 10 || e.keyCode === 13)) {
        document.getElementById("submit").click();
      }
    };

    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);

  useEffect(() => {
    const getLastUpdatedPasswordTime = () => {
      get(last_updated_password_url)
        .then((res) => {
          console.log(res);
          let password_last_updated_at = res.data?.updated_at;

          // if (password_last_updated_at.hasOwnProperty("updated_at")) {
          setLastUpdatedPasswordTime(password_last_updated_at);
          // }
        })
        .catch((err) => console.log(err));
    };

    getLastUpdatedPasswordTime();
  }, []);

  const handleInputChange = (e) => {
    setValues((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    post(account_reset_password, {
      ...values,
    })
      .then((res) => {
        console.log(res.data);

        // let datas = res.data;

        enqueueSnackbar("Password updated successfully.", {
          variant: "success",
          autoHideDuration: 3000,
        });

        setOpenModal(false);

        history.push("/");
      })
      .catch((err) => {
        console.log(err);

        if (err.response && err.response.data.message) {
          enqueueSnackbar(err.response.data.message, {
            variant: "error",
            autoHideDuration: 3000,
          });
        } else {
          enqueueSnackbar("An error occured while updating password.", {
            variant: "error",
            autoHideDuration: 3000,
          });
        }
      });
  };

  const validateNewPassword = () => {
    var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{10,}$/;

    if (!re.test(values.new_password)) {
      setPasswordError(
        "Password should include at least 10 characters with 1 symbols(s), 1 numbers(s), both upper and lower case letters."
      );
    } else {
      setPasswordError("");
    }
  };

  const [matchPasswordError, setMatchPasswordError] = useState("");

  const checkPasswordMatch = () => {
    if (values.confirm_new_password !== values.new_password) {
      setMatchPasswordError("Password don't matched.");
    } else {
      setMatchPasswordError("");
    }
  };

  // function to logout the user
  const handleLogout = () => {
    post(keycloak_logout_url)
      .then((res) => {
        // remove all data from redux
        props.logoutUser();

        // redirect the user to keycloak forgot password page
        window.location.href = REACT_APP_KEYCLOAK_FORGOT_PASSWORD_URL;
      })
      .catch((err) => {
        console.log(err);

        enqueueSnackbar("logout request failed, Please try again.", {
          variant: "error",
          autoHideDuration: 3000,
        });
      });
  };

  const validateCurrentPassword = () => {
    post(validate_password_url, {
      current_password: values.current_password,
    })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <>
      {/* password section starts */}
      <Card
        id={"change_password_div"}
        heading={"Password"}
        description={`The security policy set by your organization requires 
                you to change your password every 60 days.`}
      >
        <div className="space-y-4 text-center">
          <img
            className="mx-auto my-4 w-28 h-28"
            src={passwordChangeImage}
            alt="Change Password"
          />

          <p className="font-medium text-gray-900 dark:text-gray-300">
            Last modified{" "}
            {lastUpdatedPasswordTime ? (
              <Tooltip
                placement="top"
                trigger="hover"
                tooltip={convertTZ(lastUpdatedPasswordTime, props.timezone)}
              >
                {format(convertTZ(lastUpdatedPasswordTime, props.timezone))}
              </Tooltip>
            ) : (
              "NA"
            )}
          </p>

          <button
            className="button button-success"
            onClick={() => setOpenModal(true)}
          >
            Change Password
          </button>
        </div>
      </Card>

      <Modal
        open={openModal}
        heading={
          <div className="flex justify-between mb-4 items-center">
            <p className="text-gray-900 dark:text-gray-300">Password</p>

            <button
              type="button"
              className="closemodalicon"
              onClick={() => setOpenModal(false)}
            >
              <span className="sr-only">Close</span>
              <RiCloseLine className="w-6 h-6" aria-hidden="true" />
            </button>
          </div>
        }
      >
        <form onSubmit={handleFormSubmit} className="space-y-6">
          <p className="text-sm font-medium text-gray-900 dark:text-gray-300">
            Set a strong password to prevent unauthorized access to your
            account.
          </p>
          <div>
            <label htmlFor="current_password" className="form-label">
              Current Password
            </label>

            <div className="flex items-center justify-between space-x-4 xs:flex-col xs:space-x-0">
              <div className="relative w-full">
                <input
                  type="password"
                  name="current_password"
                  id="current_password"
                  required={true}
                  placeholder="Enter current password"
                  value={values.current_password}
                  onChange={handleInputChange}
                  className="w-full input-field"
                  onBlur={validateCurrentPassword}
                />
              </div>

              <p
                className="w-full text-sm font-medium text-blue-500 cursor-pointer xs:mt-4"
                onClick={() => setOpenConfrimationModal(true)}
              >
                Forgot Password?
              </p>

              {/* confirmation section */}

              <ConfirmationModal
                open={openConfirmationModal}
                close={setOpenConfrimationModal}
                modalWidth={true}
                heading={
                  <div className="mb-4">
                    <p className="text-sm font-medium text-gray-900 dark:text-white">
                      Are you sure?
                    </p>
                    <p className="mt-1 text-sm text-gray-500 dark:text-gray-300">
                      This action will logout you from this application and
                      redirect you to forgot passowrd page.
                    </p>
                  </div>
                }
                confirmButtonName={"Confirm"}
                confirmButtonClassName="button button-success"
                accept={handleLogout}
              />

              {/* end confirmation section */}
            </div>
          </div>

          <div>
            <div className="flex items-center">
              <label htmlFor="new_password" className="form-label">
                New Password
              </label>
              <Tooltip
                placement="top"
                trigger="hover"
                tooltip="
                      Include at least 10 characters with 1 symbols(s), 1 numbers(s), both
                      upper and lower case letters."
              >
                <RiInformationLine className="dark:text-white text-black ml-1 mb-2" />
              </Tooltip>
            </div>

            <div className="flex items-center justify-between space-x-4 xs:space-x-0">
              <div className="w-full relative">
                <input
                  type="password"
                  name="new_password"
                  id="new_password"
                  required={true}
                  placeholder="Enter new password"
                  value={values.new_password}
                  onChange={handleInputChange}
                  onBlur={validateNewPassword}
                  onFocus={() => {
                    setPasswordError("");
                  }}
                  className={`${
                    passwordError !== "" ? "error-input-field" : "input-field"
                  }`}
                />

                {passwordError !== "" && (
                  <div className="absolute flex inset-y-0 right-0 pr-3 items-center pointer-events-none">
                    <RiErrorWarningLine
                      className="text-red-500"
                      aria-hidden="true"
                    />
                  </div>
                )}
              </div>
              <div className="w-full xs:hidden"></div>
            </div>

            <div className="flex items-center space-x-3 mt-2">
              <input
                type="checkbox"
                className="checkbox"
                onClick={() => {
                  handleShowPassword("new_password");
                }}
              />
              <label className="form-label pt-2">Show password</label>
            </div>
            {passwordError !== "" && (
              <div className="flex justify-between mt-1 -mb-2 xs:-mb-0">
                <span className="text-sm text-red-600">{passwordError}</span>
                <div className="w-full xs:hidden"></div>
              </div>
            )}
          </div>

          <div>
            <label htmlFor="new_password" className="form-label">
              Confirm New Password
            </label>

            <div className="flex items-center justify-between space-x-4 xs:space-x-0">
              <div className="relative w-full">
                <input
                  type="password"
                  name="confirm_new_password"
                  id="confirm_new_password"
                  required={true}
                  placeholder="Enter confirm new password"
                  value={values.confirm_new_password}
                  onChange={handleInputChange}
                  onBlur={checkPasswordMatch}
                  onFocus={() => {
                    setMatchPasswordError("");
                  }}
                  className={`${
                    matchPasswordError !== ""
                      ? "error-input-field"
                      : "input-field"
                  }`}
                />

                {matchPasswordError !== "" && (
                  <div className="absolute flex inset-y-0 right-0 pr-3 items-center pointer-events-none">
                    <RiErrorWarningLine
                      className="text-red-500"
                      aria-hidden="true"
                    />
                  </div>
                )}
              </div>

              <div className="w-full xs:hidden"></div>
            </div>
            <span className="text-sm text-red-600">{matchPasswordError}</span>
          </div>

          <button
            id="submit"
            type="submit"
            className={`button
                        ${
                          Object.values(values).includes("") ||
                          matchPasswordError !== "" ||
                          passwordError !== ""
                            ? "button-success-disabled"
                            : "button-success"
                        }`}
            disabled={
              Object.values(values).includes("") ||
              matchPasswordError !== "" ||
              passwordError !== ""
                ? true
                : false
            }
          >
            Change Password
          </button>
        </form>
      </Modal>
      {/* password section ends */}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

const mapDispatchToProps = (dispatch) => ({
  logoutUser: () => dispatch({ type: "logoutUser" }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(SecurityPassword));
