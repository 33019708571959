import React from "react";

export default function PreferenceRow({
  heading,
  selectedValue,
  buttonName,
  onClick = () => {},
}) {
  const [style, setStyle] = React.useState({ display: "none" });

  return (
    <div
      className="flex items-center justify-between hover:bg-gray-200 dark:hover:bg-gray-600
                hover:cursor-pointer px-5 py-4"
      onMouseOver={(e) => {
        setStyle({ display: "block" });
      }}
      onMouseLeave={(e) => {
        setStyle({ display: "none" });
      }}
      onClick={onClick}
    >
      <div>
        <p className="text-gray-900 dark:text-white">{heading}</p>

        <p className="mt-1 text-sm text-gray-1300 dark:text-gray-1500">
          {selectedValue ? (
            selectedValue
          ) : (
            <span className="opacity-0">empty</span>
          )}
        </p>
      </div>

      <span className="text-blue-500 px-4" style={style}>
        {buttonName}
      </span>
    </div>
  );
}
