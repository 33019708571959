// import react component
import { useState } from "react";
// import react icons
import { RiCloseLine, RiSearchLine } from "react-icons/ri";

function SearchInput(props) {
  // search state for this page
  const [searchInput, setSearchInput] = useState("");

  // funtion which calls props search handler
  // when user hit enter key it calls props
  const searchSubmitHandler = (e) => {
    var key = e.which;
    // the enter key code
    if (props.hasOwnProperty("enterToSearch") && key === 13) {
      e.preventDefault();
      // setSearchQuery(e.target.value);
      props.searchHandler(e.target.value);
      return false;
    } else {
      e.preventDefault();
      // setSearchQuery(e.target.value);
      props.searchHandler(e.target.value);
      return false;
    }
  };

  return (
    <div className="w-full">
      <label htmlFor="search" className="sr-only">
        Search
      </label>
      <div className="relative flex justify-between">
        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          <RiSearchLine
            className="w-5 h-5 text-black dark:text-white text-opacity-30 dark:text-opacity-30"
            aria-hidden="true"
          />
        </div>
        <input
          id="searchInput"
          name="searchInput"
          className="searchinput"
          placeholder={props.placeholder ? props.placeholder : "Search"}
          type="text"
          value={searchInput}
          onChange={(e) => {
            setSearchInput(e.target.value);
          }}
          onKeyUp={searchSubmitHandler}
          required={false}
        />
        {searchInput !== "" && (
          <div className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer">
            <button
              className="w-6 h-6 text-black dark:text-white text-opacity-30 dark:text-opacity-30"
              aria-hidden="true"
              type="button"
              onClick={(e) => {
                setSearchInput("");
                props.searchHandler("");
              }}
            >
              <RiCloseLine
                className="w-5 h-5 text-black dark:text-white text-opacity-30 dark:text-opacity-30"
                aria-hidden="true"
              />
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

export default SearchInput;
