import React from "react";
// import react route
import { Route, Redirect, withRouter } from "react-router-dom";
// import react redux
import { connect } from "react-redux";


// function to redirect to home page if user already logged in
function RedirectIfAuthComponent({ component: Component, ...state }) {
  // console.log(state.isAuthenticated , state.permission, state.user_type , state.organization_uid !== "");

  return (
    <Route
      {...state}
      render={(props) => {
        // check user is authenticated and if user has permission
        // if user has permission redirect to home page

        if (
          state.isAuthenticated &&
          state.permission.includes(state.user_type) &&
          state.organization_uid !== ""
        ) {
          return (
            <Redirect
              to={{
                pathname: "/profile/personal",
                state: {
                  showAlreadyLoggedInAlert: true,
                },
              }}
            />
          );
        }
        // else redirect to login page
        else {
          return <Component {...state} />;
        }
      }}
    />
  );
}

function ProtectedRoute({ component: Component, ...state }) {
  // console.log('redux state response', state);

  return (
    <Route
      {...state}
      render={(props) => {
        if (
          state.isAuthenticated
          // state.isAuthenticated &&
          // state.permission.includes(state.user_type) &&
          // state.organization_uid !== ""
        ) {
          return <Component {...state} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: "/",
              }}
            />
          );
        }
      }}
    />
  );
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getUserAuth: () => dispatch({ type: "getUserAuth" }),
  logoutUser: () => dispatch({ type: "logoutUser" }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ProtectedRoute));


export const RedirectIfAuth = connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(RedirectIfAuthComponent));
