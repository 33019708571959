import * as React from "react";
import { createPortal } from "react-dom";
import { usePopperTooltip } from "react-popper-tooltip";
import "react-popper-tooltip/dist/styles.css";

const canUseDOM = Boolean(
  typeof window !== "undefined" &&
    window.document &&
    window.document.createElement
);

const mutationObserverDefaults = {
  childList: true,
  subtree: true,
};

function TooltipTrigger({
  children,
  // Some defaults changed in the hook implementation.
  // For backward compatibility we have to override them here.
  closeOnReferenceHidden = true,
  defaultTooltipShown,
  getTriggerRef,
  modifiers,
  mutationObserverOptions = mutationObserverDefaults,
  onVisibilityChange,
  placement = "top",
  portalContainer = canUseDOM ? document.body : null,
  tooltip,
  tooltipShown,
  usePortal = canUseDOM,
  ...restProps
}) {
  const {
    triggerRef,
    getArrowProps,
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
    visible,
    state,
  } = usePopperTooltip(
    {
      // Some props renamed in the hook implementation.
      defaultVisible: defaultTooltipShown,
      onVisibleChange: onVisibilityChange,
      visible: tooltipShown,
      closeOnTriggerHidden: closeOnReferenceHidden,
      ...restProps,
    },
    {
      placement,
      modifiers,
    }
  );

  const reference = children({
    // No longer required, for backward compatibility.
    getTriggerProps: (props) => props,
    triggerRef: setTriggerRef,
  });

  const popper = tooltip({
    tooltipRef: setTooltipRef,
    getArrowProps,
    getTooltipProps,
    placement: state ? state.placement : undefined,
  });

  React.useEffect(() => {
    if (typeof getTriggerRef === "function") getTriggerRef(triggerRef);
  }, [triggerRef, getTriggerRef]);

  return (
    <>
      {reference}
      {visible
        ? usePortal
          ? createPortal(popper, portalContainer)
          : popper
        : null}
    </>
  );
}

const Tooltip = ({ children, tooltip, hideArrow, ...props }) => {
  return (
    <TooltipTrigger
      {...props}
      tooltip={({
        arrowRef,
        tooltipRef,
        getArrowProps,
        getTooltipProps,
        placement,
      }) => (
        <div
          {...getTooltipProps({
            ref: tooltipRef,
            className:
              "tooltip-container-custom absolute z-50 p-2 text-xs leading-tight text-white dark:text-gray-900 text-center bg-gray-800 dark:bg-white rounded-lg max-w-xs",
          })}
        >
          {!hideArrow && (
            <div
              {...getArrowProps({
                ref: arrowRef,
                className: "tooltip-arrow -bottom-2",
                "data-placement": placement,
              })}
            >
              <svg className="w-6 h-6" width="8" height="8">
                <rect
                  className="text-gray-800 fill-current dark:text-gray-100"
                  x="12"
                  y="-10"
                  width="8"
                  height="8"
                  transform="rotate(45)"
                />
              </svg>
            </div>
          )}
          {tooltip}
        </div>
      )}
    >
      {({ getTriggerProps, triggerRef }) => (
        <span
          {...getTriggerProps({
            ref: triggerRef,
            className: "trigger cursor-pointer",
          })}
        >
          {children}
        </span>
      )}
    </TooltipTrigger>
  );
};

export default Tooltip;
