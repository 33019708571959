import React from 'react';
// import vegamaster logo component
import VegastackLogo from "../Components/VegastackLogo";
// import react icons
import { RiArrowRightSLine, RiBuilding4Line } from "react-icons/ri";
// import react router component
import { Link, withRouter } from "react-router-dom";
// import redux
import { connect } from "react-redux";
// import axios
import { get } from "../Components/AxiosUtilities";
import { account_user_data_url } from '../Components/Urls';


function SwitchOrganizationPage(props) {

  const { logoutUser } = props;

  // handle component update
  React.useEffect(() => {
    const getUserData = () => {
      get(account_user_data_url)
        .then((res) => {
          // console.log("response", res);

          // if (props.hasOwnProperty("handleTimezone")) {
          //   props.handleTimezone(res.data.timezone);
          // }

        })
        .catch((err) => {
          // console.log(err);

          // if user is unathenticated logout the user
          logoutUser();
        });
    };

    // function to get user data
    getUserData();
  }, [logoutUser]);
  // values.timezone


  return (
    <div className="flex flex-col items-center justify-center min-h-screen py-12 bg-gray-50 dark:bg-gray-900 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <VegastackLogo className="ap-logo" />

        {/* <h2 className="ap-heading">Select Organization Account</h2> */}
      </div>

      <div className="mt-8 overflow-hidden border border-gray-400 md:w-2/4 sm:rounded-md">
        <ul className="divide-y divide-gray-200 dark:divide-gray-500">
          <li key={"create_organization_account"}>
            <Link
              onClick={(e) => e.preventDefault()}
              className="block bg-gray-200 dark:bg-gray-600"
            >
              <div className="flex items-center px-4 py-4 sm:px-6">
                <div className="flex items-center flex-1 min-w-0">
                  <div className="flex-1 min-w-0 px-4">
                    <div className="w-full">
                      <p className="text-lg font-medium text-gray-900 truncate dark:text-gray-300">
                        Select Account
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <button
                    onClick={() =>
                      props.history.push({
                        pathname: "/onboard/organization/create/",
                      })
                    }
                    type="button"
                    className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-blue-500 border border-transparent rounded shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-200 dark:focus:ring-offset-gray-600 focus:ring-blue-500"
                  >
                    Create Organization Account
                  </button>
                </div>
              </div>
            </Link>
          </li>

          {props.organizations.map((organization) => (
            <li key={organization.organization_uid}>
              <Link
                // to={organization.status ? '' : `/onboard/organization/${organization.uid}/connection/`}
                className="block bg-white dark:bg-gray-800"
                onClick={(e) => {
                  e.preventDefault();

                  props.setUserAuth(organization);
                  // props.setOrganizationUID({ organization_uid: organization.organization_uid });

                  if (organization.status) {
                    // redirect to home page
                    props.history.push("/profile/personal");
                  } else {
                    if (
                      organization.hasOwnProperty("redirect") &&
                      organization.redirect === true
                    ) {
                      // redirect to gitlab access page
                      props.history.push(`/admin/gitlab_access`);
                    } else {
                      // redirect to connection page
                      props.history.push(`/onboard/organization/connection/`);
                    }
                  }
                }}
              >
                <div className="flex items-center px-4 py-4 sm:px-6">
                  <div className="flex items-center flex-1 min-w-0">
                    <div className="flex-shrink-0">
                      <RiBuilding4Line className="w-12 h-6 text-gray-500 dark:text-gray-300" />
                    </div>
                    <div className="flex-1 min-w-0 px-4">
                      <div className="w-full">
                        <p className="text-sm font-medium text-gray-900 truncate dark:text-gray-300">
                          {organization.name}
                        </p>
                        <p className="flex items-center mt-1 text-sm text-gray-500 dark:text-gray-400">
                          {/* <MailIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-500" aria-hidden="true" /> */}
                          <span className="truncate">
                            {organization.organization_email}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <RiArrowRightSLine
                      className="w-5 h-5 text-gray-500 dark:text-gray-300"
                      aria-hidden="true"
                    />
                  </div>
                </div>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => {
  return {
    // dispatching plain actions
    logoutUser: () => dispatch({ type: "logoutUser" }),
    setUserAuth: (data) => dispatch({ type: "setUserAuth", ...data }),
    setOrganizationUID: (data) => dispatch({ type: "setOrganizationUID", ...data }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SwitchOrganizationPage));
