import React from "react";
import { RiCloseLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import CustomSlideOver from "./CustomSlideOver";
import { announcementTypes, socialHandles } from "./utilities";


const AnnouncementSlider = (props) => {
  return (
    <CustomSlideOver
      Open={props.open}
      Close={() => { }}
      customCss={
        "w-screen max-w-sm xs:max-w-sm bg-white shadow-xl dark:bg-gray-800 overflow-y-auto"
      }
    >
      <div className="flex justify-end pt-4 px-4">
        <button
          type="button"
          className="closeSlideOvericon "
          style={{
            background: "none",
          }}
          onClick={() => {
            props.Close();
          }}
        >
          <span className="sr-only">Close panel</span>
          <RiCloseLine className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>

      <div className="px-4 grid grid-cols-3 xs:grid-cols-3 align-middle py-6">
        {announcementTypes.map((res) => {
          const Icon = res.icon;
          return (
            <button
              key={res.index}
              className="hover:bg-blue-50 dark:hover:bg-gray-500 rounded py-4 px-1"
            >
              <div
                className={`${res.bgColor} w-12 mx-auto rounded-2xl p-3  mb-2`}
              >
                <Icon
                  className={`${res.iconColor} w-6 h-6 rounded mx-auto`}
                />
              </div>
              <p
                className={`text-md mt-4 font-medium text-gray-900 dark:text-gray-300`}
              >
                {res.title}
              </p>
            </button>
          );
        })}
      </div>
      <div className="p-4 mx-2 flex items-center">
        <p className="font-md text-gray-1500 pt-2 text-md dark:text-gray-100">
          ANNOUNCEMENTS
        </p>
        <p className="border-t-2 w-full mt-8 mx-2 dark:border-gray-600 border-gray-300">
          &nbsp;
        </p>
      </div>
      <div className="mx-2 px-4">
        <div>
          <p className="text-md text-gray-900 dark:text-gray-300">
            Instagram DM and Alt Text on Zoho Social
          </p>
          <p className="text-sm text-gray-1300 dark:text-gray-1500">
            You ipsum dolor sit amet consectetur, adipisicing elit. Est, amet
            optio amet sit beataeje...{" "}
            <Link
              to="/#"
              className="text-sm font-md text-gray-1300 dark:text-gray-1500"
            >
              Read More
            </Link>
          </p>
          <div className="flex items-center mt-3">
            <span className="text-sm text-gray-1500 pt-2 dark:text-gray-400">
              Jul 20, 2021
            </span>
            <p className="text-xs bg-pink-100 py-1 mt-1 px-2 rounded ml-4 font-medium text-pink-600">
              ANNOUNCEMENT
            </p>
          </div>
        </div>
        <div className="my-4 pt-4">
          <p className="text-md text-gray-900 dark:text-gray-300">
            Instagram DM and Alt Text on Zoho Social
          </p>
          <p className="text-sm text-gray-1300 dark:text-gray-1500">
            You ipsum dolor sit amet consectetur, adipisicing elit. Est, amet
            optio amet sit beataeje...{" "}
            <Link
              to="/#"
              className="text-sm font-md text-gray-1300 dark:text-gray-1500"
            >
              Read More
            </Link>
          </p>
          <div className="flex items-center mt-3">
            <span className="text-sm text-gray-1500 pt-2 dark:text-gray-400">
              Jul 20, 2021
            </span>
            <p className="text-xs bg-indigo-100 py-1 mt-1 px-2 rounded ml-4 font-medium text-indigo-600">
              FORUM
            </p>
          </div>
        </div>
        <div className="my-4 pt-4">
          <p className="text-md text-gray-900 dark:text-gray-300">
            Instagram DM and Alt Text on Zoho Social
          </p>
          <p className="text-sm text-gray-1300 dark:text-gray-1500">
            You ipsum dolor sit amet consectetur, adipisicing elit. Est, amet
            optio amet sit beataeje...
            <Link
              to="/#"
              className="text-sm font-md text-gray-1300 dark:text-gray-1500"
            >
              Read More
            </Link>
          </p>
          <div className="flex items-center mt-3">
            <span className="text-sm text-gray-1500 pt-2 dark:text-gray-400">
              Feb 12, 2022
            </span>
            <p className="text-xs bg-yellow-100 py-1 mt-1 px-2 rounded ml-4 font-medium text-yellow-600">
              JOURNAL
            </p>
          </div>
        </div>
      </div>
      <footer className="flex flex-col items-center border-t mt-8 py-4 border-gray-300 dark:border-gray-600 xs:flex-col">
        <h1 className="font-md text-gray-1500 dark:text-gray-100 ml-4 px-4 py-4">
          STAY IN TOUCH WITH US
        </h1>
        <div className="flex items-center">
          {socialHandles.map((item) => {
            const Icon = item.icon;
            return (
              <div
                key={item.index}
                className={`h-8 w-8 mx-2 rounded-full ${item.color}`}
              >
                <Icon className={`h-6 w-6 mx-1 mt-1 cursor-pointer`} />
              </div>
            );
          })}
        </div>
      </footer>
    </CustomSlideOver>
  );
};

export default AnnouncementSlider;
