// import use previous function
import { setInitialTheme } from "../Components/utilities";
// import react router dom component
import { Link } from "react-router-dom";

// set theme function
setInitialTheme();

// 404 page not found page
function PageNotFound(props) {
  // return jsx elements
  return (
    <div className="max-w-lg px-4 mx-auto text-center">
      <svg viewBox="0 0 500 500" xmlns="http://www.w3.org/2000/svg">
        <g fill="#06f">
          <path d="M147.68,287.64H86.83V260.17l60.85-72.34H176.8v73.9h15.09v25.91H176.8v22.48H147.68Zm0-25.91V223.89l-32.16,37.84Z" />
          <path d="m202.3 249.51q0-34.29 12.34-48t37.61-13.7q12.13 0 19.93 3a36.79 36.79 0 0 1 12.71 7.79 41.59 41.59 0 0 1 7.75 10.09 52.38 52.38 0 0 1 4.55 12.34 115.36 115.36 0 0 1 3.36 28q0 32.72-11.07 47.89t-38.13 15.18q-15.18 0-24.53-4.84a39.76 39.76 0 0 1-15.33-14.19q-4.35-6.64-6.77-18.17a124.33 124.33 0 0 1-2.42-25.39zm33.14 0.08q0 23 4.05 31.37t11.77 8.41a12.34 12.34 0 0 0 8.82-3.57q3.74-3.57 5.5-11.28t1.76-24q0-23.94-4.06-32.19t-12.18-8.24q-8.28 0-12 8.41t-3.66 31.09z" />
          <path d="M371.74,287.64H310.89V260.17l60.85-72.34h29.12v73.9H416v25.91H400.86v22.48H371.74Zm0-25.91V223.89l-32.15,37.84Z" />
        </g>
        <g fill="#06f" opacity=".3">
          <path d="m201 145.62a1.87 1.87 0 1 1-1.86-1.87 1.86 1.86 0 0 1 1.86 1.87z" />
          <circle cx="72.97" cy="216.13" r="1.32" />
          <circle cx="291.05" cy="408.33" r="1.89" />
          <circle cx="336.5" cy="332" r="1.32" />
          <path d="m424.17 95.62a1.32 1.32 0 1 1-1.32-1.32 1.32 1.32 0 0 1 1.32 1.32z" />
          <path d="m172.75 69a1.32 1.32 0 1 1-1.32-1.32 1.33 1.33 0 0 1 1.32 1.32z" />
          <circle cx="277.7" cy="136.94" r="1.32" />
        </g>
        <circle cx="141.23" cy="116.36" r="21.91" fill="#06f" />
        <circle cx="141.23" cy="116.36" r="21.91" fill="#fff" opacity=".7" />
        <path
          d="m133.68 99.83a21.84 21.84 0 0 0-8.68 1.77 21.92 21.92 0 0 0 24.87 34.89 21.92 21.92 0 0 0-16.23-36.65z"
          fill="#06f"
          opacity=".2"
        />
        <path
          d="m131.5 105.62a2 2 0 1 1-2-2 2 2 0 0 1 2 2z"
          fill="#06f"
          opacity=".2"
        />
        <path
          d="m155.06 103.62a2 2 0 1 1-2-2 2 2 0 0 1 2 2z"
          fill="#06f"
          opacity=".2"
        />
        <path
          d="m151.06 117.9a3.28 3.28 0 1 1-3.28-3.28 3.28 3.28 0 0 1 3.28 3.28z"
          fill="#06f"
          opacity=".2"
        />
        <path
          d="m140.64 127.25a4.38 4.38 0 1 1-4.38-4.38 4.38 4.38 0 0 1 4.38 4.38z"
          fill="#06f"
          opacity=".2"
        />
        <circle
          transform="translate(-71.8 661.78) rotate(-76.72)"
          cx="382.2"
          cy="376.25"
          r="19.23"
          fill="#06f"
        />
        <circle
          transform="translate(-71.8 661.78) rotate(-76.72)"
          cx="382.2"
          cy="376.25"
          r="19.23"
          fill="#fff"
          opacity=".3"
        />
        <path
          d="M394.33,361.34a19.22,19.22,0,0,0-17.67,33.32,19,19,0,0,0,5.53.82,19.23,19.23,0,0,0,12.14-34.14Z"
          fill="#06f"
          opacity=".4"
        />
        <path
          d="m363.83 382c-20.53 9.66-5.22 17.11 23.71 6.71 26.79-9.63 37-21.77 13-18 1.29 5.05-32.26 18.12-36.71 11.29z"
          fill="#06f"
        />
        <path
          d="M394.1,187.83C367.21,206,332.4,230,322.79,287.64h-2.05c9.35-57,42.89-81.57,69.79-99.81Z"
          opacity=".2"
        />
        <path
          d="M255,368.27c-17,0-33.81-7.67-42-20.19-5.05-7.74-10.92-23.95,6.56-45.58l1.55,1.26c-12.36,15.3-14.64,30.65-6.43,43.23,10,15.3,33.59,23,53.73,17.52,20.63-5.61,33.15-23.55,34.36-49.22,4.13-87.81,50.78-114.86,84.84-134.61,21.17-12.27,36.46-21.13,33.1-39.84-.47-2.59-1.5-4.38-3.17-5.48-4.35-2.87-12.85-.88-22.69,1.41-19.31,4.5-45.75,10.66-61.5-16.13l1.73-1c15,25.53,39.57,19.8,59.32,15.2,10.29-2.39,19.17-4.46,24.24-1.13,2.15,1.41,3.47,3.64,4,6.8,3.61,20.08-13,29.72-34.05,41.92-33.67,19.52-79.77,46.25-83.85,133-1.26,26.6-14.32,45.21-35.84,51.06A52.88,52.88,0,0,1,255,368.27Z"
          fill="#06f"
        />
        <path
          d="M255,368.27c-17,0-33.81-7.67-42-20.19-5.05-7.74-10.92-23.95,6.56-45.58l1.55,1.26c-12.36,15.3-14.64,30.65-6.43,43.23,10,15.3,33.59,23,53.73,17.52,20.63-5.61,33.15-23.55,34.36-49.22,4.13-87.81,50.78-114.86,84.84-134.61,21.17-12.27,36.46-21.13,33.1-39.84-.47-2.59-1.5-4.38-3.17-5.48-4.35-2.87-12.85-.88-22.69,1.41-19.31,4.5-45.75,10.66-61.5-16.13l1.73-1c15,25.53,39.57,19.8,59.32,15.2,10.29-2.39,19.17-4.46,24.24-1.13,2.15,1.41,3.47,3.64,4,6.8,3.61,20.08-13,29.72-34.05,41.92-33.67,19.52-79.77,46.25-83.85,133-1.26,26.6-14.32,45.21-35.84,51.06A52.88,52.88,0,0,1,255,368.27Z"
          fill="#fff"
          opacity=".2"
        />
        <path
          d="M312.76,97a46.05,46.05,0,0,1,13.58,2.13s11,18.77,12.3,23.07c-.46,4.24-7.61,11.19-7.61,11.19Z"
          fill="#06f"
        />
        <path
          d="M312.76,97a46.05,46.05,0,0,1,13.58,2.13s11,18.77,12.3,23.07c-.46,4.24-7.61,11.19-7.61,11.19Z"
          fill="#fff"
          opacity=".3"
        />
        <path
          d="M345.34,188.13a141.41,141.41,0,0,1-11.56-16.38q-1.26-2.17-2.39-4.42c-.43-.85-.84-1.7-1.24-2.56a10.76,10.76,0,0,1-1.21-2.69c-1.2-12.67,3.14-22-1-32.17l-16.48,6.44s1.4,18.12,4.6,29c2,6.73,6.48,12.55,10.81,17.94,1.35,1.68,2.65,3.41,4,5.1s2.71,3.06,4,4.65c1.95,2.41,2.59,4.72,1.12,7.56l-.25.45c-.42.74,1.54,1.58,2.78,0,2-2.58,1.72-2.42,3.46-4.62,1.06-1.33,2.27-2.78,3.32-4A3.37,3.37,0,0,0,345.34,188.13Z"
          fill="#06f"
        />
        <path
          d="M345.34,188.13a141.41,141.41,0,0,1-11.56-16.38q-1.26-2.17-2.39-4.42c-.43-.85-.84-1.7-1.24-2.56a10.76,10.76,0,0,1-1.21-2.69c-1.2-12.67,3.14-22-1-32.17l-16.48,6.44s1.4,18.12,4.6,29c2,6.73,6.48,12.55,10.81,17.94,1.35,1.68,2.65,3.41,4,5.1s2.71,3.06,4,4.65c1.95,2.41,2.59,4.72,1.12,7.56l-.25.45c-.42.74,1.54,1.58,2.78,0,2-2.58,1.72-2.42,3.46-4.62,1.06-1.33,2.27-2.78,3.32-4A3.37,3.37,0,0,0,345.34,188.13Z"
          fill="#fff"
          opacity=".7"
        />
        <path
          d="M341.31,182.92a54.69,54.69,0,0,1-8.66,7.52c.43.48.85,1,1.28,1.46a43.92,43.92,0,0,0,8.5-7.51Z"
          fill="#06f"
          opacity=".3"
        />
        <path
          d="m345.34 188.13-0.12-0.14a5.18 5.18 0 0 0-1.27 3.17 5 5 0 0 0 0.38 2.35l0.95-1.13a3.37 3.37 0 0 0 0.06-4.25z"
          fill="#06f"
          opacity=".3"
        />
        <path
          d="M308.84,109a35.38,35.38,0,0,1-6.37,7.19,23.27,23.27,0,0,1-4.42,3,19,19,0,0,1-2.58,1.09l-.68.22-.22.06-.47.13a5.93,5.93,0,0,1-.88.14,7.55,7.55,0,0,1-2.51-.23,12.24,12.24,0,0,1-2.94-1.27,25,25,0,0,1-2.15-1.41,40.31,40.31,0,0,1-3.58-3,53.16,53.16,0,0,1-6-6.74,2.51,2.51,0,0,1,3.35-3.62l.08,0c2.36,1.5,4.74,3.08,7.06,4.49,1.18.69,2.32,1.39,3.45,1.93a15.29,15.29,0,0,0,1.59.72,3.12,3.12,0,0,0,1.07.26c.06,0,0-.07-.37-.06a2.93,2.93,0,0,0-.35,0l-.22.05,0,0,.33-.17a13.53,13.53,0,0,0,1.29-.79,18.4,18.4,0,0,0,2.5-2.12,63.62,63.62,0,0,0,4.9-5.79l0,0a5,5,0,0,1,8,5.93Z"
          fill="#06f"
        />
        <path
          d="M308.84,109a35.38,35.38,0,0,1-6.37,7.19,23.27,23.27,0,0,1-4.42,3,19,19,0,0,1-2.58,1.09l-.68.22-.22.06-.47.13a5.93,5.93,0,0,1-.88.14,7.55,7.55,0,0,1-2.51-.23,12.24,12.24,0,0,1-2.94-1.27,25,25,0,0,1-2.15-1.41,40.31,40.31,0,0,1-3.58-3,53.16,53.16,0,0,1-6-6.74,2.51,2.51,0,0,1,3.35-3.62l.08,0c2.36,1.5,4.74,3.08,7.06,4.49,1.18.69,2.32,1.39,3.45,1.93a15.29,15.29,0,0,0,1.59.72,3.12,3.12,0,0,0,1.07.26c.06,0,0-.07-.37-.06a2.93,2.93,0,0,0-.35,0l-.22.05,0,0,.33-.17a13.53,13.53,0,0,0,1.29-.79,18.4,18.4,0,0,0,2.5-2.12,63.62,63.62,0,0,0,4.9-5.79l0,0a5,5,0,0,1,8,5.93Z"
          fill="#fff"
          opacity=".7"
        />
        <path
          d="M272.29,102.42l1.17,2s.89,2.62,2.68,3.1l4.86-1.57-.25-.41h0c-.62-.94-.55-2.77-.34-4.29s-.57-1.57-1.15-1.19a3.82,3.82,0,0,0-.84,1.65,7.77,7.77,0,0,0-.79-.93l-1.48-1.48a1.72,1.72,0,0,0-2.34-.06l-1.2,1.07A1.71,1.71,0,0,0,272.29,102.42Z"
          fill="#06f"
        />
        <path
          d="M272.29,102.42l1.17,2s.89,2.62,2.68,3.1l4.86-1.57-.25-.41h0c-.62-.94-.55-2.77-.34-4.29s-.57-1.57-1.15-1.19a3.82,3.82,0,0,0-.84,1.65,7.77,7.77,0,0,0-.79-.93l-1.48-1.48a1.72,1.72,0,0,0-2.34-.06l-1.2,1.07A1.71,1.71,0,0,0,272.29,102.42Z"
          fill="#fff"
          opacity=".7"
        />
        <path
          d="m317.67 95.22a59.64 59.64 0 0 0-15.34 6.47 4.32 4.32 0 0 0-1.94 4.53c1.93 9.44 6.32 22.08 11.06 30.13l22.11-9.15c0.15-3.9-5.22-16.52-10.69-28.72-0.98-2.19-2.87-3.82-5.2-3.26z"
          fill="#06f"
        />
        <path
          d="m317.67 95.22a59.64 59.64 0 0 0-15.34 6.47 4.32 4.32 0 0 0-1.94 4.53c1.93 9.44 6.32 22.08 11.06 30.13l22.11-9.15c0.15-3.9-5.22-16.52-10.69-28.72-0.98-2.19-2.87-3.82-5.2-3.26z"
          fill="#fff"
          opacity=".8"
        />
        <path
          d="m326.3 106.21-4.39-1.47c1 2.57 4.53 5.82 7 7.73-0.8-2-1.69-4.1-2.61-6.26z"
          fill="#06f"
          opacity=".3"
        />
        <path
          d="m316.22 85.32c-1.83-3.48-5.78-5.23-10.52-4.84-4 0.34-7.54 4.42-7.12 6.62s3.78 3.14 4.42 3.9l-2.77 2a3 3 0 0 0-0.6 4.29c1.17 1.48 2.71 3 3.6 4.12 7.66-0.2 13.33-3.12 15.38-5.93-0.77-3.56-0.61-6.7-2.39-10.16z"
          fill="#06f"
        />
        <path
          d="m316.22 85.32c-1.83-3.48-5.78-5.23-10.52-4.84-4 0.34-7.54 4.42-7.12 6.62s3.78 3.14 4.42 3.9l-2.77 2a3 3 0 0 0-0.6 4.29c1.17 1.48 2.71 3 3.6 4.12 7.66-0.2 13.33-3.12 15.38-5.93-0.77-3.56-0.61-6.7-2.39-10.16z"
          fill="#fff"
          opacity=".8"
        />
        <path
          d="m312.46 87.48a7.57 7.57 0 1 1-9.81-4.3 7.58 7.58 0 0 1 9.81 4.3z"
          fill="#263238"
        />
        <path
          d="M377.39,177.6c-.11-3.29-.26-3-.35-5.77-.06-1.7-.07-3.59-.08-5.22a3.36,3.36,0,0,0-2.7-3.28c-1.32-.27-2.65-.52-4-.8-1.73-.37-3.44-.77-5.13-1.26-1.32-.38-2.62-.8-3.91-1.27s-2.74-1-4.08-1.62c-1.58-.67-3.14-1.39-4.68-2.14-1.73-.82-3.44-1.68-5.15-2.55-6.58-10.89-6.72-18.07-13.78-26.49l-15.16,6.86s11.14,19.76,18.72,28.14c4.37,4.82,11.22,7,17.33,8.58,4.41,1.13,8.88,2,13.35,2.83,1.74.32,3.63.44,5.13,1.48a5.74,5.74,0,0,1,2.14,3.45q.1.42.18.84C375.41,180.22,377.46,179.58,377.39,177.6Z"
          fill="#06f"
        />
        <path
          d="M377.39,177.6c-.11-3.29-.26-3-.35-5.77-.06-1.7-.07-3.59-.08-5.22a3.36,3.36,0,0,0-2.7-3.28c-1.32-.27-2.65-.52-4-.8-1.73-.37-3.44-.77-5.13-1.26-1.32-.38-2.62-.8-3.91-1.27s-2.74-1-4.08-1.62c-1.58-.67-3.14-1.39-4.68-2.14-1.73-.82-3.44-1.68-5.15-2.55-6.58-10.89-6.72-18.07-13.78-26.49l-15.16,6.86s11.14,19.76,18.72,28.14c4.37,4.82,11.22,7,17.33,8.58,4.41,1.13,8.88,2,13.35,2.83,1.74.32,3.63.44,5.13,1.48a5.74,5.74,0,0,1,2.14,3.45q.1.42.18.84C375.41,180.22,377.46,179.58,377.39,177.6Z"
          fill="#fff"
          opacity=".8"
        />
        <path
          d="M369.7,162.4c-.6-.13-1.2-.26-1.81-.41.05,3.46-1.57,9.42-2.16,11.23l1.9.36A38.11,38.11,0,0,0,369.7,162.4Z"
          fill="#06f"
          opacity=".3"
        />
        <path
          d="M377,166.61a3.36,3.36,0,0,0-2.69-3.28l-1-.19a4.58,4.58,0,0,0,1.63,2.9,5.09,5.09,0,0,0,2,1.14C377,167,377,166.8,377,166.61Z"
          fill="#06f"
          opacity=".3"
        />
        <path
          d="M311.05,87.54c.4,1.52-1.3,3.11-2.65,1.8a30.83,30.83,0,0,0-4.12-3.69c-1.39-.87.46-2.39,2.65-1.8A5.94,5.94,0,0,1,311.05,87.54Z"
          fill="#fff"
        />
        <path
          d="M311.16,135.86c-.7.26.58,1.46.58,1.46s14-4.79,22.5-9.72a1.88,1.88,0,0,0-.68-1.58A216,216,0,0,1,311.16,135.86Z"
          fill="#06f"
        />
        <path
          d="M311.16,135.86c-.7.26.58,1.46.58,1.46s14-4.79,22.5-9.72a1.88,1.88,0,0,0-.68-1.58A216,216,0,0,1,311.16,135.86Z"
          fill="#fff"
          opacity=".5"
        />
        <path
          d="M321.46,94.56c2.76,1.4,5.35,2.87,8,4.5,1.29.82,2.57,1.65,3.84,2.55s2.53,1.82,3.8,2.86l.47.39.59.54a12.74,12.74,0,0,1,1,1c.32.35.59.69.85,1s.54.68.77,1a43.8,43.8,0,0,1,2.58,4,59.05,59.05,0,0,1,4,8.35,2.52,2.52,0,0,1-4.19,2.62l-.05-.06c-2-2.13-3.93-4.37-5.87-6.46s-3.91-4.21-5.54-5.14c-2.27-1.41-4.8-2.82-7.31-4.2l-7.56-4.2h0a5,5,0,0,1,4.68-8.84Z"
          fill="#06f"
        />
        <path
          d="M321.46,94.56c2.76,1.4,5.35,2.87,8,4.5,1.29.82,2.57,1.65,3.84,2.55s2.53,1.82,3.8,2.86l.47.39.59.54a12.74,12.74,0,0,1,1,1c.32.35.59.69.85,1s.54.68.77,1a43.8,43.8,0,0,1,2.58,4,59.05,59.05,0,0,1,4,8.35,2.52,2.52,0,0,1-4.19,2.62l-.05-.06c-2-2.13-3.93-4.37-5.87-6.46s-3.91-4.21-5.54-5.14c-2.27-1.41-4.8-2.82-7.31-4.2l-7.56-4.2h0a5,5,0,0,1,4.68-8.84Z"
          fill="#fff"
          opacity=".8"
        />
        <path
          d="M349.73,125.74l-.85-2.13s-.47-2.72-2.16-3.48l-5,.79.17.44h0c.46,1,.11,2.83-.34,4.29s.31,1.65.95,1.36c.36-.16.71-.81,1.09-1.5a8.46,8.46,0,0,0,.63,1l1.23,1.69a1.72,1.72,0,0,0,2.3.44l1.36-.87A1.7,1.7,0,0,0,349.73,125.74Z"
          fill="#06f"
        />
        <path
          d="M349.73,125.74l-.85-2.13s-.47-2.72-2.16-3.48l-5,.79.17.44h0c.46,1,.11,2.83-.34,4.29s.31,1.65.95,1.36c.36-.16.71-.81,1.09-1.5a8.46,8.46,0,0,0,.63,1l1.23,1.69a1.72,1.72,0,0,0,2.3.44l1.36-.87A1.7,1.7,0,0,0,349.73,125.74Z"
          fill="#fff"
          opacity=".8"
        />
        <path
          d="m317.24 106.06-1.22 0.1-7.49 18.08a4 4 0 0 0 1.22-0.1s9.76-3.64 12.71-5c-1.98-4.09-5.22-13.08-5.22-13.08z"
          fill="#06f"
          opacity=".3"
        />
        <path
          d="m303.57 110.8a43.41 43.41 0 0 0 5 13.44c3.66-1.26 9.76-3.64 12.72-5a135.36 135.36 0 0 1-5.29-13.08c-3.13 0.21-10 2.84-12.43 4.64z"
          fill="#fff"
        />
        <path
          d="m311 114.71a2.58 2.58 0 1 1-1.73-3.21 2.58 2.58 0 0 1 1.73 3.21z"
          fill="#06f"
          opacity=".3"
        />
        <path
          d="m312.91 111.27a0.85 0.85 0 1 1-0.56-1 0.84 0.84 0 0 1 0.56 1z"
          fill="#06f"
          opacity=".6"
        />
        <path
          d="M315.15,110.4a.85.85,0,1,1-1.62-.49.84.84,0,0,1,1.05-.56A.85.85,0,0,1,315.15,110.4Z"
          fill="#06f"
          opacity=".6"
        />
        <polygon
          points="318.29 118.19 309.04 121.84 308.49 120.02 317.73 116.37"
          fill="#06f"
          opacity=".5"
        />
        <path
          d="M267.26,257.17a94,94,0,0,1-1.68,17.35q-1.77,7.71-5.5,11.28a12.3,12.3,0,0,1-8.81,3.57q-7.71,0-11.77-8.41a23.79,23.79,0,0,1-1.21-3.11,144.31,144.31,0,0,0-15.92,16l-5.31,6.26a38.62,38.62,0,0,0,9.77,7.19q9.34,4.83,24.52,4.84c1.78,0,3.5-.05,5.17-.15a143.39,143.39,0,0,0,15.1-29l14.85-38.72Z"
          opacity=".2"
        />
        <path
          d="m133.39 310 17.5 17.5 49-46.17c-16.89-6.45-49.73 11.86-66.5 28.67z"
          fill="#263238"
        />
        <path
          d="m194.42 371c-5.59-5.6-17.5-17.5-17.5-17.5l46.17-49c6.41 16.85-11.9 49.72-28.67 66.5z"
          fill="#263238"
        />
        <path
          d="M261.4,260.7l19.09-36.81L243.68,243a144.22,144.22,0,0,0-32.44,23l-62.75,59.07,30.83,30.83,59.07-62.75A144.22,144.22,0,0,0,261.4,260.7Z"
          fill="#06f"
        />
        <path
          d="M261.4,260.7l19.09-36.81L243.68,243a144.22,144.22,0,0,0-32.44,23l-62.75,59.07,30.83,30.83,59.07-62.75A144.22,144.22,0,0,0,261.4,260.7Z"
          fill="#fff"
          opacity=".6"
        />
        <circle cx="222.2" cy="282.18" r="12.9" fill="#fff" />
        <circle cx="222.2" cy="282.18" r="8.29" fill="#06f" />
        <polygon
          points="189.75 344.82 159.56 314.63 184.28 291.37 213.01 320.11"
          fill="#06f"
          opacity=".3"
        />
        <path
          d="m140.22 337.62c-22.6 1.83-30.09 16.3-32.65 35.53-1.3 9.81-1.88 19.74-10.11 25.48a2.77 2.77 0 0 0 1.63 5.06c30.34-0.95 44.49-15.8 46.27-22a43.06 43.06 0 0 1-2.49 9.47 2.76 2.76 0 0 0 4 3.39c8.51-5.33 19.19-15.15 19.9-31.08-6.26-8.87-26.55-25.85-26.55-25.85z"
          fill="#06f"
        />
        <path
          d="m140.22 337.62c-22.6 1.83-30.09 16.3-32.65 35.53-1.3 9.81-1.88 19.74-10.11 25.48a2.77 2.77 0 0 0 1.63 5.06c30.34-0.95 44.49-15.8 46.27-22a43.06 43.06 0 0 1-2.49 9.47 2.76 2.76 0 0 0 4 3.39c8.51-5.33 19.19-15.15 19.9-31.08-6.26-8.87-26.55-25.85-26.55-25.85z"
          fill="#fff"
          opacity=".2"
        />
        <polygon
          points="170.28 370.3 134.08 334.1 153.36 329.93 174.45 351.02"
          fill="#06f"
        />
      </svg>

      <h4 className="text-lg font-semibold text-center text-gray-900 dark:text-white">
        Page Not Found
      </h4>

      <p className="mt-2 text-sm font-light text-center text-gray-900 dark:text-white">
        Make sure the address is correct and the page hasn't moved.
      </p>

      <p className="mt-2 text-sm font-light text-center text-gray-900 dark:text-white">
        Please contact vegamaster administrator if you think this is a mistake.
      </p>

      <Link
        to="/profile/personal"
        className="inline-flex items-center px-3 py-2 mt-4 text-sm font-medium leading-4 text-gray-900 bg-transparent border border-gray-700 rounded-md shadow-sm dark:text-white dark:border-white dark:hover:bg-transparent hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Home
      </Link>
    </div>
  );
}

export default PageNotFound;
