// import react components
import React from "react";
// import React router compontents
import { Switch, Route } from "react-router-dom";
// import wrapper function
import { Wrapper, mediaQueryListener } from "./Components/utilities";
// import Route utilities
import ProtectedRoute from "./Components/RouteUtilities";
// import permissions
import {
  IsGuest,
  IsUser,
  IsManager,
  IsAdmin,
  IsOwner,
  IsBot,
} from "./Components/Permissions";

// import pages

// Error pages
import PageNotFound from "./Pages/404ErrorPage";
import ForbiddenPage from "./Pages/403ErrorPage";
import ServerErrorPage from "./Pages/500ServerErrorPage";

// Onboarding pages
import OnboardActivation from "./Pages/OnboardActivation";
import VerifyEmail from "./Pages/VerifyEmail";

import SwitchOrganizationPage from "./Pages/SwitchOrganizationPage";

// redirect auth page
import RedirectAuth from "./Pages/RedirectAuth";

// sign up pages
import SignUP from "./Pages/SignUP/SignUP";

// profile pages
import Profile from "./Pages/Profile/Profile";

// security pages
import Security from "./Pages/Security/Security";

// Settings pages
import Settings from "./Pages/Settings/Settings";
import ActiveSessions from "./Pages/Activity/ActiveSessions";
import AuthenticationLog from "./Pages/Activity/AuthenticationLog";
import ActivityHistory from "./Pages/Activity/ActivityHistory";
// import VerifyEmail from "./Components/VerifyEmail";

const { REACT_APP_KEYCLOAK_URL } = process.env;

export function Keycloak() {
  React.useEffect(() => {
    window.location.href = REACT_APP_KEYCLOAK_URL;
  }, []);

  return <></>;
}

function App() {
  // This function check for Environment variable
  // if ENV is production we will hide the console log.
  React.useEffect(() => {
    const { NODE_ENV } = process.env;

    // if Environment is production
    if (NODE_ENV === "production") {
      console.log = () => {};
    }
  }, []);

  // listner for media query prefers-color-scheme
  React.useEffect(() => {
    // Assign variable for match media
    const darkThemeMq = window.matchMedia("(prefers-color-scheme: dark)");
    // add event listner for change event
    darkThemeMq.addEventListener("change", mediaQueryListener);

    // unmount callback
    // remove event listner
    return () => darkThemeMq.removeEventListener("change", mediaQueryListener);
  }, []);

  return (
    <Switch>
      {/* Onboarding Pages  */}

      <Route exact path="/" component={Keycloak} />

      <Route
        exact
        path="/redirect"
        component={Wrapper({
          component: RedirectAuth,
          title: "Vegastack . Redirect",
        })}
      />

      <Route
        exact
        path="/signup"
        component={Wrapper({
          component: SignUP,
          title: "Sign UP",
        })}
      />

      <Route
        exact
        path="/user-activation/:uid/:token/"
        component={Wrapper({
          component: OnboardActivation,
          title: "User Activation",
        })}
      />

      <Route
        exact
        path="/verify-email/:uid/:token/"
        component={Wrapper({
          component: VerifyEmail,
          title: "Verify Email",
        })}
      />

      {/* Onboarding Pages ends */}

      <ProtectedRoute
        exact
        path="/switch/organization/"
        component={Wrapper({
          component: SwitchOrganizationPage,
          title: "Switch Organization",
        })}
        permission={[IsGuest, IsUser, IsManager, IsAdmin, IsOwner, IsBot]}
      />

      <ProtectedRoute
        exact
        path="/profile/personal"
        component={Wrapper({
          component: Profile,
          title: "Profile . Personal",
        })}
        permission={[IsManager, IsAdmin, IsOwner]}
      />

      <ProtectedRoute
        exact
        path="/profile/email-address"
        component={Wrapper({
          component: Profile,
          title: "Profile . Contact",
        })}
        permission={[IsManager, IsAdmin, IsOwner]}
      />
      <ProtectedRoute
        exact
        path="/profile/mobile-numbers"
        component={Wrapper({
          component: Profile,
          title: "Profile . Contact",
        })}
        permission={[IsManager, IsAdmin, IsOwner]}
      />

      <ProtectedRoute
        exact
        path="/profile/connected-accounts"
        component={Wrapper({
          component: Profile,
          title: "Profile . Connected Accounts",
        })}
        permission={[IsManager, IsAdmin, IsOwner]}
      />

      {/* starts security pages */}
      <ProtectedRoute
        exact
        path="/security/change_password"
        component={Wrapper({
          component: Security,
          title: "Security . Change Password",
        })}
        permission={[IsManager, IsAdmin, IsOwner]}
      />

      <ProtectedRoute
        exact
        path="/security/mfa_authentication"
        component={Wrapper({
          component: Security,
          title: "Security . Multi-Factor Authentication",
        })}
        permission={[IsManager, IsAdmin, IsOwner]}
      />

      {/* end security pages */}

      {/* start settings pages */}

      <ProtectedRoute
        exact
        path="/setting/preferences"
        component={Wrapper({
          component: Settings,
          title: "Settings . Preferences",
        })}
        permission={[IsManager, IsAdmin, IsOwner]}
      />

      <ProtectedRoute
        exact
        path="/setting/email_settings"
        component={Wrapper({
          component: Settings,
          title: "Settings . Email Setings",
        })}
        permission={[IsManager, IsAdmin, IsOwner]}
      />

      {/* end settings pages */}

      {/* user setting pages */}

      {/* start activity & session pages */}
      <ProtectedRoute
        exact
        path="/activity/active-sessions"
        component={Wrapper({
          component: ActiveSessions,
          title: "Activity . Active Sessions",
        })}
        permission={[IsManager, IsAdmin, IsOwner]}
      />

      <ProtectedRoute
        exact
        path="/activity/authentication-log"
        component={Wrapper({
          component: AuthenticationLog,
          title: "Activity . Authentication Log",
        })}
        permission={[IsManager, IsAdmin, IsOwner]}
      />

      <ProtectedRoute
        exact
        path="/activity/activity-history"
        component={Wrapper({
          component: ActivityHistory,
          title: "Activity . Activity",
        })}
        permission={[IsManager, IsAdmin, IsOwner]}
      />

      {/* end activity & session pages */}

      {/* Error pages */}
      <Route exact path="/forbidden/" component={ForbiddenPage} />
      <Route exact path="/server_error/" component={ServerErrorPage} />
      <Route component={PageNotFound} />
    </Switch>
  );
}

export default App;
