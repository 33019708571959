// import react component
import { useState } from "react";
// import redux component
import { connect } from "react-redux";
// import react star component
import ReactStars from "react-rating-stars-component";

function Feedback(props) {
  // intialize state
  const [values, setvalues] = useState({
    Name_First: props.fullname,
    Email: props.email,
    Rating: 5,
    Comment: true,
    Suggestion: false,
    Others: false,
    MultiLine: "",
    Radio: "Comment",
    DecisionBox: false,
  });

  // function to set rating value
  const handleRating = (rating) => {
    // set rating value in state
    setvalues({
      ...values,
      Rating: parseInt(rating),
    });
  };

  // handle input change
  const handleInputChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    if (name === "Comment") {
      setvalues({
        ...values,
        Comment: true,
        Suggestion: false,
        Others: false,
        Radio: "Comment",
      });

      return true;
    } else if (name === "Suggestion") {
      setvalues({
        ...values,
        Comment: false,
        Suggestion: true,
        Others: false,
        Radio: "Suggestion",
      });

      return true;
    } else if (name === "Others") {
      setvalues({
        ...values,
        Comment: false,
        Suggestion: false,
        Others: true,
        Radio: "Others",
      });

      return true;
    }

    setvalues({
      ...values,
      [name]: value,
    });

    return true;
  };

  return (
    <div className="px-2 pt-4">
      <form
        action="https://forms.peerxp.com/peerxp/form/VegaMasterProductFeedback/formperma/l42t0ndSA49DlYqvZbdmRVm3pr37Li54AB63_qrqu3U/htmlRecords/submit"
        name="form"
        id="form"
        method="POST"
        accept-charset="UTF-8"
        enctype="multipart/form-data"
      >
        <div className="hidden mb-4">
          <label for="Name_First">
            <p className="mb-1 text-sm font-medium text-gray-900 dark:text-gray-300">
              Name
              <span className="text-base text-red-600 ml-1">*</span>
            </p>
          </label>
          <input
            type="text"
            className="block w-full py-2 pl-4 placeholder-gray-300 border border-gray-300 rounded shadow-sm focus:ring-blue-300 focus:border-blue-500 sm:text-sm"
            id="Name_First"
            name="Name_First"
            value={values.Name_First}
            onChange={handleInputChange}
            placeholder="Enter First Name"
            required
          />
        </div>

        <div className="hidden mb-4">
          <label for="Email">
            <p className="mb-1 text-sm font-medium text-gray-900 dark:text-gray-300">
              Email
              <span className="text-base text-red-600 ml-1">*</span>
            </p>
          </label>
          <input
            type="email"
            className="block w-full py-2 pl-4 placeholder-gray-300 border border-gray-300 rounded shadow-sm focus:ring-blue-300 focus:border-blue-500 sm:text-sm"
            id="Email"
            name="Email"
            value={values.Email}
            onChange={handleInputChange}
            placeholder="Enter Your Email"
            required
          />
        </div>

        <div className="mb-4">
          <label htmlFor="Rating">
            <p className="mb-1 text-sm font-medium text-gray-900 dark:text-gray-300 xs:text-left">
              How happy are you with VegaMaster?
            </p>
          </label>
          <ReactStars
            count={5}
            onChange={handleRating}
            value={values.Rating}
            size={24}
            isHalf={false}
            emptyIcon={<i className="far fa-star"></i>}
            halfIcon={<i className="fa fa-star-half-alt"></i>}
            fullIcon={<i className="fa fa-star"></i>}
            activeColor="#ffd700"
          />
        </div>

        <div className="hidden">
          <input
            className="block w-full py-2 pl-4 placeholder-gray-300 border border-gray-300 rounded shadow-sm focus:ring-blue-300 focus:border-blue-500 sm:text-sm"
            name="Number"
            value={values.Rating}
          />
        </div>

        <div className="mb-2">
          <label htmlFor="Comment">
            <p className="mb-1 text-sm font-medium text-gray-900 dark:text-gray-300 xs:text-left">
              Feedback Type
            </p>
          </label>
        </div>

        <div className="flex justify-between">
          <label className="flex items-center mb-4">
            <input
              type="radio"
              className="w-4 h-4 text-blue-600 bg-gray-200 border-gray-300 rounded-full cursor-pointer dark:border-gray-500 dark:bg-gray-600 focus:ring-blue-300 focus:ring-offset-gray-100 dark:focus:ring-offset-gray-800"
              id="Comment"
              name="Comment"
              checked={values.Comment}
              onChange={handleInputChange}
            />

            <p className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Comment
            </p>
          </label>

          <label className="flex items-center mb-4">
            <input
              type="radio"
              className="w-4 h-4 text-blue-600 bg-gray-200 border-gray-300 rounded-full cursor-pointer dark:border-gray-500 dark:bg-gray-600 focus:ring-blue-300 focus:ring-offset-gray-100 dark:focus:ring-offset-gray-800"
              id="DecisionBox"
              name="Suggestion"
              checked={values.Suggestion}
              onChange={handleInputChange}
            />

            <p className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Suggestion
            </p>
          </label>

          <label className="flex items-center mb-4">
            <input
              type="radio"
              className="w-4 h-4 text-blue-600 bg-gray-200 border-gray-300 rounded-full cursor-pointer dark:border-gray-500 dark:bg-gray-600 focus:ring-blue-300 focus:ring-offset-gray-100 dark:focus:ring-offset-gray-800"
              id="Others"
              name="Others"
              checked={values.Others}
              onChange={handleInputChange}
            />

            <p className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
              Others
            </p>
          </label>
        </div>

        <div className="hidden">
          <input
            className="block w-full py-2 pl-4 placeholder-gray-300 border border-gray-300 rounded shadow-sm focus:ring-blue-300 focus:border-blue-500 sm:text-sm"
            name="Radio"
            value={values.Radio}
          />
        </div>

        <div className="mt-4 mb-4">
          <label for="MultiLine">
            <p className="mb-2 text-sm font-medium text-gray-900 dark:text-gray-300 xs:text-left">
              Tell us more about your experience with us
              <span className="text-base text-red-600 ml-1">*</span>
            </p>
          </label>
          <textarea
            type="text"
            className="input-field"
            id="MultiLine"
            name="MultiLine"
            value={values.MultiLine}
            onChange={handleInputChange}
            placeholder="Enter Feedback"
            required
          />
        </div>

        <label className="flex items-center mb-4">
          <input
            type="checkbox"
            className="checkbox"
            id="DecisionBox"
            name="DecisionBox"
            checked={values.DecisionBox}
            onChange={handleInputChange}
          />
          <p className="ml-2 text-sm font-medium text-gray-900 dark:text-gray-300">
            <span>Use my feedback as testimonial</span>
          </p>
        </label>

        <div className="text-right">
          <button
            onClick={() => {
              props.setShowFeedBackModal(false);
            }}
            type="button"
            className="text-gray-900 dark:text-gray-300 mr-4 mt-4"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-white bg-indigo-500 border border-transparent rounded shadow-sm hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto focus:ring-offset-gray-100 dark:focus:ring-offset-gray-900 sm:text-sm"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

export default connect(mapStateToProps)(Feedback);
