// import react components
import React, { useCallback, useEffect, useState } from "react";
// import react router components
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Select from "react-select";
import Avatar from "react-avatar";
import {
  account_profile_url,
  fetch_image_url,
  post_image_url,
} from "../../Components/Urls";
import { get, put } from "../../Components/AxiosUtilities";
import { useSnackbar } from "notistack";
import { RiCameraLine } from "react-icons/ri";
import { strCapitalize } from "../../Components/utilities";
import { RiLoader4Fill } from "react-icons/ri";

import axios from "axios";

function ProfileInformation(props) {
  // show alert and close alert functions
  const { enqueueSnackbar } = useSnackbar();

  // Initialize states
  const [values, setValues] = useState({
    // profile form
    first_name: "",
    last_name: "",
    organization: "",
    location: "",
  });

  const [gender, setGender] = useState([]);

  const [showEditForm, setShowEditForm] = useState(false);

  const [profileImageLoading, setProfileImageLoading] = useState(false);

  const { setUserDetails } = props;

  // function to save inputs in states
  const handleInputChange = (e) => {
    // takes input name and value from event target input field
    const { name, value } = e.target;

    // sets state values
    setValues({
      ...values,
      [name]: value,
    });
  };

  const gender_options = React.useMemo(
    () => [
      { value: "female", label: "Female" },
      { value: "male", label: "Male" },
      { value: "i'd_prefer_not_to_say", label: "Prefer not to say" },
    ],
    []
  );

  const [image, setImage] = useState({
    preview: "",
    raw: "",
  });

  useEffect(() => {
    const close = (e) => {
      if (e.ctrlKey && (e.keyCode === 10 || e.keyCode === 13)) {
        document.getElementById("submit").click();
      }
    };

    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, []);

  const handleChange = (e) => {
    if (e.target.files.length) {
      setImage({
        preview: URL.createObjectURL(e.target.files[0]),
        raw: e.target.files[0],
      });
    }
  };

  useEffect(() => {
    get(account_profile_url)
      .then((res) => {
        // console.log(res.data);

        let datas = res.data;

        setValues((prevState) => ({
          ...prevState,
          first_name: datas.user.first_name && datas.user.first_name,
          last_name: datas.user.last_name && datas.user.last_name,
          gender: datas.gender && datas.gender,
          organization: datas.organization && datas.organization,
          location: datas.location && datas.location,
        }));

        if (datas.gender) {
          setGender(
            gender_options.filter(
              (gender) =>
                gender.value.toLowerCase() === datas.gender.toLowerCase()
            )[0]
          );
        } else {
          setGender(gender_options[2]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [gender_options]);

  const { user_uid } = props;

  // fetching the image
  const fetchImage = useCallback(() => {
    get(fetch_image_url + `?image_type=profile&user_uid=${user_uid}&width=56`)
      .then((res) => {
        // console.log(res);
        setImage({
          preview: res.data.url,
        });

        setUserDetails({
          avatar_url: res.data.url,
        });

        setProfileImageLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setProfileImageLoading(false);

        enqueueSnackbar("Error occured while fetching profile image.", {
          variant: "error",
          autoHideDuration: 3000,
        });
      });
  }, [user_uid]);

  useEffect(() => {
    fetchImage();
  }, [fetchImage]);

  const profileFormSubmit = (e) => {
    e.preventDefault();

    if (image.hasOwnProperty("raw") && image.raw !== null && image.raw !== "") {
      setProfileImageLoading(true);

      const formData = new FormData();
      // Update the formData object
      formData.append("image", image.raw, image.raw.name);

      axios
        .post(post_image_url, formData)
        .then((res) => {
          console.log(res);
          fetchImage();
        })
        .catch((err) => {
          console.log(err);

          enqueueSnackbar("Error occured while uploading profile image.", {
            variant: "error",
            autoHideDuration: 3000,
          });

          fetchImage();
        });
    }

    put(account_profile_url, {
      ...values,
      gender: gender.value,
    })
      .then((res) => {
        console.log(res.data);
        let datas = res.data;
        setValues((prevState) => ({
          ...prevState,
          first_name: datas.user?.first_name && datas.user?.first_name,
          last_name: datas.user?.last_name && datas.user?.last_name,
          gender: datas.gender && datas.gender,
          organization: datas.organization && datas.organization,
          location: datas.location && datas.location,
        }));

        if (datas.gender) {
          setGender(
            gender_options.filter(
              (gender) =>
                gender.value.toLowerCase() === datas.gender.toLowerCase()
            )[0]
          );
        } else {
          setGender(gender_options[2]);
        }

        setShowEditForm(false);

        enqueueSnackbar("Personal information updated successfully.", {
          variant: "success",
          autoHideDuration: 3000,
        });
      })
      .catch((err) => {
        console.log(err);

        if (err.response && err.response.data.message) {
          enqueueSnackbar(err.response.data.message + ".", {
            variant: "error",
            autoHideDuration: 3000,
          });
        } else {
          enqueueSnackbar(
            "An error occured while updating profile information.",
            {
              variant: "error",
              autoHideDuration: 3000,
            }
          );
        }
      });
  };

  const showProfileImage = () => {
    if (profileImageLoading) {
      return (
        <RiLoader4Fill className="h-12 w-12 animate-spin text-blue-500 mx-auto" />
      );
    } else if (props.avatar_url && image.preview === "") {
      return (
        <img
          src={props.avatar_url}
          alt={props.first_name}
          className="h-14 w-14 rounded-full"
        />
      );
    } else if (!image.preview || image.preview[0] === "/") {
      return (
        <Avatar
          name={props.email && props.email[0]}
          size="60"
          round="30px"
          className="w-12 h-12 rounded-full"
        />
      );
    } else if (image.preview) {
      return (
        <img
          src={image.preview}
          alt={props.first_name}
          className="h-14 w-14 rounded-full"
        />
      );
    } else {
      return (
        <Avatar
          name={props.email && props.email[0]}
          size="60"
          round="30px"
          className="w-12 h-12 rounded-full"
        />
      );
    }
  };

  return (
    <>
      <div
        className="flex items-center justify-between align-middle"
        id="personal_div"
      >
        <h6 className="text-2xl font-medium text-gray-900 dark:text-white">
          Profile
        </h6>
      </div>

      <div className="bg-white dark:bg-gray-800 mt-2 border rounded-lg sm:rounded-lg overflow-hidden shadow border-gray-300  dark:border-gray-600">
        <div className="px-4 py-5 sm:px-6">
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-4">
              {!showEditForm && showProfileImage()}

              {showEditForm && (
                <div>
                  <label htmlFor="upload-button">
                    {image.preview ? (
                      <img
                        src={image.preview}
                        alt="dummy"
                        className="h-14 w-14 rounded-full cursor-pointer"
                      />
                    ) : (
                      <>
                        <div
                          className="bg-gray-300 hover:bg-gray-500 w-14 h-14 flex justify-center items-center rounded-full cursor-pointer"
                          style={{
                            backgroundImage: `url(${image.preview})`,
                          }}
                        >
                          <RiCameraLine className="h-7 w-7 text-center text-gray-300 dark:text-gray-300" />
                        </div>
                      </>
                    )}
                  </label>
                  <input
                    type="file"
                    id="upload-button"
                    style={{ display: "none" }}
                    accept="image/png, image/jpeg,image/jpg"
                    onChange={handleChange}
                  />
                </div>
              )}

              <div>
                <p className="text-lg text-gray-900 dark:text-white">
                  {props.first_name} {props.last_name}
                </p>

                <p className="text-sm text-gray-1300 dark:text-gray-1500">
                  {props.email}
                </p>
              </div>
            </div>
            {!showEditForm && (
              <button
                className="button button-success"
                onClick={() => {
                  setShowEditForm(true);
                }}
              >
                Edit
              </button>
            )}
          </div>
        </div>

        {!showEditForm && (
          <div className="border-t border-gray-200 dark:border-gray-600 px-4 py-5 sm:px-6">
            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">
                  Full name
                </dt>
                <dd className="mt-1 text-sm text-gray-900 dark:text-gray-300">
                  {strCapitalize(values?.first_name)}{" "}
                  {strCapitalize(values?.last_name)}
                </dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">
                  Gender
                </dt>
                <dd className="mt-1 text-sm text-gray-900 dark:text-gray-300">
                  {gender.label}
                </dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">
                  Organization
                </dt>
                <dd className="mt-1 text-sm text-gray-900 dark:text-gray-300">
                  {strCapitalize(props.name)}
                </dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">
                  Location
                </dt>
                <dd className="mt-1 text-sm text-gray-900 dark:text-gray-300">
                  {strCapitalize(values.location)}
                </dd>
              </div>
            </dl>
          </div>
        )}

        {showEditForm && (
          <form
            onSubmit={profileFormSubmit}
            encType="multipart/form-data"
            className="border-t border-gray-200 dark:border-gray-600 px-4 py-5 sm:px-6"
          >
            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-2">
                  First Name
                  <span className="text-red-600"> *</span>
                </dt>
                <input
                  name="first_name"
                  id="first_name"
                  className="input-field"
                  value={values?.first_name}
                  onChange={handleInputChange}
                  placeholder="Enter first name"
                  required
                />
              </div>

              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-2">
                  Last Name
                </dt>
                <input
                  name="last_name"
                  id="last_name"
                  className="input-field"
                  value={values?.last_name}
                  onChange={handleInputChange}
                  placeholder="Enter last name"
                />
              </div>

              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-2">
                  Gender
                </dt>
                <Select
                  name="gender"
                  id="gender"
                  value={gender}
                  onChange={(result) => {
                    setGender(result);
                  }}
                  options={gender_options}
                  isSearchable={false}
                />
              </div>

              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-2">
                  Organization
                </dt>
                <input
                  name="organization"
                  id="organization"
                  className="input-field"
                  defaultValue={props.name}
                  readOnly
                  required={false}
                />
              </div>

              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500 dark:text-gray-400 mb-2">
                  Location
                </dt>
                <input
                  name="location"
                  id="location"
                  className="input-field"
                  value={values?.location}
                  onChange={handleInputChange}
                  placeholder="Enter location"
                />
              </div>
            </dl>

            <div className="space-x-3 mt-8">
              <button
                id="submit"
                type="submit"
                className="button button-success"
              >
                Save
              </button>
              <button
                type="button"
                className="text-gray-900 dark:text-gray-300"
                onClick={() => {
                  setShowEditForm(false);
                }}
              >
                Cancel
              </button>
            </div>
          </form>
        )}
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

const mapDispatchToProps = (dispatch) => ({
  // dispatching plain actions
  setTimeZone: (data) => dispatch({ type: "setTimeZone", ...data }),
  setUserDetails: (data) => dispatch({ type: "setUserDetails", ...data }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ProfileInformation));
