// import react components
import React from "react";
// import react router components
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
// import page components
import Header from "../../Components/Header";
import Content from "../../Components/Content";
import Sidebar from "../../Components/Sidebar";
import {
  HideInitialSidebarInMobile,
  navigation,
} from "../../Components/utilities";
import SecurityPassword from "./SecurityPassword";
import SecurityMFAAuthentication from "./SecurityMFAAuthentication";

function Security(props) {
  // for sidebar
  const [showSidebar, setshowSidebar] = React.useState(
    HideInitialSidebarInMobile()
  );

  const { match } = props;
  const currentURL = match.url;

  // listner for url change
  React.useEffect(() => {
    try {
      let div_id = "";
      // if url matches assign div_id
      if (currentURL === "/security/change_password") {
        div_id = "change_password_div";
      } else if (currentURL === "/security/mfa_authentication") {
        div_id = "mfa_authentication_div";
      }

      // scroll to div position
      let div_position = document.getElementById(div_id);

      // div_position && div_position.scrollIntoView(true);
      window.scrollTo({
        top: div_position.offsetTop - 50,
        behavior: "smooth",
      });
    } catch (err) {
      console.log(err);
    }
  }, [currentURL]);

  return (
    <>
      {/* pass current page as props as true */}
      <Header handleSidebar={() => setshowSidebar(!showSidebar)} />

      <Sidebar
        showSidebar={showSidebar}
        setshowSidebar={setshowSidebar}
        navigation={navigation(props)}
      />

      <Content showSidebar={showSidebar}>
        <div >
          <h6 className="text-2xl font-semibold text-gray-900 dark:text-white">
            Security
          </h6>

          <div className="space-y-8 mt-2">
            {/* password section starts */}
            <SecurityPassword />
            {/* password section ends */}

            {/* SecurityMFAAuthentication section starts */}
            <SecurityMFAAuthentication />
            {/* SecurityMFAAuthentication section ends */}
          </div>
        </div>
      </Content>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    ...state,
  };
};

const mapDispatchToProps = (dispatch) => ({
  setTimeZone: (data) => dispatch({ type: "setTimeZone", ...data }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(Security));
