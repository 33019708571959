// import react component
import React from "react";
// import react table functions
import { useTable, usePagination, useRowSelect } from "react-table";
// import react icons
import {
  RiArrowLeftSLine,
  RiArrowRightSLine,
  RiAlignJustify,
} from "react-icons/ri";
// import react responsive
import { useMediaQuery } from "react-responsive";
// import No data Message component
import NoDataMessageComponent from "../NoDataMessageComponent";
import InfiniteScroll from "react-infinite-scroll-component";

import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from "immutability-helper";

// import jquery
import $ from "jquery";

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <input type="checkbox" className="checkbox" ref={resolvedRef} {...rest} />
    );
  }
);

export default function Table({
  columns,
  data,
  fetchData,
  loading,
  pageCount: controlledPageCount,
  showFooter = true,
  manualPagination = true,
  showRowSelect = false,
  noDataInfo = {},
  maxHeight = false,
  isDragable = false,
  selectedRow = (e) => e,
  setRowOrder = (e) => e,
  uniqueID = "",
  hasMore = true,
  highlightData = {},
  highlightDataKey = "",
  borderProperty = true,
}) {
  const [records, setRecords] = React.useState(data);

  const isDesktopOrLaptop = useMediaQuery({ minDeviceWidth: 768 });
  const isTabletOrMobile = useMediaQuery({ maxWidth: 767 });

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    // page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    // pageCount,
    gotoPage,
    nextPage,
    previousPage,
    // setPageSize,
    // selectedFlatRows,

    // Get the state from the instance
    state: { pageIndex, pageSize, selectedRowIds },
  } = useTable(
    {
      data: records,
      columns,
      initialState: { pageSize: 5, pageIndex: 0 },
      manualPagination: manualPagination,
      pageCount: controlledPageCount,
      autoResetSelectedRows: false,
      autoResetPage: manualPagination,
      // getRowId,
      getRowId: (row) => row.key,
    },
    usePagination,
    useRowSelect,
    (hooks) => {
      // if props has showRowSelect as true we will show select row option
      if (showRowSelect) {
        hooks.visibleColumns.push((columns) => [
          // Let's make a column for selection
          {
            id: "selection",
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({ getToggleAllPageRowsSelectedProps }) => (
              <div>
                <IndeterminateCheckbox
                  {...getToggleAllPageRowsSelectedProps()}
                />
              </div>
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }) => (
              <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            ),
          },
          ...columns,
        ]);
      }
    }
  );

  const moveRow = (dragIndex, hoverIndex) => {
    setRecords((prevState) => {
      // console.log(dragIndex, hoverIndex, prevState[dragIndex].datas, prevState[dragIndex].uid);
      setRowOrder(
        hoverIndex + 1,
        prevState[dragIndex].datas,
        prevState[dragIndex].uid
      );

      return update(prevState, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevState[dragIndex]],
        ],
      });
    });
  };

  const DND_ITEM_TYPE = "row";

  const Row = ({
    row,
    index,
    moveRow,
    showHighlight = false,
    showHighlightBlink = false,
  }) => {
    const dropRef = React.useRef(null);
    const dragRef = React.useRef(null);

    const [, drop] = useDrop({
      accept: DND_ITEM_TYPE,
      hover(item, monitor) {
        if (!dropRef.current) {
          return;
        }
        const dragIndex = item.index;
        const hoverIndex = index;
        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
          return;
        }
        // Determine rectangle on screen
        const hoverBoundingRect = dropRef.current.getBoundingClientRect();
        // Get vertical middle
        const hoverMiddleY =
          (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
        // Determine mouse position
        const clientOffset = monitor.getClientOffset();
        // Get pixels to the top
        const hoverClientY = clientOffset.y - hoverBoundingRect.top;
        // Only perform the move when the mouse has crossed half of the items height
        // When dragging downwards, only move when the cursor is below 50%
        // When dragging upwards, only move when the cursor is above 50%
        // Dragging downwards
        if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
          return;
        }
        // Dragging upwards
        if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
          return;
        }

        monitor.canDrop();
      },
      drop(props, monitor, component) {
        if (!dropRef.current) {
          return;
        }
        const dragIndex = props.index;
        const hoverIndex = index;

        moveRow(dragIndex, hoverIndex);
        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.

        props.index = hoverIndex;
      },
    });

    const [{ isDragging }, drag, preview] = useDrag({
      type: DND_ITEM_TYPE,
      item: { index },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    });

    const opacity = isDragging ? 0.5 : 1;

    preview(drop(dropRef));
    drag(dragRef);

    return isTabletOrMobile ? (
      <div
        ref={dropRef}
        {...row.getRowProps()}
        className="my-4 overflow-hidden border border-r-0 border-l-0 border-gray-300 dark:border-gray-600"
      >
        <ul
          id={`row_${index}`}
          className={`divide-y divide-gray-300 dark:divide-gray-600 
        ${
          !showHighlight &&
          !showHighlightBlink &&
          "hover:bg-gray-200 dark:hover:bg-gray-500"
        } 
        ${showHighlight && "bg-gray-200 dark:bg-gray-400"}
        ${showHighlightBlink && "bg-green-100 dark:bg-green-800"}
        `}
          onMouseMove={() =>
            setTimeout(() => {
              $(`#row_${index}`).removeClass("bg-green-100 dark:bg-green-800");
            }, 60000)
          }
        >
          {row.cells.map((cell, index) => {
            if (isDragable && index === 0) {
              return (
                <li ref={dragRef} {...cell.getCellProps()}>
                  <span className="block ">
                    <div className="px-4 py-4 sm:px-6">
                      <div className="flex items-center justify-start cursor-pointer">
                        <span>
                          <RiAlignJustify className="w-5 h-5 mr-2" />
                        </span>
                        <span>{cell.render("Cell")}</span>
                      </div>
                    </div>
                  </span>
                </li>
              );
            } else {
              return (
                <li {...cell.getCellProps()}>
                  <span className="block ">
                    <div className="px-4 py-4 sm:px-6">
                      <div className="flex items-center justify-between">
                        {headerGroups.length &&
                          headerGroups[0].headers[index].render("Header") && (
                            <div
                              className="text-xs dark:text-gray-300"
                              {...headerGroups[0].headers[
                                index
                              ].getHeaderProps()}
                            >
                              {headerGroups[0].headers[index].render("Header")}
                            </div>
                          )}

                        <div
                          className={`ml-2 text-gray-900 dark:text-gray-300 xs:text-right xs:text-sm ${
                            headerGroups.length &&
                            headerGroups[0].headers[index].render("Header")
                              ? ""
                              : "w-full"
                          }`}
                          {...cell.getCellProps()}
                        >
                          {cell.render("Cell")}
                        </div>
                      </div>
                    </div>
                  </span>
                </li>
              );
            }
          })}
        </ul>
      </div>
    ) : (
      <tr
        id={`row_${index}`}
        ref={dropRef}
        style={{ opacity }}
        {...row.getRowProps()}
        className={`align-middle ${
          !showHighlight &&
          !showHighlightBlink &&
          "hover:bg-gray-200 dark:hover:bg-gray-500"
        } 
        ${showHighlight && "bg-gray-200 dark:bg-gray-400"}
        ${showHighlightBlink && "bg-green-100 dark:bg-green-800"}
        `}
        onMouseMove={() =>
          setTimeout(() => {
            $(`#row_${index}`).removeClass("bg-green-100 dark:bg-green-800");
          }, 60000)
        }
      >
        {row.cells.map((cell, index) => {
          if (isDragable && index === 0) {
            return (
              <td
                ref={dragRef}
                {...cell.getCellProps()}
                className={`${
                  maxHeight ? "w-full" : ""
                } px-5 py-4 text-sm text-gray-900  whitespace-nowrap dark:text-gray-300`}
              >
                <div className="flex items-center justify-start cursor-pointer">
                  <span>
                    <RiAlignJustify className="w-5 h-5 mr-2" />
                  </span>

                  <span>{cell.render("Cell")}</span>
                </div>
              </td>
            );
          } else {
            return (
              <td
                {...cell.getCellProps()}
                className={`${
                  maxHeight ? "w-full" : ""
                } px-5 py-4 text-sm text-gray-900  whitespace-nowrap dark:text-gray-300`}
              >
                {cell.render("Cell")}
              </td>
            );
          }
        })}
      </tr>
    );
  };

  const [newPageIndex, setNewPageIndex] = React.useState(0);

  React.useEffect(() => {
    setNewPageIndex(pageIndex + 1);
  }, [pageIndex]);

  // Listen for changes in pagination and use the state to fetch our new data
  React.useEffect(() => {
    fetchData({ pageSize, pageIndex });
  }, [fetchData, pageIndex, pageSize]);

  // listen for changes in selected row and use the state to set selected row to parent component
  React.useEffect(() => {
    const selectedIds = Object.keys(selectedRowIds);

    selectedRow(selectedIds);

    setRecords(data);
  }, [selectedRowIds, data, selectedRow]);

  // const [newhighlightData, setNewHighlightData] = React.useState(highlightData);

  React.useEffect(() => {
    // console.log(highlightData);

    if (highlightData.hasOwnProperty("uid")) {
      setRecords(data);
    }
  }, [highlightData, data]);

  const getHighlightBlinkStatus = (row) => {
    return row.original.hasOwnProperty(highlightDataKey)
      ? new Date() - new Date(row.original[highlightDataKey]) < 60000
      : false;
  };

  // Render the UI for your table
  return (
    <>
      {!loading && data.length > 0 && (
        <div className="flex flex-col">
          <div className="-my-2 sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div
                className={`overflow-hidden border-gray-300 shadow dark:bg-gray-900 xs:shadow-none ${
                  borderProperty ? "sm:rounded-lg border" : ""
                }  dark:border-gray-600 xs:border-0`}
              >
                {/* FOR DESKTOP AND LAPTOP DEVICES */}

                {isDesktopOrLaptop && (
                  <DndProvider backend={HTML5Backend}>
                    <table
                      {...getTableProps()}
                      className="min-w-full divide-y divide-gray-300 dark:divide-gray-600"
                    >
                      <thead
                        className={`${
                          maxHeight ? "block" : ""
                        } bg-gray-200 dark:bg-gray-600`}
                      >
                        {headerGroups.map((headerGroup) => (
                          <tr
                            {...headerGroup.getHeaderGroupProps()}
                            className={`hover:bg-gray-200 dark:hover:bg-gray-500 `}
                          >
                            {headerGroup.headers.map((column) => (
                              <th
                                {...column.getHeaderProps()}
                                scope="col"
                                style={column.style}
                                className={`${
                                  maxHeight ? "w-full" : ""
                                }  px-5 py-3 text-xs font-medium tracking-wider text-left text-gray-600 bg-gray-200 dark:bg-gray-600 dark:text-gray-300`}
                              >
                                {column.render("Header")}
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      <tbody
                        {...getTableBodyProps()}
                        id={uniqueID}
                        className={`${
                          maxHeight
                            ? "block h-80 overflow-x-hidden overflow-y-scroll"
                            : ""
                        } bg-white divide-y  divide-gray-300 dark:bg-gray-800 dark:divide-gray-600`}
                      >
                        {maxHeight && (
                          <InfiniteScroll
                            dataLength={data.length} //This is important field to render the next data
                            next={() => nextPage()}
                            hasMore={hasMore}
                            scrollableTarget={uniqueID}
                            loader={
                              <div className="flex items-center justify-center align-middle py-6">
                                <div className="w-6 h-6 border-t-2 border-b-2 border-gray-700 rounded-full dark:border-gray-500 animate-spin"></div>
                              </div>
                            }
                            endMessage={""}
                            className="divide-y divide-gray-300 dark:bg-gray-800 dark:divide-gray-600"
                          >
                            {rows.map(
                              (row, index) =>
                                prepareRow(row) || (
                                  <Row
                                    // key={row.id}
                                    style={{ width: 200 }}
                                    index={index}
                                    row={row}
                                    moveRow={moveRow}
                                    showHighlight={
                                      highlightData.hasOwnProperty("uid")
                                        ? highlightData.uid === row.id
                                        : false
                                    }
                                    {...row.getRowProps()}
                                  />
                                )
                            )}
                          </InfiniteScroll>
                        )}

                        {!maxHeight && (
                          <>
                            {rows.map((row, index) => {
                              // console.log(row, row.original.hasOwnProperty(highlightDataKey) && ((new Date() - new Date(row.original[highlightDataKey])) < 60000));
                              return (
                                prepareRow(row) || (
                                  <Row
                                    // key={row.id}
                                    index={index}
                                    row={row}
                                    moveRow={moveRow}
                                    showHighlight={
                                      highlightData.hasOwnProperty("uid")
                                        ? highlightData.uid === row.id
                                        : false
                                    }
                                    showHighlightBlink={getHighlightBlinkStatus(
                                      row
                                    )}
                                    {...row.getRowProps()}
                                  />
                                )
                              );
                            })}
                          </>
                        )}
                      </tbody>
                    </table>
                  </DndProvider>
                )}

                {/* FOR MOBILE AND TABLET DEVICES */}

                {isTabletOrMobile && (
                  <>
                    {maxHeight && (
                      <DndProvider backend={HTML5Backend}>
                        <table
                          {...getTableProps()}
                          className="min-w-full border divide-y divide-gray-300 dark:border-gray-500 -dark:divide-gray-600"
                        >
                          <thead
                            className={`${
                              maxHeight ? "block" : ""
                            } bg-gray-200 dark:bg-gray-600`}
                          >
                            {headerGroups.map((headerGroup) => (
                              <tr
                                {...headerGroup.getHeaderGroupProps()}
                                className="hover:bg-gray-200 dark:hover:bg-gray-500"
                              >
                                {headerGroup.headers.map((column) => (
                                  <th
                                    {...column.getHeaderProps()}
                                    scope="col"
                                    className={`${
                                      maxHeight ? "w-full" : ""
                                    } px-5 py-3 text-xs font-medium tracking-wider text-left text-gray-600 bg-gray-200 dark:bg-gray-600 dark:text-gray-300`}
                                  >
                                    {column.render("Header")}
                                  </th>
                                ))}
                              </tr>
                            ))}
                          </thead>
                          <tbody
                            {...getTableBodyProps()}
                            id={uniqueID}
                            className={`${
                              maxHeight
                                ? "block h-80 overflow-x-hidden overflow-y-scroll"
                                : ""
                            } bg-white divide-y divide-gray-300 dark:bg-gray-800 dark:divide-gray-600`}
                          >
                            <InfiniteScroll
                              dataLength={data.length} //This is important field to render the next data
                              next={() => nextPage()}
                              hasMore={hasMore}
                              scrollableTarget={uniqueID}
                              // scrollThreshold="100px"
                              loader={
                                <div className="flex items-center justify-center align-middle py-6">
                                  <div className="w-6 h-6 border-t-2 border-b-2 border-gray-700 rounded-full dark:border-gray-500 animate-spin"></div>
                                </div>
                              }
                              endMessage={""}
                              className="divide-y divide-gray-300 dark:bg-gray-800 dark:divide-gray-600"
                            >
                              {rows.map((row, i) => {
                                prepareRow(row);
                                return (
                                  <tr
                                    id={`row_${i}`}
                                    {...row.getRowProps()}
                                    className={`flex flex-col py-1 md:py-0 lg:py-0 
                                    ${
                                      highlightData.hasOwnProperty("uid") &&
                                      !(highlightData.uid === row.id) &&
                                      !getHighlightBlinkStatus(row) &&
                                      "hover:bg-gray-200 dark:hover:bg-gray-500"
                                    } 
                                    ${
                                      highlightData.hasOwnProperty("uid") &&
                                      highlightData.uid === row.id &&
                                      "bg-gray-200 dark:bg-gray-400"
                                    }
                                    ${
                                      getHighlightBlinkStatus(row) &&
                                      "bg-green-100 dark:bg-green-800"
                                    }
                                    `}
                                    onMouseMove={() =>
                                      setTimeout(() => {
                                        $(`#row_${i}`).removeClass(
                                          "bg-green-100 dark:bg-green-800"
                                        );
                                      }, 60000)
                                    }
                                  >
                                    {row.cells.map((cell) => {
                                      return (
                                        <td
                                          {...cell.getCellProps()}
                                          className={`${
                                            maxHeight ? "w-full" : ""
                                          } px-5 py-1 text-sm text-gray-900  whitespace-nowrap dark:text-gray-300`}
                                        >
                                          {cell.render("Cell")}
                                        </td>
                                      );
                                    })}
                                  </tr>
                                );
                              })}
                            </InfiniteScroll>
                          </tbody>
                        </table>
                      </DndProvider>
                    )}

                    {!maxHeight && (
                      <DndProvider backend={HTML5Backend}>
                        <div className="py-6 mx-auto max-w-7xl sm:py-2 xs:py-0 sm:px-6 lg:px-8">
                          <div className="mx-auto max-w-none">
                            {rows.map(
                              (row, index) =>
                                prepareRow(row) || (
                                  <Row
                                    // key={row.id}
                                    index={index}
                                    row={row}
                                    moveRow={moveRow}
                                    showHighlight={
                                      highlightData.hasOwnProperty("uid")
                                        ? highlightData.uid === row.id
                                        : false
                                    }
                                    showHighlightBlink={getHighlightBlinkStatus(
                                      row
                                    )}
                                    {...row.getRowProps()}
                                  />
                                )
                            )}
                          </div>
                        </div>
                      </DndProvider>
                    )}
                  </>
                )}
                {showFooter && (
                  <div className="flex items-center justify-between xs:py-0 py-3 bg-white border-t border-gray-300 rounded-b-lg xs:border-0 dark:bg-gray-800 xs:dark:bg-transparent xs:bg-transparent dark:border-gray-600 sm:px-6">
                    <div className="p-2 flex justify-between flex-1 sm:hidden">
                      <span
                        className="relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-300 rounded shadow-sm cursor-pointer hover:bg-gray-200 dark:border-gray-500 dark:bg-gray-900 dark:hover:bg-gray-800 dark:text-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 dark:focus:ring-offset-gray-900 focus:ring-blue-300"
                        onClick={() => previousPage()}
                        disabled={!canPreviousPage}
                      >
                        Previous
                      </span>
                      <span
                        className="relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-300 rounded shadow-sm cursor-pointer hover:bg-gray-200 dark:border-gray-500 dark:bg-gray-900 dark:hover:bg-gray-800 dark:text-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 dark:focus:ring-offset-gray-900 focus:ring-blue-300"
                        onClick={() => nextPage()}
                        disabled={!canNextPage}
                      >
                        Next
                      </span>
                    </div>

                    <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                      <div>
                        <p className="text-sm text-gray-900 dark:text-gray-300">
                          Page{" "}
                          <span className="font-medium">{pageIndex + 1}</span>{" "}
                          of{" "}
                          <span className="font-medium">
                            {pageOptions.length}
                          </span>
                        </p>
                      </div>
                      <div>
                        <nav
                          className="relative z-0 inline-flex -space-x-px rounded-md shadow-sm"
                          aria-label="Pagination"
                        >
                          <span
                            className="relative inline-flex items-center px-2 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 cursor-pointer rounded-l-md dark:border-gray-500 dark:hover:bg-gray-500 hover:bg-gray-200 dark:bg-gray-600 dark:text-gray-300"
                            onClick={(e) => {
                              e.preventDefault();
                              previousPage();
                            }}
                            disabled={!canPreviousPage}
                          >
                            <span className="sr-only">Previous</span>
                            <RiArrowLeftSLine
                              className="w-5 h-5"
                              aria-hidden="true"
                            />
                          </span>

                          <>
                            {newPageIndex - 2 > 0 && (
                              <span
                                className="relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-300 cursor-pointer dark:border-gray-500 dark:hover:bg-gray-500 hover:bg-gray-200 dark:bg-gray-600 dark:text-gray-300"
                                onClick={(e) => {
                                  e.preventDefault();
                                  gotoPage(newPageIndex - 3);
                                }}
                                disabled={!canNextPage}
                              >
                                {newPageIndex - 2}
                              </span>
                            )}

                            {newPageIndex - 1 > 0 && (
                              <span
                                className="relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-300 cursor-pointer dark:border-gray-500 dark:hover:bg-gray-500 hover:bg-gray-200 dark:bg-gray-600 dark:text-gray-300"
                                onClick={(e) => {
                                  e.preventDefault();
                                  gotoPage(newPageIndex - 2);
                                }}
                                disabled={!canNextPage}
                              >
                                {newPageIndex - 1}
                              </span>
                            )}

                            {newPageIndex - 1 === pageIndex && (
                              <span
                                onClick={(e) => e.preventDefault()}
                                className="relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-300 bg-blue-500 border border-blue-500 cursor-pointer dark:border-blue-500"
                              >
                                {newPageIndex}
                              </span>
                            )}

                            {newPageIndex + 1 <= pageOptions.length && (
                              <span
                                className="relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-300 cursor-pointer dark:border-gray-500 dark:hover:bg-gray-500 hover:bg-gray-200 dark:bg-gray-600 dark:text-gray-300"
                                onClick={(e) => {
                                  e.preventDefault();
                                  gotoPage(newPageIndex);
                                }}
                                disabled={!canNextPage}
                              >
                                {newPageIndex + 1}
                              </span>
                            )}

                            {newPageIndex + 2 <= pageOptions.length && (
                              <span
                                className="relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-300 cursor-pointer dark:border-gray-500 dark:hover:bg-gray-500 hover:bg-gray-200 dark:bg-gray-600 dark:text-gray-300"
                                onClick={(e) => {
                                  e.preventDefault();
                                  gotoPage(newPageIndex + 1);
                                }}
                                disabled={!canNextPage}
                              >
                                {newPageIndex + 2}
                              </span>
                            )}
                          </>

                          <span
                            className="relative inline-flex items-center px-2 py-2 text-sm font-medium text-gray-500 bg-white border border-gray-300 cursor-pointer rounded-r-md dark:border-gray-500 dark:hover:bg-gray-500 hover:bg-gray-200 dark:bg-gray-600 dark:text-gray-300"
                            onClick={(e) => {
                              e.preventDefault();
                              nextPage();
                            }}
                            disabled={!canNextPage}
                          >
                            <span className="sr-only">Next</span>
                            <RiArrowRightSLine
                              className="w-5 h-5"
                              aria-hidden="true"
                            />
                          </span>
                        </nav>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* No DATA INFO SECTION */}
      {!loading && data.length === 0 && !maxHeight && (
        <NoDataMessageComponent {...noDataInfo} />
      )}

      {!loading && data.length === 0 && maxHeight && (
        <div className="flex flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className="overflow-hidden bg-white border border-gray-300 shadow dark:bg-gray-900 xs:shadow-none sm:rounded-lg dark:border-gray-500 xs:border-0">
                {/* FOR DESKTOP AND LAPTOP DEVICES */}

                {isDesktopOrLaptop && (
                  <table
                    {...getTableProps()}
                    className="min-w-full divide-y divide-gray-300 dark:divide-gray-600"
                  >
                    <thead
                      className={`${
                        maxHeight ? "block" : ""
                      } bg-gray-200 dark:bg-gray-600`}
                    >
                      {headerGroups.map((headerGroup) => (
                        <tr
                          {...headerGroup.getHeaderGroupProps()}
                          className="hover:bg-gray-200 dark:hover:bg-gray-500"
                        >
                          {headerGroup.headers.map((column) => (
                            <th
                              {...column.getHeaderProps()}
                              scope="col"
                              className={`${
                                maxHeight ? "w-full" : ""
                              }  px-4 py-3 text-xs font-medium tracking-wider text-left text-gray-600 bg-gray-200 dark:bg-gray-600 dark:text-gray-300`}
                            >
                              {column.render("Header")}
                            </th>
                          ))}
                        </tr>
                      ))}
                    </thead>
                    <tbody
                      {...getTableBodyProps()}
                      id={uniqueID}
                      className={`${
                        maxHeight
                          ? "block h-80 overflow-x-hidden overflow-y-scroll"
                          : ""
                      } bg-white divide-y divide-gray-300 dark:bg-gray-800 dark:divide-gray-600`}
                    >
                      <NoDataMessageComponent {...noDataInfo} />
                    </tbody>
                  </table>
                )}

                {/* FOR MOBILE AND TABLET DEVICES */}

                {isTabletOrMobile && (
                  <>
                    {maxHeight && (
                      <DndProvider backend={HTML5Backend}>
                        <table
                          {...getTableProps()}
                          className="min-w-full border border-gray-300 divide-y divide-gray-300 dark:border-gray-500 -dark:divide-gray-600"
                        >
                          <thead
                            className={`${
                              maxHeight ? "block" : ""
                            } bg-gray-200 dark:bg-gray-600`}
                          >
                            {headerGroups.map((headerGroup) => (
                              <tr
                                {...headerGroup.getHeaderGroupProps()}
                                className="hover:bg-gray-200 dark:hover:bg-gray-500"
                              >
                                {headerGroup.headers.map((column) => (
                                  <th
                                    {...column.getHeaderProps()}
                                    scope="col"
                                    className={`${
                                      maxHeight ? "w-full" : ""
                                    } px-5 py-3 text-xs font-medium tracking-wider text-left text-gray-600 bg-gray-200 dark:bg-gray-600 dark:text-gray-300`}
                                  >
                                    {column.render("Header")}
                                  </th>
                                ))}
                              </tr>
                            ))}
                          </thead>
                          <tbody
                            {...getTableBodyProps()}
                            id={uniqueID}
                            className={`${
                              maxHeight
                                ? "block h-80 overflow-x-hidden overflow-y-scroll"
                                : ""
                            } bg-white divide-y divide-gray-300 dark:bg-gray-800 dark:divide-gray-600`}
                          >
                            <NoDataMessageComponent {...noDataInfo} />
                          </tbody>
                        </table>
                      </DndProvider>
                    )}

                    {!maxHeight && (
                      <DndProvider backend={HTML5Backend}>
                        <div className="py-6 mx-auto max-w-7xl sm:py-2 xs:py-0 sm:px-6 lg:px-8">
                          <div className="mx-auto max-w-none">
                            <NoDataMessageComponent {...noDataInfo} />
                          </div>
                        </div>
                      </DndProvider>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
